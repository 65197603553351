import { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row, Table } from 'reactstrap';
import useSliceList from '../../hooks/useSliceList';
import { excelDL } from '../../util/excel';
import GridTable from '../Common/GridTable';
import PagingBox from '../Common/PagingBox';

const ROLE_ADMIN = 'ROLE_ADMIN';

const ItemAcceptTable = ({
  data,
  evClickBtn,
  evClickBtn2,
  excelData,
  fileName,
  btn,
  role,
  onCheckAll,
  evCheckItem,
}) => {
  const [page, setPage] = useState(1);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const { columns, rows } = data;
  const totalLength = data.rows.length || 0;
  const listSize = 20;
  const rangeSize = 10;
  const onChangePage = p => {
    setPage(p);
  };

  const { sliceList } = useSliceList(rows, page, listSize);

  const onCheckAllbox = list => {
    // console.log('asd');
    // console.log(list);
    // console.log('asd');
    setIsCheckAll(b => !b);
    onCheckAll(list, !isCheckAll);
  };

  const adminColumns = [
    {
      label: (
        <input
          type="checkbox"
          onChange={() => onCheckAllbox(sliceList)}
          checked={isCheckAll}
        />
      ), // 전체선택은 개편 후
      field: 'itemCheckBox',
      witdh: 80,
    },
    ...columns,
  ];

  const excelBtn = () => {
    excelDL(fileName, excelData);
  };

  useEffect(() => {
    if (sliceList.length > 0) {
      const noselected = !sliceList.find(item => !item.isChecked);
      setIsCheckAll(noselected);
    }
  }, [sliceList]);

  if (role !== ROLE_ADMIN) {
    return (
      <GridTable
        data={{
          columns: data.columns,
          rows: data.rows,
        }}
        evClickBtn={null}
        evClickBtn2={null}
        excelData={excelData}
        fileName={fileName}
        btn={btn}
      />
    );
  }

  return (
    <Card>
      <CardBody>
        <div className="d-flex justify-content-between align-items-center">
          <Row className="align-items-center">
            {excelData && (
              <>
                <Col>
                  <span className="h4">총 {data.rows.length}건 조회</span>
                </Col>
                <button className="btn btn-success" onClick={excelBtn}>
                  엑셀 다운로드
                </button>
              </>
            )}
          </Row>
          {evClickBtn && (
            <Row className="">
              {evClickBtn2 && (
                <Col>
                  <button
                    className="btn btn-sm waves-effect waves-light btn-danger"
                    onClick={evClickBtn2}
                  >
                    반려
                  </button>
                </Col>
              )}
              <Col>
                <button
                  className="btn btn-sm waves-effect waves-light btn-primary"
                  onClick={evClickBtn}
                >
                  승인
                </button>
              </Col>
            </Row>
          )}
        </div>
        {/* 여기에 테이블 들어가야댐 */}
        <Table className="mt-2">
          <thead>
            <tr>
              {adminColumns.map(c => (
                <th width={`${c.width}px`} key={c.label}>
                  {c.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.rows &&
              sliceList.map(r => (
                <tr key={r.index}>
                  <td className="align-middle">
                    <input
                      key={r.itemId}
                      type="checkbox"
                      name="itemCheckBox"
                      value={r.itemId}
                      className="form-control"
                      onChange={() => evCheckItem(r.itemId)}
                      checked={r.isChecked}
                    />
                  </td>
                  <td className="align-middle">{r.itemId}</td>
                  <td className="align-middle">{r.bizName}</td>
                  <td className="align-middle">{r.itemName}</td>
                  <td className="align-middle">{r.infoModel}</td>
                  <td className="align-middle">{r.optionStock}</td>
                  <td className="align-middle">{r.statusOpen}</td>
                  <td className="align-middle">{r.statusSell}</td>
                  <td className="align-middle">{r.shipCost}</td>
                  <td className="align-middle">{r.priceSupl}</td>
                  <td className="align-middle">{r.priceSell}</td>
                  <td className="align-middle">{r.grossMargin}</td>
                  <td className="align-middle">{r.createdAt}</td>
                  <td className="align-middle">{r.statusAccept}</td>
                </tr>
              ))}
          </tbody>
        </Table>
        <PagingBox
          currentPage={page}
          totalLength={totalLength}
          listSize={listSize}
          onChangePage={onChangePage}
          rangeSize={rangeSize}
        />
      </CardBody>
    </Card>
  );
};

export default ItemAcceptTable;
