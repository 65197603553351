import axios from 'axios';

const serverURI = `http://localhost:8081/api/`;

// export const getCodeList = async => {
export default async function getCodeList(parentCode, codeLabel) {
  const query = `/api/code`;

  const params = {};
  if (parentCode) params.parentCode = parentCode;
  if (codeLabel) params.codeLabel = codeLabel;

  try {
    return await axios.get(query, { params });
    // const response = await axios.get(query);
    // return response.data;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function getCodeInfo(codeId) {
  const query = `/api/code/${codeId}`;

  try {
    return await axios.get(query);
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function insertCode(codeInfo) {
  const query = `/api/code/`;

  try {
    return await axios.post(query, codeInfo);
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function updateCode(codeInfo) {
  const query = `/api/code/`;

  try {
    return await axios.put(query, codeInfo);
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function deleteCode(codeId) {
  const query = `/api/code/`;

  const params = { codeId };

  try {
    return await axios.delete(query, { params });
  } catch (e) {
    console.error(e);
    return null;
  }
}
