import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import SettingMenu from '../Shared/SettingMenu';

class IconThemify extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Themify Icons</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/#">Veltrix</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/#">Icons</Link>
                  </li>
                  <li className="breadcrumb-item active">Themify Icons</li>
                </ol>
              </div>
            </Col>

            <Col sm="6">
              <div className="float-right d-none d-md-block">
                <SettingMenu />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <h4 className="card-title">Arrows & Direction Icons</h4>
                  <p className="card-title-desc">
                    Use <code>&lt;i class="ti-arrow-up"&gt;&lt;/i&gt;</code>.
                  </p>

                  <Row className="icon-demo-content">
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-arrow-up" /> ti-arrow-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-arrow-right" /> ti-arrow-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-arrow-left" /> ti-arrow-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-arrow-down" /> ti-arrow-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-arrows-vertical" /> ti-arrows-vertical
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-arrows-horizontal" />{' '}
                      ti-arrows-horizontal
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-angle-up" /> ti-angle-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-angle-right" /> ti-angle-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-angle-left" /> ti-angle-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-angle-down" /> ti-angle-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-angle-double-up" /> ti-angle-double-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-angle-double-right" />{' '}
                      ti-angle-double-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-angle-double-left" />{' '}
                      ti-angle-double-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-angle-double-down" />{' '}
                      ti-angle-double-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-move" /> ti-move
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-fullscreen" /> ti-fullscreen
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-arrow-top-right" /> ti-arrow-top-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-arrow-top-left" /> ti-arrow-top-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-arrow-circle-up" /> ti-arrow-circle-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-arrow-circle-right" />{' '}
                      ti-arrow-circle-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-arrow-circle-left" />{' '}
                      ti-arrow-circle-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-arrow-circle-down" />{' '}
                      ti-arrow-circle-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-arrows-corner" /> ti-arrows-corner
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-split-v" /> ti-split-v
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-split-v-alt" /> ti-split-v-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-split-h" /> ti-split-h
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-hand-point-up" /> ti-hand-point-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-hand-point-right" /> ti-hand-point-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-hand-point-left" /> ti-hand-point-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-hand-point-down" /> ti-hand-point-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-back-right" /> ti-back-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-back-left" /> ti-back-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-exchange-vertical" />{' '}
                      ti-exchange-vertical
                    </Col>
                  </Row>

                  <h4 className="card-title mb-4 mt-5">Web App Icons</h4>
                  <Row className="icon-demo-content">
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-wand" /> ti-wand
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-save" /> ti-save
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-save-alt" /> ti-save-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-direction" /> ti-direction
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-direction-alt" /> ti-direction-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-user" /> ti-user
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-link" /> ti-link
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-unlink" /> ti-unlink
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-trash" /> ti-trash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-target" /> ti-target
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-tag" /> ti-tag
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-desktop" /> ti-desktop
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-tablet" /> ti-tablet
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-mobile" /> ti-mobile
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-email" /> ti-email
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-star" /> ti-star
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-spray" /> ti-spray
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-signal" /> ti-signal
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-shopping-cart" /> ti-shopping-cart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-shopping-cart-full" />{' '}
                      ti-shopping-cart-full
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-settings" /> ti-settings
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-search" /> ti-search
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-zoom-in" /> ti-zoom-in
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-zoom-out" /> ti-zoom-out
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-cut" /> ti-cut
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-ruler" /> ti-ruler
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-ruler-alt-2" /> ti-ruler-alt-2
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-ruler-pencil" /> ti-ruler-pencil
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-ruler-alt" /> ti-ruler-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-bookmark" /> ti-bookmark
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-bookmark-alt" /> ti-bookmark-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-reload" /> ti-reload
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-plus" /> ti-plus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-minus" /> ti-minus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-close" /> ti-close
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-pin" /> ti-pin
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-pencil" /> ti-pencil
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-pencil-alt" /> ti-pencil-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-paint-roller" /> ti-paint-roller
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-paint-bucket" /> ti-paint-bucket
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-na" /> ti-na
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-medall" /> ti-medall
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-medall-alt" /> ti-medall-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-marker" /> ti-marker
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-marker-alt" /> ti-marker-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-lock" /> ti-lock
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-unlock" /> ti-unlock
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-location-arrow" /> ti-location-arrow
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout" /> ti-layout
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layers" /> ti-layers
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layers-alt" /> ti-layers-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-key" /> ti-key
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-image" /> ti-image
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-heart" /> ti-heart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-heart-broken" /> ti-heart-broken
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-hand-stop" /> ti-hand-stop
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-hand-open" /> ti-hand-open
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-hand-drag" /> ti-hand-drag
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-flag" /> ti-flag
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-flag-alt" /> ti-flag-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-flag-alt-2" /> ti-flag-alt-2
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-eye" /> ti-eye
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-import" /> ti-import
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-export" /> ti-export
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-cup" /> ti-cup
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-crown" /> ti-crown
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-comments" /> ti-comments
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-comment" /> ti-comment
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-comment-alt" /> ti-comment-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-thought" /> ti-thought
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-clip" /> ti-clip
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-check" /> ti-check
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-check-box" /> ti-check-box
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-camera" /> ti-camera
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-announcement" /> ti-announcement
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-brush" /> ti-brush
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-brush-alt" /> ti-brush-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-palette" /> ti-palette
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-briefcase" /> ti-briefcase
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-bolt" /> ti-bolt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-bolt-alt" /> ti-bolt-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-blackboard" /> ti-blackboard
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-bag" /> ti-bag
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-world" /> ti-world
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-wheelchair" /> ti-wheelchair
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-car" /> ti-car
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-truck" /> ti-truck
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-timer" /> ti-timer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-ticket" /> ti-ticket
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-thumb-up" /> ti-thumb-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-thumb-down" /> ti-thumb-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-stats-up" /> ti-stats-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-stats-down" /> ti-stats-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-shine" /> ti-shine
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-shift-right" /> ti-shift-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-shift-left" /> ti-shift-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-shift-right-alt" /> ti-shift-right-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-shift-left-alt" /> ti-shift-left-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-shield" /> ti-shield
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-notepad" /> ti-notepad
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-server" /> ti-server
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-pulse" /> ti-pulse
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-printer" /> ti-printer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-power-off" /> ti-power-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-plug" /> ti-plug
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-pie-chart" /> ti-pie-chart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-panel" /> ti-panel
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-package" /> ti-package
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-music" /> ti-music
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-music-alt" /> ti-music-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-mouse" /> ti-mouse
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-mouse-alt" /> ti-mouse-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-money" /> ti-money
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-microphone" /> ti-microphone
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-menu" /> ti-menu
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-menu-alt" /> ti-menu-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-map" /> ti-map
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-map-alt" /> ti-map-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-location-pin" /> ti-location-pin
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-light-bulb" /> ti-light-bulb
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-info" /> ti-info
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-infinite" /> ti-infinite
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-id-badge" /> ti-id-badge
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-hummer" /> ti-hummer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-home" /> ti-home
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-help" /> ti-help
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-headphone" /> ti-headphone
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-harddrives" /> ti-harddrives
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-harddrive" /> ti-harddrive
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-gift" /> ti-gift
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-game" /> ti-game
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-filter" /> ti-filter
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-files" /> ti-files
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-file" /> ti-file
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-zip" /> ti-zip
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-folder" /> ti-folder
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-envelope" /> ti-envelope
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-dashboard" /> ti-dashboard
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-cloud" /> ti-cloud
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-cloud-up" /> ti-cloud-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-cloud-down" /> ti-cloud-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-clipboard" /> ti-clipboard
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-calendar" /> ti-calendar
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-book" /> ti-book
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-bell" /> ti-bell
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-basketball" /> ti-basketball
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-bar-chart" /> ti-bar-chart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-bar-chart-alt" /> ti-bar-chart-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-archive" /> ti-archive
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-anchor" /> ti-anchor
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-alert" /> ti-alert
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-alarm-clock" /> ti-alarm-clock
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-agenda" /> ti-agenda
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-write" /> ti-write
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-wallet" /> ti-wallet
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-video-clapper" /> ti-video-clapper
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-video-camera" /> ti-video-camera
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-vector" /> ti-vector
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-support" /> ti-support
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-stamp" /> ti-stamp
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-slice" /> ti-slice
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-shortcode" /> ti-shortcode
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-receipt" /> ti-receipt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-pin2" /> ti-pin2
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-pin-alt" /> ti-pin-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-pencil-alt2" /> ti-pencil-alt2
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-eraser" /> ti-eraser
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-more" /> ti-more
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-more-alt" /> ti-more-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-microphone-alt" /> ti-microphone-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-magnet" /> ti-magnet
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-line-double" /> ti-line-double
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-line-dotted" /> ti-line-dotted
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-line-dashed" /> ti-line-dashed
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-ink-pen" /> ti-ink-pen
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-info-alt" /> ti-info-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-help-alt" /> ti-help-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-headphone-alt" /> ti-headphone-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-gallery" /> ti-gallery
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-face-smile" /> ti-face-smile
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-face-sad" /> ti-face-sad
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-credit-card" /> ti-credit-card
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-comments-smiley" /> ti-comments-smiley
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-time" /> ti-time
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-share" /> ti-share
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-share-alt" /> ti-share-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-rocket" /> ti-rocket
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-new-window" /> ti-new-window
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-rss" /> ti-rss
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-rss-alt" /> ti-rss-alt
                    </Col>
                  </Row>

                  <h4 className="card-title mt-5 mb-4">Control Icons</h4>

                  <Row className="icon-demo-content">
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-control-stop" /> ti-control-stop
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-control-shuffle" /> ti-control-shuffle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-control-play" /> ti-control-play
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-control-pause" /> ti-control-pause
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-control-forward" /> ti-control-forward
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-control-backward" /> ti-control-backward
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-volume" /> ti-volume
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-control-skip-forward" />{' '}
                      ti-control-skip-forward
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-control-skip-backward" />{' '}
                      ti-control-skip-backward
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-control-record" /> ti-control-record
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-control-eject" /> ti-control-eject
                    </Col>
                  </Row>

                  <h4 className="card-title mt-5 mb-4">Text Editor</h4>

                  <Row className="icon-demo-content">
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-paragraph" /> ti-paragraph
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-uppercase" /> ti-uppercase
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-underline" /> ti-underline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-text" /> ti-text
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-Italic" /> ti-Italic
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-smallcap" /> ti-smallcap
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-list" /> ti-list
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-list-ol" /> ti-list-ol
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-align-right" /> ti-align-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-align-left" /> ti-align-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-align-justify" /> ti-align-justify
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-align-center" /> ti-align-center
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-quote-right" /> ti-quote-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-quote-left" /> ti-quote-left
                    </Col>
                  </Row>

                  <h4 className="card-title mt-5 mb-4">Layout Icons</h4>

                  <Row className="icon-demo-content">
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-width-full" />{' '}
                      ti-layout-width-full
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-width-default" />{' '}
                      ti-layout-width-default
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-width-default-alt" />{' '}
                      ti-layout-width-default-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-tab" /> ti-layout-tab
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-tab-window" />{' '}
                      ti-layout-tab-window
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-tab-v" /> ti-layout-tab-v
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-tab-min" /> ti-layout-tab-min
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-slider" /> ti-layout-slider
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-slider-alt" />{' '}
                      ti-layout-slider-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-sidebar-right" />{' '}
                      ti-layout-sidebar-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-sidebar-none" />{' '}
                      ti-layout-sidebar-none
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-sidebar-left" />{' '}
                      ti-layout-sidebar-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-placeholder" />{' '}
                      ti-layout-placeholder
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-menu" /> ti-layout-menu
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-menu-v" /> ti-layout-menu-v
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-menu-separated" />{' '}
                      ti-layout-menu-separated
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-menu-full" /> ti-layout-menu-full
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-media-right" />{' '}
                      ti-layout-media-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-media-right-alt" />{' '}
                      ti-layout-media-right-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-media-overlay" />{' '}
                      ti-layout-media-overlay
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-media-overlay-alt" />{' '}
                      ti-layout-media-overlay-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-media-overlay-alt-2" />{' '}
                      ti-layout-media-overlay-alt-2
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-media-left" />{' '}
                      ti-layout-media-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-media-left-alt" />{' '}
                      ti-layout-media-left-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-media-center" />{' '}
                      ti-layout-media-center
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-media-center-alt" />{' '}
                      ti-layout-media-center-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-list-thumb" />{' '}
                      ti-layout-list-thumb
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-list-thumb-alt" />{' '}
                      ti-layout-list-thumb-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-list-post" /> ti-layout-list-post
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-list-large-image" />{' '}
                      ti-layout-list-large-image
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-line-solid" />{' '}
                      ti-layout-line-solid
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-grid4" /> ti-layout-grid4
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-grid3" /> ti-layout-grid3
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-grid2" /> ti-layout-grid2
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-grid2-thumb" />{' '}
                      ti-layout-grid2-thumb
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-cta-right" /> ti-layout-cta-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-cta-left" /> ti-layout-cta-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-cta-center" />{' '}
                      ti-layout-cta-center
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-cta-btn-right" />{' '}
                      ti-layout-cta-btn-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-cta-btn-left" />{' '}
                      ti-layout-cta-btn-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-column4" /> ti-layout-column4
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-column3" /> ti-layout-column3
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-column2" /> ti-layout-column2
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-accordion-separated" />{' '}
                      ti-layout-accordion-separated
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-accordion-merged" />{' '}
                      ti-layout-accordion-merged
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-accordion-list" />{' '}
                      ti-layout-accordion-list
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-widgetized" /> ti-widgetized
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-widget" /> ti-widget
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-widget-alt" /> ti-widget-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-view-list" /> ti-view-list
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-view-list-alt" /> ti-view-list-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-view-grid" /> ti-view-grid
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-upload" /> ti-upload
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-download" /> ti-download
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-loop" /> ti-loop
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-sidebar-2" /> ti-layout-sidebar-2
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-grid4-alt" /> ti-layout-grid4-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-grid3-alt" /> ti-layout-grid3-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-grid2-alt" /> ti-layout-grid2-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-column4-alt" />{' '}
                      ti-layout-column4-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-column3-alt" />{' '}
                      ti-layout-column3-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-layout-column2-alt" />{' '}
                      ti-layout-column2-alt
                    </Col>
                  </Row>

                  <h4 className="card-title mt-5 mb-4">Brand Icons</h4>

                  <Row className="icon-demo-content">
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-flickr" /> ti-flickr
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-flickr-alt" /> ti-flickr-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-instagram" /> ti-instagram
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-google" /> ti-google
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-github" /> ti-github
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-facebook" /> ti-facebook
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-dropbox" /> ti-dropbox
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-dropbox-alt" /> ti-dropbox-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-dribbble" /> ti-dribbble
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-apple" /> ti-apple
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-android" /> ti-android
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-yahoo" /> ti-yahoo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-trello" /> ti-trello
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-stack-overflow" /> ti-stack-overflow
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-soundcloud" /> ti-soundcloud
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-sharethis" /> ti-sharethis
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-sharethis-alt" /> ti-sharethis-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-reddit" /> ti-reddit
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-microsoft" /> ti-microsoft
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-microsoft-alt" /> ti-microsoft-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-linux" /> ti-linux
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-jsfiddle" /> ti-jsfiddle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-joomla" /> ti-joomla
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-html5" /> ti-html5
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-css3" /> ti-css3
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-drupal" /> ti-drupal
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-wordpress" /> ti-wordpress
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-tumblr" /> ti-tumblr
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-tumblr-alt" /> ti-tumblr-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-skype" /> ti-skype
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-youtube" /> ti-youtube
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-vimeo" /> ti-vimeo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-vimeo-alt" /> ti-vimeo-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-twitter" /> ti-twitter
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-twitter-alt" /> ti-twitter-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-linkedin" /> ti-linkedin
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-pinterest" /> ti-pinterest
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-pinterest-alt" /> ti-pinterest-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-themify-logo" /> ti-themify-logo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-themify-favicon" /> ti-themify-favicon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ti-themify-favicon-alt" />{' '}
                      ti-themify-favicon-alt
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default IconThemify;
