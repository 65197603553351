import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { useSelector } from 'react-redux';

import ListHeader from '../../components/Common/ListHeader';
import GridTable from '../../components/Common/GridTable';
import FormGenerator from '../../util/FormGenerator';
import Tag from '../../api/tag';

const headerInfo = {
  parentLabel: '태그 관리',
  parentLink: '/tag',
  current: '태그 목록',
  btnLabel: '태그 등록',
  btnIcon: 'mdi mdi-account-edit mr-2',
};

const formRowList = [
  [
    {
      type: 'label',
      label: '태그명',
    },
    {
      type: 'input',
      mdSize: 4,
      feild: 'tag',
    },
  ],
];

const defaultSearchForm = {
  tag: '',
};

const tagCols = [
  {
    label: 'Id',
    field: 'tagId',
    width: 105,
  },
  {
    label: '카테고리 코드',
    field: 'tag',
  },
  {
    label: '등록일',
    field: 'createdAt',
    width: 160,
  },
];

const TagList = ({ history }) => {
  /* search Form */
  const [searchForm, setSearchForm] = useState(defaultSearchForm);
  const { tokenValid } = useSelector(state => ({
    tokenValid: state.Token.data,
  }));

  const evChangeSearch = ({ target }) => {
    const { name, value } = target;

    setSearchForm({
      ...searchForm,
      [name]: value,
    });
  };

  /* Data Grid */
  const [tagRows, setTagRows] = useState([]);

  /* Button Action */
  const evClickInit = () => {
    setSearchForm({
      ...defaultSearchForm,
    });
  };

  const evClickAdd = () => history.push('/tag/add');

  const evClickSearch = useCallback(() => {
    Tag.getList(searchForm).then(response => {
      setTagRows(
        response.map(tag => ({
          ...tag,
          createdAt: tag.createdAt.replace('T', ' '),
          tag: <Link to={`/tag/${tag.tagId}`}>{tag.tag}</Link>,
        })),
      );
    });
  }, [searchForm]);

  // run when side effect
  useEffect(() => {
    if (tokenValid) {
      Tag.getList(defaultSearchForm).then(response => {
        setTagRows(
          response.map(tag => ({
            ...tag,
            createdAt: tag.createdAt.replace('T', ' '),
            tag: <Link to={`/tag/${tag.tagId}`}>{tag.tag}</Link>,
          })),
        );
      });
    }
  }, [tokenValid]);

  return (
    <>
      <div className="container-fluid">
        <ListHeader headerInfo={headerInfo} onClick={evClickAdd} />

        <Row>
          <Col>
            <Card>
              <CardBody>
                <FormGenerator
                  formData={searchForm}
                  changeEvent={evChangeSearch}
                  formRowList={formRowList}
                />

                <div className="form-group line align-items-center justify-content-center">
                  <button
                    onClick={evClickSearch}
                    className="btn btn-primary waves-effect waves-light"
                  >
                    검색
                  </button>
                  <button
                    onClick={evClickInit}
                    className="btn btn-secondary waves-effect waves-light"
                  >
                    초기화
                  </button>
                </div>
              </CardBody>
            </Card>

            <GridTable data={{ columns: tagCols, rows: tagRows }} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TagList;
