import axios from './axios';
import { BIZ_MANAGER } from './urls';

export default {
  async getList(params) {
    try {
      return await axios.get(BIZ_MANAGER.GET_LIST, { params });
    } catch (e) {
      return e;
    }
  },

  async getOne(id) {
    try {
      return await axios.get(BIZ_MANAGER.GET_OR_DELETE + id);
    } catch (e) {
      return e;
    }
  },

  async insert(params) {
    try {
      return await axios.post(BIZ_MANAGER.UPSERT, params);
    } catch (e) {
      return e;
    }
  },

  async update(params) {
    try {
      return await axios.put(BIZ_MANAGER.UPSERT, params);
    } catch (e) {
      return e;
    }
  },

  async delete(id) {
    try {
      return await axios.delete(BIZ_MANAGER.GET_OR_DELETE + id);
    } catch (e) {
      return e;
    }
  },

  async deleteList(params) {
    try {
      return await axios.put(BIZ_MANAGER.DELETE_LIST, params);
    } catch (e) {
      return e;
    }
  },
};
