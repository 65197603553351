import { Card, CardBody, Col, Row } from 'reactstrap';

function ItemImageForm({
  fileInput1,
  fileInput2,
  fileInput3,
  fileInput4,
  fileInput5,
  itemImages,
}) {
  const fileUrl =
    'https://s3.ap-northeast-2.amazonaws.com/files.ai-q.co.kr/shop/thumbnail/';
  const order0 = itemImages?.find(i => i.oder === '0');
  const order1 = itemImages?.find(i => i.oder === '1');
  const order2 = itemImages?.find(i => i.oder === '2');
  const order3 = itemImages?.find(i => i.oder === '3');
  const order4 = itemImages?.find(i => i.oder === '4');
  return (
    <Card>
      <CardBody>
        <Row className="mb-3">
          <Col>
            <h4
              style={{
                borderBottom: '1px solid #EBEBEB',
                paddingBottom: '0.5rem',
              }}
            >
              상품 이미지 등록
              <span
                style={{
                  fontSize: '12px',
                  marginLeft: '8px',
                }}
              >
                권장 사이즈 (500 x 500)
              </span>
            </h4>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={12}>
            <Row className="align-items-center">
              <Col lg={2}>
                <label
                  htmlFor="fileInput1"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  상품 대표 이미지
                </label>
              </Col>
              <Col lg={6}>
                {order0 && (
                  <button
                    className="btn btn-primary mr-2"
                    onClick={() =>
                      window.open(fileUrl + order0.imgName, '_blank')
                    }
                  >
                    이전 이미지
                  </button>
                )}
                <input
                  id="fileInput1"
                  name="fileInput1"
                  type="file"
                  ref={fileInput1}
                  accept="image/jpg, image/jpeg, image/png"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={12}>
            <Row className="align-items-center">
              <Col lg={2}>
                <label
                  htmlFor="fileInput2"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  상품 이미지 2
                </label>
              </Col>
              <Col lg={6}>
                {order1 && (
                  <button
                    className="btn btn-primary mr-2"
                    onClick={() =>
                      window.open(
                        order1.imgExt === '1'
                          ? order1.imgName
                          : fileUrl + order1.imgName,
                        '_blank',
                      )
                    }
                  >
                    이전 이미지
                  </button>
                )}
                <input
                  id="fileInput2"
                  name="fileInput2"
                  type="file"
                  ref={fileInput2}
                  accept="image/jpg, image/jpeg, image/png"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={12}>
            <Row className="align-items-center">
              <Col lg={2}>
                <label
                  htmlFor="fileInput3"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  상품 이미지 3
                </label>
              </Col>
              <Col lg={6}>
                {order2 && (
                  <button
                    className="btn btn-primary mr-2"
                    onClick={() =>
                      window.open(
                        order2.imgExt === '1'
                          ? order2.imgName
                          : fileUrl + order2.imgName,
                        '_blank',
                      )
                    }
                  >
                    이전 이미지
                  </button>
                )}
                <input
                  id="fileInput3"
                  name="fileInput3"
                  type="file"
                  ref={fileInput3}
                  accept="image/jpg, image/jpeg, image/png"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={12}>
            <Row className="align-items-center">
              <Col lg={2}>
                <label
                  htmlFor="fileInput4"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  상품 이미지 4
                </label>
              </Col>
              <Col lg={6}>
                {order3 && (
                  <button
                    className="btn btn-primary mr-2"
                    onClick={() =>
                      window.open(
                        order3.imgExt === '1'
                          ? order3.imgName
                          : fileUrl + order3.imgName,
                        '_blank',
                      )
                    }
                  >
                    이전 이미지
                  </button>
                )}
                <input
                  id="fileInput4"
                  name="fileInput4"
                  type="file"
                  ref={fileInput4}
                  accept="image/jpg, image/jpeg, image/png"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={12}>
            <Row className="align-items-center">
              <Col lg={2}>
                <label
                  htmlFor="fileInput5"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  상품 이미지 5
                </label>
              </Col>
              <Col lg={6}>
                {order4 && (
                  <button
                    className="btn btn-primary mr-2"
                    onClick={() =>
                      window.open(
                        order4.imgExt === '1'
                          ? order4.imgName
                          : fileUrl + order4.imgName,
                        '_blank',
                      )
                    }
                  >
                    이전 이미지
                  </button>
                )}
                <input
                  id="fileInput5"
                  name="fileInput5"
                  type="file"
                  ref={fileInput5}
                  accept="image/jpg, image/jpeg, image/png"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default ItemImageForm;
