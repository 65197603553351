import { Card, CardBody, Col, Row } from 'reactstrap';

function ItemOptionInfoForm({
  optionForm,
  isOptionEnabel,
  evOpenOptionForm,
  evAddOption,
  evRemoveOption,
  evChangeOptionForm,
  evAddSubOption,
  evRemoveSubOption,
  evChangeSubOption,
}) {
  const deleteBtn = () => (window.innerWidth > 512 ? '제거' : 'X');
  return (
    <Card>
      <CardBody>
        <Row className="mb-3">
          <Col>
            <h4
              style={{
                borderBottom: '1px solid #EBEBEB',
                paddingBottom: '0.5rem',
              }}
            >
              옵션 및 재고 정보
            </h4>
          </Col>
        </Row>
        <Row className="align-items-center mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col sm={4}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  옵션
                </label>
              </Col>
              <Col sm={8}>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={!isOptionEnabel ? evOpenOptionForm : evAddOption}
                >
                  추가
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
        {isOptionEnabel ? (
          <div className="table-responsive">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th>번호</th>
                  <th>옵션명</th>
                  <th>추가 옵션명</th>
                  <th>추가금액</th>
                  <th>재고량</th>
                  <th>비고</th>
                </tr>
              </thead>
              <tbody>
                {optionForm.map(o => (
                  <tr key={o.index}>
                    <th scope="row">{o.index + 1}</th>
                    <td>
                      <input
                        key={o.index}
                        name="optionName"
                        className="form-control mb-1"
                        value={o.optionName || ''}
                        onChange={e => evChangeOptionForm(e, o.index)}
                      />
                    </td>
                    <td>
                      {o.subOption.map(s =>
                        o.subOption.length === 1 ? (
                          <Row key={s.index}>
                            <Col sm={9}>
                              <input
                                name="subName"
                                className="form-control mb-1"
                                placeholder="옵션을 추가해주세요"
                                value=""
                                readOnly
                              />
                            </Col>
                            <Col sm={3}>
                              <button
                                key={s.index}
                                className="btn btn-primary"
                                onClick={() => evAddSubOption(o.index)}
                              >
                                추가
                              </button>
                            </Col>
                          </Row>
                        ) : (
                          <Row key={s.index}>
                            <Col
                              sm={s.index !== o.subOption.length - 1 ? 12 : 9}
                            >
                              <input
                                key={s.index}
                                name="subName"
                                className="form-control mb-1"
                                value={s.subName || ''}
                                onChange={e =>
                                  evChangeSubOption(e, o.index, s.index)
                                }
                              />
                            </Col>
                            {s.index === o.subOption.length - 1 ? (
                              <Col sm={3} key={s.index}>
                                <button
                                  key={s.index}
                                  className="btn btn-primary"
                                  onClick={() => evAddSubOption(o.index)}
                                >
                                  추가
                                </button>
                              </Col>
                            ) : undefined}
                          </Row>
                        ),
                      )}
                    </td>
                    <td>
                      {o.subOption.map(s => (
                        <Row key={s.index}>
                          <Col>
                            <input
                              type="number"
                              name="optionPrice"
                              className="form-control mb-1"
                              value={0}
                              onChange={e =>
                                evChangeSubOption(e, o.index, s.index)
                              }
                              readOnly
                            />
                          </Col>
                        </Row>
                      ))}
                    </td>
                    <td>
                      {o.subOption.map(s => (
                        <Row key={s.index}>
                          <Col>
                            <input
                              type="number"
                              name="optionStock"
                              className="form-control mb-1"
                              value={s.optionStock}
                              onChange={e =>
                                evChangeSubOption(e, o.index, s.index)
                              }
                            />
                          </Col>
                        </Row>
                      ))}
                    </td>
                    <td>
                      {o.subOption.map(s =>
                        o.subOption.length === 1 ? (
                          <Row key={s.index}>
                            <button
                              className="btn btn-danger mb-1"
                              onClick={() => evRemoveOption(o.index)}
                            >
                              {deleteBtn()}
                            </button>
                          </Row>
                        ) : (
                          <Row key={s.index}>
                            <button
                              className="btn btn-danger mb-1"
                              onClick={() =>
                                evRemoveSubOption(o.index, s.index)
                              }
                            >
                              {deleteBtn()}
                            </button>
                          </Row>
                        ),
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <Row className="mb-3">
            <Col sm={12}>
              <Row className="align-items-center">
                <Col lg={2}>
                  <label
                    htmlFor="optionStock"
                    className="col-lg-12 col-form-label bg-secondary rounded"
                  >
                    재고량
                  </label>
                </Col>
                <Col lg={2}>
                  <input
                    id="optionStock"
                    name="optionStock"
                    type="number"
                    className="form-control"
                    value={optionForm[0].subOption[0]?.optionStock || ''}
                    onChange={e => evChangeSubOption(e, 0, 0)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
}

export default ItemOptionInfoForm;
