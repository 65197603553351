import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { Container } from 'reactstrap';
import orderAPI from '../../api/order';
import DetailHeader from '../../components/Common/DetailHeader';
import OrderSearchForm from '../../components/Orders/OrderSearchForm';
import OrderTableForm from '../../components/Orders/OrderTableForm';
import { dateFormat } from '../../util/dateFormat';

const allowCodeList = [
  '3',
  '4',
  '10',
  '12',
  '30',
  '31',
  '33',
  '40',
  '41',
  '43',
  '44',
];
const ORDER_MAIN_PATH = '/order';
const ORDER_CONDITIONAL_PATH = '/order/status/:code';
const toEditPage = id => `/order/${id}`;
const fileName = '주문목록';
// const STATUS_ORDER_3 = '3';
const STATUS_ORDER_4 = '4';
// const STATUS_ORDER_30 = '30';
// const STATUS_ORDER_31 = '31';
// const STATUS_ORDER_40 = '40';
const STATUS_ORDER_41 = '41';

function OrderList({ match }) {
  const location = useLocation();
  const history = useHistory();
  const { path } = match;
  const { code: status } = match.params;
  const [orderStatusList, setOrderStatusList] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [isFetched, setIsFetched] = useState(false);
  const { countState, tokenValid } = useSelector(state => ({
    countState: state.Count.countState,
    tokenValid: state.Token.data,
  }));
  const needFetch = () => {
    setIsFetched(true);
  };
  const btn = status && { val: '수정' };
  const headerInfo = {
    parentLabel: '주문 관리',
    parentLink: '/order',
    current: status
      ? orderStatusList?.find(s => s.code === status)?.codeLabel
      : '전체 주문 조회',
  };

  const listMapping = list => {
    const mappingList = list.map(data => ({
      ...data,
      itemName: <Link to={toEditPage(data.orderId)}>{data.itemName}</Link>,
      mallName: '해든몰',
      priceSell: `${data.priceSell?.toLocaleString()}원`,
      shipCost: data.shipCost ? `${data.shipCost?.toLocaleString()}원` : '무료',
      totalAmount: `${data.totalAmount?.toLocaleString()}원`,
      createdAt: dateFormat(data.createdAt),
    }));
    return mappingList;
  };
  const excelMapping = list => {
    return list.map((data, index) => ({
      번호: index + 1,
      주문번호: data.orderId,
      구매자: data.userName,
      상품명: data.itemName,
      수량: data.itemCount,
      상품금액: data.priceSell?.toLocaleString(),
      배송비: data.shipCost?.toLocaleString(),
      결제금액: data.totalAmount?.toLocaleString(),
      받는분: data.recvrName,
      결제방법: data.payMethodLabel,
      주문상태: data.codeLabel,
      주문일자: dateFormat(data.createdAt),
    }));
  };

  const getOrderList = useCallback(async form => {
    const result = await orderAPI.getList(form);
    setOrderList(() => listMapping(result));
    setExcelData(() => excelMapping(result));
  }, []);

  const evSearch = form => {
    getOrderList(form);
  };

  const distinctList = list => {
    const filteredlist = list.reduce((acc, cur) => {
      const index = acc.findIndex(row => row.orderId === cur.orderId);
      if (index === -1) {
        acc.push(cur);
      } else {
        const count = 1;
        const prevCount = parseInt(acc[index].count, 10);
        const itemCount =
          parseInt(acc[index].itemCount, 10) + parseInt(cur.itemCount, 10);
        acc[index] = {
          ...acc[index],
          count: acc[index].count ? prevCount + count : count,
          itemCount,
          itemName: `${cur.itemName} 외 ${prevCount || 1} 건`,
          priceSell:
            acc[index].priceSell + cur.priceSell * parseInt(cur.itemCount, 10),
        };
      }
      return acc;
    }, []);
    return filteredlist?.filter(f => f.payMethod);
  };

  const fetchData = useCallback(async statusOrder => {
    const { codeList: statusList } = await orderAPI.getStatusCodeList();
    setOrderStatusList(() => statusList);
    const orderData = await orderAPI.getList({ statusOrder });
    const distinctData = distinctList(orderData);
    setOrderList(() => listMapping(distinctData));
    setExcelData(() => excelMapping(distinctData));
    if (
      distinctData.length !== 0 &&
      (statusOrder === STATUS_ORDER_4 || statusOrder === STATUS_ORDER_41)
    ) {
      const companies = await orderAPI.getDeliverCompanyList();
      setCompanyList(companies.list);
    }
    setIsFetched(true);
  }, []);

  const evUpdate = useCallback(
    async form => {
      let result;
      if (status === STATUS_ORDER_4 || status === STATUS_ORDER_41) {
        result = await orderAPI.updateDeliver(form);
      }
      result = await orderAPI.updateStatusList(form);
      if (form[0].statusOrder === '33') {
        Promise.all(
          form.map(async f => {
            const prev = orderList.find(
              o =>
                o.childrenId === parseInt(f.id, 10) ||
                o.childrenId === parseInt(f.orderId, 10),
            );
            if (prev) {
              const cancel = {
                orderId: prev.orderId,
                childrenId: prev.childrenId,
                userName: prev.userName,
                reason: '반품확인',
              };
              await orderAPI.cancel(cancel);
            }
          }),
        );
      }
      fetchData(status);
      return result;
    },
    [status, fetchData, orderList],
  );

  useEffect(() => {
    if (path === ORDER_CONDITIONAL_PATH) {
      const allowCode = allowCodeList.find(c => c === status);
      if (!allowCode) history.push('/#');
    }
    if (tokenValid) {
      fetchData(status);
      setIsFetched(false);
    }
    return () => setIsFetched(false);
  }, [status, history, path, fetchData, location.key, countState, tokenValid]);
  return (
    <Container fluid>
      <DetailHeader headerInfo={headerInfo} />
      <OrderSearchForm
        orderStatusList={orderStatusList}
        status={status}
        evSearch={evSearch}
      />
      <OrderTableForm
        needFetch={needFetch}
        isFetched={isFetched}
        status={status}
        orderList={orderList}
        companyList={companyList}
        fileName={fileName}
        excelData={excelData}
        evUpdate={evUpdate}
        btn={btn}
      />
    </Container>
  );
}

export default OrderList;
