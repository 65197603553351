import axios from './axios';
import { USER } from './urls';

export default {
  async getList(params) {
    try {
      if (!params) return await axios.get(USER.GET_LIST);
      return await axios.get(USER.GET_LIST, {
        params: { ...params, [params.userStatus]: params.userStatus },
      });
    } catch (e) {
      return [];
    }
  },

  async getOne(id) {
    try {
      return await axios.get(USER.GET_OR_DELETE + id);
    } catch (e) {
      console.error(e);
      return e;
    }
  },

  async insert(params) {
    try {
      return await axios.post(USER.UPSERT, params);
    } catch (e) {
      return e;
    }
  },

  async update(params) {
    try {
      return await axios.put(USER.UPSERT, params);
    } catch (e) {
      return e;
    }
  },

  async delete(id) {
    try {
      return await axios.delete(USER.GET_OR_DELETE + id);
    } catch (e) {
      return e;
    }
  },

  async getAddressListById(id) {
    try {
      return await axios.get(USER.GET_ADDRESS_LIST(id));
    } catch (e) {
      return [];
    }
  },

  async getPointListById(id) {
    try {
      return await axios.get(USER.GET_POINT_LIST(id));
    } catch (e) {
      return [];
    }
  },

  async excelInsert(params) {
    try {
      return await axios.post(USER.EXCEL, { params });
    } catch (e) {
      return e;
    }
  },

  async getOrderListById(id) {
    try {
      return await axios.get(USER.GET_ORDER_LIST(id));
    } catch (e) {
      return [];
    }
  },

  async getByAccountOrId(params) {
    try {
      return await axios.get(USER.GET_ID_OR_ACCOUNT, { params });
    } catch (e) {
      return null;
    }
  },

  async getListByBizId(id) {
    try {
      return await axios.get(USER.GET_BY_BIZ_ID(id));
    } catch (e) {
      return null;
    }
  },
};
