import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import itemAPI from '../../api/item';
import { updated } from '../../store/actions';
import GridTable from '../Common/GridTable';
import ItemAcceptTable from './ItemAcceptTable';

const ROLE_ADMIN = 'ROLE_ADMIN';

const columns = [
  {
    label: '번호',
    field: 'itemCode',
    width: 80,
  },
  {
    label: '협력사',
    field: 'bizName',
    width: 150,
  },
  {
    label: '상품명',
    field: 'itemName',
    width: 350,
  },
  {
    label: '모델명',
    field: 'infoModel',
    width: 150,
  },
  {
    label: '재고량',
    field: 'optionStock',
    width: 100,
  },
  {
    label: '전시상태',
    field: 'statusOpen',
    width: 100,
  },
  {
    label: '판매상태',
    field: 'statusSell',
    width: 100,
  },
  {
    label: '배송비',
    field: 'shipCost',
    width: 100,
  },
  {
    label: '매입가',
    field: 'priceSupl',
    width: 100,
  },
  {
    label: '판매가',
    field: 'priceSell',
    width: 100,
  },
  {
    label: '매익율',
    field: 'grossMargin',
    width: 100,
  },
  {
    label: '등록일',
    field: 'createdAt',
    width: 100,
  },
  {
    label: '승인상태',
    field: 'statusAccept',
    width: 100,
  },
];

function ItemTable({
  itemList,
  toAddPage,
  excelData,
  fileName,
  isAcceptPage,
  needFetch,
  isFetched,
  isLoaded,
  notLoaded,
}) {
  const [acceptItemList, setAcceptList] = useState([]);
  const [checkboxs, setCheckboxs] = useState([]);
  const [isFixed, setIsFixed] = useState(false);
  const { countState, role } = useSelector(state => ({
    countState: state.Count.countState,
    role: state.Token.role,
  }));
  const dispatch = useDispatch();
  const onUpdated = useCallback(() => dispatch(updated()), [dispatch]);

  const btn = {
    val: '승인',
  };

  const evCheckItem = id => {
    setCheckboxs(l =>
      l.map(box => (box.id === id ? { ...box, checked: !box.checked } : box)),
    );
    setAcceptList(l =>
      l.map(item =>
        item.itemId === id ? { ...item, isChecked: !item.isChecked } : item,
      ),
    );
  };

  const onCheckAll = (list, checkType) => {
    setCheckboxs(boxs =>
      boxs.map(c =>
        list.find(l => c.id === l.itemId) ? { ...c, checked: checkType } : c,
      ),
    );
    setAcceptList(al =>
      al.map(item =>
        list.find(l => l.itemId === item.itemId)
          ? {
              ...item,
              isChecked: checkType,
            }
          : item,
      ),
    );
  };

  const itemAccept = useCallback(async () => {
    // console.log(checkboxs);
    const checkedBoxs = checkboxs.reduce((acc, cur) => {
      if (cur.checked) {
        acc.push({
          ...cur,
          itemId: cur.id,
          statusAccept: '1',
        });
      }
      return acc;
    }, []);
    if (window.confirm(`총 ${checkedBoxs.length}건 승인하시겠습니까`)) {
      // console.log(checkedBoxs);
      const result = await itemAPI.updateItemAcceptBulk(checkedBoxs);
      onUpdated();
      needFetch();
      notLoaded();
      setIsFixed(false);
    }
  }, [checkboxs, onUpdated, needFetch, notLoaded]);
  const itemDenial = useCallback(async () => {
    if (window.confirm('반려하시겠습니까')) {
      const checkedBoxs = checkboxs.reduce((acc, cur) => {
        if (cur.checked) {
          acc.push({
            ...cur,
            itemId: cur.id,
            statusAccept: '2',
          });
        }
        return acc;
      }, []);
      const result = await itemAPI.updateItemAcceptBulk(checkedBoxs);
      onUpdated();
      needFetch();
      notLoaded();
      setIsFixed(false);
    }
  }, [checkboxs, onUpdated, needFetch, notLoaded]);

  useEffect(() => {
    if (isLoaded && isFetched && isFixed) setIsFixed(false);
    if (isAcceptPage && !isFixed && !isFetched && isLoaded) {
      setCheckboxs(
        itemList.map(row => ({
          id: row.itemId,
          checked: false,
        })),
      );
      setAcceptList(() =>
        itemList.map(row => ({
          ...row,
          isChecked: false,
        })),
      );
      setIsFixed(true);
    }
  }, [
    isAcceptPage,
    itemList,
    isFixed,
    checkboxs,
    countState,
    isFetched,
    isLoaded,
  ]);

  return isAcceptPage ? (
    <ItemAcceptTable
      data={{
        columns,
        rows: acceptItemList,
      }}
      evClickBtn={role === ROLE_ADMIN && itemAccept}
      evClickBtn2={role === ROLE_ADMIN && itemDenial}
      excelData={excelData}
      fileName={fileName}
      btn={btn}
      role={role}
      onCheckAll={onCheckAll}
      evCheckItem={evCheckItem}
    />
  ) : (
    <GridTable
      data={{ columns, rows: itemList }}
      evClickBtn={toAddPage}
      excelData={excelData}
      fileName={fileName}
    />
  );
}

export default ItemTable;
