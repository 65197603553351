const usePagination = (totalLength, currentPage, listSize, rangeSize) => {
  const pageCount = Math.ceil(totalLength / listSize);
  const start = Math.floor((currentPage - 1) / rangeSize) * rangeSize + 1;
  let end = start + rangeSize - 1;
  let next = end + 1;
  let prev = start - 1;
  if (end >= pageCount) {
    end = pageCount;
    next = undefined;
  }
  if (currentPage === 1) {
    prev = undefined;
  }

  return {
    start,
    end,
    next,
    prev,
  };
};

export default usePagination;
