import { Container } from 'reactstrap';

function Dashboard() {
  const onBulk = async () => {
    console.log(!'sts');
  };

  return (
    <Container fluid>
      <h1>해든몰 관리자 페이지입니다.</h1>
      {!process.env.REACT_APP_API_URL && <button onClick={onBulk}>asd</button>}
    </Container>
  );
}

export default Dashboard;
