import {useEffect, useState} from "react";
import pointKindAPI from '../../api/pointKind';

const PointKindSelect = ({onChange, firstOption, returnPointKind, id}) => {
  const [pointKinds, setPointKinds] = useState([]);

  useEffect(() => {
      async function fetchData() {
        const result = await pointKindAPI.getList();
        if (result && Array.isArray(result)) {
          setPointKinds(result);
        }
      }
      fetchData();
  }, []);

  return(
    <>
      <select
        name="pointKindId"
        className="form-control"
        onChange={onChange}
        id={id ?? ''}
      >
        <option value={firstOption.value}>{firstOption.text}</option>
        {
          pointKinds.map(pk => {
            return <option key={pk.pointKindId}
                           value={returnPointKind ? JSON.stringify(pk) : pk.pointKindId}>
              {pk.pointName}</option>})
        }
      </select>
    </>
  );

};
export default PointKindSelect;
