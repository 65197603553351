import { Card, CardBody, Col, Row } from 'reactstrap';

function OrderBuyerInfo({ buyer }) {
  const { userName, account, phone, email } = buyer;
  return (
    <Card>
      <CardBody>
        <Row className="mb-3">
          <Col>
            <h4
              style={{
                borderBottom: '1px solid #EBEBEB',
                paddingBottom: '0.5rem',
              }}
            >
              구매자 정보
            </h4>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label
                  htmlFor="infoModel"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  구매자 성함
                </label>
              </Col>
              <Col lg={8}>
                <span className="px-2">{userName}</span>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label
                  htmlFor="infoOrigin"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  구매자 아이디
                </label>
              </Col>
              <Col lg={8}>
                <span className="px-2">{account}</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label
                  htmlFor="infoModel"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  구매자 전화번호
                </label>
              </Col>
              <Col lg={8}>
                <span className="px-2">{phone}</span>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label
                  htmlFor="infoOrigin"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  구매자 이메일주소
                </label>
              </Col>
              <Col lg={8}>
                <span className="px-2">{email}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default OrderBuyerInfo;
