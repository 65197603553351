import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody, Container } from 'reactstrap';
import { useSelector } from 'react-redux';

import bizApi from '../../api/biz';
import GridTable from '../../components/Common/GridTable';
import DetailHeader from '../../components/Common/DetailHeader';
import { dateFormat } from '../../util/dateFormat';

const headerInfo = {
  parentLabel: '공급사 관리',
  parentLink: '/biz',
  current: '공급사 조회',
  btnLabel: '워커 등록',
  btnIcon: 'mdi mdi-account-edit mr-2',
};

const defaultSearchForm = {
  searchKind: 'bizName',
  searchText: '',
};

const cols = [
  {
    label: '번호',
    field: 'bizId',
  },
  {
    label: '회사명',
    field: 'bizName',
  },
  {
    label: '관리 아이디',
    field: 'account',
  },
  {
    label: '사업자등록번호',
    field: 'bizCrn',
  },
  // {
  //   label: '회사 코드',
  //   field: 'bizCode',
  // },
  {
    label: '대표자명',
    field: 'bizCeo',
  },
  {
    label: '전화번호',
    field: 'bizPhone',
  },
  {
    label: '승인상태',
    field: 'statusAccept',
  },
  {
    label: '등록일',
    field: 'createdAt',
  },
  {
    label: '메모',
    field: 'memo',
  },
];

const BizList = ({ history }) => {
  const [searchForm, setSearchForm] = useState(defaultSearchForm);
  const [bizList, setBizList] = useState([]);
  const { tokenValid } = useSelector(state => ({
    tokenValid: state.Token.data,
  }));
  const ACTIVE_EXCEL = true;

  const evChangeSearch = ({ target }) => {
    const { name, value } = target;
    setSearchForm({
      ...searchForm,
      [name]: value,
    });
  };

  const evToAddPage = () => history.push('/biz/add');

  const reset = () => {
    setSearchForm({
      ...defaultSearchForm,
    });
  };

  const evSearch = useCallback(async () => {
    const result = await bizApi.getList(searchForm);

    // If no results empty string is returned throwing error
    if (Array.isArray(result)) {
      setBizList(() =>
        result.map(biz => ({
          ...biz,
          statusAccept: biz.statusAccept === 1 ? '승인' : '미승인',
          bizName: <Link to={`biz/${biz.bizId}`}>{biz.bizName}</Link>,
        })),
      );
    } else {
      // No search results
      setBizList(() => []);
    }
  }, [searchForm]);

  useEffect(() => {
    async function fetchData() {
      const result = await bizApi.getList();

      // If no results empty string is returned throwing error
      if (Array.isArray(result)) {
        setBizList(() =>
          result.map(biz => ({
            ...biz,
            createdAt: dateFormat(biz.createdAt),
            statusAccept: biz.statusAccept === 1 ? '승인' : '미승인',
            bizName: <Link to={`biz/${biz.bizId}`}>{biz.bizName}</Link>,
          })),
        );
      }
    }
    if (tokenValid) {
      fetchData();
    }
  }, [tokenValid]);

  return (
    <>
      <Container fluid>
        <DetailHeader headerInfo={headerInfo} />
        <Card>
          <CardBody>
            <Row>
              <Col>
                <h4>검색</h4>
              </Col>
            </Row>
            <Row className="align-items-center justify-content-center line mb-3">
              <Col sm={3}>
                <select
                  className="form-select col-sm-12 form-control"
                  aria-label="Default select example"
                  name="searchKind"
                  value={searchForm.searchKind}
                  onChange={evChangeSearch}
                >
                  <option value="bizName">회사명</option>
                  <option value="account">관리 아이디</option>
                  <option value="bizCode">회사 코드</option>
                  <option value="bizCeo">대표자명</option>
                  <option value="bizCrn">사업자등록번호</option>
                </select>
              </Col>
              <Col sm={3}>
                <input
                  className="form-control"
                  type="text"
                  name="searchText"
                  value={searchForm.searchText}
                  onChange={evChangeSearch}
                />
              </Col>
            </Row>
            <Row className="form-group align-items-center justify-content-center button-items">
              <button
                type="button"
                onClick={evSearch}
                className="btn btn-primary waves-effect waves-light"
              >
                검색
              </button>
              <button
                type="button"
                onClick={reset}
                className="btn btn-info waves-effect waves-light"
              >
                초기화
              </button>
            </Row>
          </CardBody>
        </Card>
        <GridTable
          data={{ columns: cols, rows: bizList }}
          activeExcel={ACTIVE_EXCEL}
          evClickBtn={evToAddPage}
        />
      </Container>
    </>
  );
};
export default BizList;
