import { Card, CardBody, Col, Row } from 'reactstrap';

function UserBizForm({ evChangeForm, biz, fetched }) {
  if (!fetched)
    return (
      <Card>
        <CardBody>
          <Row className="mb-3">
            <Col>
              <h4
                style={{
                  borderBottom: '1px solid #EBEBEB',
                  paddingBottom: '0.5rem',
                }}
              >
                회사 정보
              </h4>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col sm={6}>
              <Row className="align-items-center">
                <Col lg={3}>
                  <label
                    htmlFor="bizId"
                    className="col-lg-12 col-form-label bg-secondary rounded"
                  >
                    회사명
                  </label>
                </Col>
                <Col lg={9}>
                  <select
                    className="form-select col-sm-12 form-control"
                    aria-label="Default select example"
                    name="bizId"
                    onChange={evChangeForm}
                  >
                    <option value="0">선택</option>
                  </select>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col sm={6}>
              <Row className="align-items-center">
                <Col lg={3}>
                  <label
                    htmlFor="bizRank"
                    className="col-lg-12 col-form-label bg-secondary rounded"
                  >
                    직급
                  </label>
                </Col>
                <Col lg={9}>
                  <input
                    id="bizRank"
                    name="bizRank"
                    className="form-control"
                    onChange={evChangeForm}
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={6}>
              <Row className="align-items-center">
                <Col lg={3}>
                  <label
                    htmlFor="bizEnteredAt"
                    className="col-lg-12 col-form-label bg-secondary rounded"
                  >
                    입사일
                  </label>
                </Col>
                <Col lg={9}>
                  <input
                    type="date"
                    id="bizEnteredAt"
                    name="bizEnteredAt"
                    className="form-control"
                    onChange={evChangeForm}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  return (
    <Card>
      <CardBody>
        <Row className="mb-3">
          <Col>
            <h4
              style={{
                borderBottom: '1px solid #EBEBEB',
                paddingBottom: '0.5rem',
              }}
            >
              회사 정보
            </h4>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={3}>
                <label
                  htmlFor="bizId"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  회사명
                </label>
              </Col>
              <Col lg={9}>
                <select
                  className="form-select col-sm-12 form-control"
                  aria-label="Default select example"
                  name="bizId"
                  onChange={evChangeForm}
                >
                  <option value="0">선택</option>
                  {biz &&
                    biz.map(b => (
                      <option key={b.bizId} value={b.bizId}>
                        {b.bizName}
                      </option>
                    ))}
                </select>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={3}>
                <label
                  htmlFor="bizRank"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  직급
                </label>
              </Col>
              <Col lg={9}>
                <input
                  id="bizRank"
                  name="bizRank"
                  className="form-control"
                  onChange={evChangeForm}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={3}>
                <label
                  htmlFor="bizEnteredAt"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  입사일
                </label>
              </Col>
              <Col lg={9}>
                <input
                  type="date"
                  id="bizEnteredAt"
                  name="bizEnteredAt"
                  className="form-control"
                  onChange={evChangeForm}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default UserBizForm;
