import { useState } from 'react';
import { Row, Col, Card, CardBody, Alert } from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { AvForm, AvField } from 'availity-reactstrap-validation';

import Loader from '../../components/Loader';
import { loginWorker } from '../../store/actions';

import logoSm from '../../assets/images/logo3.png';
import auth from '../../api/auth';

function Login() {
  const { loading, error } = useSelector(state => ({
    loading: state.Login.loading,
    error: state.Login.error,
  }));
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const login = (params, h) => dispatch(loginWorker(params, h));

  const handleValidSubmit = async (e, values) => {
    setIsLoading(true);
    const result = await auth.checkRefreshToken();
    if (result) {
      await auth.logout();
    }
    auth.tokenRenewal();
    login(values, history);
    setIsLoading(false);
  };

  return (
    <>
      {/* <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="fas fa-home h2" />
          </Link>
        </div> */}
      <div className="account-pages my-5 pt-5">
        <div className="container">
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="position-relative">
                <Card className="overflow-hidden">
                  <div className="bg-primary">
                    <div className="text-primary text-center p-4">
                      <h5 className="text-white font-size-20">HEADEN M&C</h5>
                      <p className="text-white-50">관리자 관리콘솔</p>
                      <Link
                        to="/"
                        className="logo logo-admin"
                        style={{ borderBottom: '2px solid #009bcb' }}
                      >
                        <img src={logoSm} height="36" alt="logo" />
                      </Link>
                    </div>
                  </div>

                  <CardBody className="p-4">
                    <div className="p-3">
                      <AvForm
                        className="form-horizontal mt-4"
                        onValidSubmit={handleValidSubmit}
                      >
                        {loading ? <Loader /> : null}
                        {error ? <Alert color="danger">{error}</Alert> : null}

                        <div className="form-group">
                          <AvField
                            name="account"
                            label="아이디"
                            className="form-control"
                            placeholder="아이디를 입력해주세요"
                            errorMessage="아이디를 입력해주세요"
                            type="text"
                          />
                        </div>
                        <div className="form-group">
                          <AvField
                            name="password"
                            label="비밀번호"
                            type="password"
                            placeholder="비밀번호를 입력해주세요"
                            errorMessage="비밀번호를 입력해주세요"
                          />
                          {/* <AvGroup>
                            <Label for="example">example</Label>
                            <AvInput
                              name="example"
                              id="example"
                              placeholder="test"
                              required
                            />
                            <AvFeedback>example</AvFeedback>
                          </AvGroup> */}
                        </div>

                        <Row className="form-group">
                          <Col sm={6}>
                            &nbsp;
                            {/* <div className="custom-control custom-checkbox">
                                  <input type="checkbox" className="custom-control-input" id="customControlInline" />
                                  <label className="custom-control-label" for="customControlInline">Remember me</label>
                                </div> */}
                          </Col>
                          <Col sm={6} className="text-right">
                            {isLoading || loading ? (
                              <button
                                className="btn btn-info w-md waves-effect waves-light"
                                type="button"
                              >
                                로그인
                              </button>
                            ) : (
                              <button
                                className="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                              >
                                로그인
                              </button>
                            )}
                          </Col>
                        </Row>
                        {/* <Row className="form-group mt-2 mb-0">
                          <div className="col-12 mt-4">
                            <Link to="/forget-password">
                              <i className="mdi mdi-lock" /> Forgot your
                              password?
                            </Link>
                          </div>
                        </Row> */}
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div className="mt-5 text-center">
                <p>
                  등록요청은 ?{' '}
                  <Link
                    to="/register"
                    className="font-weight-medium text-primary"
                  >
                    {' '}
                    이쪽에서{' '}
                  </Link>{' '}
                </p>
                <p className="mb-0">
                  Copyright {new Date().getFullYear()}. HEADEN M&C. All rights
                  reserved.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default Login;
