import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import {
  aMonthAgo,
  aYearAgo,
  sixMonthsAgo,
  threeMonthsAgo,
  today,
} from '../../util/dateFormat';
import { notNumber } from '../../util/validation';
import categoryAPI from '../../api/category';

const initialForm = {
  searchKind: 'item_name',
  searchText: '',
  dateKind: 'created_at',
  startDate: threeMonthsAgo,
  endDate: today,
  category1: 0,
  category2: 0,
  category3: 0,
  startShipCost: '',
  endShipCost: '',
  startPriceSupl: '',
  endPriceSupl: '',
  startPriceSell: '',
  endPriceSell: '',
  startGrossMargin: '',
  endGrossMargin: '',
  startMileageRatio: '',
  endMileageRatio: '',
  startStock: '',
  endStock: '',
  statusAccept: undefined,
  statusOpen: undefined,
  statusSell: undefined,
  taxKind: undefined,
  usedFlag: '1',
};

function ItemSearchForm({ evSearch, isAcceptPage }) {
  const [catgryList, setCatgryList] = useState([]);
  const [searchForm, setSearchForm] = useState(initialForm);
  const { tokenValid } = useSelector(state => ({
    tokenValid: state.Token.data,
  }));
  const {
    searchKind,
    searchText,
    dateKind,
    startDate,
    endDate,
    category1,
    category2,
    category3,
    startShipCost,
    endShipCost,
    startPriceSupl,
    endPriceSupl,
    startPriceSell,
    endPriceSell,
    startGrossMargin,
    endGrossMargin,
    startMileageRatio,
    endMileageRatio,
    startStock,
    endStock,
    statusAccept,
    statusOpen,
    statusSell,
    taxKind,
    usedFlag,
  } = searchForm;

  const evChangeSearch = useCallback(({ target }) => {
    const { name, value } = target;
    setSearchForm(form => ({
      ...form,
      [name]: value,
    }));
    if (name === 'category1' || name === 'category2' || name === 'category3') {
      setSearchForm(form => ({
        ...form,
        catgryId: value,
      }));
      if (name === 'category1') {
        setSearchForm(form => ({
          ...form,
          category2: 0,
          category3: 0,
        }));
      }
      if (name === 'category2') {
        setSearchForm(form => ({
          ...form,
          category3: 0,
        }));
      }
    }
  }, []);

  const reset = useCallback(() => {
    setSearchForm({
      ...initialForm,
    });
  }, []);

  const evClickDateBtn = useCallback(date => {
    setSearchForm(form => ({
      ...form,
      startDate: date,
      endDate: today,
    }));
  }, []);

  const fetchCategory = useCallback(async () => {
    const result = await categoryAPI.getList();
    setCatgryList(() => result);
  }, []);

  const vaildate = () => {
    const text = '숫자만 입력해주세요';
    if (startShipCost !== '' && notNumber(startShipCost)) {
      alert(`배송비 : ${text}`);
      return false;
    }
    if (endShipCost !== '' && notNumber(endShipCost)) {
      alert(`배송비 : ${text}`);
      return false;
    }
    if (startPriceSupl !== '' && notNumber(startPriceSupl)) {
      alert(`매입가 : ${text}`);
      return false;
    }
    if (endPriceSupl !== '' && notNumber(endPriceSupl)) {
      alert(`매입가 : ${text}`);
      return false;
    }
    if (startPriceSell !== '' && notNumber(startPriceSell)) {
      alert(`판매가 : ${text}`);
      return false;
    }
    if (endPriceSell !== '' && notNumber(endPriceSell)) {
      alert(`판매가 : ${text}`);
      return false;
    }
    if (startGrossMargin !== '' && notNumber(startGrossMargin)) {
      alert(`매익율 : ${text}`);
      return false;
    }
    if (endGrossMargin !== '' && notNumber(endGrossMargin)) {
      alert(`매익율 : ${text}`);
      return false;
    }
    if (startMileageRatio !== '' && notNumber(startMileageRatio)) {
      alert(`적립률 : ${text} start : ${startMileageRatio}`);
      return false;
    }
    if (endMileageRatio !== '' && notNumber(endMileageRatio)) {
      alert(`적립률 : ${text} end`);
      return false;
    }
    if (startStock !== '' && notNumber(startStock)) {
      alert(`재고량 : ${text}`);
      return false;
    }
    if (endStock !== '' && notNumber(endStock)) {
      alert(`재고량 : ${text}`);
      return false;
    }
    return true;
  };

  const evSearchBtn = () => {
    const result = vaildate();
    if (result) evSearch(searchForm);
  };

  useEffect(() => {
    if (tokenValid) fetchCategory();
    if (isAcceptPage) {
      setSearchForm(f => ({
        ...f,
        statusAccept: '0',
      }));
    }
  }, [fetchCategory, isAcceptPage, tokenValid]);

  // useEffect(() => {
  //   setSearchForm(initialForm);
  // }, []);

  return (
    <Card>
      <CardBody>
        <Row className="align-items-center mb-3">
          <Col>
            <h4>검색</h4>
          </Col>
        </Row>
        <Row className="align-items-center mb-3">
          <Col sm={6}>
            <Row>
              <Col sm={3}>
                <select
                  className="form-select form-control bg-secondary"
                  name="searchKind"
                  value={searchKind}
                  onChange={evChangeSearch}
                >
                  <option value="item_name">상품명</option>
                  <option value="item_id">상품번호</option>
                  <option value="info_model">모델명</option>
                  <option value="memo">메모</option>
                </select>
              </Col>
              <Col sm={9}>
                <input
                  className="form-control"
                  type="text"
                  name="searchText"
                  value={searchText}
                  onChange={evChangeSearch}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-center mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col sm={3}>
                <select
                  className="form-select form-control bg-secondary"
                  name="dateKind"
                  value={dateKind}
                  onChange={evChangeSearch}
                >
                  <option value="created_at">등록일</option>
                  <option value="updated_at">수정일</option>
                </select>
              </Col>
              <Col sm={4}>
                <input
                  className="form-control"
                  type="date"
                  name="startDate"
                  max={endDate}
                  value={startDate}
                  onChange={evChangeSearch}
                />
              </Col>
              <i className="mdi mdi-tilde col-sm-1 d-flex justify-content-center" />
              <Col sm={4}>
                <input
                  className="form-control"
                  type="date"
                  name="endDate"
                  value={endDate}
                  min={startDate}
                  onChange={evChangeSearch}
                />
              </Col>
            </Row>
          </Col>

          <Col sm={6}>
            <Row>
              <Col className="button-items">
                <button
                  type="button"
                  className="btn btn-outline-dark"
                  onClick={() => evClickDateBtn(today)}
                >
                  오늘
                </button>

                <button
                  type="button"
                  className="btn btn-outline-dark"
                  onClick={() => evClickDateBtn(aMonthAgo)}
                >
                  한달 전
                </button>
                <button
                  type="button"
                  className="btn btn-outline-dark"
                  onClick={() => evClickDateBtn(threeMonthsAgo)}
                >
                  3개월 전
                </button>
                <button
                  type="button"
                  className="btn btn-outline-dark"
                  onClick={() => evClickDateBtn(sixMonthsAgo)}
                >
                  6개월 전
                </button>
                <button
                  type="button"
                  className="btn btn-outline-dark"
                  onClick={() => evClickDateBtn(aYearAgo)}
                >
                  1년 전
                </button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col sm={3}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  카테고리
                </label>
              </Col>
              <Col sm={3}>
                <select
                  className="form-control"
                  name="category1"
                  value={category1}
                  onChange={evChangeSearch}
                >
                  <option value={0}>1차 카테고리</option>
                  {catgryList &&
                    catgryList.map(catgry => {
                      if (catgry.parentId === 0) {
                        return (
                          <option value={catgry.catgryId} key={catgry.catgryId}>
                            {catgry.catgryName}
                          </option>
                        );
                      }
                      return undefined;
                    })}
                </select>
              </Col>
              <Col sm={3}>
                <select
                  className="form-control"
                  name="category2"
                  value={category2}
                  onChange={evChangeSearch}
                >
                  <option value={0}>2차 카테고리</option>
                  {parseInt(category1, 10) &&
                    catgryList.map(catgry => {
                      if (
                        parseInt(category1, 10) &&
                        catgry.parentId === parseInt(category1, 10)
                      ) {
                        return (
                          <option value={catgry.catgryId} key={catgry.catgryId}>
                            {catgry.catgryName}
                          </option>
                        );
                      }
                      return undefined;
                    })}
                </select>
              </Col>
              <Col sm={3}>
                <select
                  className="form-control"
                  name="category3"
                  value={category3}
                  onChange={evChangeSearch}
                >
                  <option value={0}>3차 카테고리</option>
                  {parseInt(category2, 10) &&
                    catgryList.map(catgry => {
                      if (
                        category2 &&
                        catgry.parentId === parseInt(category2, 10)
                      ) {
                        return (
                          <option value={catgry.catgryId} key={catgry.catgryId}>
                            {catgry.catgryName}
                          </option>
                        );
                      }
                      return undefined;
                    })}
                </select>
              </Col>
            </Row>
          </Col>
          {!isAcceptPage && (
            <Col sm={6}>
              <Row className="align-items-center">
                <Col sm={3}>
                  <label className="col-sm-12 col-form-label bg-secondary rounded">
                    승인 상태
                  </label>
                </Col>
                <Col sm={9}>
                  <div className="form-check form-check-inline">
                    <input
                      id="statusAcceptAll"
                      name="statusAccept"
                      className="form-check-input"
                      type="radio"
                      value={undefined}
                      onChange={evChangeSearch}
                      checked={!statusAccept}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="statusAcceptAll"
                    >
                      전체
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      id="statusAccept1"
                      name="statusAccept"
                      className="form-check-input"
                      type="radio"
                      value="1"
                      onChange={evChangeSearch}
                    />
                    <label className="form-check-label" htmlFor="statusAccept1">
                      승인
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      id="statusAccept0"
                      name="statusAccept"
                      className="form-check-input"
                      type="radio"
                      value="0"
                      onChange={evChangeSearch}
                    />
                    <label className="form-check-label" htmlFor="statusAccept0">
                      미승인
                    </label>
                  </div>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <Row className="align-items-center mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col sm={3}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  배송비
                </label>
              </Col>
              <Col sm={4}>
                <input
                  className="form-control"
                  type="text"
                  name="startShipCost"
                  value={startShipCost}
                  onChange={evChangeSearch}
                />
              </Col>
              <i className="mdi mdi-tilde col-sm-1 d-flex justify-content-center" />
              <Col sm={4}>
                <input
                  className="form-control"
                  type="text"
                  name="endShipCost"
                  value={endShipCost}
                  onChange={evChangeSearch}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col sm={3}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  전시 상태
                </label>
              </Col>
              <Col sm={9}>
                <div className="form-check form-check-inline">
                  <input
                    id="statusOpenAll"
                    name="statusOpen"
                    className="form-check-input"
                    type="radio"
                    value={undefined}
                    onChange={evChangeSearch}
                    checked={!statusOpen}
                  />
                  <label className="form-check-label" htmlFor="statusOpenAll">
                    전체
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    id="statusOpen1"
                    name="statusOpen"
                    className="form-check-input"
                    type="radio"
                    value="1"
                    onChange={evChangeSearch}
                  />
                  <label className="form-check-label" htmlFor="statusOpen1">
                    전시
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    id="statusOpen0"
                    name="statusOpen"
                    className="form-check-input"
                    type="radio"
                    value="0"
                    onChange={evChangeSearch}
                  />
                  <label className="form-check-label" htmlFor="statusOpen0">
                    미전시
                  </label>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-center mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col sm={3}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  매입가
                </label>
              </Col>
              <Col sm={4}>
                <input
                  className="form-control"
                  type="text"
                  name="startPriceSupl"
                  value={startPriceSupl}
                  onChange={evChangeSearch}
                />
              </Col>
              <i className="mdi mdi-tilde col-sm-1 d-flex justify-content-center" />
              <Col sm={4}>
                <input
                  className="form-control"
                  type="text"
                  name="endPriceSupl"
                  value={endPriceSupl}
                  onChange={evChangeSearch}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col sm={3}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  판매 상태
                </label>
              </Col>
              <Col sm={9}>
                <div className="form-check form-check-inline">
                  <input
                    id="statusSellAll"
                    name="statusSell"
                    className="form-check-input"
                    type="radio"
                    value={undefined}
                    onChange={evChangeSearch}
                    checked={!statusSell}
                  />
                  <label className="form-check-label" htmlFor="statusSellAll">
                    전체
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    id="statusSell0"
                    name="statusSell"
                    className="form-check-input"
                    type="radio"
                    value="0"
                    onChange={evChangeSearch}
                  />
                  <label className="form-check-label" htmlFor="statusSell0">
                    판매중
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    id="statusSell2"
                    name="statusSell"
                    className="form-check-input"
                    type="radio"
                    value="2"
                    onChange={evChangeSearch}
                  />
                  <label className="form-check-label" htmlFor="statusSell2">
                    품절
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    id="statusSell1"
                    name="statusSell"
                    className="form-check-input"
                    type="radio"
                    value="1"
                    onChange={evChangeSearch}
                  />
                  <label className="form-check-label" htmlFor="statusSell1">
                    판매중단
                  </label>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-center mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col sm={3}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  판매가
                </label>
              </Col>
              <Col sm={4}>
                <input
                  className="form-control"
                  type="text"
                  name="startPriceSell"
                  value={startPriceSell}
                  onChange={evChangeSearch}
                />
              </Col>
              <i className="mdi mdi-tilde col-sm-1 d-flex justify-content-center" />
              <Col sm={4}>
                <input
                  className="form-control"
                  type="text"
                  name="endPriceSell"
                  value={endPriceSell}
                  onChange={evChangeSearch}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col sm={3}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  과세 유형
                </label>
              </Col>
              <Col sm={9}>
                <div className="form-check form-check-inline">
                  <input
                    id="taxKindAll"
                    name="taxKind"
                    className="form-check-input"
                    type="radio"
                    value={undefined}
                    onChange={evChangeSearch}
                    checked={!taxKind}
                  />
                  <label className="form-check-label" htmlFor="taxKindAll">
                    전체
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    id="taxKind1"
                    name="taxKind"
                    className="form-check-input"
                    type="radio"
                    value="1"
                    onChange={evChangeSearch}
                  />
                  <label className="form-check-label" htmlFor="taxKind1">
                    과세
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    id="taxKind0"
                    name="taxKind"
                    className="form-check-input"
                    type="radio"
                    value="0"
                    onChange={evChangeSearch}
                  />
                  <label className="form-check-label" htmlFor="taxKind0">
                    비과세
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    id="taxKind2"
                    name="taxKind"
                    className="form-check-input"
                    type="radio"
                    value="2"
                    onChange={evChangeSearch}
                  />
                  <label className="form-check-label" htmlFor="taxKind2">
                    상품권
                  </label>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-center mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col sm={3}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  매익률
                </label>
              </Col>
              <Col sm={4}>
                <Row className="align-items-center">
                  <Col sm={5}>
                    <input
                      className="form-control"
                      type="text"
                      name="startGrossMargin"
                      value={startGrossMargin}
                      onChange={evChangeSearch}
                    />
                  </Col>
                  <i className="mdi mdi-tilde col-sm-2 d-flex justify-content-center" />
                  <Col sm={5}>
                    <input
                      className="form-control"
                      type="text"
                      name="endGrossMargin"
                      value={endGrossMargin}
                      onChange={evChangeSearch}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col sm={3}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  현재 상태
                </label>
              </Col>
              <Col sm={9}>
                <div className="form-check form-check-inline">
                  <input
                    id="usedFlagAll"
                    name="usedFlag"
                    className="form-check-input"
                    type="radio"
                    value={undefined}
                    onChange={evChangeSearch}
                  />
                  <label className="form-check-label" htmlFor="usedFlagAll">
                    전체
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    id="usedFlag1"
                    name="usedFlag"
                    className="form-check-input"
                    type="radio"
                    value="1"
                    onChange={evChangeSearch}
                    checked={usedFlag === null || usedFlag === '1'}
                  />
                  <label className="form-check-label" htmlFor="usedFlag1">
                    정상
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    id="usedFlag0"
                    name="usedFlag"
                    className="form-check-input"
                    type="radio"
                    value="0"
                    onChange={evChangeSearch}
                  />
                  <label className="form-check-label" htmlFor="usedFlag0">
                    삭제
                  </label>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-center mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col sm={3}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  적립률
                </label>
              </Col>
              <Col sm={4}>
                <Row className="align-items-center">
                  <Col sm={5}>
                    <input
                      className="form-control"
                      type="text"
                      name="startMileageRatio"
                      value={startMileageRatio}
                      onChange={evChangeSearch}
                    />
                  </Col>
                  <i className="mdi mdi-tilde col-sm-2 d-flex justify-content-center" />
                  <Col sm={5}>
                    <input
                      className="form-control"
                      type="text"
                      name="endMileageRatio"
                      value={endMileageRatio}
                      onChange={evChangeSearch}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col sm={3}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  재고량
                </label>
              </Col>
              <Col sm={4}>
                <input
                  className="form-control"
                  type="text"
                  name="startStock"
                  value={startStock}
                  onChange={evChangeSearch}
                />
              </Col>
              <i className="mdi mdi-tilde col-sm-1 d-flex justify-content-center" />
              <Col sm={4}>
                <input
                  className="form-control"
                  type="text"
                  name="endStock"
                  value={endStock}
                  onChange={evChangeSearch}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="form-group align-items-center justify-content-center button-items">
          <button
            type="button"
            onClick={evSearchBtn}
            className="btn btn-primary waves-effect waves-light"
          >
            검색
          </button>
          <button
            type="button"
            onClick={reset}
            className="btn btn-info waves-effect waves-light"
          >
            초기화
          </button>
        </Row>
      </CardBody>
    </Card>
  );
}

export default React.memo(ItemSearchForm);
