import axios from './axios';
import { AUTH } from './urls';

export default {
  async login(params) {
    try {
      const response = await axios.post(AUTH.LOGIN_WORKER, params, {
        withCredentials: true,
      });
      // this.tokenRenewal();
      return response;
    } catch (e) {
      return Promise.reject(new Error('통신 오류'));
    }
  },
  // async apiCheck() {
  //   try {
  //     return await axios.get('');
  //   } catch (e) {
  //     return false;
  //   }
  // },
  async refresh() {
    try {
      const response = await axios.get('/auth/refresh');
      // this.tokenRenewal();
      return response;
    } catch (e) {
      return e;
    }
  },

  async checkRefreshToken() {
    try {
      return await axios.get('/auth');
    } catch (e) {
      return false;
    }
  },

  tokenRenewal() {
    return setInterval(() => this.refresh(), 1000 * 60 * 30); // 30분 마다 토큰 갱신
  },

  async logout() {
    try {
      return await axios.post('/auth/logout');
    } catch (e) {
      return e;
    }
  },
};
