import axios from './axios';
import { POINT_KIND } from "./urls";

export default {
  async getList(params) {
    try {
      if (!params) return await axios.get(POINT_KIND.GET_LIST, { params });
      return await axios.get(POINT_KIND.GET_LIST, params);
    } catch (e) {
      return e;
    }
  },

  async getOne(id) {
    try {
      return await axios.get(POINT_KIND.GET_OR_DELETE + id);
    } catch (e) {
      return e;
    }
  },

  async insert(params) {
    try {
      return await axios.post(POINT_KIND.UPSERT, params);
    } catch (e) {
      return e;
    }
  },

  async update(params) {
    try {
      return await axios.put(POINT_KIND.UPSERT, params);
    } catch (e) {
      return e;
    }
  },

  async delete(id) {
    try {
      return await axios.delete(POINT_KIND.GET_OR_DELETE + id);
    } catch (e) {
      return e;
    }
  }
};

