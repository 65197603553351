import React, { useCallback, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import {
  aMonthAgo,
  aWeekAgo,
  threeDaysAgo,
  threeMonthsAgo,
  today,
} from '../../util/dateFormat';
import { pointSearchValid } from '../../util/validation';

const initialForm = {
  searchKind: 'account',
  searchText: '',
  dateKind: 'created_at',
  startDate: aWeekAgo,
  endDate: today,
  startPoint: '',
  endPoint: '',
  userStatus: undefined,
};

function UserSearchForm({ evSearch }) {
  const [searchForm, setSearchForm] = useState(initialForm);
  const {
    searchKind,
    searchText,
    dateKind,
    startDate,
    endDate,
    startPoint,
    endPoint,
    userStatus,
  } = searchForm;

  const evChangeSearch = useCallback(({ target }) => {
    const { name, value } = target;
    setSearchForm(form => ({
      ...form,
      [name]: value,
    }));
  }, []);

  const reset = useCallback(() => {
    setSearchForm({
      ...initialForm,
    });
  }, []);
  const evClickDateBtn = useCallback(date => {
    setSearchForm(form => ({
      ...form,
      startDate: date,
      endDate: today,
    }));
  }, []);

  const vaildate = () => {
    return pointSearchValid(startPoint, endPoint);
  };

  const evSearchBtn = () => {
    const result = vaildate();
    if (!result.status) return alert(result.message);
    return evSearch(searchForm);
  };

  return (
    <Card>
      <CardBody>
        <Row className="align-items-center mb-3">
          <Col>
            <h4>검색</h4>
          </Col>
        </Row>
        <Row className="align-items-center mb-3">
          <Col sm={6}>
            <Row>
              <Col sm={3}>
                <select
                  className="form-select form-control bg-secondary"
                  name="searchKind"
                  value={searchKind}
                  onChange={evChangeSearch}
                >
                  <option value="account">아이디</option>
                  <option value="user_name">회원명</option>
                  <option value="phone">전화번호</option>
                </select>
              </Col>
              <Col sm={9}>
                <input
                  className="form-control"
                  type="text"
                  name="searchText"
                  value={searchText}
                  onChange={evChangeSearch}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-center mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col sm={3}>
                <select
                  className="form-select form-control bg-secondary"
                  name="dateKind"
                  value={dateKind}
                  onChange={evChangeSearch}
                >
                  <option value="created_at">가입일</option>
                  <option value="updated_at">정보 수정일</option>
                  <option value="login_at">최근 로그인</option>
                </select>
              </Col>
              <Col sm={4}>
                <input
                  className="form-control"
                  type="date"
                  name="startDate"
                  max={endDate}
                  value={startDate}
                  onChange={evChangeSearch}
                />
              </Col>
              <i className="mdi mdi-tilde col-sm-1 d-flex justify-content-center" />
              <Col sm={4}>
                <input
                  className="form-control"
                  type="date"
                  name="endDate"
                  value={endDate}
                  min={startDate}
                  onChange={evChangeSearch}
                />
              </Col>
            </Row>
          </Col>

          <Col sm={6}>
            <Row>
              <Col className="button-items">
                <button
                  type="button"
                  className="btn btn-outline-dark"
                  onClick={() => evClickDateBtn(today)}
                >
                  오늘
                </button>
                <button
                  type="button"
                  className="btn btn-outline-dark"
                  onClick={() => evClickDateBtn(threeDaysAgo)}
                >
                  3일 전
                </button>
                <button
                  type="button"
                  className="btn btn-outline-dark"
                  onClick={() => evClickDateBtn(aWeekAgo)}
                >
                  1주 전
                </button>
                <button
                  type="button"
                  className="btn btn-outline-dark"
                  onClick={() => evClickDateBtn(aMonthAgo)}
                >
                  한달 전
                </button>
                <button
                  type="button"
                  className="btn btn-outline-dark"
                  onClick={() => evClickDateBtn(threeMonthsAgo)}
                >
                  3개월 전
                </button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col sm={3}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  포인트
                </label>
              </Col>
              <Col sm={4}>
                <input
                  className="form-control"
                  type="text"
                  name="startPoint"
                  max={endPoint}
                  value={startPoint}
                  onChange={evChangeSearch}
                />
              </Col>
              <i className="mdi mdi-tilde col-sm-1 d-flex justify-content-center" />
              <Col sm={4}>
                <input
                  className="form-control"
                  type="text"
                  name="endPoint"
                  min={startPoint}
                  value={endPoint}
                  onChange={evChangeSearch}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col sm={3}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  현재상태
                </label>
              </Col>
              <Col sm={9}>
                <div className="form-check form-check-inline">
                  <input
                    id="statusAll"
                    name="userStatus"
                    className="form-check-input"
                    type="radio"
                    value={undefined}
                    onChange={evChangeSearch}
                    checked={!userStatus}
                  />
                  <label className="form-check-label" htmlFor="statusAll">
                    전체
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    id="activeUser"
                    name="userStatus"
                    className="form-check-input"
                    type="radio"
                    value="activeUser"
                    onChange={evChangeSearch}
                  />
                  <label className="form-check-label" htmlFor="activeUser">
                    회원
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    id="pendingUser"
                    name="userStatus"
                    className="form-check-input"
                    type="radio"
                    value="pendingUser"
                    onChange={evChangeSearch}
                  />
                  <label className="form-check-label" htmlFor="pendingUser">
                    승인대기
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    id="dormantUser"
                    name="userStatus"
                    className="form-check-input"
                    type="radio"
                    value="dormantUser"
                    onChange={evChangeSearch}
                  />
                  <label className="form-check-label" htmlFor="dormantUser">
                    휴면
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    id="withdrawalUser"
                    name="userStatus"
                    className="form-check-input"
                    type="radio"
                    value="withdrawalUser"
                    onChange={evChangeSearch}
                  />
                  <label className="form-check-label" htmlFor="withdrawalUser">
                    탈퇴
                  </label>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="form-group align-items-center justify-content-center button-items">
          <button
            type="button"
            onClick={evSearchBtn}
            className="btn btn-primary waves-effect waves-light"
          >
            검색
          </button>
          <button
            type="button"
            onClick={reset}
            className="btn btn-info waves-effect waves-light"
          >
            초기화
          </button>
        </Row>
      </CardBody>
    </Card>
  );
}

export default React.memo(UserSearchForm);
