import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { useSelector } from 'react-redux';

import DetailHeader from '../../components/Common/DetailHeader';
import FormGenerator from '../../util/FormGenerator';
import Tag from '../../api/tag';

const headerInfo = {
  parentLabel: '태그 관리',
  parentLink: '/tag',
  current: '태그 추가',
};

const defaultForm = {
  tag: '',
};

const formRowList = [
  [
    {
      type: 'label',
      label: '태그명',
    },
    {
      type: 'input',
      mdSize: 4,
      feild: 'tag',
    },
  ],
];

const TagEdit = ({ history, match }) => {
  const { tagId } = match.params;

  /* Data Form */
  const [formData, setFormData] = useState(defaultForm);
  const { tokenValid } = useSelector(state => ({
    tokenValid: state.Token.data,
  }));

  const evChangeForm = ({ target }) => {
    const { name, value } = target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  /* Button Action */
  const evClickList = () => {
    history.push('/tag');
  };

  const evClickSave = () => {
    const now = new Date();

    formData.updated_at = now;

    if (tagId) {
      Tag.update(formData).then(response => {
        if (response?.result === 'success') {
          alert('수정 성공');
        }
      });
    } else {
      Tag.insert(formData).then(response => {
        if (response?.result === 'success') {
          alert('추가 성공');
          history.push(`/tag/${response.tagId}`);
        }
      });
    }
  };

  const evClickDelete = () => {
    if (tagId) {
      Tag.delete(tagId).then(response => {
        if (response?.result === 'success') {
          alert('삭제 성공');
          history.push('/tag');
        }
      });
    }
  };

  // run when comoponentDidMount
  useEffect(() => {
    // Edit mode
    if (tagId && tokenValid) {
      headerInfo.current = '태그 편집';

      Tag.getOne(tagId).then(response => {
        setFormData(response);
      });
    }
  }, [tagId, tokenValid]);

  let deleteButtonHtml = '';
  if (tagId) {
    deleteButtonHtml = (
      <button
        onClick={evClickDelete}
        className="btn btn-warning waves-effect waves-light"
      >
        삭제
      </button>
    );
  }

  return (
    <>
      <div className="container-fluid">
        <DetailHeader headerInfo={headerInfo} />
        <Row>
          <Col>
            <Card>
              <CardBody>
                <FormGenerator
                  formData={formData}
                  changeEvent={evChangeForm}
                  formRowList={formRowList}
                />

                <div className="form-group line align-items-center justify-content-center">
                  <button
                    onClick={evClickList}
                    className="btn btn-info waves-effect waves-light"
                  >
                    목록으로
                  </button>
                  <button
                    onClick={evClickSave}
                    className="btn btn-primary waves-effect waves-light"
                  >
                    저장
                  </button>
                  {deleteButtonHtml}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TagEdit;
