import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { MDBInput } from 'mdbreact';

import bizAPI from '../../api/biz';
import supplierAPI from '../../api/supplier';
import GridTable from '../../components/Common/GridTable';
import DetailHeader from '../../components/Common/DetailHeader';
import { dateFormat, stringToDate } from '../../util/dateFormat';

const headerInfo = {
  parentLabel: '공급사 관리',
  parentLink: '/biz',
  current: '승인 대기 공급사',
};

const defaultSearchForm = {
  searchKind: 'statusNotAccepted',
  searchText: '',
};

const BizApprove = () => {
  const [searchForm, setSearchForm] = useState(defaultSearchForm);
  const [bizList, setBizList] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const { tokenValid } = useSelector(state => ({
    tokenValid: state.Token.data,
  }));
  const ACTIVE_EXCEL = true;

  const dateToString = val => {
    if (val) {
      const date = stringToDate(val);
      return dateFormat(date, 'LLL');
    }
    return '';
  };

  const evToggleAll = () => {
    if (allChecked) {
      const newVals = checkedList.map(() => false);
      setCheckedList([...newVals]);
    }
    setAllChecked(!allChecked);
  };

  // toggle all checked box if all rows manually selected
  useEffect(() => {
    if (checkedList.length > 0 && !checkedList.includes(false)) {
      setAllChecked(true);
    }
  }, [checkedList]);

  const cols = [
    {
      label: (
        <input
          label=""
          type="checkbox"
          id="headerCheckbox"
          checked={allChecked}
          onClick={evToggleAll}
        />
      ),
      field: 'checkbox',
      sort: 'disabled',
    },
    {
      label: '번호',
      field: 'bizId',
    },
    {
      label: '회사명',
      field: 'bizName',
    },
    {
      label: '관리 아이디',
      field: 'account',
    },
    {
      label: '사업자등록번호',
      field: 'bizCrn',
    },
    {
      label: '회사 코드',
      field: 'bizCode',
    },
    {
      label: '대표자명',
      field: 'bizCeo',
    },
    {
      label: '전화번호',
      field: 'bizPhone',
    },
    {
      label: '승인상태',
      field: 'statusAccept',
    },
    {
      label: '등록일',
      field: 'createdAt',
    },
    {
      label: '메모',
      field: 'memo',
    },
  ];

  const evChangeSearch = ({ target }) => {
    const { name, value } = target;
    setSearchForm({
      ...searchForm,
      [name]: value,
    });
  };

  const reset = () => {
    setSearchForm({
      ...defaultSearchForm,
    });
  };

  const evSearch = async () => {
    let result;
    if (searchForm.searchKind === 'statusNotAccepted') {
      result = await bizAPI.getList({
        searchKind: 'statusAccept',
        searchText: '0',
      });
    } else if (searchForm.searchKind === 'statusAccepted') {
      result = await bizAPI.getList({
        searchKind: 'statusAccept',
        searchText: '1',
      });
    } else {
      result = await bizAPI.getList(searchForm);
    }

    // If no results empty string is returned throwing error
    if (result && Array.isArray(result)) {
      const data = result.map(biz => ({
        ...biz,
        statusAccept: biz.statusAccept === 1 ? '승인' : '미승인',
        createdAt: dateToString(biz.createdAt),
      }));
      setApiData(data);
    } else {
      // No search results
      setApiData(() => []);
    }
  };

  // todo - Korean alert messages - Alex (최송합니다 ㅠㅠ)
  const update = async status => {
    if (!allChecked && !checkedList.includes(true)) {
      alert('No records selected');
      return;
    }

    let valsToUpdate = [];
    if (allChecked) {
      valsToUpdate = bizList.map(biz => {
        return { bizId: biz.bizId, statusAccept: status };
      });
    } else {
      checkedList.forEach((val, i) => {
        if (val) {
          valsToUpdate.push({ bizId: bizList[i].bizId, statusAccept: status });
        }
      });
    }

    const result = await supplierAPI.updateStatuses(valsToUpdate);

    if (Array.isArray(result)) {
      let failed = 0;
      result.forEach(r => {
        if (r === 0) {
          failed += 1;
        }
      });

      if (failed === 0) {
        alert('All successfully updated');
      } else {
        alert(`Failed to delete ${failed} records`);
      }
      evSearch();
      setAllChecked(false);
      return;
    }

    console.error(result);
    alert('Something went wrong. Please try again');
  };

  const approve = () => {
    update(1);
  };

  const reject = () => {
    update(0);
  };

  // Init data
  useEffect(() => {
    if (tokenValid) evSearch();
    // Force empty array for effect to run just one on load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Init checkedList if data changes
  useEffect(() => {
    setAllChecked(false);
    const checkedVals = [];
    for (let i = 0; i < apiData.length; i += 1) {
      checkedVals.push(false);
    }
    setCheckedList([...checkedVals]);
  }, [apiData]);

  // Modify apiData on change for table
  useEffect(() => {
    if (!apiData) return;
    setBizList(() =>
      apiData.map((biz, i) => ({
        ...biz,
        checkbox: (
          <input
            type="checkbox"
            name={biz.bizId}
            checked={(allChecked ? true : checkedList[i]) || false}
            onChange={() => {
              if (allChecked) {
                const newVals = checkedList.map(() => true);
                setAllChecked(false);
                newVals[i] = false;
                setCheckedList([...newVals]);
              } else {
                const checkedVals = [...checkedList];
                checkedVals[i] = !checkedVals[i];
                setCheckedList(checkedVals);
              }
            }}
          />
        ),
      })),
    );
  }, [apiData, allChecked, checkedList]);

  return (
    <>
      <Container fluid>
        <DetailHeader headerInfo={headerInfo} />
        <Card>
          <CardBody>
            <Row>
              <Col>
                <h4>검색</h4>
              </Col>
            </Row>
            <Row className="align-items-center justify-content-center line form-group">
              <Col sm={4}>
                <select
                  className="form-select col-sm-12 form-control"
                  aria-label="Default select example"
                  name="searchKind"
                  value={searchForm.searchKind}
                  onChange={evChangeSearch}
                >
                  <option value="statusAccepted">승인</option>
                  <option value="statusNotAccepted">미승인</option>
                  <option value="bizName">회사명</option>
                  <option value="account">관리 아이디</option>
                  <option value="bizCode">회사 코드</option>
                  <option value="bizCeo">대표자명</option>
                  <option value="bizCrn">사업자등록번호</option>
                </select>
              </Col>
              <Col sm={4}>
                <input
                  className="form-control"
                  type="text"
                  name="searchText"
                  disabled={
                    searchForm.searchKind === 'statusAccepted' ||
                    searchForm.searchKind === 'statusNotAccepted'
                  }
                  value={searchForm.searchText}
                  onChange={evChangeSearch}
                />
              </Col>
            </Row>
            <Row className="form-group align-items-center justify-content-center button-items">
              <button
                type="button"
                onClick={evSearch}
                className="btn btn-primary waves-effect waves-light"
              >
                검색
              </button>
              <button
                type="button"
                onClick={reset}
                className="btn btn-info waves-effect waves-light"
              >
                초기화
              </button>
            </Row>
          </CardBody>
        </Card>
        <Card style={{ marginBottom: '-10px' }}>
          <CardBody>
            <Row
              className="form-group align-items-center justify-content-end button-items"
              style={{ paddingRight: '12px' }}
            >
              <button
                type="button"
                className="btn btn-danger waves-effect waves-light"
                onClick={reject}
              >
                미승인
              </button>
              <button
                type="button"
                className="btn waves-effect waves-light btn-primary"
                onClick={approve}
              >
                승인
              </button>
            </Row>
          </CardBody>
        </Card>
        <GridTable
          data={{ columns: cols, rows: bizList }}
          activeExcel={ACTIVE_EXCEL}
          evClickBtn={null}
        />
      </Container>
    </>
  );
};
export default BizApprove;
