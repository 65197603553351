const result = {
  fail: text => ({
    status: false,
    message: text,
  }),
  success: level => ({
    status: true,
    message: '통과',
    level, // using passwordValid
  }),
};

const LENGTH_0 = '빈칸을 입력해주세요.';
const TRIM = '공백으로 시작하거나 끝내지 마세요';
const NOT_ENOUGH_VALUE = '8자 이상 입력해주세요.';
const NOT_USING_KOR = '한국어를 사용하실수 없습니다.';
const PASSWORD_PATTERN = '영어 대소문자, 숫자 및 특수문자만 사용 가능합니다.';
const PASSWORD_CONDITION =
  '8 자 이상의 영문 대소문자, 숫자 및 특수문자를 두 종류 이상 사용해주세요.';
const ONLY_NUMBER = '숫자만 입력해주세요.';
const COMPARING_NUMBERS = '최소값은 최대값보다 클 수 없습니다.';
const POINT_SEARCH_CONDITION = '최소값과 최대값을 입력해주세요.';
const MAX_MILEAGE = '마일리지 적립률은 100 을 넘을 수 없습니다.';
const MIN_MILEAGE = '마일리지 적립률은 0 보다 낮을 수 없습니다.';
const MIN_GROSS_MARGIN = '매익률은 최소 15% 입니다.';
const PRODUCT_INFOMATION = '필수정보고시를 작성해주세요.';
const REQUIRE_CATEGORY = '3차 카테고리를 선택해주세요';
const REQUIRE_OPTION_NAME = '옵션명을 입력해주세요';
const NO_NEGATIVE_NUMBER = '음수는 입력할 수 없습니다';
const OVER_KEYWORDS = '키워드가 너무 많습니다';

const number = text => /[0-9]/.test(text);
export const notNumber = num => /[^0-9]/.test(num);
export const emailPattern = email =>
  /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i.test(
    email,
  );
const lowerCase = text => /[a-z]/.test(text);
const upperCase = text => /[A-Z]/.test(text);
// const space = text => /\s/.test(text);
export const kor = text => /[가-힣|ㄱ-ㅎ|ㅏ-ㅣ]/.test(text);
export const specialCharacters = text =>
  /[!"#$%&'()*+,-./:;<>=?@[＼\]^_`{|}~ ]/.test(text);
const passwordPattern = text =>
  /[^A-Za-z0-9!"#$%&'()*+,-./:;<>=?@[＼\]^_`{|}~ ]/.test(text);

export const passwordValid = password => {
  const { length } = password;
  let level = 0;

  if (length === 0) return result.fail(LENGTH_0);
  if (length < 8) return result.fail(NOT_ENOUGH_VALUE);
  if (password !== password.trim()) return result.fail(TRIM);
  if (kor(password)) return result.fail(NOT_USING_KOR);
  if (passwordPattern(password)) return result.fail(PASSWORD_PATTERN);
  if (length >= 10) level += 1;
  if (number(password)) level += 1;
  if (lowerCase(password)) level += 1;
  if (upperCase(password)) level += 1;
  if (specialCharacters(password)) level += 1;
  if (level < 3) return result.fail(PASSWORD_CONDITION);
  return result.success(level);
};

export const pointSearchValid = (startPoint, endPoint) => {
  if (startPoint && endPoint) {
    if (notNumber(startPoint) || notNumber(endPoint)) {
      return result.fail(ONLY_NUMBER);
    }
    if (parseInt(startPoint, 10) > parseInt(endPoint, 10))
      return result.fail(COMPARING_NUMBERS);

    return result.success();
  }
  if (!startPoint && !endPoint) {
    return result.success();
  }

  return result.fail(POINT_SEARCH_CONDITION);
};

export const itemValid = (basicInfo, optionList, productInfo) => {
  const {
    category3,
    catgryId,
    itemName,
    infoModel,
    infoOrigin,
    priceReal,
    priceSupl,
    priceSell,
    mileageRatio,
    shipCost,
    shipCostMountain,
    keywords,
  } = basicInfo;

  let text = '상품명';
  if (itemName.length === 0) return result.fail(`${text} : ${LENGTH_0}`);
  if (itemName !== itemName.trim()) return result.fail(`${text} : ${TRIM}`);

  text = '모델명';
  if (infoModel.length === 0) return result.fail(`${text} : ${LENGTH_0}`);
  if (infoModel !== infoModel.trim()) return result.fail(`${text} : ${TRIM}`);

  text = '생산국';
  if (infoOrigin.length === 0) return result.fail(`${text} : ${LENGTH_0}`);
  if (infoOrigin !== infoOrigin.trim()) return result.fail(`${text} : ${TRIM}`);

  text = '권장소비자가격';
  const numberPriceReal = parseInt(priceReal, 10);
  if (priceReal.length === 0) return result.fail(`${text} : ${LENGTH_0}`);
  if (typeof priceReal === 'string' && priceReal !== priceReal.trim())
    return result.fail(`${text} : ${TRIM}`);
  if (numberPriceReal < 0)
    return result.fail(`${text} : ${NO_NEGATIVE_NUMBER}`);
  if (notNumber(priceReal)) return result.fail(`${text} : ${ONLY_NUMBER}`);

  text = '공급가격';
  const numberPriceSupl = parseInt(priceSupl, 10);
  if (priceSupl.length === 0) return result.fail(`${text} : ${LENGTH_0}`);
  if (typeof priceSupl === 'string' && priceSupl !== priceSupl.trim())
    return result.fail(`${text} : ${TRIM}`);
  if (numberPriceSupl < 0)
    return result.fail(`${text} : ${NO_NEGATIVE_NUMBER}`);
  if (notNumber(priceSupl)) return result.fail(`${text} : ${ONLY_NUMBER}`);

  text = '판매가격';
  const numberPriceSell = parseInt(priceSell, 10);
  if (priceSell.length === 0) return result.fail(`${text} : ${LENGTH_0}`);
  if (typeof priceSell === 'string' && priceSell !== priceSell.trim())
    return result.fail(`${text} : ${TRIM}`);
  if (numberPriceSell < 0)
    return result.fail(`${text} : ${NO_NEGATIVE_NUMBER}`);
  if (notNumber(priceSell)) return result.fail(`${text} : ${ONLY_NUMBER}`);

  // text = '적립금';
  // const numberMileage = parseInt(mileageRatio, 10);
  // if (mileageRatio.length === 0) return result.fail(`${text} : ${LENGTH_0}`);
  // if (typeof mileageRatio === 'string' && mileageRatio !== mileageRatio.trim())
  //   return result.fail(`${text} : ${TRIM}`);
  // if (numberMileage < 0) return result.fail(`${text} : ${MIN_MILEAGE}`);
  // if (numberMileage > 100) return result.fail(`${text} : ${MAX_MILEAGE}`);
  // if (notNumber(mileageRatio)) return result.fail(`${text} : ${ONLY_NUMBER}`);

  text = '배송비';
  const numberShipCost = parseInt(shipCost, 10);
  // if (shipCost.length === 0) return result.fail(`${text} : ${LENGTH_0}`);
  if (typeof shipCost === 'string' && shipCost !== shipCost.trim())
    return result.fail(`${text} : ${TRIM}`);
  if (numberShipCost < 0) return result.fail(`${text} : ${NO_NEGATIVE_NUMBER}`);
  if (notNumber(shipCost)) return result.fail(`${text} : ${ONLY_NUMBER}`);

  text = '배송비(도서산간)';
  const numberMountain = parseInt(shipCostMountain, 10);
  // if (shipCostMountain.length === 0)
  //   return result.fail(`${text} : ${LENGTH_0}`);
  if (
    typeof shipCostMountain === 'string' &&
    shipCostMountain !== shipCostMountain.trim()
  )
    return result.fail(`${text} : ${TRIM}`);
  if (numberMountain < 0) return result.fail(`${text} : ${NO_NEGATIVE_NUMBER}`);
  if (notNumber(shipCostMountain))
    return result.fail(`${text} : ${ONLY_NUMBER}`);

  // text = '매익률';
  // const numberGrossMargin = parseInt(grossMargin, 10);
  // if (!numberGrossMargin || numberGrossMargin < 15)
  //   return result.fail(`${text} : ${MIN_GROSS_MARGIN}`);

  text = '카테고리';
  if (!category3 || category3 !== catgryId)
    return result.fail(`${text} : ${REQUIRE_CATEGORY}`);

  text = '옵션';
  if (optionList.length > 1) {
    const noHaveValue = optionList.reduce((acc1, cur1) => {
      if (cur1.optionName.length === 0) acc1.push('optionName');
      const { subOption } = cur1;
      if (subOption.length > 1) {
        const subOptionValues = subOption.reduce((acc2, cur2, index2) => {
          if (cur2.subName.length === 0) acc2.push(index2);
          return acc2;
        }, []);
        acc1.push(...subOptionValues);
      }
      return acc1;
    }, []);
    if (noHaveValue.length > 0) return result.fail(REQUIRE_OPTION_NAME);
  }

  if (!productInfo) return result.fail(PRODUCT_INFOMATION);
  const keys = Object.keys(productInfo);
  const noHaveValue = keys?.reduce((acc, cur) => {
    if (productInfo[cur].length === 0) {
      acc.push(cur);
    }
    return acc;
  }, []);
  if (noHaveValue.length > 0)
    return result.fail(`${PRODUCT_INFOMATION} : ${noHaveValue}`);

  if (keywords && keywords.split(',').length > 20) {
    return result.fail(`${OVER_KEYWORDS}`);
  }

  return result.success();
};

export const orderSearchValid = form => {
  const {
    orderId,
    startItemPrice,
    endItemPrice,
    startShipCost,
    endShipCost,
    startPayAmount,
    endPayAmount,
    startItemCount,
    endItemCount,
  } = form;

  let text = '주문번호';
  const numOrderId = parseInt(orderId === '' ? 0 : orderId, 10);
  if (notNumber(numOrderId)) return result.fail(`${text} : ${ONLY_NUMBER}`);

  text = '상품금액';
  const numStartItemPrice = parseInt(
    startItemPrice === '' ? 0 : startItemPrice,
    10,
  );
  const numEndItemPrice = parseInt(endItemPrice === '' ? 0 : endItemPrice, 10);
  if (notNumber(numStartItemPrice))
    return result.fail(`${text} : ${ONLY_NUMBER}`);
  if (notNumber(numEndItemPrice))
    return result.fail(`${text} : ${ONLY_NUMBER}`);
  if (numStartItemPrice > numEndItemPrice)
    return result.fail(`${text} : ${COMPARING_NUMBERS}`);

  text = '배송비';
  const numStartShipCost = parseInt(
    startShipCost === '' ? 0 : startShipCost,
    10,
  );
  const numEndShipCost = parseInt(endShipCost === '' ? 0 : endShipCost, 10);
  if (notNumber(numStartShipCost))
    return result.fail(`${text} : ${ONLY_NUMBER}`);
  if (notNumber(numEndShipCost)) return result.fail(`${text} : ${ONLY_NUMBER}`);
  if (numStartShipCost > numEndShipCost)
    return result.fail(`${text} : ${COMPARING_NUMBERS}`);

  text = '결제금액';
  const numStartPayAmount = parseInt(
    startPayAmount === '' ? 0 : startPayAmount,
    10,
  );
  const numEndPayAmount = parseInt(endPayAmount === '' ? 0 : endPayAmount, 10);
  if (notNumber(numStartPayAmount))
    return result.fail(`${text} : ${ONLY_NUMBER}`);
  if (notNumber(numEndPayAmount))
    return result.fail(`${text} : ${ONLY_NUMBER}`);
  if (numStartPayAmount > numEndPayAmount)
    return result.fail(`${text} : ${COMPARING_NUMBERS}`);

  text = '수량';
  const numStartItemCount = parseInt(
    startItemCount === '' ? 0 : startItemCount,
    10,
  );
  const numEndItemCount = parseInt(endItemCount === '' ? 0 : endItemCount, 10);
  if (notNumber(numStartItemCount))
    return result.fail(`${text} : ${ONLY_NUMBER}`);
  if (notNumber(numEndItemCount))
    return result.fail(`${text} : ${ONLY_NUMBER}`);
  if (numStartItemCount > numEndItemCount)
    return result.fail(`${text} : ${COMPARING_NUMBERS}`);

  return result.success();
};

export const bulkItemValid = list => {
  return list.reduce((acc, cur, i) => {
    const index = i + 2;
    const {
      itemName,
      infoModel,
      infoOrigin,
      catgryId,
      priceReal,
      priceSupl,
      priceSell,
      shipCost,
      statusSell,
      statusOpen,
      gender,
      taxKind,
      stock,
      infoProduct,
      infoDetail,
      image1,
    } = cur;
    if (itemName.length > 60) {
      acc.push(`${index}열: 상품명 길이`);
      return acc;
    }
    if (infoModel.length > 60) {
      acc.push(`${index}열: 모델명 길이`);
      return acc;
    }
    if (infoOrigin.length > 60) {
      acc.push(`${index}열: 생산국 길이`);
      return acc;
    }
    if (notNumber(catgryId)) {
      acc.push(`${index}열: 카테고리`);
      return acc;
    }
    if (notNumber(priceReal)) {
      acc.push(`${index}열: 권장소비자가격`);
      return acc;
    }
    if (notNumber(priceSupl)) {
      acc.push(`${index}열: 공급가격`);
      return acc;
    }
    if (notNumber(priceSell)) {
      acc.push(`${index}열: 판매가격`);
      return acc;
    }
    if (shipCost && notNumber(shipCost)) {
      acc.push(`${index}열: 배송비`);
      return acc;
    }
    if (!(statusSell === 1 || statusSell === 2 || statusSell === 0)) {
      acc.push(`${index}열: 판매상태`);
      return acc;
    }
    if (!(statusOpen === 1 || statusOpen === 0)) {
      acc.push(`${index}열: 전시상태`);
      return acc;
    }
    if (!(gender === 'both' || gender === 'male' || gender === 'female')) {
      acc.push(`${index}열: 판매대상`);
      return acc;
    }
    if (!(taxKind === 1 || taxKind === 2 || taxKind === 0)) {
      acc.push(`${index}열: 과세/면세/상품권`);
      return acc;
    }
    if (notNumber(stock)) {
      acc.push(`${index}열: 재고`);
      return acc;
    }
    if (notNumber(infoProduct)) {
      acc.push(`${index}열: 필수정보고지 분류`);
      return acc;
    }
    if (image1.length === 0 || !image1) {
      acc.push(`${index}열: 대표이미지`);
      return acc;
    }
    return acc;
  }, []);
};
