import { Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

const ListHeader = ({ onClick, headerInfo }) => {
  const { parentLabel, parentLink, current, btnLabel, btnIcon } = headerInfo;

  return (
    <Row className="align-items-center">
      <Col sm={6}>
        <div className="page-title-box">
          <h4 className="font-size-18">{current}</h4>
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to={parentLink}>{parentLabel}</Link>
            </li>
            <li className="breadcrumb-item active">{current}</li>
          </ol>
        </div>
      </Col>
      <Col sm={6}>
        <div className="float-right d-none d-md-block">
          <Button color="primary" onClick={onClick}>
            <i className={btnIcon} />
            {btnLabel}
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default ListHeader;
