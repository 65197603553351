import React, { Component, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Alert,
  Carousel,
  CarouselItem,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';

// availity-reactstrap-validation

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';
// action
import { registerUser, loginUser } from '../../store/actions';

// import images
import logoSm from '../../assets/images/logo2.png';
import {
  emailPattern,
  kor,
  notNumber,
  passwordValid,
  specialCharacters,
} from '../../util/validation';
import registerAPI from '../../api/registerAPI';

const initialBizInput = {
  bizName: '',
  bizCrn: '',
  bizCeo: '',
  bizPhone: '',
};

const initialMgrInput = {
  mgrName: '',
  mgrPhone: '',
  mgrGrade: '',
  mgrTeam: '',
  mgrEmail: '',
  mgrEntered: '',
};

const initialWorkerInput = {
  account: '',
  passowrd: '',
  repeat: '',
  workerGrade: '1',
};

const emptyValue = '해당 항목을 작성해주세요.';
// const slides;

const CustomFormGroup = ({
  id = name,
  name,
  value,
  label,
  type = 'text',
  onChange,
  error,
  required,
  className,
  placeholder,
}) => {
  if (error) {
    return (
      <FormGroup>
        <Label htmlFor={id} className="text-danger">
          {label}
        </Label>
        <Input
          className="border-danger"
          type={type}
          value={value}
          name={name}
          id={id}
          placeholder={placeholder}
          onChange={onChange}
          required={required}
        />
        <Label className="text-danger">{error}</Label>
      </FormGroup>
    );
  }
  return (
    <FormGroup>
      <Label htmlFor={id}>{label}</Label>
      <Input
        type={type}
        value={value}
        name={name}
        id={id}
        placeholder={placeholder}
        onChange={onChange}
        required={required}
      />
    </FormGroup>
  );
};

const Register = () => {
  const [bizInput, setBizInput] = useState(initialBizInput);
  const [bizError, setBizError] = useState(initialBizInput);
  const [mgrInput, setMgrInput] = useState(initialMgrInput);
  const [mgrError, setMgrError] = useState(initialMgrInput);
  const [workerInput, setWorkerInput] = useState(initialWorkerInput);
  const [workerError, setWorkerError] = useState(initialWorkerInput);
  const [step, setStep] = useState(0);
  const [animating, setAnimating] = useState(false);

  const onNextStep = () => {
    if (animating) return;
    setStep(s => s + 1);
  };

  const onPrevStep = () => {
    if (animating) return;
    setStep(s => s - 1);
  };

  const onChangeBizInput = ({ target }) => {
    const { name, value } = target;
    setBizInput(b => ({
      ...b,
      [name]: value,
    }));
    setBizError(b => ({ ...b, [name]: '' }));
  };

  const onChangeMgrInput = ({ target }) => {
    const { name, value } = target;
    setMgrInput(m => ({
      ...m,
      [name]: value,
    }));
    setMgrError(m => ({ ...m, [name]: '' }));
  };

  const onChangeWorkerInput = ({ target }) => {
    const { name, value } = target;
    setWorkerInput(w => ({
      ...w,
      [name]: value,
    }));
    setWorkerError(w => ({ ...w, [name]: '' }));
  };

  const validBiz = () => {
    if (animating) return;
    const { bizName, bizCrn, bizCeo, bizPhone } = bizInput;
    let count = 0;
    if (!bizName.trim()) {
      count += 1;
      setBizError(b => ({ ...b, bizName: emptyValue }));
    }
    if (!bizCrn.trim()) {
      count += 1;
      setBizError(b => ({ ...b, bizCrn: emptyValue }));
    }
    if (!bizCeo.trim()) {
      count += 1;
      setBizError(b => ({ ...b, bizCeo: emptyValue }));
    }
    if (notNumber(bizPhone)) {
      count += 1;
      setBizError(b => ({ ...b, bizPhone: '숫자만 입력해주세요' }));
    }
    if (!bizPhone.trim()) {
      count += 1;
      setBizError(b => ({ ...b, bizPhone: emptyValue }));
    }
    if (count === 0) onNextStep();
  };

  const validMgr = () => {
    if (animating) return;
    const {
      mgrName,
      mgrPhone,
      mgrGrade,
      mgrTeam,
      mgrEmail,
      mgrEntered,
    } = mgrInput;
    let count = 0;
    if (notNumber(mgrPhone)) {
      count += 1;
      setMgrError(m => ({ ...m, mgrPhone: '숫자만 입력해주세요.' }));
    }
    if (!mgrName.trim()) {
      count += 1;
      setMgrError(m => ({ ...m, mgrName: emptyValue }));
    }
    if (!mgrPhone.trim()) {
      count += 1;
      setMgrError(m => ({ ...m, mgrPhone: emptyValue }));
    }
    if (!mgrGrade.trim()) {
      count += 1;
      setMgrError(m => ({ ...m, mgrGrade: emptyValue }));
    }
    if (!mgrTeam.trim()) {
      count += 1;
      setMgrError(m => ({ ...m, mgrTeam: emptyValue }));
    }
    if (!emailPattern(mgrEmail)) {
      count += 1;
      setMgrError(m => ({ ...m, mgrEmail: '이메일을 작성해주세요.' }));
    }
    if (!mgrEmail.trim()) {
      count += 1;
      setMgrError(m => ({ ...m, mgrEmail: emptyValue }));
    }
    if (!mgrEntered.trim()) {
      count += 1;
      setMgrError(m => ({ ...m, mgrEntered: emptyValue }));
    }

    if (count === 0) onNextStep();
  };

  const validWorker = () => {
    if (animating) return;
    const { account, password, repeat } = workerInput;
    let count = 0;
    if (account.length < 6 || account.length > 17) {
      count += 1;
      setWorkerError(w => ({
        ...w,
        account: '6자 이상 16자 이하로 작성해주세요',
      }));
    }
    if (kor(account)) {
      count += 1;
      setWorkerError(w => ({
        ...w,
        account: '아이디에 한글은 사용하실 수 없습니다.',
      }));
    }
    if (specialCharacters(account)) {
      count += 1;
      setWorkerError(w => ({
        ...w,
        account: '아이디에 특수문자는 사용하실 수 없습니다.',
      }));
    }
    const validPw = passwordValid(password);
    if (!validPw.status) {
      count += 1;
      setWorkerError(w => ({
        ...w,
        password: validPw.message,
      }));
    }

    if (repeat !== password) {
      count += 1;
      setWorkerError(w => ({
        ...w,
        repeat: '비밀번호와 일치하지 않습니다.',
      }));
    }
    if (!account.trim()) {
      count += 1;
      setWorkerError(w => ({
        ...w,
        account: emptyValue,
      }));
    }
    if (!password.trim()) {
      count += 1;
      setWorkerError(w => ({
        ...w,
        password: emptyValue,
      }));
    }
    if (!repeat.trim()) {
      count += 1;
      setWorkerError(w => ({
        ...w,
        repeat: emptyValue,
      }));
    }
    if (count === 0) {
      onNextStep();
      onSubmit();
    }
  };

  const onSubmit = async () => {
    const checkAccount = await registerAPI.checkAccount(workerInput);
    if (checkAccount.result) {
      const bizRes = await registerAPI.insertBiz(bizInput);
      const workerRes = await registerAPI.insertWorker(workerInput);
      if (bizRes.result === 1 && workerRes.result === 1) {
        const supplier = {
          bizId: bizRes.bizId,
          workerId: workerRes.workerId,
        };
        const mgr = {
          ...mgrInput,
          bizId: bizRes.bizId,
        };
        const mgrRes = await registerAPI.insertMgr(mgr);
        const supplierRes = await registerAPI.insertSupplier(supplier);
        if (supplierRes.result === 1) {
          onNextStep();
        }
      }
    } else {
      onPrevStep();
      setWorkerError(w => ({ ...w, account: '사용할 수 없는 아이디입니다.' }));
    }

    // TODO 요청 실패한거(아이디 중복등) 관리해야됨
  };

  return (
    <>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-5">
        <div className="container">
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="position-relative">
                <Card className="overflow-hidden">
                  <div className="bg-primary">
                    <div className="text-primary text-center p-4">
                      <h5 className="text-white font-size-20">등록 요청</h5>
                      <p className="text-white-50">
                        협력사 여러분을 환영합니다.
                      </p>
                      {/* <Link to="/" className="logo logo-admin">
                        <img src={logoSm} height="24" alt="logo" />
                      </Link> */}
                    </div>
                  </div>
                  <Carousel
                    activeIndex={step}
                    next={onNextStep}
                    previous={onPrevStep}
                    interval={false}
                    keyboard={false}
                  >
                    {[
                      <CarouselItem
                        key={0}
                        onExiting={() => setAnimating(true)}
                        onExited={() => setAnimating(false)}
                      >
                        <CardBody className="p-4">
                          <div className="p-3">
                            <Row className="mb-3">
                              <Col>
                                <h4
                                  style={{
                                    borderBottom: '1px solid #EBEBEB',
                                    paddingBottom: '0.5rem',
                                  }}
                                >
                                  회사 정보
                                </h4>
                              </Col>
                            </Row>
                            <Form className="form-horizontal mt-4">
                              <div className="form-group">
                                <CustomFormGroup
                                  name="bizName"
                                  label="회사명"
                                  className="form-control"
                                  placeholder="회사명을 입력해주세요."
                                  type="text"
                                  value={bizInput.bizName}
                                  onChange={onChangeBizInput}
                                  error={bizError.bizName}
                                  required
                                />
                              </div>
                              <div className="form-group">
                                <CustomFormGroup
                                  name="bizCrn"
                                  label="사업자 등록번호"
                                  type="text"
                                  required
                                  value={bizInput.bizCrn}
                                  onChange={onChangeBizInput}
                                  placeholder="사업자 등록번호를 입력해주세요."
                                  error={bizError.bizCrn}
                                />
                              </div>
                              <div className="form-group">
                                <CustomFormGroup
                                  name="bizCeo"
                                  label="대표명"
                                  type="text"
                                  required
                                  value={bizInput.bizCeo}
                                  onChange={onChangeBizInput}
                                  placeholder="대표님 성함을 입력해주세요."
                                  error={bizError.bizCeo}
                                />
                              </div>
                              <div className="form-group">
                                <CustomFormGroup
                                  name="bizPhone"
                                  label="대표번호"
                                  type="text"
                                  required
                                  value={bizInput.bizPhone}
                                  onChange={onChangeBizInput}
                                  placeholder="대표번호를 - 없이 입력해주세요."
                                  error={bizError.bizPhone}
                                />
                              </div>
                              <Row className="form-group">
                                <div className="col-12 text-right">
                                  <button
                                    className="btn btn-primary w-md waves-effect waves-light"
                                    type="button"
                                    onClick={validBiz}
                                  >
                                    다음으로
                                  </button>
                                </div>
                              </Row>
                              <h5 className="text-primary text-right">1/3</h5>
                            </Form>
                          </div>
                        </CardBody>
                      </CarouselItem>,
                      <CarouselItem
                        key={1}
                        onExiting={() => setAnimating(true)}
                        onExited={() => setAnimating(false)}
                      >
                        <CardBody className="p-4">
                          <div className="p-3">
                            <Row className="mb-3">
                              <Col>
                                <h4
                                  style={{
                                    borderBottom: '1px solid #EBEBEB',
                                    paddingBottom: '0.5rem',
                                  }}
                                >
                                  담당자 정보
                                </h4>
                              </Col>
                            </Row>
                            <Form className="form-horizontal mt-4">
                              <div className="form-group">
                                <CustomFormGroup
                                  name="mgrName"
                                  label="담당자명"
                                  className="form-control"
                                  placeholder="담당자분의 성함을 입력해주세요."
                                  type="text"
                                  value={mgrInput.mgrName}
                                  onChange={onChangeMgrInput}
                                  required
                                  error={mgrError.mgrName}
                                />
                              </div>
                              <div className="form-group">
                                <CustomFormGroup
                                  name="mgrTeam"
                                  label="부서"
                                  type="text"
                                  required
                                  value={mgrInput.mgrTeam}
                                  onChange={onChangeMgrInput}
                                  placeholder="담당자분의 부서를 입력해주세요."
                                  error={mgrError.mgrTeam}
                                />
                              </div>
                              <div className="form-group">
                                <CustomFormGroup
                                  name="mgrGrade"
                                  label="직급"
                                  type="text"
                                  required
                                  value={mgrInput.mgrGrade}
                                  onChange={onChangeMgrInput}
                                  placeholder="담당자분의 직급을 입력해주세요."
                                  error={mgrError.mgrGrade}
                                />
                              </div>
                              <div className="form-group">
                                <CustomFormGroup
                                  name="mgrPhone"
                                  label="전화번호"
                                  type="text"
                                  required
                                  value={mgrInput.mgrPhone}
                                  onChange={onChangeMgrInput}
                                  placeholder="담당자분의 연락처를 - 없이 입력해주세요."
                                  error={mgrError.mgrPhone}
                                />
                              </div>
                              <div className="form-group">
                                <CustomFormGroup
                                  name="mgrEmail"
                                  label="이메일"
                                  type="text"
                                  required
                                  value={mgrInput.mgrEmail}
                                  onChange={onChangeMgrInput}
                                  placeholder="담당자분의 이메일을 입력해주세요."
                                  error={mgrError.mgrEmail}
                                />
                              </div>
                              <div className="form-group">
                                <CustomFormGroup
                                  name="mgrEntered"
                                  label="입사일"
                                  type="date"
                                  required
                                  value={mgrInput.mgrEntered}
                                  onChange={onChangeMgrInput}
                                  placeholder="담당자분의 입사일을 입력해주세요."
                                  error={mgrError.mgrEntered}
                                />
                              </div>
                              <Row className="form-group">
                                <div className="col-12 d-flex justify-content-between align-items-center">
                                  <button
                                    className="btn btn-primary w-md waves-effect waves-light"
                                    type="button"
                                    onClick={onPrevStep}
                                  >
                                    이전으로
                                  </button>
                                  <button
                                    className="btn btn-primary w-md waves-effect waves-light"
                                    type="button"
                                    onClick={validMgr}
                                  >
                                    다음으로
                                  </button>
                                </div>
                              </Row>
                              <h5 className="text-primary text-right">2/3</h5>
                            </Form>
                          </div>
                        </CardBody>
                      </CarouselItem>,
                      <CarouselItem
                        key={2}
                        onExiting={() => setAnimating(true)}
                        onExited={() => setAnimating(false)}
                      >
                        <CardBody className="p-4">
                          <div className="p-3">
                            <Row className="mb-3">
                              <Col>
                                <h4
                                  style={{
                                    borderBottom: '1px solid #EBEBEB',
                                    paddingBottom: '0.5rem',
                                  }}
                                >
                                  로그인 정보
                                </h4>
                              </Col>
                            </Row>
                            <Form className="form-horizontal mt-4">
                              <div className="form-group">
                                <CustomFormGroup
                                  name="account"
                                  label="아이디"
                                  className="form-control"
                                  placeholder="아이디을 입력해주세요."
                                  type="text"
                                  required
                                  value={workerInput.account}
                                  onChange={onChangeWorkerInput}
                                  error="asd"
                                  error={workerError.account}
                                />
                              </div>
                              <div className="form-group">
                                <CustomFormGroup
                                  name="password"
                                  label="비밀번호"
                                  type="password"
                                  required
                                  value={workerInput.password}
                                  onChange={onChangeWorkerInput}
                                  placeholder="비밀번호를 입력해주세요."
                                  error={workerError.password}
                                />
                              </div>
                              <div className="form-group">
                                <CustomFormGroup
                                  name="repeat"
                                  label="비밀번호 확인"
                                  type="password"
                                  required
                                  value={workerInput.repeat}
                                  onChange={onChangeWorkerInput}
                                  placeholder="비밀번호를 다시 입력해주세요."
                                  error={workerError.repeat}
                                />
                              </div>
                              <Row className="form-group">
                                <div className="col-12 d-flex justify-content-between align-items-center">
                                  <button
                                    className="btn btn-primary w-md waves-effect waves-light"
                                    type="button"
                                    onClick={onPrevStep}
                                  >
                                    이전으로
                                  </button>
                                  <button
                                    className="btn btn-primary w-md waves-effect waves-light"
                                    type="button"
                                    onClick={validWorker}
                                  >
                                    요청보내기
                                  </button>
                                </div>
                              </Row>
                              <h5 className="text-primary text-right">3/3</h5>
                            </Form>
                          </div>
                        </CardBody>
                      </CarouselItem>,
                      <CarouselItem
                        key={3}
                        onExiting={() => setAnimating(true)}
                        onExited={() => setAnimating(false)}
                      >
                        <CardBody className="p-4">
                          <div className="p-3">
                            <h5 className="p-6 m-auto d-flex justify-content-center">
                              잠시만 기다려주세요
                            </h5>
                          </div>
                        </CardBody>
                      </CarouselItem>,
                      <CarouselItem
                        key={4}
                        onExiting={() => setAnimating(true)}
                        onExited={() => setAnimating(false)}
                      >
                        <CardBody className="p-4">
                          <div className="p-3">
                            <h5 className="p-6 mb-4 mx-auto d-flex justify-content-center">
                              정상적으로 접수되었습니다.
                            </h5>
                            <h6 className="p-6 mb-1 mx-auto d-flex justify-content-center">
                              확인 후 연락드리겠습니다.
                            </h6>
                            <h6 className="p-6 m-auto d-flex justify-content-center">
                              문의사항: md@haeden.co.kr
                            </h6>
                          </div>
                        </CardBody>
                      </CarouselItem>,
                    ]}
                  </Carousel>
                </Card>
              </div>
              <div className="mt-5 text-center">
                <p>
                  계정이 이미 있으시다면?{' '}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {' '}
                    로그인{' '}
                  </Link>{' '}
                </p>
                <p className="mb-0">
                  Copyright {new Date().getFullYear()}. HEADEN M&C. All rights
                  reserved.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

// class Register extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {};

//     // handleValidbutton
//     this.handleValidbutton = this.handleValidbutton.bind(this);
//   }

//   // handleValidbutton
//   handleValidbutton(event, values) {
//     this.props.loginUser(
//       { email: 'admin@themesbrand.com', password: '123456' },
//       this.props.history,
//     );
//     // this.props.registerUser(values);
//   }

//   render() {
//     return (
//       <>
//         <div className="home-btn d-none d-sm-block">
//           <Link to="/" className="text-dark">
//             <i className="fas fa-home h2" />
//           </Link>
//         </div>
//         <div className="account-pages my-5 pt-5">
//           <div className="container">
//             <Row className="justify-content-center">
//               <Col md={8} lg={6} xl={5}>
//                 <div className="position-relative">
//                   {this.props.loading ? <Loader /> : null}

//                   <Card className="overflow-hidden">
//                     <div className="bg-primary">
//                       <div className="text-primary text-center p-4">
//                         <h5 className="text-white font-size-20">
//                           Free Register
//                         </h5>
//                         <p className="text-white-50">
//                           Get your free Veltrix account now.
//                         </p>
//                         <Link to="/" className="logo logo-admin">
//                           <img src={logoSm} height="24" alt="logo" />
//                         </Link>
//                       </div>
//                     </div>
//                     <CardBody className="p-4">
//                       <div className="p-3">
//                         <Form
//                           className="form-horizontal mt-4"
//                           onValidbutton={this.handleValidbutton}
//                         >
//                           {this.props.user && this.props.user ? (
//                             <Alert color="success">
//                               Register User Successfully
//                             </Alert>
//                           ) : null}
//                           {this.props.registrationError &&
//                           this.props.registrationError ? (
//                             <Alert color="danger">
//                               {this.props.registrationError}
//                             </Alert>
//                           ) : null}

//                           <div className="form-group">
//                             <CustomFormGroup
//                               name="email"
//                               label="Email"
//                               className="form-control"
//                               placeholder="Enter email"
//                               type="email"
//                               required
//                             />
//                           </div>
//                           <div className="form-group">
//                             <CustomFormGroup
//                               name="username"
//                               label="Username"
//                               type="text"
//                               required
//                               placeholder="Enter username"
//                             />
//                           </div>
//                           <div className="form-group">
//                             <CustomFormGroup
//                               name="password"
//                               label="Password"
//                               type="password"
//                               required
//                               placeholder="Enter Password"
//                             />
//                           </div>
//                           <Row className="form-group">
//                             <div className="col-12 text-right">
//                               <button
//                                 className="btn btn-primary w-md waves-effect waves-light"
//                                 type="button"
//                               >
//                                 Register
//                               </button>
//                             </div>
//                           </Row>
//                           <Row className="form-group mt-2 mb-0">
//                             <div className="col-12 mt-4">
//                               <p className="mb-0">
//                                 By registering you agree to the Veltrix{' '}
//                                 <Link to="#" className="text-primary">
//                                   Terms of Use
//                                 </Link>
//                               </p>
//                             </div>
//                           </Row>
//                         </Form>
//                       </div>
//                     </CardBody>
//                   </Card>
//                 </div>
//                 <div className="mt-5 text-center">
//                   <p>
//                     Already have an account ?{' '}
//                     <Link
//                       to="pages-login"
//                       className="font-weight-medium text-primary"
//                     >
//                       {' '}
//                       Login{' '}
//                     </Link>{' '}
//                   </p>
//                   <p>
//                     © {new Date().getFullYear()} Veltrix. Crafted with{' '}
//                     <i className="mdi mdi-heart text-danger" /> by Themesbrand
//                   </p>
//                 </div>
//               </Col>
//             </Row>
//           </div>
//         </div>
//       </>
//     );
//   }
// }

// const mapStatetoProps = state => {
//   const { user, registrationError, loading } = state.Account;
//   return { user, registrationError, loading };
// };

// export default connect(mapStatetoProps, { registerUser, loginUser })(Register);
export default Register;
