import { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { dateFormat } from '../../util/dateFormat';
import claimAPI from '../../api/claim';

const initialClaim = {
  date: '',
  contents: '',
  memo: '',
  label: '',
};
function OrderClaimInfo({ claim, statusOrder }) {
  const [claimInfo, setClaimInfo] = useState(initialClaim);
  const { date, contents, memo, label } = claimInfo;
  const evChangeForm = ({ target }) => {
    const { name, value } = target;
    setClaimInfo(c => ({
      ...c,
      [name]: value,
    }));
  };
  const evUpdateMemo = useCallback(async () => {
    const result = await claimAPI.update(claimInfo);
  }, [claimInfo]);
  useEffect(() => {
    setClaimInfo(initialClaim);
    if (claim) {
      setClaimInfo({
        ...claim,
        label: statusOrder,
        date: dateFormat(claim.createdAt),
      });
    }
  }, [claim, statusOrder]);
  return (
    <Card>
      <CardBody>
        <Row className="mb-3">
          <Col>
            <h4
              style={{
                borderBottom: '1px solid #EBEBEB',
                paddingBottom: '0.5rem',
              }}
            >
              반품 / 교환 정보
            </h4>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label
                  htmlFor="infoModel"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  반품 / 교환 일자
                </label>
              </Col>
              <Col lg={8}>
                <span className="px-2">{date === '' ? '-' : date}</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label
                  htmlFor="infoModel"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  반품 / 교환 사유
                </label>
              </Col>
              <Col lg={8}>
                <span className="px-2">{contents === '' ? '-' : contents}</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label
                  htmlFor="infoModel"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  반품 / 교환 상태
                </label>
              </Col>
              <Col lg={8}>
                <span className="px-2">{label === '' ? '-' : label}</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={12}>
            <Row className="align-items-center">
              <Col lg={2}>
                <label
                  htmlFor="infoModel"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  메모
                </label>
              </Col>
              <Col lg={10}>
                {statusOrder === '' || !claim ? (
                  <span className="px-2">-</span>
                ) : (
                  <Row>
                    <Col sm={10}>
                      <input
                        name="memo"
                        className="form-control"
                        onChange={evChangeForm}
                        value={memo || ''}
                      />
                    </Col>
                    <Col sm={2}>
                      <button
                        className="btn btn-primary"
                        onClick={evUpdateMemo}
                      >
                        수정
                      </button>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default OrderClaimInfo;
