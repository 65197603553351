import { Row, Col } from 'reactstrap';
import '../css/form-generator.css';

/**
 * Form Group Component
 * ------------------------------------------
 * This component props and elements are TBD.
 * We need to discuss and modify about this.
 * ------------------------------------------
 * @param {Array}  formRowList : Information to render form group
 * @param {Event}  changeEvent : Event when input value is changed
 * formRowList
 *  [ : rowElements
 *    [
 *      {
 *        type: 'label'
 *        forTarget: String - related element id
 *        className: String
 *        text: String
 *      },
 *      {
 *        type: 'text/search/email'
 *        name: String - element id/name
 *        mdSize: number
 *        initValue: String
 *      },
 *      {
 *        type: 'select',
 *        name: String - element id/name
 *        mdSize: number
 *        initValue: String
 *        optionList: [
 *          { label: String, value: String }
 *        ]
 *      }
 *    ]
 *  }
 * @returns {formElements}
 */

const HeadenForm = ({ formRowList, changeEvent }) => {
  const makeLabel = element => {
    return (
      <label htmlFor={element.forTarget} className={element.className}>
        {element.text}
      </label>
    );
  };

  const makeTextInput = element => {
    return (
      <Col md={element.mdSize ?? 10}>
        <input
          type="{element.type ?? text}"
          id={element.name}
          name={element.name}
          value={element.initValue}
          width={element.width ?? 100}
          onChange={changeEvent}
          className="form-control"
        />
      </Col>
    );
  };

  const makeSelect = element => {
    const optionTags = element.optionList.map(opt => {
      return <option value={opt.value}> {opt.label} </option>;
    });

    return (
      <Col md={element.mdSize ?? 10}>
        <select
          id={element.name}
          name={element.name}
          value={element.initValue}
          width={element.width ?? 100}
          onChange={changeEvent}
          className="form-select form-control"
        >
          {optionTags}
        </select>
      </Col>
    );
  };

  const makeTextarea = element => {
    return (
      <Col md={element.mdSize ?? 10}>
        <textarea
          id={element.name}
          name={element.name}
          width={element.width ?? '100%'}
          rows={element.rows ?? 5}
          onChange={changeEvent}
          className="form-control"
        >
          {element.initValue}
        </textarea>
      </Col>
    );
  };

  const makeRadioGroup = element => {
    const optionTags = element.optionList.map(opt => {
      return (
        <div className="custom-control custom-radio custom-control-inline">
          <input
            type="radio"
            id={opt.id}
            name={opt.name}
            value={opt.value}
            onChange={changeEvent}
            className="custom-control-input"
          />
          <label htmlFor={opt.id} className="custom-control-label">
            {opt.label}
          </label>
        </div>
      );
    });

    return <Col md={element.mdSize ?? 10}>{optionTags}</Col>;
  };

  const getElement = element => {
    switch (element.type) {
      case 'label':
        return makeLabel(element);

      case 'select':
        return makeSelect(element);

      case 'textarea':
        return makeTextarea(element);

      case 'radio':
        return makeRadioGroup(element);

      default:
        return makeTextInput(element);
    }
  };

  /* From group rendering */
  const formElements = formRowList.map(formRow => {
    const rowElements = formRow.map(element => {
      return getElement(element);
    });
    return <Row className="form-group from-generator"> {rowElements} </Row>;
  });

  return <>{formElements}</>;
};

export default HeadenForm;
