import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, Col, Row } from 'reactstrap';
import categoryAPI from '../../api/category';
import { dateFormat } from '../../util/dateFormat';

function ItemBasicInfo({ evChangeForm, form }) {
  const {
    category1,
    category2,
    category3,
    statusAccept,
    statusSell,
    statusOpen,
    itemId,
    infoBrand,
    infoFactory,
    itemCode,
    countUpdated,
    infoModel,
    itemName,
    infoOrigin,
    bizName,
    createdAt,
    updatedAt,
    gender,
  } = form;
  const [catgryList, setCatgryList] = useState([]);
  const { tokenValid, role } = useSelector(state => ({
    tokenValid: state.Token.data,
    role: state.Token.role,
  }));

  const fetchCategory = useCallback(async () => {
    const result = await categoryAPI.getList();
    setCatgryList(() => result);
  }, []);

  useEffect(() => {
    if (tokenValid) fetchCategory();
  }, [fetchCategory, tokenValid]);

  return (
    <Card>
      <CardBody>
        <Row className="mb-3">
          <Col>
            <h4
              style={{
                borderBottom: '1px solid #EBEBEB',
                paddingBottom: '0.5rem',
              }}
            >
              기본 정보
            </h4>
          </Col>
        </Row>
        <Row className="align-items-center mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  상품 번호
                </label>
              </Col>
              <Col lg={8}>
                <span className="px-2">{itemCode}</span>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  카테고리
                </label>
              </Col>
              <Col lg={8}>
                <Row>
                  <Col lg={4}>
                    <select
                      className="form-control"
                      name="category1"
                      value={category1}
                      onChange={evChangeForm}
                    >
                      <option value={undefined}>1차 카테고리</option>
                      {catgryList &&
                        catgryList.map(catgry => {
                          if (catgry.parentId === 0) {
                            return (
                              <option
                                value={catgry.catgryId}
                                key={catgry.catgryId}
                              >
                                {catgry.catgryName}
                              </option>
                            );
                          }
                          return undefined;
                        })}
                    </select>
                  </Col>
                  <Col lg={4}>
                    <select
                      className="form-control"
                      name="category2"
                      value={category2}
                      onChange={evChangeForm}
                    >
                      <option value={undefined}>2차 카테고리</option>
                      {category1 &&
                        catgryList.map(catgry => {
                          if (catgry.parentId === parseInt(category1, 10)) {
                            return (
                              <option
                                value={catgry.catgryId}
                                key={catgry.catgryId}
                              >
                                {catgry.catgryName}
                              </option>
                            );
                          }
                          return undefined;
                        })}
                    </select>
                  </Col>
                  <Col lg={4}>
                    <select
                      className="form-control"
                      name="category3"
                      value={category3}
                      onChange={evChangeForm}
                    >
                      <option value={undefined}>3차 카테고리</option>
                      {category2 &&
                        catgryList.map(catgry => {
                          if (catgry.parentId === parseInt(category2, 10)) {
                            return (
                              <option
                                value={catgry.catgryId}
                                key={catgry.catgryId}
                              >
                                {catgry.catgryName}
                              </option>
                            );
                          }
                          return undefined;
                        })}
                    </select>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={12}>
            <Row className="align-items-center">
              <Col lg={2}>
                <label
                  htmlFor="itemName"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  상품명
                </label>
              </Col>
              <Col lg={6}>
                <input
                  id="itemName"
                  name="itemName"
                  type="text"
                  className="form-control"
                  onChange={evChangeForm}
                  value={itemName}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label className="col-lg-12 col-form-label bg-secondary rounded">
                  판매자
                </label>
              </Col>
              <Col lg={8}>
                <span className="px-2">{bizName}</span>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label className="col-lg-12 col-form-label bg-secondary rounded">
                  판매 대상 (통계용)
                </label>
              </Col>
              <Col lg={8}>
                <div className="form-check form-check-inline">
                  <input
                    id="both"
                    name="gender"
                    className="form-check-input"
                    type="radio"
                    value="both"
                    onChange={evChangeForm}
                    checked={gender === 'both'}
                  />
                  <label className="form-check-label" htmlFor="both">
                    공용
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    id="male"
                    name="gender"
                    className="form-check-input"
                    type="radio"
                    value="male"
                    onChange={evChangeForm}
                    checked={gender === 'male'}
                  />
                  <label className="form-check-label" htmlFor="male">
                    남성
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    id="female"
                    name="gender"
                    className="form-check-input"
                    type="radio"
                    value="female"
                    onChange={evChangeForm}
                    checked={gender === 'female'}
                  />
                  <label className="form-check-label" htmlFor="female">
                    여성
                  </label>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label
                  htmlFor="infoModel"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  모델명
                </label>
              </Col>
              <Col lg={8}>
                <input
                  id="infoModel"
                  name="infoModel"
                  type="text"
                  className="form-control"
                  onChange={evChangeForm}
                  value={infoModel}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label
                  htmlFor="infoOrigin"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  생산국(원산지)
                </label>
              </Col>
              <Col lg={8}>
                <input
                  id="infoOrigin"
                  name="infoOrigin"
                  type="text"
                  className="form-control"
                  onChange={evChangeForm}
                  value={infoOrigin}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label
                  htmlFor="infoBrand"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  브랜드명
                </label>
              </Col>
              <Col lg={8}>
                <input
                  id="infoBrand"
                  name="infoBrand"
                  type="text"
                  className="form-control"
                  onChange={evChangeForm}
                  value={infoBrand}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label
                  htmlFor="infoFactory"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  제조사
                </label>
              </Col>
              <Col lg={8}>
                <input
                  id="infoFactory"
                  name="infoFactory"
                  type="text"
                  className="form-control"
                  onChange={evChangeForm}
                  value={infoFactory}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label className="col-lg-12 col-form-label bg-secondary rounded">
                  최초 등록일
                </label>
              </Col>
              <Col lg={8}>
                <span className="px-2">{dateFormat(createdAt)}</span>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label className="col-lg-12 col-form-label bg-secondary rounded">
                  마지막 수정일/ 횟수
                </label>
              </Col>
              <Col lg={8}>
                <span className="px-2">
                  {updatedAt
                    ? `${dateFormat(updatedAt)} / ${countUpdated}`
                    : '없음'}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label className="col-lg-12 col-form-label bg-secondary rounded">
                  승인 여부
                </label>
              </Col>
              {role === 'ROLE_ADMIN' ? (
                <Col lg={8}>
                  <div className="form-check form-check-inline">
                    <input
                      id="statusAccept1"
                      name="statusAccept"
                      className="form-check-input"
                      type="radio"
                      value="1"
                      onChange={evChangeForm}
                      checked={statusAccept === '1'}
                    />
                    <label className="form-check-label" htmlFor="statusAccept1">
                      승인
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      id="statusAccept0"
                      name="statusAccept"
                      className="form-check-input"
                      type="radio"
                      value="0"
                      onChange={evChangeForm}
                      checked={statusAccept === '0'}
                    />
                    <label className="form-check-label" htmlFor="statusAccept0">
                      미승인
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      id="statusAccept2"
                      name="statusAccept"
                      className="form-check-input"
                      type="radio"
                      value="2"
                      onChange={evChangeForm}
                      checked={statusAccept === '2'}
                    />
                    <label className="form-check-label" htmlFor="statusAccept2">
                      승인반려
                    </label>
                  </div>
                </Col>
              ) : (
                <Col lg={8}>
                  <span className="px-2">
                    {(() => {
                      if (statusAccept === '0') {
                        return '미승인';
                      }
                      if (statusAccept === '1') {
                        return '승인';
                      }
                      if (statusAccept === '2') {
                        return '승인반려';
                      }
                      return '오류';
                    })()}
                  </span>
                </Col>
              )}
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label className="col-lg-12 col-form-label bg-secondary rounded">
                  전시 상태
                </label>
              </Col>
              <Col lg={8}>
                <div className="form-check form-check-inline">
                  <input
                    id="statusOpen1"
                    name="statusOpen"
                    className="form-check-input"
                    type="radio"
                    value="1"
                    onChange={evChangeForm}
                    checked={statusOpen === '1'}
                  />
                  <label className="form-check-label" htmlFor="statusOpen1">
                    전시
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    id="statusOpen0"
                    name="statusOpen"
                    className="form-check-input"
                    type="radio"
                    value="0"
                    onChange={evChangeForm}
                    checked={statusOpen === '0'}
                  />
                  <label className="form-check-label" htmlFor="statusOpen0">
                    미전시
                  </label>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  판매 상태
                </label>
              </Col>
              <Col lg={8}>
                <div className="form-check form-check-inline">
                  <input
                    id="statusSell1"
                    name="statusSell"
                    className="form-check-input"
                    type="radio"
                    value="1"
                    onChange={evChangeForm}
                    checked={statusSell === '1'}
                  />
                  <label className="form-check-label" htmlFor="statusSell1">
                    판매중
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    id="statusSell2"
                    name="statusSell"
                    className="form-check-input"
                    type="radio"
                    value="2"
                    onChange={evChangeForm}
                    checked={statusSell === '2'}
                  />
                  <label className="form-check-label" htmlFor="statusSell2">
                    품절
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    id="statusSell0"
                    name="statusSell"
                    className="form-check-input"
                    type="radio"
                    value="0"
                    onChange={evChangeForm}
                    checked={statusSell === '0'}
                  />
                  <label className="form-check-label" htmlFor="statusSell0">
                    판매중단
                  </label>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default ItemBasicInfo;
