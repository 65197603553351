import { MDBDataTable, MDBDataTableV5 } from 'mdbreact';
import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { excelDL } from '../../util/excel';
import './GridStyles.css';

type TableFormat = {
  data: {
    columns: {
      label: string;
      field: string;
      sort?: string;
      width?: number;
      searchable?: boolean;
      minimal: string;
    }[];
    rows: any;
  };
  evClickBtn: any;
  evClickBtn2: any;
  btn: {
    val?: string;
    class?: string;
  };
  excelData: any;
  fileName: any;
};

const searchLabel = '결과 내 검색';
const entryLabel = '조회건수';
const infoLabel = ['조회', '~', '건 전체', '건'];
const pageLabel = ['이전', '다음'];

function GridTable({
  data,
  evClickBtn,
  evClickBtn2,
  excelData,
  fileName,
  btn,
}: TableFormat) {
  // const excelDL = () => {
  //   const cells = data.columns.map(col => col.label);
  //   const ws = xlsx.utils.json_to_sheet(excelData);
  //   const wb = xlsx.utils.book_new();
  //   xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
  //   xlsx.writeFile(wb, 'text.xlsx'); // 파일명 바꿔야함
  // };

  const excelBtn = () => {
    excelDL(fileName, excelData);
  };

  const btnData = {
    val: '등록',
    class: 'btn btn-sm waves-effect waves-light btn-primary',
  };
  if (btn && btn.val) {
    btnData.val = btn.val;
  }

  if (btn && btn.class) {
    btnData.class = `btn btn-sm waves-effect waves-light ${btn.class}`;
  }

  return (
    <Card>
      <CardBody>
        <div className="d-flex justify-content-between align-items-center">
          <Row className="align-items-center">
            {excelData && (
              <>
                <Col>
                  <span className="h4">총 {data.rows.length}건 조회</span>
                </Col>
                <button className="btn btn-success" onClick={excelBtn}>
                  엑셀 다운로드
                </button>
              </>
            )}
          </Row>
          {evClickBtn && (
            <Row className="">
              {evClickBtn2 && (
                <Col>
                  <button
                    className="btn btn-sm waves-effect waves-light btn-danger"
                    onClick={evClickBtn2}
                  >
                    반려
                  </button>
                </Col>
              )}
              <Col>
                <button className={btnData.class} onClick={evClickBtn}>
                  {btnData.val}
                </button>
              </Col>
            </Row>
          )}
        </div>

        <MDBDataTableV5
          responsiveSm
          striped
          entriesOptions={[10, 30, 50]}
          entries={10}
          data={data}
          searchLabel={searchLabel}
          entriesLabel={entryLabel}
          infoLabel={infoLabel}
          paginationLabel={pageLabel}
        />
      </CardBody>
    </Card>
  );
}

export default React.memo(GridTable);
