import { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { cps } from 'redux-saga/effects';
import {
  aMonthAgo,
  aWeekAgo,
  threeDaysAgo,
  threeMonthsAgo,
  today,
} from '../../util/dateFormat';
import { orderSearchValid } from '../../util/validation';

const initialForm = {
  orderId: '',
  nameKind: '',
  searchName: '',
  startDate: aWeekAgo,
  endDate: today,
  itemName: '',
  mallName: '',
  startItemPrice: '',
  endItemPrice: '',
  startShipCost: '',
  endShipCost: '',
  startPayAmount: '',
  endPayAmount: '',
  startItemCount: '',
  endItemCount: '',
  statusOrder: undefined,
  payMethod: undefined,
};

const payMethodList = [
  {
    value: 1,
    label: '카드결제',
  },
  {
    value: 2,
    label: '핸드폰결제',
  },
  {
    value: 3,
    label: '계좌이체',
  },
  {
    value: 4,
    label: '가상계좌',
  },
  {
    value: 5,
    label: '포인트',
  },
  {
    value: 6,
    label: '마일리지',
  },
];

function OrderSearchForm({ orderStatusList, status, evSearch }) {
  const [searchForm, setSearchForm] = useState(initialForm);
  const {
    orderId,
    nameKind,
    searchName,
    startDate,
    endDate,
    itemName,
    mallName,
    startItemPrice,
    endItemPrice,
    startShipCost,
    endShipCost,
    startPayAmount,
    endPayAmount,
    startItemCount,
    endItemCount,
    statusOrder,
    payMethod,
  } = searchForm;
  const evChangeSearch = useCallback(({ target }) => {
    const { name, value } = target;
    setSearchForm(f => ({
      ...f,
      [name]: value,
    }));
  }, []);

  const reset = useCallback(() => {
    setSearchForm(initialForm);
  }, []);

  const evClickDateBtn = useCallback(date => {
    setSearchForm(form => ({
      ...form,
      startDate: date,
      endDate: today,
    }));
  }, []);

  const evSearchBtn = () => {
    const result = orderSearchValid(searchForm);
    if (result.status) {
      evSearch(searchForm);
    }
    if (!result.status) alert(result.message);
  };

  const payMethodLabels = () => {
    return payMethodList.map(method => (
      <div className="form-check form-check-inline" key={method.value}>
        <input
          type="radio"
          id={method.label}
          name="payMethod"
          className="form-check-input"
          value={method.value}
          onChange={evChangeSearch}
        />
        <label className="form-check-label" htmlFor={method.label}>
          {method.label}
        </label>
      </div>
    ));
  };

  const statusOrderLabels = () => {
    return orderStatusList.map(s => (
      <div className="form-check form-check-inline" key={s.codeId}>
        <input
          type="radio"
          id={s.codeLabel}
          name="statusOrder"
          className="form-check-input"
          value={s.code}
          onChange={evChangeSearch}
        />
        <label className="form-check-label" htmlFor={s.codeLabel}>
          {s.codeLabel}
        </label>
      </div>
    ));
  };

  useEffect(() => {
    if (status) {
      setSearchForm(f => ({
        ...f,
        statusOrder: status,
      }));
    }
  }, [status]);

  return (
    <Card>
      <CardBody>
        <Row className="align-items-center mb-3">
          <Col>
            <h4>검색</h4>
          </Col>
        </Row>
        <Row className="align-items-center mb-3">
          <Col sm={6}>
            <Row>
              <Col sm={4}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  주문번호
                </label>
              </Col>
              <Col sm={8}>
                <input
                  className="form-control"
                  type="text"
                  name="orderId"
                  value={orderId}
                  onChange={evChangeSearch}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row>
              <Col sm={4}>
                <select
                  className="form-select form-control bg-secondary"
                  name="nameKind"
                  value={nameKind}
                  onChange={evChangeSearch}
                >
                  <option value="user_name">구매자</option>
                  <option value="recvr_name">받는이</option>
                </select>
              </Col>
              <Col sm={8}>
                <input
                  className="form-control"
                  type="text"
                  name="searchName"
                  value={searchName}
                  onChange={evChangeSearch}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-center mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col sm={4}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  주문일자
                </label>
              </Col>
              <Col sm={8}>
                <Row className="align-items-center">
                  <Col sm={5}>
                    <input
                      className="form-control"
                      type="date"
                      name="startDate"
                      max={endDate}
                      value={startDate}
                      onChange={evChangeSearch}
                    />
                  </Col>
                  <i className="mdi mdi-tilde col-sm-2 d-flex justify-content-center" />
                  <Col sm={5}>
                    <input
                      className="form-control"
                      type="date"
                      name="endDate"
                      value={endDate}
                      min={startDate}
                      onChange={evChangeSearch}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col sm={6}>
            <Row className="align-items-center">
              <Col className="button-items">
                <button
                  type="button"
                  className="btn btn-outline-dark"
                  onClick={() => evClickDateBtn(today)}
                >
                  오늘
                </button>
                <button
                  type="button"
                  className="btn btn-outline-dark"
                  onClick={() => evClickDateBtn(threeDaysAgo)}
                >
                  3일 전
                </button>
                <button
                  type="button"
                  className="btn btn-outline-dark"
                  onClick={() => evClickDateBtn(aWeekAgo)}
                >
                  1주 전
                </button>
                <button
                  type="button"
                  className="btn btn-outline-dark"
                  onClick={() => evClickDateBtn(aMonthAgo)}
                >
                  한달 전
                </button>
                <button
                  type="button"
                  className="btn btn-outline-dark"
                  onClick={() => evClickDateBtn(threeMonthsAgo)}
                >
                  3개월 전
                </button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col sm={4}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  상품명
                </label>
              </Col>
              <Col sm={8}>
                <input
                  className="form-control"
                  type="text"
                  name="itemName"
                  value={itemName}
                  onChange={evChangeSearch}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col sm={4}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  쇼핑몰
                </label>
              </Col>
              <Col sm={8}>
                <select
                  className="form-select form-control"
                  name="mallName"
                  value={mallName}
                  onChange={evChangeSearch}
                >
                  <option value="전체">전체</option>
                  <option value="해든몰">해든몰</option>
                </select>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col sm={4}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  상품금액
                </label>
              </Col>
              <Col sm={8}>
                <Row className="align-items-center">
                  <Col sm={5}>
                    <input
                      className="form-control"
                      type="text"
                      name="startItemPrice"
                      value={startItemPrice}
                      onChange={evChangeSearch}
                    />
                  </Col>
                  <i className="mdi mdi-tilde col-sm-2 d-flex justify-content-center" />
                  <Col sm={5}>
                    <input
                      className="form-control"
                      type="text"
                      name="endItemPrice"
                      value={endItemPrice}
                      onChange={evChangeSearch}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col sm={4}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  배송비
                </label>
              </Col>
              <Col sm={8}>
                <Row className="align-items-center">
                  <Col sm={5}>
                    <input
                      className="form-control"
                      type="text"
                      name="startShipCost"
                      value={startShipCost}
                      onChange={evChangeSearch}
                    />
                  </Col>
                  <i className="mdi mdi-tilde col-sm-2 d-flex justify-content-center" />
                  <Col sm={5}>
                    <input
                      className="form-control"
                      type="text"
                      name="endShipCost"
                      value={endShipCost}
                      onChange={evChangeSearch}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-center mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col sm={4}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  결제금액
                </label>
              </Col>
              <Col sm={8}>
                <Row className="align-items-center">
                  <Col sm={5}>
                    <input
                      className="form-control"
                      type="text"
                      name="startPayAmount"
                      value={startPayAmount}
                      onChange={evChangeSearch}
                    />
                  </Col>
                  <i className="mdi mdi-tilde col-sm-2 d-flex justify-content-center" />
                  <Col sm={5}>
                    <input
                      className="form-control"
                      type="text"
                      name="endPayAmount"
                      value={endPayAmount}
                      onChange={evChangeSearch}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col sm={4}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  수량
                </label>
              </Col>
              <Col sm={8}>
                <Row className="align-items-center">
                  <Col sm={5}>
                    <input
                      className="form-control"
                      type="text"
                      name="startItemCount"
                      value={startItemCount}
                      onChange={evChangeSearch}
                    />
                  </Col>
                  <i className="mdi mdi-tilde col-sm-2 d-flex justify-content-center" />
                  <Col sm={5}>
                    <input
                      className="form-control"
                      type="text"
                      name="endItemCount"
                      value={endItemCount}
                      onChange={evChangeSearch}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        {!status && (
          <Row className="align-items-center mb-3">
            <Col sm={2}>
              <label className="col-sm-12 col-form-label bg-secondary rounded">
                주문상태
              </label>
            </Col>
            <Col sm={10}>
              <Row className="align-items-center">
                <Col>
                  <div className="form-check form-check-inline">
                    <input
                      id="statusOrderAll"
                      name="statusOrder"
                      className="form-check-input"
                      type="radio"
                      value={undefined}
                      onChange={evChangeSearch}
                      checked={!statusOrder}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="statusOrderAll"
                    >
                      전체
                    </label>
                  </div>
                  {statusOrderLabels()}
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        <Row className="align-items-center mb-3">
          <Col sm={2}>
            <label className="col-sm-12 col-form-label bg-secondary rounded">
              결제방법
            </label>
          </Col>
          <Col sm={10}>
            <Row className="align-items-center">
              <Col>
                <div className="form-check form-check-inline">
                  <input
                    id="payMethodAll"
                    name="payMethod"
                    className="form-check-input"
                    type="radio"
                    value={undefined}
                    onChange={evChangeSearch}
                    checked={!payMethod}
                  />
                  <label className="form-check-label" htmlFor="payMethodAll">
                    전체
                  </label>
                </div>
                {payMethodLabels()}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="form-group align-items-center justify-content-center button-items">
          <button
            type="button"
            onClick={evSearchBtn}
            className="btn btn-primary waves-effect waves-light"
          >
            검색
          </button>
          <button
            type="button"
            onClick={reset}
            className="btn btn-info waves-effect waves-light"
          >
            초기화
          </button>
        </Row>
      </CardBody>
    </Card>
  );
}

export default OrderSearchForm;
