import { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import DetailHeader from '../../components/Common/DetailHeader';
import textBan from '../../api/textBan';

const TO_LIST = '/text-ban';

const header = {
  parentLabel: '금지어 관리',
  parentLink: '/text-ban',
  current: '금지어 설정',
};

const initialState = {
  banText: '',
};

const TextBanEdit = ({ match, history }) => {
  const { banId } = match.params;
  const [textData, setTextData] = useState(initialState);
  const { tokenValid } = useSelector(state => ({
    tokenValid: state.Token.data,
  }));

  const evChangeForm = ({ target }) => {
    const { name, value } = target;
    setTextData({
      ...textData,
      [name]: value,
    });
  };
  const evToList = () => history.push(TO_LIST);

  const evClickSave = async () => {
    if (banId) {
      await textBan.update(textData);
    } else {
      await textBan.insert(textData);
    }
    evToList();
  };

  const evClickDelete = () => {
    textBan.delete(banId);
    evToList();
  };

  useEffect(() => {
    async function fetchData() {
      if (!banId) return;
      const result = await textBan.getOne(banId);
      setTextData(() => result);
    }
    if (tokenValid) fetchData();
  }, [banId, tokenValid]);

  return (
    <Container fluid>
      <DetailHeader headerInfo={header} />
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h4
                    style={{
                      borderBottom: '1px solid #EBEBEB',
                      paddingBottom: '0.5rem',
                    }}
                  >
                    기본 정보
                  </h4>
                </Col>
              </Row>
              {banId && (
                <Row className="form-group">
                  <Col sm={2}>
                    <label className="col-sm-12 col-form-label bg-secondary rounded">
                      번호
                    </label>
                  </Col>
                  <Col sm={4} className="d-flex align-items-center">
                    {textData.banId}
                  </Col>
                </Row>
              )}
              <Row className="form-group">
                <Col sm={2}>
                  <label
                    htmlFor="ban-text"
                    className="col-sm-12 col-form-label bg-secondary rounded"
                  >
                    금지어
                  </label>
                </Col>
                <Col sm={4}>
                  <input
                    id="ban-text"
                    name="banText"
                    className="form-control"
                    type="text"
                    onChange={evChangeForm}
                    value={textData.banText}
                  />
                </Col>
              </Row>

              <div className="form-group line align-items-center justify-content-center">
                <button
                  onClick={evToList}
                  className="btn btn-info waves-effect waves-light"
                >
                  목록으로
                </button>
                <button
                  onClick={evClickSave}
                  className="btn btn-primary waves-effect waves-light"
                >
                  저장
                </button>
                {banId && (
                  <button
                    onClick={evClickDelete}
                    className="btn btn-warning waves-effect waves-light"
                  >
                    삭제
                  </button>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default TextBanEdit;
