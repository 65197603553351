import axios from './axios';
import { CLAIM } from './urls';

export default {
  async update(params) {
    try {
      return await axios.put(CLAIM.UPSERT, params);
    } catch (e) {
      return e;
    }
  },
};
