import { useRef, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import xlsx from 'xlsx';
import DetailHeader from '../../components/Common/DetailHeader';
import userAPI from '../../api/user';
import { formDL } from '../../util/excel';

const headerInfo = {
  parentLabel: '회원 관리',
  parentLink: '/user',
  current: '회원 일괄 등록',
};

const standardForm = [['이름', '아이디', '전화번호']];

const cols = [
  [
    'userName',
    'account',
    'password',
    'birthday',
    'email',
    'gender',
    'phone',
    'bizId',
    'bizRank',
    'bizEnteredAt',
  ],
];

const temp = [
  {
    userName: 'a',
    account: 'a',
    password: 'a',
    birthday: 19990909,
    email: 'a@a.a',
    gender: 'M',
    phone: '01011112222',
    bizId: 1,
    bizRank: null,
    bizEnteredAt: null,
  },
  {
    userName: 'a1',
    account: 'a1',
    password: '1a',
    birthday: 19990909,
    email: 'a@a.a',
    gender: 'M',
    phone: '01011112222',
    bizId: 1,
    bizRank: null,
    bizEnteredAt: null,
  },
];

const initialState = {
  fileName: '없음',
  length: 0,
  list: undefined,
};
const fileName = '회원일괄등록';

function UserExcel() {
  const [fileInfo, setFileInfo] = useState(initialState);
  const [isReady, setIsReady] = useState(false);
  const fileRef = useRef(null);
  const evFileChange = () => {
    if (fileRef.current.files.length !== 0) {
      setFileInfo(f => ({ ...f, fileName: fileRef.current.files[0].name }));

      const { files } = fileRef.current;
      const f = files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const data = new Uint8Array(e.target.result);
        const workbook = xlsx.read(data, { type: 'array' });
        const wbName = workbook.SheetNames[0];
        const ws = workbook.Sheets[wbName];
        const list = xlsx.utils.sheet_to_json(ws);
        setFileInfo(info => ({
          ...info,
          length: list.length,
          list,
        }));
      };
      reader.readAsArrayBuffer(f);
      setIsReady(true);
    } else {
      setIsReady(false);
      setFileInfo(info => ({
        ...info,
        fileName: '없음',
      }));
    }
  };
  const evSubmitBtn = async () => {
    const result = await userAPI.excelInsert(fileInfo.list);
    if (result > 0) alert('성공');
  };
  // const formDL = () => {
  //   const ws = xlsx.utils.aoa_to_sheet(cells);
  //   const wb = xlsx.utils.book_new();
  //   xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
  //   xlsx.writeFile(wb, 'text.xlsx');
  // };
  const standardDL = () => {
    formDL(fileName, cols);
  };
  return (
    <Container fluid>
      <DetailHeader headerInfo={headerInfo} />
      <Card>
        <CardBody>
          <Row className="align-items-center my-3">
            <span
              style={{ fontSize: '1.2rem', fontWeight: 'bold' }}
              className="ml-3"
            >
              엑셀로 회원 일괄 등록
            </span>
            <button
              type="button"
              className="btn btn-primary btn-sm waves-effect waves-light mx-2"
              onClick={standardDL}
            >
              표준양식 다운로드
            </button>
          </Row>
          <p>
            엑셀 파일로 <strong style={{ fontWeight: 'bold' }}>등록</strong>할
            회원들을 정리하여 다량의 회원을 등록할 수 있습니다
            <br />
            표준 양식을 다운로드 받아 등록에 필요한 정보들을 입력해주시기
            바립니다.
          </p>
          <Row className="mb-2">
            <Col sm={12}>
              <Row className="align-items-center">
                <Col lg={2}>
                  <label className="col-lg-12 col-form-label bg-secondary rounded">
                    Excel 업로드
                  </label>
                </Col>
                <Col lg={9}>
                  <input
                    type="file"
                    tabIndex="-1"
                    id="excelUpload"
                    style={{ display: 'none' }}
                    ref={fileRef}
                    onChange={evFileChange}
                  />
                  <label
                    htmlFor="excelUpload"
                    className="btn btn-success btn-sm waves-effect waves-light col-form-label"
                  >
                    파일 첨부
                  </label>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col sm={12}>
              <Row className="align-items-center">
                <Col lg={2}>
                  <label className="col-lg-12 col-form-label bg-secondary rounded">
                    첨부된 파일명
                  </label>
                </Col>
                <Col lg={9}>
                  <span>{fileInfo.fileName}</span>
                  {isReady && (
                    <button
                      type="button"
                      className="btn btn-primary btn-sm waves-effect waves-light ml-3 col-form-label"
                      onClick={evSubmitBtn}
                    >
                      {fileInfo.length}건 등록 완료하기
                    </button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
}

export default UserExcel;
