// const moment = require('moment');
import moment from 'moment';
import 'moment/locale/ko';

// const KST = 9 * 60 * 60 * 1000;
const FORMAT = 'YYYY-MM-DD';

export const dateFormat = date =>
  moment(new Date(date).getTime()).format('LLL');

export const dateToString = str => moment(str).format(FORMAT);

export const stringToDate = date => moment(date, FORMAT).toDate();

export const toLocalDateTime = date =>
  moment(new Date(date).getTime()).format('YYYY-MM-DD HH:mm:ss');

export const today = moment().format(FORMAT);

export const threeDaysAgo = moment().subtract(3, 'd').format(FORMAT);

export const aWeekAgo = moment().subtract(1, 'w').format(FORMAT);

export const aMonthAgo = moment().subtract(1, 'M').format(FORMAT);

export const threeMonthsAgo = moment().subtract(3, 'M').format(FORMAT);

export const sixMonthsAgo = moment().subtract(6, 'M').format(FORMAT);

export const aYearAgo = moment().subtract(1, 'Y').format(FORMAT);

export const afterSixHours = moment().add(6, 'h').utc();

export const loginTime = moment().utc();
