import { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import GridTable from '../../components/Common/GridTable';
import textBan from '../../api/textBan';
import { dateFormat } from '../../util/dateFormat';
import DetailHeader from '../../components/Common/DetailHeader';

const textCols = [
  {
    label: '번호',
    field: 'banId',
    sort: 'asc',
  },
  {
    label: '금지어',
    field: 'banText',
  },
  {
    label: '등록일',
    field: 'createdAt',
  },
];

const initialForm = {
  banId: undefined,
  banText: '',
  createdAt: '',
  usedFlag: '',
};

const header = {
  parentLabel: '금지어 관리',
  parentLink: '/text-ban',
  current: '금지어 목록',
  btnLabel: '금지어 등록',
  btnIcon: 'mdi mdi-account-edit mr-2',
};

const ADD_PAGE = '/text-ban/add';
const toEdit = id => `/text-ban/${id}`;

const TextBanList = ({ history }) => {
  const [searchForm, setSearchForm] = useState(initialForm);
  const [textList, setTextList] = useState([]);
  const { tokenValid } = useSelector(state => ({
    tokenValid: state.Token.data,
  }));
  const evChangeSearch = ({ target }) => {
    const { name, value } = target;
    setSearchForm(search => ({
      ...search,
      [name]: value,
    }));
  };
  const evSearch = useCallback(async () => {
    const result = await textBan.getList(searchForm);
    setTextList(() =>
      result.map(text => ({
        ...text,
        banText: <Link to={`${toEdit(text.banId)}`}>{text.banText}</Link>,
        createdAt: dateFormat(new Date(text.createdAt)),
      })),
    );
  }, [searchForm]);
  const evToAddPage = () => {
    history.push(ADD_PAGE);
  };
  const reset = () => {
    setSearchForm({
      ...initialForm,
    });
  };

  useEffect(() => {
    async function fetchData() {
      const result = await textBan.getList();
      setTextList(() =>
        result.map(text => ({
          ...text,
          banText: <Link to={`${toEdit(text.banId)}`}>{text.banText}</Link>,
          createdAt: dateFormat(new Date(text.createdAt)),
        })),
      );
    }
    if (tokenValid) fetchData();
  }, [tokenValid]);
  return (
    <Container fluid>
      <DetailHeader headerInfo={header} onClick={evToAddPage} />
      <Card>
        <CardBody>
          <Row>
            <Col>
              <h4>검색</h4>
            </Col>
          </Row>
          <Row>
            <Col sm={2}>
              <label
                htmlFor="ban-text"
                className="col-sm-12 col-form-label bg-secondary rounded"
              >
                금지어
              </label>
            </Col>
            <Col sm={4}>
              <input
                id="ban-text"
                name="banText"
                className="form-control"
                type="text"
                onChange={evChangeSearch}
                value={searchForm.banText}
              />
            </Col>
          </Row>
          <Row className="form-group line align-items-center justify-content-center">
            <button
              type="button"
              onClick={evSearch}
              className="btn btn-primary waves-effect waves-light"
            >
              검색
            </button>
            <button
              type="button"
              onClick={reset}
              className="btn btn-secondary waves-effect waves-light"
            >
              초기화
            </button>
          </Row>
        </CardBody>
      </Card>
      <GridTable
        data={{ columns: textCols, rows: textList }}
        evClickButton={evToAddPage}
      />
    </Container>
  );
};

export default TextBanList;
