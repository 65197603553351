import React, { useEffect, useState, useCallback, useMemo } from 'react';
import queryString from 'query-string';
import { Link, useLocation } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import {
  aMonthAgo,
  aWeekAgo,
  threeDaysAgo,
  threeMonthsAgo,
  today,
} from '../../util/dateFormat';
import { excelDL } from '../../util/excel';
import FormGenerator from '../../util/FormGenerator';
import GridTable from '../../components/Common/GridTable';
import Settlement from '../../api/settlement';
import DetailHeader from '../../components/Common/DetailHeader';
import SettlementListTable from '../../components/Settlement/SettlementListTable';
import biz from '../../api/biz';

const headerInfo = {
  parentLabel: '정산 관리',
  parentLink: '/settlement',
  current: '정산 조회',
  btnLabel: '정산 조회',
  btnIcon: 'mdi mdi-account-edit mr-2',
};

const formRowList = [
  // [
  // {
  //   type: 'label',
  //   label: '협력사',
  // },
  // {
  //   type: 'input',
  //   mdSize: 4,
  //   feild: 'bizName',
  // },
  //   {
  //     offset: 2,
  //     type: 'label',
  //     label: '쇼핑몰',
  //   },
  //   {
  //     type: 'select',
  //     mdSize: 2,
  //     feild: '',
  //     initValue: -1,
  //     optionList: [{ label: '해든몰', value: 'headen' }],
  //   },
  // ],
  [
    // {
    //   type: 'label',
    //   label: '구분',
    // },
    // {
    //   type: 'select',
    //   mdSize: 2,
    //   feild: 'taxKind',
    //   optionList: [
    //     { label: '전체', value: '' },
    //     { label: '과세', value: 0 },
    //     { label: '비과세', value: 1 },
    //     { label: '상품권', value: 2 },
    //   ],
    // },
    {
      type: 'select',
      mdSize: 2,
      offset: 0,
      feild: 'searchType',
      className: ['form-control-label'],
      optionList: [
        { label: '매출이익', value: 'profit' },
        { label: '매입액', value: 'purchase_amount' },
        { label: '매출액', value: 'sales_amount' },
      ],
    },
    {
      type: 'range',
      mdSize: 4,
      feild: 'startRange',
      placeHolder: '이상',
      secondFeild: 'endRange',
      secondPlaceHolder: '이하',
    },
    {
      type: 'label',
      label: '매익률',
      // offset: 2
    },
    {
      type: 'range',
      mdSize: 2,
      feild: 'startPercent',
      placeHolder: '% 이상',
      secondFeild: 'endPercent',
      secondPlaceHolder: '% 이하',
    },
    {
      type: 'text',
      mdSize: 2,
      text: '음수는 숫자 앞에 - 를 붙여주세요.',
      style: {
        lineHeight: '35px',
        fontSize: '12px',
      },
    },
  ],
  [],
];

const defaultSearchForm = {
  startDate: aMonthAgo,
  endDate: today,
  bizName: '',
  // taxKind: '',
  searchType: 'profit',
  startRange: '',
  endRange: '',
  startPercent: '',
  bizId: '',
  endPercent: '',
};

const SettlementList = ({ history }) => {
  const location = useLocation();
  const query = useMemo(() => queryString.parse(location.search), [location]);
  const [settlementRows, setSettlementRows] = useState([]);
  const [searchForm, setSearchForm] = useState(defaultSearchForm);
  const [bizs, setBizs] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const { tokenValid, role } = useSelector(state => ({
    tokenValid: state.Token.data,
    role: state.Token.role,
  }));

  const onToggle = () => {
    setIsOpen(b => !b);
  };

  const onChangeBizName = value => {
    setSearchForm(f => ({ ...f, bizName: value }));
  };

  const evChangeSearch = ({ target }) => {
    const { name, value } = target;
    setSearchForm({
      ...searchForm,
      [name]: value,
    });
  };

  /* Button Action */
  const evClickInit = () => {
    setSearchForm({
      ...defaultSearchForm,
    });
  };

  const evClickDateBtn = useCallback(date => {
    setSearchForm(form => ({
      ...form,
      startDate: date,
      endDate: today,
    }));
  }, []);

  const evClickSearch = useCallback(() => {
    const filteredForm = {
      ...searchForm,
      bizName: '',
      bizId: bizs.find(b => b.bizName === searchForm.bizName)?.bizId,
    };
    const search = queryString.stringify(filteredForm, {
      skipNull: true,
      skipEmptyString: true,
    });
    history.push(`/settlement?${search}`);
  }, [searchForm, history, bizs]);

  const fetchData = useCallback(async () => {
    const response = await Settlement.getList(query);
    if (bizs.length === 0) {
      const bizNames = await biz.getBizNames('bizName');
      setBizs(() => bizNames);
    }
    setSettlementRows(() => response);
    setSearchForm(f => {
      return { ...f, ...query };
    });
  }, [query, bizs]);

  // run when side effect
  useEffect(() => {
    if (tokenValid) {
      fetchData();
    }
  }, [tokenValid, fetchData]);

  const btnList = [
    { label: '오늘', value: today },
    { label: '3일 전', value: threeDaysAgo },
    { label: '1주 전', value: aWeekAgo },
    { label: '한달 전', value: aMonthAgo },
    { label: '3개월 전', value: threeMonthsAgo },
  ];

  const btnElements = btnList.map(btn => {
    return (
      <button
        className="btn btn-outline-dark"
        onClick={() => evClickDateBtn(btn.value)}
      >
        {btn.label}
      </button>
    );
  });

  return (
    <>
      <div className="container-fluid">
        <DetailHeader headerInfo={headerInfo} />

        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row className="form-group">
                  <Col sm={2}>
                    <input
                      className="form-control form-control-label"
                      type="text"
                      value="기간"
                      disabled
                    />
                  </Col>
                  <Col md={4}>
                    <Row className="align-items-center">
                      <Col sm={6}>
                        <input
                          className="form-control"
                          type="date"
                          name="startDate"
                          max={searchForm.endDate}
                          value={searchForm.startDate}
                          onChange={evChangeSearch}
                        />
                      </Col>
                      {/* <i className="mdi mdi-tilde" /> */}
                      <Col sm={6} className="form-tilde">
                        <input
                          className="form-control"
                          type="date"
                          name="endDate"
                          value={searchForm.endDate}
                          min={searchForm.startDate}
                          onChange={evChangeSearch}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col className="button-items"> {btnElements} </Col>
                </Row>
                <Row className="form-group">
                  {role === 'ROLE_ADMIN' && (
                    <>
                      <Col sm={2}>
                        <input
                          className="form-control form-control-label"
                          type="text"
                          value="협력사"
                          disabled
                        />
                      </Col>
                      <Col sm={4}>
                        <Dropdown isOpen={isOpen} toggle={onToggle}>
                          <DropdownToggle
                            tag="span"
                            data-toggle="dropdown"
                            aria-expanded={isOpen}
                          >
                            <input
                              className="form-control"
                              autoComplete="off"
                              name="bizName"
                              placeholder="정확한 협력사명으로만 검색됩니다."
                              value={searchForm.bizName}
                              onChange={evChangeSearch}
                              onKeyDown={() => setIsOpen(true)}
                            />
                          </DropdownToggle>
                          {isOpen && (
                            <DropdownMenu
                              onBlur={onToggle}
                              className="d-flex flex-wrap"
                              modifiers={{
                                setMaxHeight: {
                                  enabled: true,
                                  order: 890,
                                  fn: data => {
                                    return {
                                      ...data,
                                      styles: {
                                        ...data.styles,
                                        overflow: 'auto',
                                        maxHeight: '160px',
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        width: '100%',
                                      },
                                    };
                                  },
                                },
                              }}
                            >
                              {bizs &&
                                bizs
                                  .filter(
                                    b =>
                                      b.bizName.indexOf(searchForm.bizName) !==
                                      -1,
                                  )
                                  ?.map(b => (
                                    <DropdownItem
                                      key={b.bizId}
                                      className="d-flex"
                                      onClick={() => onChangeBizName(b.bizName)}
                                    >
                                      {b.bizName}
                                    </DropdownItem>
                                  ))}
                            </DropdownMenu>
                          )}
                        </Dropdown>
                      </Col>
                    </>
                  )}
                  <Col sm={2}>
                    <input
                      className="form-control form-control-label"
                      type="text"
                      value="쇼핑몰"
                      disabled
                    />
                  </Col>
                  <Col sm={2}>
                    <select className="form-control">
                      <option>해든몰</option>
                    </select>
                  </Col>
                </Row>
                <FormGenerator
                  formData={searchForm}
                  changeEvent={evChangeSearch}
                  formRowList={formRowList}
                />

                <div className="form-group line align-items-center justify-content-center button-items row">
                  <button
                    onClick={evClickSearch}
                    className="btn btn-primary waves-effect waves-light"
                  >
                    검색
                  </button>
                  <button
                    onClick={evClickInit}
                    className="btn btn-secondary waves-effect waves-light"
                  >
                    초기화
                  </button>
                </div>
              </CardBody>
            </Card>

            {/* <Row>
              <Col
                style={{
                  margin: '0 12px',
                  padding: '10px 30px',
                  background: '#FFF',
                }}
              >
                <span style={{ fontSize: '22px' }}>
                  총 {settlementRows.length}건 조회
                </span>

                <button
                  className="btn btn-success waves-effect waves-light"
                  style={{ marginLeft: '20px' }}
                  onClick={evClickExcelDown}
                >
                  엑셀 다운로드
                </button>
              </Col>
            </Row> */}
            <SettlementListTable list={settlementRows} form={searchForm} />
            {/* <GridTable
              data={{ columns: settlementCols, rows: settlementRows }}
            /> */}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SettlementList;
