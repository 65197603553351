import axios from './axios';
import { TAG } from './urls';

export default {
  async insert(params) {
    try {
      return await axios.post(TAG.UPSERT, params);
    } catch (e) {
      return 'INSERT ERROR';
    }
  },

  async getOne(tagId) {
    try {
      return await axios.get(TAG.GET_OR_DELETE + tagId);
    } catch (e) {
      return {};
    }
  },

  /**
   * @param {Object} params : Search conditions
   * @returns {Array}
   */
  async getList(params) {
    try {
      return await axios.get(TAG.GET_LIST, { params });
    } catch (e) {
      return [];
    }
  },

  async update(params) {
    try {
      return await axios.put(TAG.UPSERT, params);
    } catch (e) {
      return 'UPDATE ERROR';
    }
  },

  async delete(tagId) {
    try {
      return await axios.delete(TAG.GET_OR_DELETE + tagId);
    } catch (e) {
      return 'DELETE ERROR';
    }
  },

  async bulkInsert(params) {
    try {
      return await axios.post(TAG.EXCEL, { params });
    } catch (e) {
      return e;
    }
  },
};
