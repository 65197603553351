import axios from './axios';
import { STATISTICS } from './urls';

export default {
  async addProduct(params) {
    try {
      const response = await axios.post(STATISTICS.products, params);
      return response;
    } catch (e) {
      return undefined;
    }
  },
  async updateProduct(params) {
    try {
      const response = await axios.put(STATISTICS.products, params);
      return response;
    } catch (e) {
      return undefined;
    }
  },
  async addBulkProducts(params) {
    try {
      const response = await axios.post(STATISTICS.bulkProducts, {
        dtos: params,
      });
      return response;
    } catch (e) {
      return undefined;
    }
  },
};
