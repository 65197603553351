import { useEffect, useState } from 'react';
import { Row, Card, CardBody, Col, Container } from 'reactstrap';
import { useSelector } from 'react-redux';
import DetailHeader from '../../components/Common/DetailHeader';
import bizAPI from '../../api/biz';
import bizMgrAPI from '../../api/bizManager';
import supplierApi from '../../api/supplier';
import { dateFormat, dateToString, today } from '../../util/dateFormat';
import GridTable from '../../components/Common/GridTable';
import './BizStyles.css';

const headerInfo = {
  parentLabel: '공급사 관리',
  parentLink: '/biz',
  current: '공급사 상세조회',
  trail: ['공급사 조회'],
};

const defaultBizInfo = {
  bizCode: '',
  bizName: '',
  bizCrn: '',
  bizCeo: '',
  bizPhone: '',
  createdAt: '',
  memo: '',
};

let defaultManagerInfo = {
  bizId: '',
  mgrId: null,
  mgrName: '', // 담당자면
  mgrNameLink: '', // 담당자면
  mgrPhone: '',
  mgrGrade: '', // 직급
  mgrTeam: '', // 부서
  mgrEmail: '',
  mgrEnter: today,
  usedFlag: 1,
};

const BizEdit = ({ history, match }) => {
  const { bizId } = match.params;
  defaultManagerInfo = { ...defaultManagerInfo, bizId };
  const [bizInfo, setBizInfo] = useState(defaultBizInfo);
  const [managerInfo, setManagerInfo] = useState(defaultManagerInfo);
  const [apiData, setApiData] = useState([]);
  const [managers, setManagers] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const { tokenValid } = useSelector(state => ({
    tokenValid: state.Token.data,
  }));

  if (!bizId) {
    headerInfo.current = '공급사 추가';
  }

  const evToggleAll = () => {
    if (allChecked) {
      const newVals = checkedList.map(() => false);
      setCheckedList([...newVals]);
    }
    setAllChecked(!allChecked);
  };

  // needs to be inside component because evToggleAll() needs access state
  const cols = [
    {
      label: (
        <input
          type="checkbox"
          id="headerCheckbox"
          checked={allChecked}
          onClick={evToggleAll}
        />
      ),
      field: 'checkbox',
      sort: 'disabled',
    },
    {
      label: '번호',
      field: 'mgrId',
    },
    {
      label: '담당자명',
      field: 'mgrNameLink',
    },
    {
      label: '전화번호',
      field: 'mgrPhone',
    },
    {
      label: '직급',
      field: 'mgrGrade',
    },
    {
      label: '부서',
      field: 'mgrTeam',
    },
    {
      label: '이메일주소',
      field: 'mgrEmail',
    },
    {
      label: '입사일',
      field: 'mgrEnter',
    },
    {
      label: '등록일',
      field: 'createdAt',
    },
  ];

  // toggle all checked box if all rows manually selected
  useEffect(() => {
    if (checkedList.length > 0 && !checkedList.includes(false)) {
      setAllChecked(true);
    }
  }, [checkedList]);

  // ticking clock - only called if creating, not editing
  // useEffect(() => {
  //   if (!bizId) {
  //     const interval = setInterval(() => {
  //       setBizInfo({
  //         ...bizInfo,
  //         createdAt: new Date().toLocaleString(),
  //       });
  //     }, 1000);

  //     return function cleanup() {
  //       clearInterval(interval);
  //     };
  //   }
  //   return null;
  // });

  const evChangeBizInfo = ({ target }) => {
    const { name, value } = target;

    setBizInfo({
      ...bizInfo,
      [name]: value,
    });

    // Validation
    if ((name === 'bizName' || name === 'bizCrn') && value.trim() === '') {
      document.getElementById(target.id).classList.add('is-error');
    } else if (target.id) {
      document.getElementById(target.id).classList.remove('is-error');
    }
  };

  const evChangeManagerInfo = ({ target }) => {
    const { name, value } = target;
    setManagerInfo({
      ...managerInfo,
      [name]: value,
    });
  };

  const evClearManger = () => {
    setManagerInfo(defaultManagerInfo);
  };

  /* Button Action */
  const evToList = id => {
    history.push(`/biz/${id}`);
  };

  // todo - Korean alert messages - Alex (최송합니다 ㅠㅠ)
  const evSaveBizInfo = async () => {
    // required
    if (bizInfo.bizName.trim() === '') {
      document.getElementById('bizNameInput').classList.add('is-error');
      return;
    }
    if (bizInfo.bizCrn.trim() === '') {
      document.getElementById('bizCrnInput').classList.add('is-error');
      return;
    }

    if (bizId) {
      const data = { ...bizInfo, createdAt: '' };
      delete data.createdAt;
      const result = await bizAPI.update(data);
      if (result.result === 'success') {
        alert('수정 성공');
        const bizResult = await bizAPI.getOne(bizId);
        bizResult.createdAt = dateFormat(bizResult.createdAt);
        if (bizResult) {
          setBizInfo(bizResult);
        }
      }
    } else {
      const bizData = { ...bizInfo, ...managerInfo, createdAt: '' };
      delete bizData.createdAt;
      const bizResult = await bizAPI.insert(bizData);
      if (bizResult.resultCode === 'success' && bizResult.bizId) {
        if (
          managerInfo.mgrName ||
          managerInfo.mgrPhone ||
          managerInfo.mgrPhone ||
          managerInfo.mgrGrade ||
          managerInfo.mgrTeam ||
          managerInfo.mgrEmail
        ) {
          const mgrData = { ...managerInfo, bizId: bizResult.bizId };
          await bizMgrAPI.insert(mgrData);
        }
        await supplierApi.insert({ bizId: bizResult.bizId });
        alert('수정 성공');
        evToList(bizResult.bizId);
      } else {
        alert('something went wrong');
      }
    }
  };

  // todo - Korean alert messages - Alex (최송합니다 ㅠㅠ)
  const evSaveManagerInfo = async () => {
    const data = { ...managerInfo, bizId };
    if (managerInfo.mgrId) {
      const result = await bizMgrAPI.update(data);
      if (result.result === 'success') {
        alert('수정 성공');
        window.location.reload();
      }
    } else {
      const result = await bizMgrAPI.insert(data);
      if (result.result === 'success') {
        alert('수정 성공');
        window.location.reload();
      }
    }
  };

  // todo - Korean alert messages - Alex (최송합니다 ㅠㅠ)
  const evDeleteManagers = async () => {
    if (!allChecked && !checkedList.includes(true)) {
      alert('No records selected');
      return;
    }

    let data = [];
    if (allChecked) {
      managers.forEach(mgr => data.push(mgr));
    } else {
      checkedList.forEach((val, i) => {
        if (val) {
          data.push(managers[i]);
        }
      });
    }

    // Strip unserializable props
    data = data.map(mgr => ({
      ...mgr,
      // createdAt: '',
      mgrNameLink: '',
      mgrEnter: '',
      checkbox: '',
    }));
    const result = await bizMgrAPI.deleteList(data);

    if (Array.isArray(result)) {
      let failed = 0;
      result.forEach(r => {
        if (r !== 1) {
          failed += 1;
        }
      });

      if (failed === 0) {
        alert('All successfully updated');
      } else {
        alert(`Failed to delete ${failed} records`);
      }

      // Update data
      const managersResult = await bizMgrAPI.getList({ bizId });
      if (managersResult && Array.isArray(managersResult)) {
        setApiData(() =>
          managersResult.map(mgr => ({
            ...mgr,
            // createdAt: dateFormat(mgr.createdAt),
            mgrEnter: dateToString(mgr.mgrEnter),
            mgrNameLink: (
              <span
                role="button"
                tabIndex={0}
                onKeyDown={() => {
                  setManagerInfo(mgr);
                }}
                onClick={() => setManagerInfo(mgr)}
                style={{ color: 'blue' }}
              >
                {' '}
                {mgr.mgrName}
              </span>
            ),
          })),
        );
      }
      return;
    }
    console.error(result);
    alert('Something went wrong. Please try again');
  };

  // todo - Korean alert messages - Alex (최송합니다 ㅠㅠ)
  const evDeleteSupplier = async () => {
    if (window.confirm('Are you sure?')) {
      const result = await supplierApi.delete(bizId);
      if (result === 1) {
        history.push('/biz');
      }
    }
  };

  // Fetch data
  useEffect(() => {
    async function fetchData() {
      // todo - test
      if (!bizId) return;

      const bizResult = await bizAPI.getOne(bizId);
      bizResult.createdAt = dateFormat(bizResult.createdAt);
      if (bizResult) {
        setBizInfo(bizResult);
      }

      const managersResult = await bizMgrAPI.getList({ bizId });
      if (managersResult && Array.isArray(managersResult)) {
        setApiData(() =>
          managersResult.map(mgr => ({
            ...mgr,
            createdAt: dateFormat(mgr.createdAt),
            mgrEnter: dateToString(mgr.mgrEnter),
            mgrNameLink: (
              <span
                role="button"
                tabIndex={0}
                onKeyDown={() => {
                  setManagerInfo(mgr);
                }}
                onClick={() => setManagerInfo(mgr)}
                style={{ color: 'blue' }}
              >
                {' '}
                {mgr.mgrName}
              </span>
            ),
          })),
        );
      }
    }
    if (tokenValid) fetchData();
  }, [bizId, tokenValid]);

  // Init checkedList if apiData length changes
  useEffect(() => {
    const checkedVals = [];
    for (let i = 0; i < apiData.length; i += 1) {
      checkedVals.push(false);
    }
    setCheckedList([...checkedVals]);
  }, [apiData.length]);

  // Modify and set manger data when apiData changes
  useEffect(() => {
    if (!apiData || apiData.length === 0) return;
    setManagers(() =>
      apiData.map((mgr, i) => ({
        ...mgr,
        checkbox: (
          <input
            type="checkbox"
            name={mgr.mgrId}
            checked={(allChecked ? true : checkedList[i]) || false}
            onChange={() => {
              if (allChecked) {
                const newVals = checkedList.map(() => true);
                setAllChecked(false);
                newVals[i] = false;
                setCheckedList([...newVals]);
              } else {
                const checkedVals = [...checkedList];
                checkedVals[i] = !checkedVals[i];
                setCheckedList(checkedVals);
              }
            }}
          />
        ),
      })),
    );
  }, [allChecked, apiData, checkedList]);

  return (
    <>
      <Container fluid>
        <DetailHeader headerInfo={headerInfo} />
        <Card>
          <CardBody>
            <Row>
              <Col>
                <h4>기본 정보</h4>
              </Col>
            </Row>
            <Row className="align-items-center mb-3">
              <Col sm={2}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  회사코드 번호
                </label>
              </Col>
              <Col sm={4}>
                <input
                  className="form-control"
                  type="text"
                  name="bizCode"
                  value={bizInfo.bizCode}
                  onChange={evChangeBizInfo}
                />
              </Col>
              <Col sm={2}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  회사명
                </label>
              </Col>
              <Col sm={4}>
                <input
                  className="form-control"
                  type="text"
                  name="bizName"
                  id="bizNameInput"
                  value={bizInfo.bizName}
                  onChange={evChangeBizInfo}
                />
              </Col>
            </Row>
            <Row className="align-items-center mb-3">
              <Col sm={2}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  사업자등록번호
                </label>
              </Col>
              <Col sm={4}>
                <input
                  className="form-control"
                  type="text"
                  name="bizCrn"
                  id="bizCrnInput"
                  value={bizInfo.bizCrn}
                  onChange={evChangeBizInfo}
                />
              </Col>
              <Col sm={2}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  대표명
                </label>
              </Col>
              <Col sm={4}>
                <input
                  className="form-control"
                  type="text"
                  name="bizCeo"
                  value={bizInfo.bizCeo}
                  onChange={evChangeBizInfo}
                />
              </Col>
            </Row>
            <Row className="align-items-center mb-3">
              <Col sm={2}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  전화번호
                </label>
              </Col>
              <Col sm={4}>
                <input
                  className="form-control"
                  type="text"
                  name="bizPhone"
                  value={bizInfo.bizPhone}
                  onChange={evChangeBizInfo}
                />
              </Col>
              {bizId && (
                <>
                  <Col sm={2}>
                    <label className="col-sm-12 col-form-label bg-secondary rounded">
                      생성일
                    </label>
                  </Col>
                  <Col sm={4}>
                    <input
                      className="form-control"
                      type="text"
                      disabled
                      value={bizInfo.createdAt}
                    />
                  </Col>
                </>
              )}
            </Row>
            <Row>
              <Col>
                <h4>메모</h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <textarea
                  style={{ resize: 'both', width: '100%' }}
                  rows={7}
                  value={bizInfo.memo}
                  name="memo"
                  onChange={evChangeBizInfo}
                />
              </Col>
            </Row>
            {bizId && (
              <Row className="form-group align-items-center justify-content-center button-items">
                <button
                  className="btn btn-primary waves-effect waves-light"
                  onClick={evSaveBizInfo}
                >
                  수정사항 적용
                </button>
              </Row>
            )}
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <h4>담당자 정보</h4>
              </Col>
            </Row>
            <Row className="align-items-center mb-3">
              <Col sm={2}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  담당자명
                </label>
              </Col>
              <Col sm={4}>
                <input
                  className="form-control"
                  type="text"
                  name="mgrName"
                  value={managerInfo.mgrName}
                  onChange={evChangeManagerInfo}
                />
              </Col>
              <Col sm={2}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  전화번호
                </label>
              </Col>
              <Col sm={4}>
                <input
                  className="form-control"
                  type="text"
                  name="mgrPhone"
                  value={managerInfo.mgrPhone}
                  onChange={evChangeManagerInfo}
                />
              </Col>
            </Row>

            <Row className="align-items-center mb-3">
              <Col sm={2}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  직급
                </label>
              </Col>
              <Col sm={4}>
                <input
                  className="form-control"
                  type="text"
                  name="mgrGrade"
                  value={managerInfo.mgrGrade}
                  onChange={evChangeManagerInfo}
                />
              </Col>
              <Col sm={2}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  부서
                </label>
              </Col>
              <Col sm={4}>
                <input
                  className="form-control"
                  type="text"
                  name="mgrTeam"
                  value={managerInfo.mgrTeam}
                  onChange={evChangeManagerInfo}
                />
              </Col>
            </Row>
            <Row className="align-items-center mb-3">
              <Col sm={2}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  이메일 주소
                </label>
              </Col>
              <Col sm={4}>
                <input
                  className="form-control"
                  type="text"
                  name="mgrEmail"
                  value={managerInfo.mgrEmail}
                  onChange={evChangeManagerInfo}
                />
              </Col>
              <Col sm={2}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  입사일
                </label>
              </Col>
              <Col sm={4}>
                <input
                  className="form-control"
                  type="date"
                  name="mgrEnter"
                  value={managerInfo.mgrEnter}
                  onChange={evChangeManagerInfo}
                />
              </Col>
            </Row>

            <Row className="form-group align-items-center justify-content-center button-items">
              {bizId && (
                <button
                  className="btn btn-primary waves-effect waves-light"
                  onClick={evSaveManagerInfo}
                >
                  {managerInfo.mgrId ? '적용' : '추가'}
                </button>
              )}
              {!bizId && (
                <button
                  className="btn btn-primary waves-effect waves-light"
                  onClick={evSaveBizInfo}
                >
                  추가
                </button>
              )}
              <button
                onClick={evClearManger}
                className="btn btn-secondary waves-effect waves-light"
              >
                초기화
              </button>
            </Row>

            {bizId && (
              <>
                <GridTable
                  data={{ columns: cols, rows: managers }}
                  btn={{ val: '삭제', class: 'btn-danger' }}
                  evClickBtn={evDeleteManagers}
                />
              </>
            )}
          </CardBody>
        </Card>
        {bizId && (
          <Row className="form-group align-items-center justify-content-center button-items">
            <button
              className="btn btn-danger waves-effect waves-light"
              onClick={evDeleteSupplier}
            >
              삭제
            </button>
          </Row>
        )}
      </Container>
    </>
  );
};
export default BizEdit;
