import { useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { toLocalDateTime } from '../../util/dateFormat';

function UserInfo({ user, form, evChangeForm, evSave }) {
  const [isShown, setIsShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setIsShown(!isShown);
  };
  const {
    userId,
    account,
    createdAt,
    loginAt,
    updatedAt,
    usedFlag,
    dormant,
    bizEnteredAt,
  } = user;
  const {
    password,
    userName,
    phone,
    birthday,
    email,
    gender,
    statusAccept,
  } = form;
  const dormantFlag = () => {
    if (!dormant) return '해당 안됨';
    return dormant > 90 ? '휴면' : '정상';
  };
  const evSaveBtn = async () => {
    const result = await evSave({
      userId,
      password,
      userName,
      phone,
      birthday,
      email,
      gender,
      statusAccept,
      bizEnteredAt: toLocalDateTime(bizEnteredAt),
    });
  };
  return (
    <Card>
      <CardBody>
        <Row className="mb-2">
          <Col>
            <Row className="align-items-center">
              <Col>
                <h4
                  style={{
                    borderBottom: '1px solid #EBEBEB',
                    paddingBottom: '0.5rem',
                  }}
                >
                  개인 정보
                  <button
                    className="btn btn-primary btn-sm mx-3"
                    onClick={evSaveBtn}
                  >
                    수정사항 적용
                  </button>
                </h4>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={3}>
                <label
                  htmlFor="userId"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  번호
                </label>
              </Col>
              <Col lg={9}>
                <span className="px-2">{userId}</span>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={3}>
                <label
                  htmlFor=""
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  쇼핑몰 구분
                </label>
              </Col>
              <Col lg={9}>
                <span className="px-2">기본</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={3}>
                <label
                  htmlFor="account"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  아이디
                </label>
              </Col>
              <Col lg={9}>
                <span className="px-2">{account}</span>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={3}>
                <label
                  htmlFor="password"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  비밀번호
                </label>
              </Col>
              <Col lg={9} className="input-group">
                <input
                  id="password"
                  name="password"
                  placeholder="암호 변경 시 입력해주세요"
                  type={isShown ? 'text' : 'password'}
                  className="form-control"
                  onChange={evChangeForm}
                />
                <i
                  aria-hidden="true"
                  className={
                    isShown
                      ? 'mdi mdi-eye-off input-group-text'
                      : 'mdi mdi-eye input-group-text'
                  }
                  style={{ borderRadius: '0 0.25rem 0.25rem 0' }}
                  onClick={togglePasswordVisiblity}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={3}>
                <label
                  htmlFor="account"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  회원등급
                </label>
              </Col>
              <Col lg={9}>
                <span className="px-2">기본회원</span>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={3}>
                <label
                  htmlFor="userName"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  성명
                </label>
              </Col>
              <Col lg={9}>
                <input
                  id="userName"
                  name="userName"
                  className="form-control"
                  onChange={evChangeForm}
                  value={userName || ''}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={3}>
                <label
                  htmlFor="phone"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  핸드폰번호
                </label>
              </Col>
              <Col lg={9}>
                <input
                  id="phone"
                  name="phone"
                  className="form-control"
                  onChange={evChangeForm}
                  value={phone || ''}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={3}>
                <label
                  htmlFor="email"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  이메일주소
                </label>
              </Col>
              <Col lg={9}>
                <input
                  id="email"
                  name="email"
                  className="form-control"
                  onChange={evChangeForm}
                  value={email || ''}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={3}>
                <label className="col-lg-12 col-form-label bg-secondary rounded">
                  성별
                </label>
              </Col>
              <Col lg={9}>
                <div className="form-check form-check-inline px-2">
                  <input
                    id="usedFlagM"
                    name="gender"
                    className="form-check-input"
                    type="radio"
                    value="M"
                    onChange={evChangeForm}
                    checked={gender === 'M'}
                  />
                  <label className="form-check-label" htmlFor="genderM">
                    남성
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    id="genderF"
                    name="gender"
                    className="form-check-input"
                    type="radio"
                    value="F"
                    onChange={evChangeForm}
                    checked={gender === 'F'}
                  />
                  <label className="form-check-label" htmlFor="genderF">
                    여성
                  </label>
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={3}>
                <label
                  htmlFor="birthday"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  생년월일
                </label>
              </Col>
              <Col lg={9}>
                <input
                  id="birthday"
                  name="birthday"
                  className="form-control"
                  onChange={evChangeForm}
                  value={birthday || ''}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={3}>
                <label
                  htmlFor="loginAt"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  마지막 로그인 날짜
                </label>
              </Col>
              <Col lg={9}>
                <span className="px-2">{loginAt || `정보 없음`}</span>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={3}>
                <label
                  htmlFor="createdAt"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  최초 가입일
                </label>
              </Col>
              <Col lg={9}>
                <span className="px-2">{createdAt}</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={3}>
                <label
                  htmlFor="statusAccept"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  회원가입 승인상태
                </label>
              </Col>
              <Col lg={9}>
                <div className="form-check form-check-inline px-2">
                  <input
                    id="statusAccept1"
                    name="statusAccept"
                    className="form-check-input"
                    type="radio"
                    value="1"
                    onChange={evChangeForm}
                    checked={statusAccept === '1'}
                  />
                  <label className="form-check-label" htmlFor="statusAccept1">
                    승인
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    id="statusAccept0"
                    name="statusAccept"
                    className="form-check-input"
                    type="radio"
                    value="0"
                    onChange={evChangeForm}
                    checked={statusAccept === '0'}
                  />
                  <label className="form-check-label" htmlFor="statusAccept0">
                    미승인
                  </label>
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={3}>
                <label
                  htmlFor="updatedAt"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  마지막 정보수정일
                </label>
              </Col>
              <Col lg={9}>
                <span className="px-2">{updatedAt || `정보 없음`}</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={3}>
                <label className="col-lg-12 col-form-label bg-secondary rounded">
                  현재 상태(정상 / 탈퇴)
                </label>
              </Col>
              <Col lg={9}>
                <span className="px-2">
                  {usedFlag === '1' ? '정상' : '탈퇴'}
                </span>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={3}>
                <label className="col-lg-12 col-form-label bg-secondary rounded">
                  휴면 여부
                </label>
              </Col>
              <Col lg={9}>
                <span className="px-2">{dormantFlag()}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default UserInfo;
