export const TOKEN_REFRESH = 'TOKEN_REFRESH';
const TOKEN_REFRESH_SUCCESS = 'TOKEN_REFRESH_SUCCESS';
const TOKEN_REFRESH_ERROR = 'TOKEN_REFRESH_ERROR';
export const TOKEN_REFRESH_UNVALID = 'TOKEN_REFRESH_UNVALID';
const ROLE = 'ROLE';

export const tokenRefresh = () => ({ type: TOKEN_REFRESH });
export const tokenRefreshSuccess = payload => ({
  type: TOKEN_REFRESH_SUCCESS,
  payload,
});
export const tokenRefreshError = error => ({
  type: TOKEN_REFRESH_ERROR,
  error,
});
export const tokenRefreshUnvalid = () => ({
  type: TOKEN_REFRESH_UNVALID,
  payload: false,
});
export const setRole = payload => ({
  type: ROLE,
  payload,
});

const initialState = {
  loading: false,
  data: null,
  error: null,
  role: null,
};

export default function token(state = initialState, action) {
  switch (action.type) {
    case TOKEN_REFRESH:
      return {
        ...state,
        loading: true,
        error: null,
        role: null,
      };
    case TOKEN_REFRESH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case TOKEN_REFRESH_ERROR:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
        role: null,
      };
    case TOKEN_REFRESH_UNVALID:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload,
        role: null,
      };
    case ROLE:
      return {
        ...state,
        role: action.payload,
      };
    default:
      return state;
  }
}
