import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  LOGIN_WORKER,
  LOGOUT_WORKER,
  LOGIN_WORKER_SUCCESS,
  LOGOUT_WORKER_SUCCESS,
} from './actionTypes';

const initialState = {
  error: null,
  loading: false,
  isLogin: false,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        isLogin: true,
      };
      break;
    case LOGOUT_USER:
      state = { ...state };
      break;
    case LOGOUT_USER_SUCCESS:
      state = { ...state };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case LOGIN_WORKER:
      state = {
        ...state,
        loading: true,
        error: null,
      };
      break;
    case LOGIN_WORKER_SUCCESS:
      state = { ...state, loading: false, isLogin: true, error: null };
      break;
    case LOGOUT_WORKER:
      state = { ...state };
      break;
    case LOGOUT_WORKER_SUCCESS:
      state = { ...state, isLogin: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
