import { Card, CardBody, Col, Row } from 'reactstrap';
import GridTable from '../Common/GridTable';

const adresCol = [
  {
    label: '번호',
    field: 'adresId',
    minimal: 'sm',
  },
  {
    label: '구분',
    field: 'kind',
    minimal: 'sm',
  },
  {
    label: '우편번호',
    field: 'postCode',
    minimal: 'sm',
  },
  {
    label: '주소',
    field: 'address',
    minimal: 'sm',
  },
  {
    label: '',
    field: 'addressDetail',
    minimal: 'sm',
    sort: 'disabled',
  },
  {
    label: '생성일',
    field: 'createdAt',
    minimal: 'sm',
  },
  {
    label: '상태',
    field: 'usedFlag',
    minimal: 'sm',
  },
];

function UserAddress({ address }) {
  return (
    <Card>
      <CardBody>
        <Row className="mb-2">
          <Col>
            <h4
              style={{
                borderBottom: '1px solid #EBEBEB',
                paddingBottom: '0.5rem',
              }}
            >
              주소지 정보
            </h4>
          </Col>
        </Row>
        <GridTable
          data={{ columns: adresCol, rows: address }}
          evClickBtn={undefined}
        />
      </CardBody>
    </Card>
  );
}

export default UserAddress;
