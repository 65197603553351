import React, { useEffect, useState } from 'react';
import { CardBody, Card, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DetailHeader from '../../components/Common/DetailHeader';
import GridTable from '../../components/Common/GridTable';
import pointKindApi from '../../api/pointKind';
import { dateFormat } from '../../util/dateFormat';

const headerInfo = {
  parentLabel: '회원 관리',
  parentLink: '/pointKind',
  current: '포인트 종류 조회',
};

const defaultSearchForm = {
  searchKind: 'pointKindId',
  searchText: '',
  amountRatio: '',
  ratioType: '>',
};

const cols = [
  {
    label: '번호',
    field: 'pointKindId',
  },
  {
    label: '포인트명',
    field: 'pointName',
  },
  {
    label: '사용가능금액(%)',
    field: 'amountRatio',
  },
  {
    label: '설명',
    field: 'memo',
  },
  {
    label: '생성일',
    field: 'createdAt',
  },
];

const PointKindList = ({ history }) => {
  const [searchForm, setSearchForm] = useState(defaultSearchForm);
  const [apiData, setApiData] = useState([]);
  const [pointKindList, setPointKindList] = useState();
  const { tokenValid } = useSelector(state => ({
    tokenValid: state.Token.data,
  }));

  const evChangeSearchForm = ({ target }) => {
    const { name, value } = target;
    setSearchForm({
      ...searchForm,
      [name]: value,
    });
  };

  const evSearch = async () => {
    const params = {
      [searchForm.searchKind]: searchForm.searchText,
      ratioType: searchForm.ratioType,
      amountRatio: searchForm.amountRatio,
    };
    const result = await pointKindApi.getList({ params });
    if (result && Array.isArray(result)) {
      setApiData(result);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const result = await pointKindApi.getList();
      if (result && Array.isArray(result)) {
        setApiData(result);
      } else {
        setApiData([]);
      }
    }
    if (tokenValid) fetchData();
  }, [tokenValid]);

  useEffect(() => {
    setPointKindList(
      apiData.map(pk => ({
        ...pk,
        createdAt: dateFormat(pk.createdAt),
        amountRatio: `${pk.amountRatio}%`,
        pointName: (
          <Link to={`pointKind/${pk.pointKindId}`}>{pk.pointName}</Link>
        ),
      })),
    );
  }, [apiData]);

  return (
    <>
      <DetailHeader headerInfo={headerInfo} />
      <Card>
        <CardBody>
          <Row className="align-items-center mb-3">
            <Col>
              <h4>검색</h4>
            </Col>
          </Row>
          <Row className="align-items-center mb-3">
            <Col sm={6}>
              <Row>
                <Col sm={3}>
                  <select
                    className="form-select col-sm-12 form-control"
                    name="searchKind"
                    onChange={evChangeSearchForm}
                  >
                    <option value="pointKindId">번호</option>
                    <option value="pointName">포인트명</option>
                  </select>
                </Col>
                <Col sm={9}>
                  <input
                    className="form-control"
                    name="searchText"
                    value={searchForm.searchText}
                    onChange={evChangeSearchForm}
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={6}>
              <Row>
                <Col sm={6}>
                  <label className="col-sm-12 col-form-label bg-secondary rounded">
                    사용 가능 금액 (%)
                  </label>
                </Col>
                <Col sm={3}>
                  <input
                    className="form-control"
                    placeholder="%"
                    name="amountRatio"
                    value={searchForm.amountRatio}
                    onChange={evChangeSearchForm}
                  />
                </Col>
                <Col sm={3}>
                  <select
                    className="form-select col-sm-12 form-control"
                    name="ratioType"
                    onChange={evChangeSearchForm}
                    value={searchForm.ratioType}
                  >
                    <option value=">">이상</option>
                    <option value="<">이하</option>
                  </select>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="form-group align-items-center justify-content-center button-items">
            <button
              type="button"
              onClick={evSearch}
              className="btn btn-primary waves-effect waves-light"
            >
              검색
            </button>
            <button
              type="button"
              onClick={() => setSearchForm(defaultSearchForm)}
              className="btn btn-info waves-effect waves-light"
            >
              초기화
            </button>
          </Row>
        </CardBody>
      </Card>
      <GridTable
        data={{ columns: cols, rows: pointKindList }}
        evClickBtn={() => {
          history.push('pointKind/add');
        }}
      />
    </>
  );
};
export default PointKindList;
