import axios from './axios';
import { REGISTER } from './urls';

export default {
  async insertBiz(params) {
    try {
      const response = await axios.post(REGISTER.UPSERT_BIZ, params);
      return response;
    } catch (e) {
      throw new Error('요청에러');
    }
  },
  async insertWorker(params) {
    try {
      const response = await axios.post(REGISTER.UPSERT_WORKER, params);
      return response;
    } catch (e) {
      throw new Error('요청에러');
    }
  },
  async insertMgr(params) {
    try {
      const response = await axios.post(REGISTER.UPSERT_BIZ_MANAGER, params);
      return response;
    } catch (e) {
      throw new Error('요청에러');
    }
  },
  async insertSupplier(params) {
    try {
      const response = await axios.post(REGISTER.UPSERT_SUPPLIER, params);
      return response;
    } catch (e) {
      throw new Error('요청에러');
    }
  },
  async checkAccount(params) {
    try {
      const response = await axios.get(REGISTER.UPSERT_WORKER, { params });
      return response;
    } catch (e) {
      throw new Error('요청에러');
    }
  },
};
