import React, { Component, useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
} from 'reactstrap';
import { withRouter, Link, useHistory } from 'react-router-dom';

// users
import user4 from '../assets/images/users/user-4.jpg';
import { useDispatch } from 'react-redux';
import { logoutWorker } from '../store/actions';
import auth from '../api/auth';

// class ProfileMenu extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       menu: false,
//     };
//     this.toggle = this.toggle.bind(this);
//   }

//   toggle() {
//     this.setState(prevState => ({
//       menu: !prevState.menu,
//     }));
//   }

//   onClick() {
//     alert('check');
//     localStorage.clear();
//     history;
//   }

//   render() {
function ProfileMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const logOut = () => dispatch(logoutWorker(history));
  const toggle = () => {
    setIsOpen(o => !o);
  };
  const onClick = async () => {
    const result = await auth.logout();
    alert('로그아웃');
    sessionStorage.removeItem('authUser');
    clearInterval(auth.tokenRenewal);
    logOut();
  };

  return (
    <>
      {/* <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item waves-effect"
            id="page-header-user-dropdown"
            tag="button"
          >
            <img
              className="rounded-circle header-profile-user"
              src={user4}
              alt="Header Avatar"
            />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem tag="a" href="#">
              <i className="mdi mdi-account-circle font-size-17 align-middle mr-1" />
              Profile
            </DropdownItem>
            <DropdownItem tag="a" href="#">
              <i className="mdi mdi-wallet font-size-17 align-middle mr-1" />
              My Wallet
            </DropdownItem>
            <DropdownItem tag="a" href="#">
              <span className="badge badge-success float-right">11</span>
              <i className="mdi mdi-settings font-size-17 align-middle mr-1" />
              Settings
            </DropdownItem>
            <DropdownItem tag="a" href="#">
              <i className="mdi mdi-lock-open-outline font-size-17 align-middle mr-1" />
              Lock screen
            </DropdownItem>
            <div className="dropdown-divider" />
            <Link to="/logout" className="dropdown-item">
              <i className="mdi mdi-logout font-size-17 align-middle mr-1" />
              <span>Logout</span>
            </Link>
          </DropdownMenu>
        </Dropdown> */}
      <Dropdown isOpen={isOpen} toggle={toggle}>
        <DropdownToggle className="bg-secondary">
          관리자님 &nbsp; <i className="mdi mdi-chevron-down" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={onClick}>로그아웃</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
}

export default ProfileMenu;
