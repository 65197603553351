import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody, FormGroup, Button } from 'reactstrap';

import DetailHeader from '../../components/Common/DetailHeader';

import {
  getCodeInfo,
  insertCode,
  updateCode,
  deleteCode,
} from '../../api/code';

const headerInfo = {
  parentLabel: '코드 관리',
  parentLink: '/code',
  current: '코드 추가',
};

const initCode = {
  code: '',
  parentCode: '0',
  codeLabel: '',
};

const CodeEdit = ({ history, match }) => {
  const { codeId } = match.params;

  /* Data Form */
  const [codeData, setCodeData] = useState(initCode);

  const evChangeForm = ({ target }) => {
    const { name, value } = target;

    setCodeData({
      ...codeData,
      [name]: value,
    });
  };

  /* Button Action */
  const evClickList = () => {
    history.push('/code/');
  };

  const evClickSave = () => {
    if (codeId) {
      updateCode(codeData).then(response => {
        const result = response.data;
        if (result && result.resultCode === 'success') {
          alert('수정 성공');
        }
      });
    } else {
      insertCode(codeData).then(response => {
        const result = response.data;

        if (result && result.resultCode === 'success') {
          alert('추가 성공');
          history.push(`/code/${result.codeId}`);
        }
      });
    }
  };

  const evClickDelete = () => {
    if (codeId) {
      deleteCode(codeId).then(response => {
        const result = response.data;

        if (result && result.resultCode === 'success') {
          alert('삭제 성공');
        }
      });
    }
  };

  // run when comoponentDidMount
  useEffect(() => {
    // Edit mode
    if (codeId) {
      headerInfo.current = '코드 편집';

      getCodeInfo(codeId).then(response => {
        const result = response.data;

        setCodeData(result);
      });
    }
  }, [codeId]);

  let deleteButtonHtml = '';
  if (codeId) {
    deleteButtonHtml = (
      <button
        onClick={evClickDelete}
        className="btn btn-warning waves-effect waves-light"
      >
        삭제
      </button>
    );
  }

  return (
    <>
      <div className="container-fluid">
        <DetailHeader headerInfo={headerInfo} />

        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row className="form-group">
                  <label htmlFor="code" className="col-sm-2 col-form-label">
                    코드
                  </label>
                  <Col sm={4}>
                    <input
                      id="code"
                      name="code"
                      className="form-control"
                      type="text"
                      onChange={evChangeForm}
                      value={codeData.code}
                    />
                  </Col>

                  <label
                    htmlFor="parentCode"
                    className="col-sm-2 col-form-label"
                  >
                    상위 코드
                  </label>
                  <Col sm={4}>
                    <input
                      id="parentCode"
                      name="parentCode"
                      className="form-control"
                      type="text"
                      onChange={evChangeForm}
                      value={codeData.parentCode}
                    />
                  </Col>
                </Row>

                <Row className="form-group">
                  <label
                    htmlFor="codeLabel"
                    className="col-sm-2 col-form-label"
                  >
                    코드 명칭
                  </label>
                  <Col sm={10}>
                    <input
                      id="codeLabel"
                      name="codeLabel"
                      className="form-control"
                      type="text"
                      onChange={evChangeForm}
                      value={codeData.codeLabel}
                    />
                  </Col>
                </Row>

                <div className="form-group line align-items-center justify-content-center">
                  <button
                    onClick={evClickList}
                    className="btn btn-info waves-effect waves-light"
                  >
                    목록으로
                  </button>
                  <button
                    onClick={evClickSave}
                    className="btn btn-primary waves-effect waves-light"
                  >
                    저장
                  </button>
                  {deleteButtonHtml}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CodeEdit;
