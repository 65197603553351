import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { updated } from '../../store/actions';
import { countUpdate, updatedSaga } from '../../store/count/saga';
import GridTable from '../Common/GridTable';

const columns = [
  {
    label: '주문번호',
    field: 'orderId',
  },
  {
    label: '쇼핑몰',
    field: 'mallName',
  },
  {
    label: '구매자',
    field: 'userName',
  },
  {
    label: '상품명',
    field: 'itemName',
  },
  {
    label: '수량',
    field: 'itemCount',
  },
  {
    label: '상품금액',
    field: 'priceSell',
  },
  {
    label: '배송비',
    field: 'shipCost',
  },
  {
    label: '결제금액',
    field: 'totalAmount',
  },
  {
    label: '받는분',
    field: 'recvrName',
  },
  {
    label: '결제방법',
    field: 'payMethodLabel',
  },
  {
    label: '주문상태',
    field: 'codeLabel',
  },
  {
    label: '주문일자',
    field: 'createdAt',
  },
];

const deliverColumns = [
  {
    label: '주문번호',
    field: 'orderId',
  },
  {
    label: '쇼핑몰',
    field: 'mallName',
  },
  {
    label: '구매자',
    field: 'userName',
  },
  {
    label: '상품명',
    field: 'itemName',
  },
  {
    label: '수량',
    field: 'itemCount',
  },
  {
    label: '상품금액',
    field: 'priceSell',
  },
  {
    label: '배송비',
    field: 'shipCost',
  },
  {
    label: '결제금액',
    field: 'totalAmount',
  },
  {
    label: '택배회사',
    field: 'deliverCompany',
  },
  {
    label: '운송장번호',
    field: 'invoiceCode',
  },
  {
    label: '주문일자',
    field: 'createdAt',
  },
];
const updateAllowCodeList = ['3', '4', '30', '31', '40', '41'];
const STATUS_ORDER_3 = '3';
const STATUS_ORDER_4 = '4';
const STATUS_ORDER_30 = '30';
const STATUS_ORDER_31 = '31';
const STATUS_ORDER_40 = '40';
const STATUS_ORDER_41 = '41';

function OrderTableForm({
  orderList,
  evUpdate,
  btn,
  excelData,
  fileName,
  status,
  companyList,
  isFetched,
  needFetch,
}) {
  const [updateForm, setUpdateForm] = useState([]);
  const [list, setList] = useState([]);
  const [ready, setReady] = useState(false);
  const { countState } = useSelector(state => ({
    countState: state.Count.countState,
  }));
  const location = useLocation();
  const dispatch = useDispatch();
  const onUpdated = useCallback(() => dispatch(updated()), [dispatch]);
  const evChangeUpdateForm = ({ target }) => {
    const { id, name, value } = target;
    setUpdateForm(form =>
      form.find(f => f.id === id)
        ? form.map(f =>
            f.id === id
              ? {
                  ...f,
                  [name]: value,
                }
              : f,
          )
        : form.concat({
            id,
            [name]: value,
          }),
    );
  };

  const selectBoxDeliverCompany = useCallback(
    id => {
      return (
        <select
          className="form-select form-control"
          id={id}
          key={id}
          name="companyId"
          onChange={evChangeUpdateForm}
        >
          <option value="0">선택</option>
          {companyList &&
            companyList.map(c => (
              <option value={c.companyId} key={c.companyId}>
                {c.companyName}
              </option>
            ))}
        </select>
      );
    },
    [companyList],
  );

  const inputInvoiceCode = useCallback((id, index) => {
    return (
      <input
        type="text"
        key={id}
        onChange={evChangeUpdateForm}
        id={id}
        name="invoiceCode"
      />
    );
  }, []);

  const selectBoxStatusOrder = useCallback(
    id => {
      let options = [];
      if (status === STATUS_ORDER_3) {
        options = [
          {
            value: '3',
            label: '결제완료',
          },
          { value: '4', label: '주문접수' },
        ];
      }
      if (status === STATUS_ORDER_30) {
        options = [
          {
            value: '30',
            label: '반품신청',
          },
          { value: '31', label: '반품접수' },
          { value: '33', label: '반품완료' },
          { value: '12', label: '배송완료' },
        ];
      }
      if (status === STATUS_ORDER_31) {
        options = [
          { value: '31', label: '반품접수' },
          { value: '33', label: '반품완료' },
          { value: '12', label: '배송완료' },
        ];
      }
      if (status === STATUS_ORDER_40) {
        options = [
          { value: '40', label: '교환신청' },
          { value: '41', label: '교환접수' },
          { value: '33', label: '배송완료' },
        ];
      }
      return (
        <select
          className="form-select form-control"
          id={id}
          key={id}
          name="statusOrder"
          onChange={evChangeUpdateForm}
        >
          {options.map(o => (
            <option value={o.value} key={o.value}>
              {o.label}
            </option>
          ))}
        </select>
      );
    },
    [status],
  );

  const evUpdateBtn = useCallback(async () => {
    let updateList = updateForm;
    if (status === STATUS_ORDER_4 || status === STATUS_ORDER_41) {
      const nextStep = () => {
        if (status === STATUS_ORDER_4) {
          return '10';
        }
        if (status === STATUS_ORDER_41) {
          return '43';
        }
        return '0';
      };
      updateList = updateForm.reduce((acc, cur) => {
        const { id: orderId, companyId, invoiceCode } = cur;
        const deliverId = orderList.find(
          o => o.orderId === parseInt(orderId, 10),
        )?.deliverId;
        if (
          companyId &&
          invoiceCode &&
          companyId !== '0' &&
          parseInt(invoiceCode, 10)
        ) {
          acc.push({
            orderId: cur.childrenId || orderId,
            deliverId,
            companyId,
            invoiceCode,
            statusOrder: nextStep(),
          });
        }
        return acc;
      }, []);
    } else {
      updateList = updateForm.map(f => ({
        orderId: f.id,
        statusOrder: f.statusOrder,
      }));
    }
    const result = await evUpdate(updateList);
    if (result.list.length > 0) {
      alert(`${result.list.length}건 수정되었습니다.`);
    }
    setUpdateForm([]);
    // setReady(false);
    needFetch();
    onUpdated();
  }, [evUpdate, needFetch, onUpdated, orderList, status, updateForm]);

  useEffect(() => {
    if (!isFetched && !ready) {
      setReady(true);
    }
    if (isFetched && ready) {
      if (!status) {
        setList(orderList);
      }
      if (status === STATUS_ORDER_3) {
        setList(() =>
          orderList.map(o => ({
            ...o,
            codeLabel: selectBoxStatusOrder(o.childrenId || o.orderId),
          })),
        );
      }
      if (status === STATUS_ORDER_4 || status === STATUS_ORDER_41) {
        setUpdateForm(() =>
          orderList.map(o => ({
            id: o.orderId,
            invoiceCode: '',
            companyId: '0',
          })),
        );
        const inputList = orderList.map((o, index) => ({
          ...o,
          deliverCompany: selectBoxDeliverCompany(o.orderId),
          invoiceCode: inputInvoiceCode(o.orderId, index),
        }));
        setList(() => inputList);
      }
      if (
        status === STATUS_ORDER_30 ||
        status === STATUS_ORDER_31 ||
        status === STATUS_ORDER_40
      ) {
        setList(() =>
          orderList.map(o => ({
            ...o,
            codeLabel: selectBoxStatusOrder(o.childrenId || o.orderId),
          })),
        );
      }
      if (
        status !== STATUS_ORDER_3 &&
        status !== STATUS_ORDER_4 &&
        status !== STATUS_ORDER_41 &&
        status !== STATUS_ORDER_30 &&
        status !== STATUS_ORDER_31 &&
        status !== STATUS_ORDER_40
      ) {
        setList(() => orderList);
      }

      setReady(false);
    }
    // return () => setReady(false);
  }, [
    status,
    selectBoxDeliverCompany,
    inputInvoiceCode,
    orderList,
    updateForm,
    selectBoxStatusOrder,
    countState,
    isFetched,
    ready,
    location,
  ]);

  return (
    <GridTable
      data={{
        columns:
          status === STATUS_ORDER_4 || status === STATUS_ORDER_41
            ? deliverColumns
            : columns,
        rows: list,
      }}
      fileName={fileName}
      excelData={excelData}
      evClickBtn={updateAllowCodeList.find(c => c === status) && evUpdateBtn}
      btn={btn}
    />
  );
}

export default OrderTableForm;
