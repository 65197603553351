import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import auth from '../../api/auth';
import { setAuthToken } from '../../api/axios';
import { loginTime } from '../../util/dateFormat';
import {
  setRole,
  tokenRefresh,
  tokenRefreshError,
  tokenRefreshSuccess,
  tokenRefreshUnvalid,
  TOKEN_REFRESH,
  TOKEN_REFRESH_UNVALID,
} from './reducer';

const delay = time => new Promise(resolve => setTimeout(resolve, time));

function* token() {
  try {
    const response = yield call(auth.refresh);
    yield call(setAuthToken, response.token);
    sessionStorage.setItem('authUser', response.account);
    yield put(tokenRefreshSuccess(true));
    yield put(setRole(response.role));
    yield call(delay, 1000 * 60 * 30 - 1000);
    yield put(tokenRefresh());
  } catch (e) {
    yield put(tokenRefreshError(e.message));
  }
}

export function* watchTokenRefresh() {
  yield takeLatest(TOKEN_REFRESH, token);
}

export default function* tokenSaga() {
  yield all([
    fork(watchTokenRefresh),
    //  fork(watchTokenUnvalid)
  ]);
}
