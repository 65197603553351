import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import {
  LOGIN_USER,
  LOGIN_WORKER,
  LOGOUT_USER,
  LOGOUT_WORKER,
} from './actionTypes';
import {
  loginSuccess,
  logoutUserSuccess,
  apiError,
  loginWorkerSuccess,
  logoutWorkerSuccess,
} from './actions';

// AUTH related methods
import authUtils, { getFirebaseBackend } from '../../../helpers/authUtils';
import {
  setRole,
  tokenRefresh,
  tokenRefreshSuccess,
} from '../../token/reducer';

const fireBaseBackend = getFirebaseBackend();

const delay = time => new Promise(resolve => setTimeout(resolve, time));

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(
      fireBaseBackend.loginUser,
      user.email,
      user.password,
    );
    yield put(loginSuccess(response));
    history.push('/');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    const response = yield call(fireBaseBackend.logout);
    yield put(logoutUserSuccess(response));
    history.push('/login');
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* authSaga() {
  yield all([
    fork(watchUserLogin),
    fork(watchUserLogout),
    fork(watchWorkerLogin),
    fork(watchWorkerLogout),
  ]);
}

export function* watchWorkerLogin() {
  yield takeEvery(LOGIN_WORKER, loginWorker);
}

export function* watchWorkerLogout() {
  yield takeEvery(LOGOUT_WORKER, logoutWorker);
}

function* loginWorker({ payload: { worker, history } }) {
  try {
    const response = yield call(
      authUtils.login,
      worker.account,
      worker.password,
    );
    // const response = yield call(
    //   fireBaseBackend.loginWorker,
    //   worker.account,
    //   worker.password,
    // );
    yield put(loginWorkerSuccess(response));
    yield put(tokenRefreshSuccess(true));
    yield put(setRole(response.role));
    history.push('/');
    yield call(delay, 1000 * 60 * 30 - 1000);
    yield call(tokenRefresh());
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutWorker({ payload: { history } }) {
  try {
    // yield call(fireBaseBackend.logoutWorker);
    yield put(logoutWorkerSuccess());
    history.push('/login');
  } catch (error) {
    yield put(apiError(error));
  }
}

export default authSaga;
