import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { IndexKind } from 'typescript';
import userAPI from '../../api/user';
import DetailHeader from '../../components/Common/DetailHeader';
import GridTable from '../../components/Common/GridTable';
import UserSearchForm from '../../components/Users/UserSearchForm';
import { dateFormat, dateToString } from '../../util/dateFormat';

const headerInfo = {
  parentLabel: '회원 관리',
  parentLink: '/user',
  current: '회원 조회',
};
const userCols = [
  {
    label: '번호',
    field: 'index',
    minimal: 'sm',
  },
  {
    label: '아이디',
    field: 'account',
    minimal: 'sm',
  },
  {
    label: '성명',
    field: 'userName',
    minimal: 'sm',
  },
  {
    label: '생년월일',
    field: 'birthday',
    minimal: 'sm',
  },
  {
    label: '회사명',
    field: 'bizName',
    minimal: 'sm',
  },
  {
    label: '전화번호',
    field: 'phone',
    minimal: 'sm',
  },
  {
    label: '보유포인트',
    field: 'currentPoint',
    minimal: 'sm',
  },
  {
    label: '최근 로그인',
    field: 'loginAt',
    minimal: 'sm',
  },
  {
    label: '가입일',
    field: 'createdAt',
    minimal: 'sm',
  },
];

const ADD_PAGE = '/user/add';
const toEditPage = id => `/user/${id}`;
const fileName = '회원목록';

function UserList({ history }) {
  const [userList, setUserList] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const { tokenValid } = useSelector(state => ({
    tokenValid: state.Token.data,
  }));

  const evToAddPage = useCallback(() => {
    history.push(ADD_PAGE);
  }, [history]);

  const listMapping = list => {
    return list.map((user, index) => ({
      ...user,
      index: index + 1,
      currentPoint: `${
        parseInt(user.currentPoint, 10).toLocaleString() || 0
      } P`,
      account: <Link to={`${toEditPage(user.userId)}`}>{user.account}</Link>,
      loginAt: user.loginAt ? dateToString(user.loginAt) : '정보 없음',
      createdAt: dateToString(user.createdAt),
    }));
  };

  const excelMapping = list => {
    return list.map((user, index) => ({
      번호: index + 1,
      아이디: user.account,
      성명: user.userName,
      생년월일: user.birthday,
      전화번호: user.phone,
      보유포인트: user.currentPoint,
      최근로그인: user.loginAt && dateToString(user.loginAt),
      가입일: user.createdAt && dateToString(user.createdAt),
    }));
  };

  const evSearch = useCallback(async searchForm => {
    // 유효성검사 할것 특히 포인트
    const result = await userAPI.getList(searchForm);
    setUserList(() => listMapping(result));
    setExcelData(() => excelMapping(result));
  }, []);

  const fetchData = useCallback(async () => {
    const result = await userAPI.getList();
    setUserList(() => listMapping(result));
    setExcelData(() => excelMapping(result));
  }, []);

  useEffect(() => {
    if (tokenValid) fetchData();
  }, [fetchData, tokenValid]);

  return (
    <Container fluid>
      <DetailHeader headerInfo={headerInfo} />
      <UserSearchForm evSearch={evSearch} />
      <GridTable
        data={{ columns: userCols, rows: userList }}
        evClickBtn={evToAddPage}
        excelData={excelData}
        fileName={fileName}
      />
    </Container>
  );
}

export default React.memo(UserList);
