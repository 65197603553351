import axios from 'axios';
import {
  tokenRefreshSuccess,
  tokenRefreshUnvalid,
} from '../store/token/reducer';
import auth from './auth';
import { API_URL } from './urls';
import store from '../store';

// const AUTH_TOKEN = 'API_KEY';

const instance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    'Content-type': 'application/json; charset=utf-8',
  },
  //   common: {
  //     authorization: AUTH_TOKEN,
  //   },
});
const authorization = 'x-auth-token';

let isTokenRefreshing = false;
const refreshSubscribers = [];

export const setAuthToken = token => {
  instance.defaults.headers.common[authorization] = '';
  delete instance.defaults.headers.common[authorization];

  if (token) {
    instance.defaults.headers.common[authorization] = `${token}`;
  }
};

const onTokenRefreshed = () => {
  refreshSubscribers.map(callback => callback());
};

const addRefreshSubscriber = callback => {
  refreshSubscribers.push(callback);
};

instance.interceptors.response.use(
  response => {
    // Do something with response data
    return response.data;
  },
  async error => {
    const {
      config: originalRequest,
      response: { status },
    } = error;
    if (status === 401 && originalRequest.url !== '/auth') {
      if (!isTokenRefreshing) {
        store.dispatch(tokenRefreshUnvalid());
        isTokenRefreshing = true;
        const checkToken = await auth.checkRefreshToken();
        if (!checkToken) {
          sessionStorage.removeItem('authUser');
          window.history.pushState(null, null, '/login');
          isTokenRefreshing = false;
          return Promise.reject(error);
        }
        const refreshAccessToken = await auth.refresh();
        isTokenRefreshing = false;
        setAuthToken(refreshAccessToken.token);
        store.dispatch(tokenRefreshSuccess(true));
        // onTokenRefreshed();
      }
      // const retryOriginalRequest = new Promise(resolve => {
      //   addRefreshSubscriber(() => {
      //     resolve(axios(originalRequest));
      //   });
      // });
      // return retryOriginalRequest;
    }
    // Do something with response error
    return Promise.reject(error);
  },
);

export default instance;
