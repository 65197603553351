import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';

import DetailHeader from '../../components/Common/DetailHeader';
import FormGenerator from '../../util/FormGenerator';

import Category from '../../api/category';

const headerInfo = {
  parentLabel: '카테고리 관리',
  parentLink: '/category',
  current: '카테고리 추가',
};

const defaultForm = {
  parentId: '0',
  catgryCode: '',
  catgryName: '',
};

const defaultFormRowList = [
  [
    {
      type: 'label',
      label: '대분류 카테고리',
    },
    {
      type: 'select',
      mdSize: 4,
      feild: 'gparentId',
      optionList: [{ label: '대분류 카테고리 등록', value: 0 }],
    },
    {
      type: 'label',
      label: '중분류 카테고리',
    },
    {
      type: 'select',
      mdSize: 4,
      feild: 'parentId',
      optionList: [{ label: '-', value: 0 }],
    },
  ],
  [
    {
      type: 'label',
      label: '카테고리 이름',
    },
    {
      type: 'input',
      mdSize: 4,
      feild: 'catgryName',
    },
    {
      type: 'label',
      label: '카테고리 코드',
    },
    {
      type: 'input',
      mdSize: 4,
      feild: 'catgryCode',
    },
  ],
];

const CategoryEdit = ({ history, match }) => {
  const { catgryId, parentId } = match.params;

  const [formRowList, setFormRowList] = useState(defaultFormRowList);

  /* Data Form */
  const [formData, setFormData] = useState(defaultForm);

  const setSelectOptions = (value, options = []) => {
    const temp = formRowList;
    temp[0][3].optionList = [
      {
        label: value === '0' ? '-' : '중분류 카테고리 등록',
        value,
      },
    ];

    options.forEach(category => {
      temp[0][3].optionList.push({
        label: category.catgryName,
        value: category.catgryId,
      });
    });

    setFormRowList(temp);
    setFormData({
      ...formData,
      gparentId: value,
      parentId: value,
    });
  };

  const evChangeForm = ({ target }) => {
    const { name, value } = target;

    if (name === 'gparentId') {
      if (value === '0') {
        setSelectOptions(value);
      } else {
        Category.getList({ parentId: value }).then(response => {
          setSelectOptions(value, response);
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  /* Button Action */
  const evClickList = () => {
    history.push('/category');
  };

  const evClickSave = () => {
    const now = new Date();

    if (catgryId) {
      Category.update(formData).then(response => {
        if (response?.resultCode === 'success') {
          alert('수정 성공');
        }
      });
    } else {
      Category.insert(formData).then(response => {
        if (response?.resultCode === 'success') {
          alert('추가 성공');
          history.push(`/category/${response.catgryId}`);
        }
      });
    }
  };

  const evClickDelete = () => {
    if (catgryId) {
      Category.delete(catgryId).then(response => {
        if (response?.resultCode === 'success') {
          alert('삭제 성공');
          history.push(`/category`);
        }
      });
    }
  };

  // run when comoponentDidMount
  useEffect(() => {
    const temp = defaultFormRowList;

    if (temp[0][1].optionList.length < 2) {
      Category.getList({ parentId: '0' }).then(response => {
        temp[0][1].optionList = temp[0][1].optionList.concat(
          response.map(cat => ({
            label: cat.catgryName,
            value: cat.catgryId,
          })),
        );

        setFormRowList(temp);
      });
    }

    // Edit mode
    if (catgryId) {
      headerInfo.current = '카테고리 편집';
      Promise.all([
        Category.getOne(catgryId),
        Category.getList({ parentId }),
      ]).then(response => {
        const [userData, parentCategoryList] = response;

        if (!userData) {
          // TODO CHECK :: NOT FOUND USER
          history.push(`/category`);
          return;
        }

        // Set User Data
        if (!userData.gparentId) {
          userData.gparentId = userData.parentId;
        }
        setFormData(userData);

        // Set Parent Options
        if (parentId !== 0 && temp[0][3].optionList.length < 2) {
          temp[0][3].optionList = temp[0][3].optionList.concat(
            parentCategoryList.map(cat => ({
              label: cat.catgryName,
              value: cat.catgryId,
            })),
          );

          setFormRowList(temp);
        }
      });
    }
  }, [catgryId, parentId, history]);

  let deleteButtonHtml = '';
  if (catgryId) {
    deleteButtonHtml = (
      <button
        onClick={evClickDelete}
        className="btn btn-warning waves-effect waves-light"
      >
        삭제
      </button>
    );
  }

  return (
    <>
      <div className="container-fluid">
        <DetailHeader headerInfo={headerInfo} />
        <Row>
          <Col>
            <Card>
              <CardBody>
                <FormGenerator
                  formData={formData}
                  changeEvent={evChangeForm}
                  formRowList={formRowList}
                />

                <div className="form-group line align-items-center justify-content-center">
                  <button
                    onClick={evClickList}
                    className="btn btn-info waves-effect waves-light"
                  >
                    목록으로
                  </button>
                  <button
                    onClick={evClickSave}
                    className="btn btn-primary waves-effect waves-light"
                  >
                    저장
                  </button>
                  {deleteButtonHtml}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CategoryEdit;
