import { useCallback, useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import moment from 'moment';
import { useSelector } from 'react-redux';
import DetailHeader from '../../components/Common/DetailHeader';
import UserBiz from '../../components/Users/UserBiz';
import UserInfo from '../../components/Users/UserInfo';
import bizAPI from '../../api/biz';
import userAPI from '../../api/user';
import AdminMemo from '../../components/Common/AdminMemo';
import UserInfoForm from '../../components/Users/UserInfoForm';
import UserBizForm from '../../components/Users/UserBizForm';
import UserAddress from '../../components/Users/UserAddress';
import {
  dateFormat,
  dateToString,
  toLocalDateTime,
} from '../../util/dateFormat';
import UserPoint from '../../components/Users/UserPoint';
import UserOrder from '../../components/Users/UserOrder';
import { passwordValid } from '../../util/validation';

const initialState = {
  account: '',
  check: undefined,
  userName: '',
  password: '',
  birthday: '',
  phone: '',
  email: '',
  gender: 'M',
  statusAccept: '0',
  bizId: '0',
  bizRank: '',
  bizEnteredAt: '',
  memo: '',
};
const TO_LIST = '/user';

function UserEdit({ history, match }) {
  const userId = parseInt(match.params.userId, 10);
  const { path } = match;
  if (!userId && path !== '/user/add') {
    history.push(TO_LIST);
  }
  const headerInfo = {
    parentLabel: '회원 관리',
    parentLink: '/user',
    current: userId ? '회원 수정' : '회원 등록',
  };

  const [form, setForm] = useState(initialState);
  const [user, setUser] = useState({});
  const [address, setAddress] = useState([]);
  const [biz, setBiz] = useState([]);
  const [point, setPoint] = useState([]);
  const [pointNow, setPonintNow] = useState({});
  const [oder, setOder] = useState([]);
  const [fetched, setFetched] = useState(false);
  const { tokenValid } = useSelector(state => ({
    tokenValid: state.Token.data,
  }));

  const evChangeForm = ({ target }) => {
    const { name, value } = target;
    setForm(f => ({
      ...f,
      [name]: value,
    }));
    if (name === 'account') {
      setForm(f => ({ ...f, check: undefined }));
    }
  };

  const toListPage = useCallback(() => history.push(TO_LIST), [history]);

  const onCheckAccount = async () => {
    const { account } = form;
    if (account.trim().length === 0) {
      alert('아이디를 입력해주세요.');
      return;
    }
    if (account.trim().length < 7 || account.trim().length > 16) {
      alert('아이디를 6자 이상 16자 이하로 입력해주세요.');
      return;
    }
    const response = await userAPI.getList({ account: form.account });
    // console.log(response);
    if (response.length > 0) {
      alert('사용 불가능한 아이디입니다');
      setForm(f => ({
        ...f,
        check: false,
      }));
    } else {
      alert('사용 가능한 아이디입니다.');
      setForm(f => ({
        ...f,
        check: true,
      }));
    }
  };

  const evSave = useCallback(
    async formData => {
      // const findTrim = Object.keys(formData).find(
      //   key =>
      //     formData[key] &&
      //     typeof formData[key] === 'string' &&
      //     formData[key] !== formData[key]?.trim(),
      // );
      // console.log('trim');
      // if (findTrim) return console.log('시작 또는 끝의 공백을 제거해주세요');
      // if (formData.password) {
      //   const { status, message } = passwordValid(formData.password);
      //   if (!status) return alert(`비밀번호: ${message}`);
      // }
      if (!formData.check) {
        if (formData.check === undefined) {
          return alert('아이디 중복확인을 해주세요');
        }
        return alert('사용할 수 없는 아이디입니다.');
      }
      if (!formData.bizId || formData.bizId === '0')
        return alert('회사를 선택해주세요');
      if (!formData.bizEnteredAt || formData.bizEnteredAt === 'Invalid date')
        return alert('입사일을 입력해주세요');

      const userData = Object.keys(formData).reduce((acc, curr) => {
        acc[curr] =
          formData[curr] && typeof formData[curr] === 'string'
            ? formData[curr].trim()
            : formData[curr];
        return acc;
      }, {});
      let result;
      if (userId) {
        result = await userAPI.update({
          ...userData,
          bizEnteredAt: toLocalDateTime(userData.bizEnteredAt),
        });
        if (result.resultCode === 'success') {
          alert('수정 완료');
        }
      } else {
        result = await userAPI.insert({
          ...userData,
          bizEnteredAt: toLocalDateTime(userData.bizEnteredAt),
        });
        if (result.resultCode === 'success') {
          alert('가입 완료');
          toListPage();
        }
      }
      return result;
    },
    [toListPage, userId],
  );

  const evDelete = useCallback(async () => {
    if (window.confirm('삭제하시겠습니까?')) {
      const { resultCode } = await userAPI.delete(userId);
      if (resultCode === 'success') {
        alert('성공적으로 탈퇴처리 되었습니다.');
        toListPage();
      }
    }
  }, [userId, toListPage]);

  const fetchBizList = useCallback(async () => {
    const bizList = await bizAPI.getList();
    setBiz(bizList);
    setFetched(true);
  }, []);

  useEffect(() => {
    async function fetchUserInfo() {
      const userData = await userAPI.getOne(userId);
      const adresData = await userAPI.getAddressListById(userId);
      const pointData = await userAPI.getPointListById(userId);
      const oderData = await userAPI.getOrderListById(userId);
      const pointNowData = pointData
        .map(p => ({
          ...p.pointKind,
          ...p,
          amount: Math.abs(parseInt(p.amount, 10)).toLocaleString(),
          amountStatus: parseInt(p.amount, 10) > 0 ? '추가' : '감소',
          createdAt: dateToString(p.createdAt),
        }))
        .sort((a, b) => {
          if (a.pointId > b.pointId) return -1;
          if (a.pointId < b.pointId) return 1;
          return 0;
        });
      setPoint(() => pointNowData);
      setPonintNow(() => [
        ...pointNowData
          .filter((p, index) => {
            return (
              pointNowData.findIndex(p2 => {
                return p.pointKindId === p2.pointKindId;
              }) === index
            );
          })
          .map(pn => ({
            currentPoint: pn.pointNow,
            pointKindId: pn.pointKindId,
            pointName: pn.pointName,
          })),
      ]);

      const bizData = await bizAPI.getList();
      setBiz(() =>
        bizData.filter(
          (b, index) => bizData.findIndex(b2 => b.bizId === b2.bizId) === index,
        ),
      );
      setUser(() => ({
        ...userData,
        bizEnteredAt: dateToString(userData.bizEnteredAt),
        createdAt: dateFormat(userData.createdAt),
        loginAt: userData.loginAt && dateFormat(userData.loginAt),
        updatedAt: userData.updatedAt && dateFormat(userData.updatedAt),
        dormant:
          userData.loginAt && moment().diff(moment(userData.loginAt), 'days'),
      }));
      setAddress(
        adresData.map(a => ({
          ...a,
          createdAt: dateToString(a.createdAt),
          usedFlag: a.usedFlag === '1' ? '사용중' : '삭제',
        })),
      );
      setOder(
        oderData.map((o, index) => ({
          ...o,
          index: index + 1,
          itemName: (
            <span
              className="d-flex align-items-center "
              style={{
                textOverflow: 'ellipsis',
              }}
            >
              <img
                src={`https://s3.ap-northeast-2.amazonaws.com/files.ai-q.co.kr/shop/thumbnail/${o.imgName}`}
                alt={o.itemName}
                width="48px"
                height="48px"
              />
              {o.itemName}
              <br />
              {o.optionName &&
                `[옵션: ${
                  o.parentOptionName
                    ? `${o.parentOptionName}/${o.optionName}`
                    : o.optionName
                }]`}
            </span>
          ),
          dc: '0원',
          priceSell: `${o.priceSell.toLocaleString()}원`,
          itemCount: `${o.itemCount}개`,
          itemAmount: `${parseInt(o.itemAmount, 10).toLocaleString()}원`,
          totalAmount: `${o.totalAmount?.toLocaleString()}원`,
          shipCost:
            o.shipCost !== '0' ? `${o.shipCost.toLocaleString()}원` : '무료',
          createdAt: dateToString(o.createdAt),
        })),
      );
      setForm(f => ({
        ...f,
        userId: userData.userId,
        account: userData.account,
        userName: userData.userName,
        // password: userData.password,
        birthday: userData.birthday,
        phone: userData.phone,
        email: userData.email,
        gender: userData.gender,
        statusAccept: userData.statusAccept,
        bizRank: userData.bizRank,
        bizId: userData.bizId,
        bizEnteredAt: dateToString(userData.bizEnteredAt),
        memo: userData.memo || f.memo,
      }));
      setFetched(true);
    }

    if (tokenValid) {
      if (userId && path !== '/user/add') {
        fetchUserInfo();
      }
    }
  }, [userId, fetched, path, tokenValid, fetchBizList]);

  useEffect(() => {
    if (path === '/user/add') fetchBizList();
  }, [path, fetchBizList]);

  return (
    <Container fluid>
      <DetailHeader headerInfo={headerInfo} />
      {userId ? (
        <>
          <UserInfo
            userId={userId}
            evChangeForm={evChangeForm}
            user={user}
            form={form}
            evSave={evSave}
          />
          <UserAddress address={address} />
          <UserBiz
            userId={userId}
            evChangeForm={evChangeForm}
            biz={biz}
            user={user}
            form={form}
            fetched={fetched}
            evSave={evSave}
          />
          <UserPoint point={point} pointNow={pointNow} fetched={fetched} />
          <UserOrder fetched={fetched} oder={oder} />
          <AdminMemo evChangeMemo={evChangeForm} memo={form.memo} />
          <div className="button-items d-flex justify-content-center mb-5">
            <button
              type="button"
              className="btn btn-success"
              onClick={toListPage}
            >
              목록으로
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => evSave(form)}
            >
              수정하기
            </button>
            <button type="button" className="btn btn-danger" onClick={evDelete}>
              삭제
            </button>
          </div>
        </>
      ) : (
        <>
          <UserInfoForm
            form={form}
            evChangeForm={evChangeForm}
            onCheckAccount={onCheckAccount}
          />
          <UserBizForm
            userId={userId}
            evChangeForm={evChangeForm}
            biz={biz}
            fetched={fetched}
          />
          <AdminMemo evChangeMemo={evChangeForm} memo={form.memo} />
          <div className="button-items d-flex justify-content-center mb-5">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => evSave(form)}
            >
              등록하기
            </button>
          </div>
        </>
      )}
    </Container>
  );
}

export default UserEdit;
