import xlsx from 'xlsx';
import { today } from './dateFormat';

export function excelDL(name, data) {
  const ws = xlsx.utils.json_to_sheet(data);
  const wb = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
  const fileName = `${name}_${today}.xlsx`;
  xlsx.writeFile(wb, fileName); // 파일명 바꿔야함
}

export function formDL(name, cols) {
  const ws = xlsx.utils.aoa_to_sheet(cols);
  const wb = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
  const fileName = `표준양식_${name}.xlsx`;
  xlsx.writeFile(wb, fileName);
}
