import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

import itemAPI from '../../api/item';
import DetailHeader from '../../components/Common/DetailHeader';
import ItemSearchForm from '../../components/Items/ItemSearchForm';
import ItemTable from '../../components/Items/ItemTable';
import { dateToString } from '../../util/dateFormat';

// TODO : 승인대기상품 일괄 승인
const ADD_PAGE = '/item/add';
const ACCEPT_PAGE = '/item/accept';
const toEditPage = id => `/item/${id}`;
const fileName = '상품목록';

function ItemList({ history, match }) {
  const [itemList, setItemList] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [isAcceptPage, setIsAcceptPage] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { tokenValid, countState } = useSelector(state => ({
    tokenValid: state.Token.data,
    countState: state.Count.countState,
  }));
  // const [authUser, setAuthUser] = useState({});
  const { path } = match;
  const headerInfo = {
    parentLabel: '상품 관리',
    parentLink: '/item',
    current: path === ACCEPT_PAGE ? '승인 대기 상품' : '상품 목록',
  };

  const needFetch = () => {
    setIsFetched(true);
  };

  const notLoaded = useCallback(() => {
    setIsLoaded(false);
  }, []);

  const evToAddPage = useCallback(() => {
    history.push(ADD_PAGE);
  }, [history]);

  const listMapping = list => {
    const statusSellFilter = status => {
      if (status === '1') {
        return '판매중';
      }
      if (status === '0') {
        return '판매중단';
      }
      if (status === '2') {
        return '품절';
      }
      return '기타';
    };
    const statusAcceptFilter = status => {
      if (status === '1') {
        return '승인';
      }
      if (status === '2') {
        return '반려';
      }
      return '미승인';
    };
    return list.map((data, index) => ({
      ...data,
      index: index + 1,
      itemId: data.itemId,
      itemName: (
        <Link to={toEditPage(data.itemCode)} title={data.itemName}>
          {data.itemName.length > 25
            ? `${data.itemName.substr(0, 25)}...`
            : data.itemName}
        </Link>
      ),
      optionStock: data.optionStock.toLocaleString(),
      statusOpen: parseInt(data.statusOpen, 10) ? '전시' : '미전시',
      statusSell: statusSellFilter(data.statusSell),
      shipCost: `${data.shipCost.toLocaleString()}원`,
      priceSupl: `${data.priceSupl.toLocaleString()}원`,
      priceSell: `${data.priceSell.toLocaleString()}원`,
      grossMargin: `${data.grossMargin}%`,
      createdAt: dateToString(data.createdAt),
      statusAccept: statusAcceptFilter(data.statusAccept),
    }));
  };

  const excelMapping = list => {
    return list.map((data, index) => ({
      번호: index + 1,
      상품번호: data.itemId,
      협력사: data.bizName,
      상품명: data.itemName,
      모델명: data.infoModel,
      브랜드: data.infoBrand,
      제조사: data.infoFactory,
      재고량: data.optionStock,
      전시상태: data.statusOpen,
      판매상태: data.statusSell,
      배송비: data.shipCost,
      매입가: data.priceSupl,
      판매가: data.priceSell,
      마진율: `${data.grossMargin}%`,
      등록일: dateToString(data.createdAt),
      승인상태: data.statusAccet,
    }));
  };

  const evSearch = useCallback(async searchForm => {
    const result = await itemAPI.getList(searchForm);
    setItemList(() => listMapping(result));
    setExcelData(() => excelMapping(result));
    setIsFetched(true);
  }, []);

  const fetchData = useCallback(async () => {
    let result;
    if (path === ACCEPT_PAGE) {
      result = await itemAPI.getList({ statusAccept: '0', usedFlag: '1' });
    } else {
      result = await itemAPI.getList({ usedFlag: '1' });
    }
    setItemList(() => listMapping(result));
    setExcelData(() => excelMapping(result));
    setIsLoaded(true);
  }, [path]);

  useEffect(() => {
    if (tokenValid && !isLoaded) {
      fetchData(path);
      setIsFetched(false);
    }
    if (path === ACCEPT_PAGE) {
      setIsAcceptPage(true);
    }
    return () => setIsFetched(false);
  }, [fetchData, path, tokenValid, countState, isLoaded, isFetched]);

  return (
    <Container fluid>
      <DetailHeader headerInfo={headerInfo} />
      <ItemSearchForm evSearch={evSearch} isAcceptPage={isAcceptPage} />
      <ItemTable
        isFetched={isFetched}
        isAcceptPage={isAcceptPage}
        isLoaded={isLoaded}
        notLoaded={notLoaded}
        needFetch={needFetch}
        itemList={itemList}
        toAddPage={evToAddPage}
        excelData={excelData}
        fileName={fileName}
      />
    </Container>
  );
}

export default ItemList;
