import { Card, CardBody, Col, Row } from 'reactstrap';

function ItemKeywordsForm({ keywords, onChangeKeywords }) {
  return (
    <Card>
      <CardBody>
        <Row className="mb-3">
          <Col>
            <h4
              style={{
                borderBottom: '1px solid #EBEBEB',
                paddingBottom: '0.5rem',
              }}
            >
              검색어 등록
            </h4>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={2}>
            <label
              htmlFor="keywords"
              className="col-lg-12 col-form-label bg-secondary rounded"
            >
              검색어
            </label>
          </Col>
          <Col sm={8}>
            <input
              className="form-control"
              name="keywords"
              id="keywords"
              onChange={onChangeKeywords}
              value={keywords || ''}
            />
          </Col>
        </Row>
        <Row>
          <Col color="primary">
            쉼표(,)로 구분하며 최대 20개까지 입력 가능합니다. 카테고리와
            상품명은 기입 할 필요없습니다. <br />타 브랜드명 또는 상품과
            관련없는 검색어는 추후 해든앰앤씨에 의해 삭제, 변경될 수 있습니다.
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default ItemKeywordsForm;
