import styled, { css } from 'styled-components';
import usePagination from '../../hooks/usePagination';

const Box = styled.article`
  font-size: 0;
  text-align: center;
  padding-top: 48px;
  margin-bottom: 24px;
`;

const Ul = styled.ul`
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
`;

const Li = styled.li`
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
`;

const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border: 1px solid #e1e1e1;
  margin: 0 5px;
  font-size: 14px;
  background: white;
  cursor: pointer;
  ${({ num, current, prev, next }) => {
    // if (prev)
    //   return css`
    //     background: url(${process.env
    //         .PUBLIC_URL}/image/arrow_back_ios_black_24dp.svg)
    //       no-repeat center;
    //     border: 0;
    //   `;
    // if (next)
    //   return css`
    //     background: url(${process.env
    //         .PUBLIC_URL}/image/arrow_forward_ios_black_24dp.svg)
    //       no-repeat center;
    //     border: 0;
    //   `;
    if (current)
      return css`
        border-color: #ffac01;
        background-color: #ffac01;
        font-weight: 700;
        color: #ffffff;
      `;
    if (num)
      return css`
        font-weight: 300;
        letter-spacing: -0.48px;
        color: #7f7f7f;
      `;
    return css``;
  }}
`;

const PagingBox = ({
  totalLength,
  currentPage,
  listSize,
  rangeSize,
  onChangePage,
  scrollIntoRef,
}) => {
  const { start, end, next, prev } = usePagination(
    totalLength,
    currentPage,
    listSize,
    rangeSize,
  );
  const array = Array.from({ length: end - start + 1 }, (v, i) => start + i);
  const TRUE = true;
  const onClickPage = p => {
    onChangePage(p);
    if (scrollIntoRef) {
      scrollIntoRef();
    } else {
      window.scrollTo({ top: 0, left: 0 });
    }
  };
  return (
    <Box>
      <Ul>
        {prev && (
          <Li>
            <Button prev={TRUE} onClick={() => onClickPage(prev)}>
              &lt;
            </Button>
          </Li>
        )}
        {array.map(v => {
          if (v === currentPage) {
            return (
              <Li key={v}>
                <Button num={TRUE} current={TRUE}>
                  {v}
                </Button>
              </Li>
            );
          }
          return (
            <Li key={v}>
              <Button num={TRUE} onClick={() => onClickPage(v)}>
                {v}
              </Button>
            </Li>
          );
        })}
        {next && (
          <Li>
            <Button next={TRUE} onClick={() => onClickPage(next)}>
              &gt;
            </Button>
          </Li>
        )}
      </Ul>
    </Box>
  );
};

PagingBox.defaultProps = {
  scrollIntoRef: undefined,
};

export default PagingBox;
