import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { logoutUser, logoutWorker } from '../../store/actions';

class Logout extends Component {
  /**
   * Redirect to login
   */
  componentDidMount = () => {
    // emit the event
    this.props.logoutUser(this.props.history);
    this.props.logoutWorker(this.props.history);
  };

  render() {
    return <></>;
  }
}

export default withRouter(connect(null, { logoutUser, logoutWorker })(Logout));
