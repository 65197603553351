import { Card, CardBody, Col, Row } from 'reactstrap';

function UserBiz({ evChangeForm, biz, fetched, form, evSave }) {
  if (!fetched)
    return (
      <Card>
        <CardBody>
          <Row className="mb-2">
            <Col>
              <h4
                style={{
                  borderBottom: '1px solid #EBEBEB',
                  paddingBottom: '0.5rem',
                }}
              >
                회사 정보
              </h4>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );

  // const { bizName, bizCrn } = biz;
  const { bizEnteredAt, bizRank, bizId } = form;
  const evSaveBtn = async () => {
    if (!bizEnteredAt || bizEnteredAt === 'Invalid date')
      return alert('입사일을 입력해주세요');
    if (!bizId || bizId === '0') return alert('회사를 선택해주세요');
    const result = await evSave({
      userId: form.userId,
      bizId,
      bizEnteredAt,
      bizRank,
    });
    return result;
  };
  const bizCrn = () => {
    return biz.find(b => b.bizId === parseInt(form.bizId, 10))?.bizCrn;
  };
  return (
    <Card>
      <CardBody>
        <Row className="mb-2">
          <Col>
            <Row className="align-items-center">
              <Col>
                <h4
                  style={{
                    borderBottom: '1px solid #EBEBEB',
                    paddingBottom: '0.5rem',
                  }}
                >
                  회사 정보
                  <button
                    className="btn btn-primary btn-sm mx-3"
                    onClick={evSaveBtn}
                  >
                    수정사항 적용
                  </button>
                </h4>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={3}>
                <label
                  htmlFor="bizId"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  회사명
                </label>
              </Col>
              <Col lg={9}>
                <select
                  className="form-select col-sm-12 form-control"
                  aria-label="Default select example"
                  name="bizId"
                  onChange={evChangeForm}
                  value={bizId || 0}
                >
                  <option value="0">선택</option>
                  {biz &&
                    biz?.map(b => (
                      <option key={b.bizId} value={b.bizId}>
                        {b.bizName}
                      </option>
                    ))}
                </select>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={3}>
                <label
                  htmlFor="bizCrn"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  사업자등록번호
                </label>
              </Col>
              <Col lg={9}>
                <span className="px-2">{bizCrn()}</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col sm={6}>
            <Row>
              <Col lg={3}>
                <label
                  htmlFor="bizRank"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  직급
                </label>
              </Col>
              <Col lg={9}>
                <input
                  id="bizRank"
                  name="bizRank"
                  className="form-control"
                  onChange={evChangeForm}
                  value={bizRank}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row>
              <Col lg={3}>
                <label
                  htmlFor="bizEnteredAt"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  입사일
                </label>
              </Col>
              <Col lg={9}>
                <input
                  id="bizEnteredAt"
                  name="bizEnteredAt"
                  type="date"
                  className="form-control"
                  onChange={evChangeForm}
                  value={bizEnteredAt}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default UserBiz;
