import { Card, CardBody, Col, Row } from 'reactstrap';

import GridTable from '../Common/GridTable';

const columns = [
  {
    label: '주문번호',
    field: 'orderId',
  },
  {
    label: '쇼핑몰',
    field: 'mallName',
  },
  {
    label: '구매자',
    field: 'userName',
  },
  {
    label: '상품명',
    field: 'itemName',
  },
  {
    label: '수량',
    field: 'itemCount',
  },
  {
    label: '상품금액',
    field: 'priceSell',
  },
  {
    label: '배송비',
    field: 'shipCost',
  },
  {
    label: '결제금액',
    field: 'totalAmount',
  },
  {
    label: '받는분',
    field: 'recvrName',
  },
  {
    label: '결제방법',
    field: 'payMethodLabel',
  },
  {
    label: '주문상태',
    field: 'codeLabel',
  },
  {
    label: '주문일자',
    field: 'createdAt',
  },
];
function OrderDetailList({ orderList }) {
  return (
    <Card>
      <CardBody>
        <Row className="mb-3">
          <Col>
            <h4
              style={{
                borderBottom: '1px solid #EBEBEB',
                paddingBottom: '0.5rem',
              }}
            >
              주문 상품 정보
            </h4>
          </Col>
        </Row>
        <GridTable data={{ columns, rows: orderList }} />
      </CardBody>
    </Card>
  );
}

export default OrderDetailList;
