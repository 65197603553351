import { Card, CardBody, Col, Row } from 'reactstrap';
import { dateFormat } from '../../util/dateFormat';

function OrderPaymentInfo({ orderList, payInfo }) {
  if (orderList.length === 0 || payInfo.length === 0)
    return (
      <Card>
        <CardBody>
          <Row className="mb-3">
            <Col>
              <h4
                style={{
                  borderBottom: '1px solid #EBEBEB',
                  paddingBottom: '0.5rem',
                }}
              >
                결제 정보
              </h4>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  const { totalAmount, totalAmountItem, totalAmountShip } = orderList[0];
  const { createdAt } = payInfo[0];
  return (
    <Card>
      <CardBody>
        <Row className="mb-3">
          <Col>
            <h4
              style={{
                borderBottom: '1px solid #EBEBEB',
                paddingBottom: '0.5rem',
              }}
            >
              결제 정보
            </h4>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label
                  htmlFor="infoModel"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  결제일
                </label>
              </Col>
              <Col lg={8}>
                <span className="px-2">{dateFormat(createdAt)}</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label
                  htmlFor="infoModel"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  총 상품금액
                </label>
              </Col>
              <Col lg={8}>
                <span className="px-2">{totalAmountItem}</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label
                  htmlFor="infoModel"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  배송비
                </label>
              </Col>
              <Col lg={8}>
                <span className="px-2">{totalAmountShip}</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label
                  htmlFor="infoModel"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  쿠폰 할인
                </label>
              </Col>
              <Col lg={8}>
                <span className="px-2">0 원</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label
                  htmlFor="infoModel"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  적립금
                </label>
              </Col>
              <Col lg={8}>
                <span className="px-2">0 원</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label
                  htmlFor="infoModel"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  총 결제금액
                </label>
              </Col>
              <Col lg={8}>
                <span className="px-2">{totalAmount}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default OrderPaymentInfo;
