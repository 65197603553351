import axios from './axios';
import { BOOTPAY, ORDER } from './urls';

export default {
  async getStatusCodeList() {
    try {
      return await axios.get(ORDER.GET_STATUS_CODE_LIST);
    } catch (e) {
      return [];
    }
  },
  async getStatusCodeOne(code) {
    try {
      return await axios.get(ORDER.GET_STATUS_CODE_ONE(code));
    } catch (e) {
      return [];
    }
  },
  async getList(params) {
    try {
      return await axios.get(ORDER.GET_LIST, { params });
    } catch (e) {
      return [];
    }
  },
  async getOne(id) {
    try {
      return await axios.get(ORDER.GET_OR_DELETE + id);
    } catch (e) {
      return e;
    }
  },
  async update(params) {
    try {
      return await axios.put(ORDER.UPSERT, params);
    } catch (e) {
      return e;
    }
  },
  async getDeliverCompanyList() {
    try {
      return await axios.get(ORDER.GET_DELIVER_COMPANY_LIST);
    } catch (e) {
      return [];
    }
  },
  async updateDeliver(params) {
    try {
      return await axios.put(ORDER.UPDATE_DELIVER, params);
    } catch (e) {
      return e;
    }
  },
  async updateStatusList(params) {
    try {
      return await axios.patch(ORDER.UPSERT, params);
    } catch (e) {
      return e;
    }
  },
  async getBuyer(id) {
    try {
      return await axios.get(ORDER.GET_BUYER(id));
    } catch (e) {
      return e;
    }
  },
  async getReceiver(id) {
    try {
      return await axios.get(ORDER.GET_RECEIVER(id));
    } catch (e) {
      return e;
    }
  },
  async getPayInfo(id) {
    try {
      return await axios.get(ORDER.GET_PAY_INFO(id));
    } catch (e) {
      return [];
    }
  },
  async getClaim(id) {
    try {
      return await axios.get(ORDER.GET_CLAIM(id));
    } catch (e) {
      return e;
    }
  },
  async getStatusOrderCount(params) {
    try {
      return await axios.get(ORDER.GET_STATUS_ORDER_COUNT, { params });
    } catch (e) {
      return undefined;
    }
  },
  async cancel(params) {
    try {
      const response = await axios.post(BOOTPAY.CANCEL, params);
      return response;
    } catch (e) {
      throw new Error('요청 에러');
    }
  },
};
