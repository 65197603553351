import { Card, CardBody, Row, Col } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DetailHeader from '../../components/Common/DetailHeader';
import pointKindAPI from '../../api/pointKind';

const headerInfo = {
  parentLabel: '회원 관리',
  parentLink: '/pointKind',
  current: '포인트 종류 등록',
};

const defaultSubmit = {
  pointName: '',
  amountRatio: '',
  memo: '',
};

const PointKindEdit = ({ history, match }) => {
  const { pointKindId } = match.params;
  const [submitForm, setSubmitForm] = useState(defaultSubmit);
  const { tokenValid } = useSelector(state => ({
    tokenValid: state.Token.data,
  }));

  const evChangeSubmitForm = ({ target }) => {
    const { name, value } = target;
    setSubmitForm({
      ...submitForm,
      [name]: value,
    });
  };

  // Todo - Korean alert message
  const evSave = async () => {
    if (pointKindId) {
      const result = await pointKindAPI.update(submitForm);
      if (result === 1) {
        return history.push('/pointKind');
      }
    } else {
      const result = await pointKindAPI.insert(submitForm);
      if (result && result !== 0) {
        return history.push('/pointKind');
      }
    }
    return alert('Something went wrong');
  };

  // todo - Korean alert message
  const evDelete = async () => {
    const result = await pointKindAPI.delete(pointKindId);
    if (result && result === 1) {
      return history.push('/pointKind');
    }
    return alert('Something went wrong');
  };

  useEffect(() => {
    async function fetchData() {
      if (pointKindId) {
        const result = await pointKindAPI.getOne(pointKindId);
        if (result) {
          setSubmitForm(result);
        } else {
          setSubmitForm(defaultSubmit);
        }
      }
    }
    if (tokenValid) fetchData();
  }, [pointKindId, tokenValid]);

  return (
    <>
      <DetailHeader headerInfo={headerInfo} />
      <Card>
        <CardBody>
          <Row className="align-items-center mb-3">
            <Col>
              <h4>기본 정보</h4>
            </Col>
          </Row>
          <Row className="align-items-center mb-3">
            <Col sm={3}>
              <label className="col-sm-12 col-form-label bg-secondary rounded">
                포인트명
              </label>
            </Col>
            <Col sm={9}>
              <input
                className="form-control"
                name="pointName"
                onChange={evChangeSubmitForm}
                value={submitForm.pointName}
              />
            </Col>
          </Row>
          <Row className="align-items-center mb-3">
            <Col sm={3}>
              <label className="col-sm-12 col-form-label bg-secondary rounded">
                사용가능비율
              </label>
            </Col>
            <Col sm={9}>
              <input
                className="form-control"
                name="amountRatio"
                onChange={evChangeSubmitForm}
                value={submitForm.amountRatio}
              />
            </Col>
          </Row>
          <Row className="align-items-center mb-3">
            <Col sm={3}>
              <label className="col-sm-12 col-form-label bg-secondary rounded">
                설명
              </label>
            </Col>
            <Col sm={9}>
              <input
                className="form-control"
                name="memo"
                onChange={evChangeSubmitForm}
                value={submitForm.memo}
              />
            </Col>
          </Row>
          <Row className="form-group align-items-center justify-content-center button-items">
            <button
              type="button"
              onClick={evSave}
              className="btn btn-primary waves-effect waves-light"
            >
              {pointKindId ? '적용' : '등록하기'}
            </button>
            {pointKindId && (
              <button
                type="button"
                onClick={evDelete}
                className="btn btn-danger waves-effect waves-light"
              >
                삭제
              </button>
            )}
          </Row>
        </CardBody>
      </Card>
    </>
  );
};
export default PointKindEdit;
