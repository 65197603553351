import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Card, CardBody } from 'reactstrap';

import brandAPI from '../../api/brand';
import DetailHeader from '../../components/Common/DetailHeader';

const headerInfo = {
  parentLabel: '브랜드 관리',
  parentLink: '/brand',
  current: '브랜드 추가',
};

const initCode = {
  brandName: '',
  brandCode: '',
};

const BrandEdit = ({ history, match }) => {
  const { brandId } = match.params;

  const [brandData, setBrandData] = useState(initCode);
  const { tokenValid } = useSelector(state => ({
    tokenValid: state.Token.data,
  }));

  const evChangeForm = ({ target }) => {
    const { name, value } = target;

    setBrandData({
      ...brandData,
      [name]: value,
    });
  };

  /* Button Action */
  const evClickList = () => {
    history.push('/brand');
  };

  const evClickSave = async () => {
    if (brandId) {
      const result = await brandAPI.update(brandData);
      if (result) {
        alert('수정 성공');
      }
    } else {
      const result = await brandAPI.insert(brandData);
      if (result) {
        alert('추가 성공');
      }
    }
  };

  const evClickDelete = async () => {
    if (brandId) {
      const result = await brandAPI.delete(brandId);
      if (result) {
        alert('삭제 성공');
        evClickList();
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (brandId) {
        headerInfo.current = '브랜드 편집';

        const result = await brandAPI.getOne(brandId);
        if (result) {
          setBrandData(result);
        }
      }
    }
    if (tokenValid) fetchData();
  }, [brandId, tokenValid]);

  let deleteButtonHtml = '';
  if (brandId) {
    deleteButtonHtml = (
      <button
        onClick={evClickDelete}
        className="btn btn-warning waves-effect waves-light"
      >
        삭제
      </button>
    );
  }

  return (
    <>
      <div className="container-fluid">
        <DetailHeader headerInfo={headerInfo} />

        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row className="form-group">
                  <label
                    htmlFor="brandName"
                    className="col-sm-2 col-form-label"
                  >
                    브랜드
                  </label>
                  <Col sm={4}>
                    <input
                      id="brandName"
                      name="brandName"
                      className="form-control"
                      type="text"
                      onChange={evChangeForm}
                      value={brandData.brandName}
                    />
                  </Col>

                  <label
                    htmlFor="brandCode"
                    className="col-sm-2 col-form-label"
                  >
                    브랜드 코
                  </label>
                  <Col sm={4}>
                    <input
                      id="brandCode"
                      name="brandCode"
                      className="form-control"
                      type="text"
                      onChange={evChangeForm}
                      value={brandData.brandCode}
                    />
                  </Col>
                </Row>

                <Row className="form-group align-items-center justify-content-center button-items">
                  <button
                    onClick={evClickList}
                    className="btn btn-info waves-effect waves-light"
                  >
                    목록으로
                  </button>
                  <button
                    onClick={evClickSave}
                    className="btn btn-primary waves-effect waves-light"
                  >
                    저장
                  </button>
                  {deleteButtonHtml}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default BrandEdit;
