// import axios from 'axios';
//
// const serverURI = `http://localhost:8081/api/`;
//
// export default async function getBrandList(brandName, brandCode) {
//   const query = `/api/brand`;
//
//   const params = {
//     brandName,
//     brandCode,
//   };
//
//   try {
//     return await axios.get(query, { params });
//   } catch (e) {
//     console.error(e);
//     return null;
//   }
// }
//
// export async function getBrandInfo(brandId) {
//   const query = `/api/brand/${brandId}`;
//   try {
//     return await axios.get(query);
//   } catch (e) {
//     console.error(e);
//     return null;
//   }
// }
//
// export async function insertBrand(brand) {
//   const query = `/api/brand`;
//   try {
//     return await axios.post(query, brand);
//   } catch (e) {
//     console.error(e);
//     return e;
//   }
// }
//
// export async function updateBrand(brandInfo) {
//   const query = `/api/brand`;
//
//   try {
//     return await axios.put(query, brandInfo);
//   } catch (e) {
//     console.error(e);
//     return null;
//   }
// }
//
// export async function deleteBrand(brandId) {
//   const query = `/api/brand`;
//   const params = { brandId };
//   try {
//     return await axios.delete(query, { params });
//   } catch (e) {
//     console.error(e);
//     return null;
//   }
// }

import axios from './axios';
import { BRAND } from './urls';

export default {
  async getList(brandName, brandCode) {
    const params = {
      brandName,
      brandCode,
    };

    try {
      if (!params) return await axios.get(BRAND.GET_LIST, { params });
      return await axios.get(BRAND.GET_LIST, params);
    } catch (e) {
      return e;
    }
  },

  async getOne(id) {
    try {
      return await axios.get(BRAND.GET_OR_DELETE + id);
    } catch (e) {
      return e;
    }
  },

  async insert(params) {
    try {
      return await axios.post(BRAND.UPSERT, params);
    } catch (e) {
      return e;
    }
  },

  async update(params) {
    try {
      return await axios.put(BRAND.UPSERT, params);
    } catch (e) {
      return e;
    }
  },

  async delete(id) {
    try {
      return await axios.delete(BRAND.GET_OR_DELETE + id);
    } catch (e) {
      return e;
    }
  },
};
