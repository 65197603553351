import moment from 'moment';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Button, Card, CardBody, Container, Table } from 'reactstrap';
import settlementAPI from '../../api/settlement';
import DetailHeader from '../../components/Common/DetailHeader';
import { dateFormat, dateToString } from '../../util/dateFormat';
import { excelDL } from '../../util/excel';

const TO_LIST = '/settlement';

const headerInfo = {
  parentLabel: '정산 조회',
  parentLink: TO_LIST,
  current: '정산 상세 조회',
};

export const tableTextCSS = {
  verticalAlign: 'middle',
  textAlign: 'center',
  padding: 0,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

export const tableNumberCSS = {
  textAlign: 'right',
  padding: '0 4px',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

export const overflowCSS = {
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  padding: '0 4px',
};

const SettlementDetail = () => {
  const [list, setList] = useState([]);
  const [isShowUnsettled, setIsShowUnsettled] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { data: tokenValid, role } = useSelector(state => state.Token);
  const query = useMemo(() => queryString.parse(location.search), [location]);
  const isAdmin = role === 'ROLE_ADMIN';
  const fetchData = useCallback(async () => {
    const response = await settlementAPI.getDetailList(query);
    setList(() => response);
  }, [query]);

  const evExcelDL = () => {
    const excel = list.map(d => ({
      '주문/반품 번호': d.orderId,
      협력사: d.bizName,
      쇼핑몰: '해든몰',
      날짜: dateToString(d.createdAt),
      상품명: d.itemName,
      주문량: d.itemCount,
      구분: d.taxKindLabel,
      '매입-공급가액': d.purchaseSupplyPrice,
      '매입-세액': d.purchaseTaxAmount,
      매입액: d.purchaseAmount,
      '매출-공급가액': d.salesSupplyPrice,
      '매출-세액': d.salesTaxAmount,
      매출액: d.salesAmount,
      '결제-신용카드': d.cardAmount,
      '결제-포인트': d.pointAmount,
      결제총액: d.payAmount,
      매출이익: d.profit,
      매익률: `${d.grossMargin || '-'}`,
      주문상태: d.statusOrderLabel,
      정산여부: d.settlementFg === '1' ? '정산' : '미정산',
    }));
    excelDL(
      `정산_${list[0]?.bizName || ''}_${moment(query.startDate).format(
        'YYYYMMDD',
      )}-${moment(query.endDate).format('YYYYMMDD')}`,
      excel,
    );
  };

  const onAcceptSettlement = async () => {
    if (window.confirm('정산 승인하시겠습니까?')) {
      const target = [...new Set(list.map(s => s.orderId))];
      const response = await settlementAPI.acceptSettlement(target);
      history.replace(`${location.pathname}${location.search}`);
    }
  };

  const onShowUnsettled = () => {
    setList(l => l.filter(s => s.settlementFg === '0'));
    setIsShowUnsettled(true);
  };

  const onShowInit = () => {
    history.replace(`${location.pathname}${location.search}`);
    setIsShowUnsettled(false);
  };

  const onToList = () => {
    history.push('/settlement');
  };

  const toLocaleString = value => {
    return typeof value === 'number' ? value.toLocaleString() : '-';
  };

  useEffect(() => {
    if (tokenValid) fetchData();
  }, [fetchData, tokenValid]);

  return (
    <Container fluid>
      <DetailHeader headerInfo={headerInfo} />
      <Card>
        <CardBody>
          <h3 className="mb-4">
            {list[0]?.bizName || ''} (
            {`${dateToString(query.startDate)} ~
            ${dateToString(query.endDate)}`}
            )
            <Button color="primary" className="float-right" onClick={onToList}>
              목록으로
            </Button>
            {isShowUnsettled ? (
              <Button
                color="info"
                className="float-right mr-2"
                onClick={onShowInit}
              >
                모두보기
              </Button>
            ) : (
              <Button
                color="warning"
                className="float-right mr-2"
                onClick={onShowUnsettled}
              >
                미정산만 보기
              </Button>
            )}
            <Button
              color="success"
              className="float-right mr-2"
              onClick={evExcelDL}
            >
              엑셀 다운로드
            </Button>
            {isAdmin ? (
              <Button
                color="danger"
                className="float-right mr-2"
                onClick={onAcceptSettlement}
              >
                정산승인
              </Button>
            ) : undefined}
          </h3>
          <Table
            style={{ minWidth: '1280px', tableLayout: 'fixed' }}
            bordered
            hover
          >
            <thead>
              <tr>
                <th style={tableTextCSS} rowSpan={2} width="100px">
                  주문/반품 번호
                </th>
                <th style={tableTextCSS} rowSpan={2} width="100px">
                  협력사
                </th>
                <th style={tableTextCSS} rowSpan={2} width="80px">
                  쇼핑몰
                </th>
                <th style={tableTextCSS} rowSpan={2} width="100px">
                  날짜
                </th>
                <th style={tableTextCSS} rowSpan={2} width="100px">
                  상품명
                </th>
                <th style={tableTextCSS} rowSpan={2} width="60px">
                  주문량
                </th>
                <th style={tableTextCSS} rowSpan={2} width="60px">
                  구분
                </th>
                <th style={tableTextCSS} colSpan={3}>
                  매입액
                </th>
                <th style={tableTextCSS} colSpan={3}>
                  매출액
                </th>
                <th style={tableTextCSS} colSpan={3}>
                  결제방법
                </th>
                <th style={tableTextCSS} rowSpan={2}>
                  매출이익
                </th>
                <th style={tableTextCSS} rowSpan={2} width="60px">
                  매익률
                </th>
                <th style={tableTextCSS} rowSpan={2} width="80px">
                  주문상태
                </th>
                <th style={tableTextCSS} rowSpan={2} width="60px">
                  정산여부
                </th>
              </tr>
              <tr>
                <th style={tableTextCSS}>공급가액</th>
                <th style={tableTextCSS}>세액</th>
                <th style={tableTextCSS}>매입액</th>
                <th style={tableTextCSS}>공급가액</th>
                <th style={tableTextCSS}>세액</th>
                <th style={tableTextCSS}>매출액</th>
                <th style={tableTextCSS}>신용카드</th>
                <th style={tableTextCSS}>포인트</th>
                <th style={tableTextCSS}>합계</th>
              </tr>
            </thead>
            <tbody>
              {list.length !== 0 ? (
                list.map(s => (
                  <tr key={s.rownum}>
                    <td style={tableNumberCSS}>{s.orderId}</td>
                    <td style={overflowCSS} title={s.bizName}>
                      {s.bizName}
                    </td>
                    <td style={overflowCSS} title="해든몰">
                      해든몰
                    </td>
                    <td style={tableNumberCSS}>{dateToString(s.createdAt)}</td>
                    <td
                      style={overflowCSS}
                      title={
                        s.optionName
                          ? `${s.itemName}(${s.optionName})`
                          : s.itemName
                      }
                    >
                      {s.optionName
                        ? `${s.itemName}(${s.optionName})`
                        : s.itemName}
                    </td>
                    <td style={tableNumberCSS}>{s.itemCount}</td>
                    <td style={tableTextCSS}>{s.taxKindLabel}</td>
                    <td style={tableNumberCSS}>
                      {toLocaleString(s.purchaseSupplyPrice)}
                    </td>
                    <td style={tableNumberCSS}>
                      {toLocaleString(s.purchaseTaxAmount)}
                    </td>
                    <td style={tableNumberCSS}>
                      {toLocaleString(s.purchaseAmount)}
                    </td>
                    <td style={tableNumberCSS}>
                      {toLocaleString(s.salesSupplyPrice)}
                    </td>
                    <td style={tableNumberCSS}>
                      {toLocaleString(s.salesTaxAmount)}
                    </td>
                    <td style={tableNumberCSS}>
                      {toLocaleString(s.salesAmount)}
                    </td>
                    <td style={tableNumberCSS}>
                      {toLocaleString(s.cardAmount)}
                    </td>
                    <td style={tableNumberCSS}>
                      {toLocaleString(s.pointAmount)}
                    </td>
                    <td style={tableNumberCSS}>
                      {toLocaleString(s.payAmount)}
                    </td>
                    <td style={tableNumberCSS}>{toLocaleString(s.profit)}</td>
                    <td style={tableNumberCSS}>{`${
                      s.grossMargin ? `${s.grossMargin}%` : '-'
                    }`}</td>
                    <td style={tableTextCSS}>{s.statusOrderLabel}</td>
                    <td style={tableTextCSS}>
                      {s.settlementFg === '1' ? '정산' : '미정산'}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="20">내역이 없습니다</td>
                </tr>
              )}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Container>
  );
};

export default SettlementDetail;
