import { useCallback, useEffect, useRef, useState } from 'react';
import { Container } from 'reactstrap';
import { useSelector } from 'react-redux';

import AdminMemo from '../../components/Common/AdminMemo';
import DetailHeader from '../../components/Common/DetailHeader';
import ItemBasicInfoForm from '../../components/Items/ItemBasicInfoForm';
import ItemDetailForm from '../../components/Items/ItemDetailForm';
import ItemImageForm from '../../components/Items/ItemImageForm';
import ItemOptionInfoForm from '../../components/Items/ItemOptionInfoForm';
import ItemPriceInfoForm from '../../components/Items/ItemPriceInfoForm';
import ItemProductInfoForm from '../../components/Items/ItemProductInfoForm';
import ItemBasicInfo from '../../components/Items/ItemBasicInfo';
import { getProductInfo } from '../../util/productInfomation';
import itemAPI from '../../api/item';
import keywordsAPI from '../../api/keywords';
import { itemValid } from '../../util/validation';
import { S3THUMBNAIL } from '../../api/urls';
import statistics from '../../api/statistics';
import ItemKeywordsForm from '../../components/Items/ItemKeywordsForm';

const initialState = {
  category1: 0,
  category2: 0,
  category3: 0,
  catgryId: undefined,
  itemName: '',
  itemCode: '',
  infoModel: '',
  infoOrigin: '',
  infoBrand: '',
  infoFactory: '',
  statusAccept: '0',
  statusOpen: '0',
  statusSell: '0',
  priceReal: '',
  priceSupl: '',
  priceSell: '',
  mileageRatio: '',
  shipCost: '',
  shipCostMountain: '',
  taxKind: '1',
  grossMargin: '',
  infoDetail: '',
  memo: '',
  gender: 'both',
  keywords: '',
};

const initialSubOption = {
  index: 0,
  subName: '',
  optionStock: 0,
  optionPrice: 0,
  parntId: 0,
  oder: 0,
};

const initialOption = {
  index: 0,
  parntId: 0,
  optionName: '',
  subOption: [initialSubOption],
};

const TO_LIST = '/item';
const THUMBNAIL = '0';

const getGrossMargin = (priceSell, priceSupl) => {
  const sell = parseInt(priceSell, 10);
  const supl = parseInt(priceSupl, 10);
  if (!sell || !supl) {
    return '';
  }
  const GM = ((sell - supl) / sell) * 100;
  return `${Math.floor(GM)}`;
};

function ItemEdit({ match, history }) {
  const itemId = parseInt(match.params.itemId, 10);
  const { path } = match;
  if (!itemId && path !== '/item/add') history.push(TO_LIST);
  // const authUser =
  //   localStorage.getItem('authUser') &&
  //   JSON.parse(localStorage.getItem('authUser'));
  // const { wId } = authUser || undefined;
  const headerInfo = {
    parentLabel: '상품 관리',
    parentLink: TO_LIST,
    current: itemId ? '상품 수정' : '상품 등록',
  };

  const [form, setForm] = useState(initialState);
  const [optionForm, setOptionForm] = useState([initialOption]);
  const [isOptionEnabel, setIsOptionEnabel] = useState(false);
  const [productInfomationForm, setProductInfomationForm] = useState(undefined);
  const [productInfomation, setProductInfomation] = useState({});
  const [item, setItem] = useState({});
  const [options, setOptions] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [itemImages, setItemImages] = useState([]);
  const { tokenValid } = useSelector(state => ({
    tokenValid: state.Token.data,
  }));

  const fileInput1 = useRef();
  const fileInput2 = useRef();
  const fileInput3 = useRef();
  const fileInput4 = useRef();
  const fileInput5 = useRef();

  const evOpenOptionForm = () => {
    setIsOptionEnabel(true);
  };

  const evAddOption = () => {
    const nextIndex = Math.max(...optionForm.map(o => o.index), 0) + 1;
    setOptionForm(o => o.concat({ ...initialOption, index: nextIndex }));
  };
  const evRemoveOption = index => {
    const resultList = optionForm
      .filter(o => o.index !== index)
      .map((o, i) => ({ ...o, index: i }));
    if (resultList.length === 0) {
      resultList.push(initialOption);
      setIsOptionEnabel(false);
    }
    setOptionForm(resultList);
  };

  const evChangeOptionForm = (e, index) => {
    const { name, value } = e.target;
    setOptionForm(o =>
      o.map(option =>
        option.index === index ? { ...option, [name]: value } : option,
      ),
    );
  };

  const evAddSubOption = index => {
    const nextIndex =
      Math.max(...optionForm[index].subOption.map(so => so.index), 0) + 1;
    const nextOrder =
      Math.max(...optionForm[index].subOption.map(so => so.oder), 0) + 1;
    setOptionForm(o => {
      return o.map(option =>
        option.index === index
          ? {
              ...option,
              subOption: option.subOption.concat({
                ...initialSubOption,
                index: nextIndex,
                oder: nextOrder,
              }),
            }
          : option,
      );
    });
  };

  const evRemoveSubOption = (index1, index2) => {
    const resultList = optionForm[index1].subOption
      .filter(so => so.index !== index2)
      .map((so, i) => ({ ...so, index: i, oder: i }));
    setOptionForm(o =>
      o.map(option =>
        option.index === index1 ? { ...option, subOption: resultList } : option,
      ),
    );
  };

  const evChangeSubOption = (e, index1, index2) => {
    const { name, value } = e.target;
    setOptionForm(o =>
      o.map(option =>
        option.index === index1
          ? {
              ...option,
              subOption: option.subOption.map(so =>
                so.index === index2 ? { ...so, [name]: value } : so,
              ),
            }
          : option,
      ),
    );
  };

  const evChangeForm = ({ target }) => {
    const { name, value } = target;
    setForm(f => ({
      ...f,
      [name]: value,
    }));
    if (name === 'category1' || name === 'category2' || name === 'category3') {
      setForm(f => ({
        ...f,
        catgryId: value,
      }));
      if (name === 'category1') {
        setForm(f => ({
          ...f,
          category2: 0,
          category3: 0,
        }));
      }
      if (name === 'category2') {
        setForm(f => ({
          ...f,
          category3: 0,
        }));
      }
    }
    if (
      form.priceSell &&
      form.priceSupl &&
      (name === 'priceSell' || name === 'priceSupl')
    ) {
      setForm(f => ({
        ...f,
        grossMargin: getGrossMargin(f.priceSell, f.priceSupl),
      }));
    }
  };

  const changeProductInfoForm = index => {
    const info = getProductInfo(index);
    const data = info?.data.reduce((acc, cur) => {
      return {
        ...acc,
        title: info.title,
        index: info.id,
        [cur.field]: '',
      };
    }, {});
    setProductInfomationForm(data);
    setProductInfomation(info);
  };
  const evSelectProductInfo = ({ target }) => {
    const { value } = target;

    changeProductInfoForm(value);
  };

  const evChangeProductInfoForm = ({ target }) => {
    const { name, value } = target;
    setProductInfomationForm(i => ({
      ...i,
      [name]: value,
    }));
  };

  const evSetProductInfoText = () => {
    if (!productInfomationForm) {
      return alert('상품 종류를 선택해주세요');
    }
    const keys = productInfomationForm && Object.keys(productInfomationForm);
    const result = keys?.reduce((acc, cur) => {
      if (cur !== 'index' && cur !== 'title') {
        acc[cur] = '상세정보 별도표기';
      }
      return acc;
    }, {});
    setProductInfomationForm(i => ({
      ...i,
      ...result,
    }));
    return '';
  };

  const thumbnailImageUpload = useCallback(
    async id => {
      const inputList = [
        fileInput1,
        fileInput2,
        fileInput3,
        fileInput4,
        fileInput5,
      ];
      let oder = 0;
      const imgList = inputList.reduce((acc, cur) => {
        const file = cur.current?.files[0];
        if (file) acc.push({ file, oder });
        oder += 1;
        return acc;
      }, []);
      const imgInfoResultList = await Promise.all(
        imgList
          .reduce((acc, cur) => {
            const { file } = cur;
            const order = cur.oder;
            if (file) {
              acc.push({
                // file,
                imgName: file.name,
                itemId: id,
                oder: order,
                usedFlag: '1',
                imgType: THUMBNAIL,
                imgExt: '0',
              });
            }
            return acc;
          }, [])
          .map(img => itemAPI.insertImage(img)),
      );
      const imgInfoList = imgInfoResultList
        .map(result => ({
          ...result.img,
        }))
        .sort((a, b) => {
          if (parseInt(a.oder, 10) > parseInt(b.oder, 10)) return 1;
          if (parseInt(a.oder, 10) < parseInt(b.oder, 10)) return -1;
          return 0;
        });
      const result = await Promise.all(
        imgInfoList.map((img, index) =>
          itemAPI.uploadThumbnailImage(imgList[index].file, img.imgName),
        ),
      );
      if (itemId) {
        const changedImages = itemImages.filter(
          i1 =>
            imgList.find(i2 => parseInt(i2.oder, 10) === parseInt(i1.oder, 10))
              ?.oder === parseInt(i1.oder, 10),
        );
        if (changedImages.length > 0) {
          const resultChangedImages = await Promise.all(
            changedImages.map(i => itemAPI.deleteImage(i.imgId)),
          );
        }
        const mainThumbnail = changedImages.find(i => i.oder === '0');
        return Promise.resolve(
          mainThumbnail ? S3THUMBNAIL(mainThumbnail.imgName) : '',
        );
      }
      return Promise.resolve(result[0].location);
    },
    [itemId, itemImages],
  );

  const evChangeDetail = html => {
    setForm(f => ({
      ...f,
      infoDetail: html,
    }));
  };

  const updateDescriptionImage = useCallback(
    async id => {
      if (uploadedImages.length === 0) {
        return null;
      }
      const { infoDetail } = form;
      // 없으면 null
      const checkedImages = uploadedImages.map(i => {
        if (infoDetail.match(i.src)) {
          return {
            ...i,
            usedFlag: '1',
          };
        }
        return {
          ...i,
          usedFlag: '0',
        };
      });
      const notUsedImg = checkedImages.filter(i => i.usedFlag === '0');
      const resultDescImgs = await Promise.all(
        checkedImages.map(i =>
          itemAPI.updateImage(id, { ...i, itemId: id, imgId: i.id }),
        ),
      );
      const resultDeletedFiles = await Promise.all(
        notUsedImg.map(i => itemAPI.deleteDescriptionImage(i.key)),
      );
      return resultDescImgs;
    },
    [uploadedImages, form],
  );

  const toListPage = useCallback(() => history.push(TO_LIST), [history]);

  const validate = useCallback(() => {
    const result = itemValid(form, optionForm, productInfomationForm);
    return result;
  }, [form, optionForm, productInfomationForm]);

  const evSave = useCallback(async () => {
    const result = validate();
    if (!result.status) return alert(result.message);
    if (!fileInput1.current?.files[0] && !itemId)
      return alert('대표 이미지를 등록해주세요');
    let resultItem;
    if (itemId) {
      resultItem = await itemAPI.update({
        ...form,
        countUpdated: form.countUpdated ? form.countUpdated + 1 : 1,
        infoProduct: JSON.stringify(productInfomationForm),
      });
    } else {
      resultItem = await itemAPI.insert({
        ...form,
        infoProduct: JSON.stringify(productInfomationForm),
      });
    }
    let resultOption;
    if (itemId) {
      resultOption = await itemAPI.updateItemOption(
        resultItem.itemId,
        optionForm,
      );
    } else {
      resultOption = await itemAPI.insertItemOption(
        resultItem.itemId,
        optionForm,
      );
    }

    const resultImg = await thumbnailImageUpload(resultItem.itemCode);
    const resultDescImg = await updateDescriptionImage(resultItem.itemId);

    const product = {
      productId: resultItem.itemCode,
      name: form.itemName,
      description: form.itemName,
      gender: form.gender,
      soldOut: form.statusSell === '1' ? 0 : 1,
      price: form.priceReal,
      discountPrice: form.priceSell,
      categoryId: form.catgryId,
    };
    if (itemId) {
      const statisticsResult = resultImg
        ? await statistics.updateProduct({ ...product, photoUrl: resultImg })
        : await statistics.updateProduct(product);
      alert('수정 완료');
    } else {
      const statisticsResult = await statistics.addProduct({
        ...product,
        photoUrl: resultImg,
      });
      alert('등록 완료');
    }
    toListPage();
    return resultImg;
  }, [
    optionForm,
    productInfomationForm,
    form,
    itemId,
    validate,
    updateDescriptionImage,
    thumbnailImageUpload,
    toListPage,
  ]);

  const evDelete = useCallback(async () => {
    if (window.confirm('정말로 삭제하시겠습니까?')) {
      const result = await itemAPI.delete(itemId);
      toListPage();
    }
  }, [itemId, toListPage]);

  const evUploadedImages = useCallback(img => {
    setUploadedImages(i => {
      return i.concat(img);
    });
  }, []);

  const onClone = useCallback(async () => {
    if (window.confirm('상품을 복사하시겠습니까?')) {
      // console.log(item);
      // console.log(form);
      const clone = {
        ...item,
        statusSell: '0',
        statusOpne: '0',
        statusAccept: '0',
        updatedAt: null,
        createdAt: null,
        countUpdated: 0,
        countRead: 0,
        usedFlag: '1',
        itemId: null,
        itemCode: null,
      };
      const resultItem = await itemAPI.insert({
        ...clone,
        infoProduct: JSON.stringify(productInfomationForm),
      });
      const resultOption = await itemAPI.insertItemOption(
        resultItem.itemCode,
        optionForm,
      );
      // const resultImg = await
      // console.log(itemImages);
      const imgInfoResultList = await Promise.all(
        itemImages.map(img =>
          itemAPI.insertCloneImg({
            ...img,
            imgId: null,
            itemId: resultItem.itemCode,
            creatdAt: null,
          }),
        ),
      );
      const photoUrl = itemImages.find(img => img.oder === '0');
      const product = {
        productId: resultItem.itemCode,
        name: clone.itemName,
        description: clone.itemName,
        gender: clone.gender,
        soldOut: clone.statusSell === '1' ? 0 : 1,
        price: clone.priceReal,
        discountPrice: clone.priceSell,
        categoryId: clone.catgryId,
        photoUrl: photoUrl ? S3THUMBNAIL(photoUrl.imgName) : null,
      };
      const statisticsResult = await statistics.addProduct(product);
      if (statisticsResult) {
        alert('등록 완료');
      } else {
        alert('등록 실패');
      }
      // toListPage();
    }
  }, [
    item,
    // form,
    itemImages,
    productInfomationForm,
    optionForm,
    // toListPage,
  ]);

  useEffect(() => {
    async function fetchItemInfo() {
      const itemData = await itemAPI.getOne(itemId);
      const itemOptionData = await itemAPI.getItemOption(itemData.itemId);
      const itemImgData = await itemAPI.getItemImagesById(itemId, {
        imgType: '0',
        itemId,
      });
      const itemOptionList = itemOptionData.data?.reduce((acc, cur, i, arr) => {
        if (cur.parntId === 0) {
          if (
            arr.filter(option => cur.optionId === option.parntId).length === 0
          ) {
            acc.push({
              ...cur,
              id: cur.optionId,
              index: cur.oder,
              subOption: [
                {
                  ...initialSubOption,
                  optionStock: cur.optionStock,
                  optionPrice: cur.optionPrice,
                },
              ],
            });
          } else {
            acc.push({
              ...cur,
              id: cur.optionId,
              index: cur.oder,
              subOption: [],
            });
          }
        } else {
          const index = acc.findIndex(
            option => option.optionId === cur.parntId,
          );
          acc[index] = {
            ...acc[index],
            subOption: acc[index].subOption.concat({
              id: cur.optionId,
              index: cur.oder,
              oder: cur.oder,
              subName: cur.optionName,
              optionStock: cur.optionStock,
              optionPrice: cur.optionPrice,
            }),
          };
        }
        return acc;
      }, []);
      if (itemData.infoProduct) {
        const productInfo = JSON.parse(itemData.infoProduct);
        changeProductInfoForm(productInfo.index);
        setProductInfomationForm(productInfo);
      }
      setItem(() => ({
        ...itemData,
        grossMargin: getGrossMargin(itemData.priceSell, itemData.priceSupl),
      }));
      setOptions(() => itemOptionList);
      setForm(f => ({
        ...f,
        ...itemData,
        grossMargin: getGrossMargin(itemData.priceSell, itemData.priceSupl),
      }));
      setOptionForm(f => itemOptionList.map(option => ({ ...f, ...option })));
      setItemImages(() => itemImgData.result);
      if (itemOptionList && itemOptionList?.length >= 1) {
        setIsOptionEnabel(true);
      }
    }
    if (itemId && tokenValid) {
      fetchItemInfo();
    }
  }, [itemId, tokenValid]);

  return (
    <Container fluid>
      <DetailHeader headerInfo={headerInfo} />
      {itemId ? (
        <>
          <ItemBasicInfo evChangeForm={evChangeForm} form={form} />
          <ItemPriceInfoForm
            evChangeForm={evChangeForm}
            form={form}
            itemId={itemId}
          />
          <ItemOptionInfoForm
            optionForm={optionForm}
            isOptionEnabel={isOptionEnabel}
            evOpenOptionForm={evOpenOptionForm}
            evAddOption={evAddOption}
            evRemoveOption={evRemoveOption}
            evChangeOptionForm={evChangeOptionForm}
            evAddSubOption={evAddSubOption}
            evRemoveSubOption={evRemoveSubOption}
            evChangeSubOption={evChangeSubOption}
            itemId={itemId}
          />
          <ItemProductInfoForm
            productInfoForm={productInfomationForm}
            productInfo={productInfomation}
            evSelectProductInfo={evSelectProductInfo}
            evChangeProductInfoForm={evChangeProductInfoForm}
            evSetProductInfoText={evSetProductInfoText}
          />
          <ItemImageForm
            fileInput1={fileInput1}
            fileInput2={fileInput2}
            fileInput3={fileInput3}
            fileInput4={fileInput4}
            fileInput5={fileInput5}
            itemImages={itemImages}
          />
          <ItemDetailForm
            evChangeDetail={evChangeDetail}
            infoDetail={item.infoDetail}
            evUploadedImages={evUploadedImages}
          />
          <ItemKeywordsForm
            keywords={form.keywords}
            onChangeKeywords={evChangeForm}
          />
          <AdminMemo evChangeMemo={evChangeForm} memo={form.memo} />
          <div className="button-items d-flex justify-content-center mb-5">
            <button type="button" className="btn btn-primary" onClick={evSave}>
              수정하기
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={toListPage}
            >
              목록으로
            </button>
            <button type="button" className="btn btn-danger" onClick={evDelete}>
              삭제하기
            </button>
            <button type="button" className="btn btn-warning" onClick={onClone}>
              상품복사
            </button>
          </div>
        </>
      ) : (
        <>
          <ItemBasicInfoForm evChangeForm={evChangeForm} form={form} />
          <ItemPriceInfoForm evChangeForm={evChangeForm} form={form} />
          <ItemOptionInfoForm
            optionForm={optionForm}
            isOptionEnabel={isOptionEnabel}
            evOpenOptionForm={evOpenOptionForm}
            evAddOption={evAddOption}
            evRemoveOption={evRemoveOption}
            evChangeOptionForm={evChangeOptionForm}
            evAddSubOption={evAddSubOption}
            evRemoveSubOption={evRemoveSubOption}
            evChangeSubOption={evChangeSubOption}
          />
          <ItemProductInfoForm
            productInfoForm={productInfomationForm}
            productInfo={productInfomation}
            evSelectProductInfo={evSelectProductInfo}
            evChangeProductInfoForm={evChangeProductInfoForm}
            evSetProductInfoText={evSetProductInfoText}
          />
          <ItemImageForm
            fileInput1={fileInput1}
            fileInput2={fileInput2}
            fileInput3={fileInput3}
            fileInput4={fileInput4}
            fileInput5={fileInput5}
          />
          <ItemDetailForm
            evChangeDetail={evChangeDetail}
            evUploadedImages={evUploadedImages}
          />
          <ItemKeywordsForm
            keywords={form.keywords}
            onChangeKeywords={evChangeForm}
          />
          <AdminMemo evChangeMemo={evChangeForm} memo={form.memo} />
          <div className="button-items d-flex justify-content-center mb-5">
            <button type="button" className="btn btn-primary" onClick={evSave}>
              등록하기
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={toListPage}
            >
              목록으로
            </button>
          </div>
        </>
      )}
    </Container>
  );
}

export default ItemEdit;
