const FETCHED = 'count/FETCHED';
const UPDATED = 'count/UPDATED';

export const fetched = () => ({ type: FETCHED, payload: true });
export const updated = () => ({ type: UPDATED, payload: false });

const initialState = {
  countState: false,
};

export default function count(state = initialState, action) {
  switch (action.type) {
    case FETCHED:
      return { ...state, countState: action.payload };
    case UPDATED:
      return { ...state, countState: action.payload };
    default:
      return state;
  }
}
