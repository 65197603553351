import { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import bizManagerAPI from '../../api/bizManager';
import GridTable from '../../components/Common/GridTable';
import DetailHeader from '../../components/Common/DetailHeader';

const mgrCols = [
  {
    label: '번호',
    field: 'mgrId',
    sort: 'asc',
  },
  {
    label: 'biz_id',
    field: 'bizId',
  },
  {
    label: 'name',
    field: 'mgrName',
  },
  {
    label: 'phone',
    field: 'mgrPhone',
  },
  {
    label: 'grade',
    field: 'mgrGrade',
  },
  {
    label: 'team',
    field: 'mgrTeam',
  },
  {
    label: 'email',
    field: 'mgrEmail',
  },
];

const header = {
  parentLabel: '담당자 관리',
  parentLink: '/biz-manager',
  current: '담당자 목록',
};

const initialForm = {
  mgrId: undefined,
  bizId: undefined,
  mgrName: '',
  mgrPhone: '',
  mgrGrade: '',
  mgrTeam: '',
  mgrEmail: '',
  mgrEnter: '',
  oder: '',
};

const ADD_PAGE = '/biz-manager/add';
const toEdit = id => `/biz-manager/${id}`;

function BizManagerList({ history }) {
  const [searchForm, setSearchForm] = useState(initialForm);
  const [mgrList, setMgrList] = useState([]);
  const { tokenValid } = useSelector(state => ({
    tokenValid: state.Token.data,
  }));

  const evChangeSearch = ({ target }) => {
    const { name, value } = target;
    setSearchForm(search => ({
      ...search,
      [name]: value,
    }));
  };
  const evToAddPage = () => {
    history.push(ADD_PAGE);
  };
  const reset = () => {
    setSearchForm({
      ...initialForm,
    });
  };
  const evSearch = useCallback(async () => {
    const result = await bizManagerAPI.getList(searchForm);
    setMgrList(() =>
      result.map(mgr => ({
        ...mgr,
        mgrName: <Link to={`${toEdit(mgr.mgrId)}`}>{mgr.mgrName}</Link>,
      })),
    );
  }, [searchForm]);

  useEffect(() => {
    async function fetchData() {
      const result = await bizManagerAPI.getList();
      setMgrList(() =>
        result.map(mgr => ({
          ...mgr,
          mgrName: <Link to={`${toEdit(mgr.mgrId)}`}>{mgr.mgrName}</Link>,
        })),
      );
    }
    if (tokenValid) fetchData();
  }, [tokenValid]);

  return (
    <Container fluid>
      <DetailHeader headerInfo={header} />
      <Card>
        <CardBody>
          <Row>
            <Col>
              <h4>검색</h4>
            </Col>
          </Row>
          <Row>
            <Col sm={2}>
              <label
                htmlFor="mgr-name"
                className="col-sm-12 col-form-label bg-secondary rounded"
              >
                이름
              </label>
            </Col>
            <Col sm={4}>
              <input
                id="mgr-name"
                name="mgrName"
                className="form-control"
                type="text"
                onChange={evChangeSearch}
                value={searchForm.mgrName}
              />
            </Col>
          </Row>
          <Row className="form-group line align-items-center justify-content-center">
            <button
              type="button"
              onClick={evSearch}
              className="btn btn-primary waves-effect waves-light"
            >
              검색
            </button>
            <button
              type="button"
              onClick={reset}
              className="btn btn-secondary waves-effect waves-light"
            >
              초기화
            </button>
          </Row>
        </CardBody>
      </Card>
      <GridTable
        data={{ columns: mgrCols, rows: mgrList }}
        evClickButton={evToAddPage}
      />
    </Container>
  );
}

export default BizManagerList;
