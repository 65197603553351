import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Card, CardBody, Table } from 'reactstrap';
import {
  overflowCSS,
  tableNumberCSS,
  tableTextCSS,
} from '../../pages/Settlement/DetailPage';
import { excelDL } from '../../util/excel';

const SettlementListTable = ({ list, form }) => {
  const role = useSelector(state => state.Token.role);
  const isAdmin = role === 'ROLE_ADMIN';
  const history = useHistory();
  const toDetailPage = id => {
    if (isAdmin) {
      history.push(
        `/settlement/detail?bizId=${id}&startDate=${form.startDate}&endDate=${form.endDate}`,
      );
    } else {
      history.push(
        `/settlement/detail?startDate=${form.startDate}&endDate=${form.endDate}`,
      );
    }
  };
  const evExcelDL = () => {
    const excel = list.map(d => ({
      번호: d.rownum,
      협력사: d.bizName,
      쇼핑몰: '해든몰',
      주문량: d.itemCount,
      '매입-공급가액': d.purchaseSupplyPrice,
      '매입-세액': d.purchaseTaxAmount,
      매입액: d.purchaseAmount,
      '매출-공급가액': d.salesSupplyPrice,
      '매출-세액': d.salesTaxAmount,
      매출액: d.salesAmount,
      '결제-신용카드': d.cardAmount,
      '결제-포인트': d.pointAmount,
      결제총액: d.payAmount,
      매출이익: d.profit,
      매익률: `${d.grossMargin || '-'}%`,
    }));
    excelDL(
      `정산조회결과_${moment(form.startDate).format('YYYYMMDD')}-${moment(
        form.endDate,
      ).format('YYYYMMDD')}`,
      excel,
    );
  };

  return (
    <Card>
      <CardBody>
        <h4 className="mb-4">
          총 {list.length}건 조회
          <Button color="success" className="ml-2" onClick={evExcelDL}>
            엑셀 다운로드
          </Button>
        </h4>
        <Table
          style={{ minWidth: '1280px', tableLayout: 'fixed' }}
          bordered
          hover
        >
          <thead>
            <tr>
              <th style={tableTextCSS} rowSpan={2} width="100px">
                번호
              </th>
              <th style={tableTextCSS} rowSpan={2} width="100px">
                협력사
              </th>
              <th style={tableTextCSS} rowSpan={2} width="80px">
                쇼핑몰
              </th>
              <th style={tableTextCSS} rowSpan={2} width="60px">
                주문량
              </th>
              <th style={tableTextCSS} colSpan={3}>
                매입액
              </th>
              <th style={tableTextCSS} colSpan={3}>
                매출액
              </th>
              <th style={tableTextCSS} colSpan={3}>
                결제방법
              </th>
              <th style={tableTextCSS} rowSpan={2}>
                매출이익
              </th>
              <th style={tableTextCSS} rowSpan={2} width="60px">
                매익률
              </th>
            </tr>
            <tr>
              <th style={tableTextCSS}>공급가액</th>
              <th style={tableTextCSS}>세액</th>
              <th style={tableTextCSS}>매입액</th>
              <th style={tableTextCSS}>공급가액</th>
              <th style={tableTextCSS}>세액</th>
              <th style={tableTextCSS}>매출액</th>
              <th style={tableTextCSS}>신용카드</th>
              <th style={tableTextCSS}>포인트</th>
              <th style={tableTextCSS}>합계</th>
            </tr>
          </thead>
          <tbody>
            {list.length !== 0 ? (
              list.map(s => (
                <tr
                  key={s.bizId}
                  onClick={() => toDetailPage(s.bizId)}
                  style={{ cursor: 'pointer' }}
                >
                  <td style={tableNumberCSS}>{s.rownum}</td>
                  <td style={overflowCSS} title={s.bizName}>
                    {s.bizName}
                  </td>
                  <td style={overflowCSS} title="해든몰">
                    해든몰
                  </td>
                  <td style={tableNumberCSS}>{s.itemCount}</td>
                  <td style={tableNumberCSS}>
                    {s.purchaseSupplyPrice
                      ? s.purchaseSupplyPrice.toLocaleString()
                      : '-'}
                  </td>
                  <td style={tableNumberCSS}>
                    {s.purchaseTaxAmount
                      ? s.purchaseTaxAmount.toLocaleString()
                      : '-'}
                  </td>
                  <td style={tableNumberCSS}>
                    {s.purchaseAmount ? s.purchaseAmount.toLocaleString() : '-'}
                  </td>
                  <td style={tableNumberCSS}>
                    {s.salesSupplyPrice
                      ? s.salesSupplyPrice.toLocaleString()
                      : '-'}
                  </td>
                  <td style={tableNumberCSS}>
                    {s.salesTaxAmount ? s.salesTaxAmount.toLocaleString() : '-'}
                  </td>
                  <td style={tableNumberCSS}>
                    {s.salesAmount ? s.salesAmount.toLocaleString() : '-'}
                  </td>
                  <td style={tableNumberCSS}>
                    {s.cardAmount ? s.cardAmount.toLocaleString() : '-'}
                  </td>
                  <td style={tableNumberCSS}>
                    {s.pointAmount ? s.pointAmount.toLocaleString() : '-'}
                  </td>
                  <td style={tableNumberCSS}>
                    {s.payAmount ? s.payAmount.toLocaleString() : '-'}
                  </td>
                  <td style={tableNumberCSS}>
                    {s.profit ? s.profit.toLocaleString() : '-'}
                  </td>
                  <td style={tableNumberCSS}>{`${s.grossMargin || '-'}%`}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="15">내역이 없습니다</td>
              </tr>
            )}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default SettlementListTable;
