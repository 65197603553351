import axios from './axios';
import { BIZ } from './urls';

export default {
  async insertBiz(params) {
    try {
      return await axios.post(BIZ.UPSERT, params);
    } catch (e) {
      return 'INSERT ERROR';
    }
  },

  async getBiz(bizId) {
    try {
      return await axios.get(BIZ.GET_OR_DELETE + bizId);
    } catch (e) {
      return {};
    }
  },

  async getBizNames(field) {
    try {
      const response = await axios.get(BIZ.GET_LIST, { params: { field } });
      return response;
    } catch (e) {
      return [];
    }
  },

  /**
   * Request category list
   * @param {Object} params : Search conditions
   * @returns {Array}
   */
  async getBizList(params) {
    try {
      return await axios.get(BIZ.GET_LIST, { params });
    } catch (e) {
      return [];
    }
  },

  async updateBiz(params) {
    try {
      return await axios.put(BIZ.UPSERT, params);
    } catch (e) {
      return 'UPDATE ERROR';
    }
  },

  async deleteBiz(bizId) {
    try {
      return await axios.delete(BIZ.GET_OR_DELETE + bizId);
    } catch (e) {
      return 'DELETE ERROR';
    }
  },
  async getList(params) {
    try {
      if (!params) return await axios.get(BIZ.GET_LIST, { params });
      return await axios.get(BIZ.GET_LIST, {
        params: {
          bizCrn: params.bizCrn,
          [params.searchKind]: params.searchText,
        },
      });
    } catch (e) {
      return [];
    }
  },
  async getOne(id) {
    try {
      return await axios.get(BIZ.GET_OR_DELETE + id);
    } catch (e) {
      return e;
    }
  },
  async insert(params) {
    try {
      return await axios.post(BIZ.UPSERT, params);
    } catch (e) {
      return e;
    }
  },

  async update(params) {
    try {
      return await axios.put(BIZ.UPSERT, params);
    } catch (e) {
      return e;
    }
  },

  async delete(id) {
    try {
      return await axios.delete(BIZ.GET_OR_DELETE + id);
    } catch (e) {
      return e;
    }
  },
};
