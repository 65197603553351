import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody, FormGroup, Button } from 'reactstrap';
import { useSelector } from 'react-redux';

import ListHeader from '../../components/Common/ListHeader';
import GridTable from '../../components/Common/GridTable';
import FormMaker from '../../util/FormMaker';
import getCodeList from '../../api/code';

const headerInfo = {
  parentLabel: '코드 관리',
  parentLink: '/code',
  current: '코드 목록',
  btnLabel: '코드 등록',
  btnIcon: 'mdi mdi-account-edit mr-2',
};

const defaultSearchForm = {
  parentCode: '0',
  codeLabel: '',
};

const formRowList = [
  [
    {
      type: 'label',
      forTarget: 'parentCode',
      className: 'col-md-2 col-form-label',
      text: '상위 코드',
    },
    {
      type: 'select',
      name: 'parentCode',
      mdSize: 4,
      initValue: '0',
      optionList: [
        { label: '최상위', value: '0' },
        { label: '일반', value: '' },
      ],
    },
    {
      type: 'label',
      forTarget: 'codeLabel',
      className: 'col-sm-2 col-form-label',
      text: '코드 명칭',
    },
    {
      type: 'search',
      name: 'codeLabel',
      mdSize: 4,
      initValue: '',
    },
  ],

  [
    {
      type: 'label',
      forTarget: 'parentCode1',
      className: 'col-md-2 col-form-label',
      text: '상위 코드',
    },
    {
      type: 'select',
      name: 'parentCode1',
      mdSize: 4,
      initValue: '0',
      optionList: [
        { label: '최상위', value: '0' },
        { label: '일반', value: '' },
      ],
    },
  ],
];

const codeCols = [
  {
    label: '상위코드',
    field: 'parentCode',
    sort: 'asc',
    width: 200,
  },
  {
    label: '코드',
    field: 'code',
    width: 200,
  },
  {
    label: '코드 명칭',
    field: 'codeLabel',
    width: 600,
  },
];

const CodeList = ({ history }) => {
  /* search Form */
  const [searchForm, setSearchForm] = useState(defaultSearchForm);
  const { tokenValid } = useSelector(state => ({
    tokenValid: state.Token.data,
  }));

  const evChangeSearch = ({ target }) => {
    const { name, value } = target;

    setSearchForm({
      ...searchForm,
      [name]: value,
    });
  };

  /* Data Grid */
  const [codeRows, setCodeRows] = useState([]);

  /* Button Action */
  const evClickInit = () => {
    setSearchForm({
      ...defaultSearchForm,
    });
  };

  const evClickAdd = () => history.push('/code/add');

  const evClickSearch = useCallback(() => {
    getCodeList(searchForm.parentCode, searchForm.codeLabel).then(response => {
      const result = response.data;
      // codeRows = result.map(code => ({
      //   ...code,
      //   codeLabel: <Link to={`/code/${code.codeId}`}>{code.codeLabel}</Link>,
      // }));
      setCodeRows(
        result.map(code => ({
          ...code,
          codeLabel: <Link to={`/code/${code.codeId}`}>{code.codeLabel}</Link>,
        })),
      );
    });
  }, [searchForm.parentCode, searchForm.codeLabel]);

  /* run when comoponentDidMount */
  useEffect(() => {
    if (tokenValid) {
      getCodeList().then(response => {
        if (response == null) return;

        const result = response.data;
        setCodeRows(
          result.map(code => ({
            ...code,
            codeLabel: (
              <Link to={`/code/${code.codeId}`}>{code.codeLabel}</Link>
            ),
          })),
        );
      });
    }
  }, [tokenValid]);

  return (
    <>
      <div className="container-fluid">
        <ListHeader headerInfo={headerInfo} onClick={evClickAdd} />

        <Row>
          <Col>
            <Card>
              <CardBody>
                <FormMaker
                  formRowList={formRowList}
                  changeEvent={evChangeSearch}
                />

                <Row className="form-group">
                  <label
                    htmlFor="parentCode"
                    className="col-sm-2 col-form-label"
                  >
                    상위 코드
                  </label>
                  <Col sm={4}>
                    <input
                      id="parentCode"
                      name="parentCode"
                      className="form-control"
                      type="text"
                      onChange={evChangeSearch}
                      value={searchForm.parentCode}
                    />{' '}
                    [ready to popup]
                  </Col>

                  <label
                    htmlFor="codeLabel"
                    className="col-sm-2 col-form-label"
                  >
                    코드 명칭
                  </label>
                  <Col sm={4}>
                    <input
                      id="codeLabel"
                      name="codeLabel"
                      className="form-control"
                      type="text"
                      onChange={evChangeSearch}
                      value={searchForm.codeLabel}
                    />
                  </Col>
                </Row>

                <div className="form-group line align-items-center justify-content-center">
                  <button
                    onClick={evClickSearch}
                    className="btn btn-primary waves-effect waves-light"
                  >
                    검색
                  </button>
                  <button
                    onClick={evClickInit}
                    className="btn btn-secondary waves-effect waves-light"
                  >
                    초기화
                  </button>
                </div>
              </CardBody>
            </Card>

            <GridTable data={{ columns: codeCols, rows: codeRows }} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CodeList;
