export const API_URL =
  process.env.REACT_APP_API_URL || 'http://localhost:8081/api/';
// 'https://api.headenmall.co.kr/api/';
// '/api/';

export const AUTH = {
  LOGIN_WORKER: 'auth/worker',
};

export const CATEGORY = {
  UPSERT: 'category',
  GET_OR_DELETE: 'category/',
  GET_LIST: 'category',
};

export const TEXT_BAN = {
  UPSERT: 'text-ban',
  GET_OR_DELETE: 'text-ban/',
  GET_LIST: 'text-ban',
};

export const BIZ_MANAGER = {
  UPSERT: 'biz-manager',
  GET_OR_DELETE: 'biz-manager/',
  GET_LIST: 'biz-manager',
  DELETE_LIST: 'biz-manager/deleteManagers',
};

export const USER = {
  UPSERT: 'user',
  GET_OR_DELETE: 'user/',
  GET_LIST: 'user',
  GET_ADDRESS_LIST: id => `user/${id}/address`,
  GET_POINT_LIST: id => `user/${id}/point`,
  EXCEL: 'user/excel',
  GET_ORDER_LIST: id => `user/${id}/order`,
  GET_ID_OR_ACCOUNT: 'user/byIdOrAccount',
  GET_BY_BIZ_ID: id => `user/byBizId/${id}`,
};

export const TAG = {
  UPSERT: 'tags',
  GET_OR_DELETE: 'tags/',
  GET_LIST: 'tags',
  EXCEL: 'tags/excel',
};

export const BIZ = {
  UPSERT: 'biz',
  GET_OR_DELETE: 'biz/',
  GET_LIST: 'biz',
};

export const WORKER = {
  UPSERT: 'worker',
  GET_OR_DELETE: 'worker/',
  GET_LIST: 'worker',
};

export const BRAND = {
  UPSERT: 'brand',
  GET_OR_DELETE: 'brand/',
  GET_LIST: 'brand',
};

export const SUPPLIER = {
  UPSERT: 'suppliers',
  GET_OR_DELETE: 'suppliers/',
  GET_LIST: 'suppliers',
  STATUS_UPDATE: 'suppliers/status',
};

export const ITEM = {
  UPSERT: 'item',
  GET_OR_DELETE: 'item/',
  GET_LIST: 'item',
  UPSERT_ITEM_OPTIONS: id => `item/${id}/item-option`,
  GET_ITEM_OPTION_LIST: id => `item/${id}/item-option`,
  EXCEL: 'item/excel',
  GET_NOT_ACCEPT_COUNT: 'item/accept/count',
  UPDATE_ACCEPT: 'item/accept',
  UPDATE_IMAGE: id => `item/${id}/image`,
  GET_IMAGES: id => `item/${id}/image`,
  INSERT_CLONE: 'item/clone',
};

export const ITEM_IMG = {
  UPSERT: 'item-img',
  GET_OR_DELETE: 'item-img/',
  GET_LIST: 'item-img',
  INSERT_CLONE: 'item-img/clone',
};
export const POINT_KIND = {
  UPSERT: 'pointKind',
  GET_OR_DELETE: 'pointKind/',
  GET_LIST: 'pointKind',
};

export const POINT = {
  UPSERT: 'point',
  GET_OR_DELETE: 'point/',
  GET_LIST: 'point',
  EXCEL: 'point/excel',
  BULK_INSERT: 'point/bulkInsert',
};

export const POINT_SET = {
  UPSERT: 'pointSet',
  GET_OR_DELETE: 'pointSet/',
};

export const SETTLEMENT = {
  GET_LIST: 'settlement_sp',
  GET_DETAIL_LIST: 'settlement_sp/detail',
  ACCEPT_SETTLEMENT: 'payment/settlement',
};

export const ORDER = {
  UPSERT: 'order',
  GET_OR_DELETE: 'order/',
  GET_LIST: 'order',
  GET_STATUS_CODE_LIST: 'order/status-code',
  GET_STATUS_CODE_ONE: id => `order/status-code/${id}`,
  GET_DELIVER_COMPANY_LIST: 'deliver/company',
  UPDATE_DELIVER: 'order/deliver',
  GET_BUYER: id => `order/${id}/user`,
  GET_RECEIVER: id => `order/${id}/deliver`,
  GET_PAY_INFO: id => `order/${id}/payment`,
  GET_CLAIM: id => `order/${id}/claim`,
  GET_STATUS_ORDER_COUNT: 'order/status/count',
};

export const CLAIM = {
  UPSERT: 'claim',
};

export const BOOTPAY = {
  corfirm: 'bootpay/confirm',
  CANCEL: 'bootpay/cancel',
};

export const REGISTER = {
  UPSERT_BIZ: 'register/biz',
  UPSERT_WORKER: 'register/worker',
  UPSERT_BIZ_MANAGER: 'register/biz-manager',
  UPSERT_SUPPLIER: 'register/supplier',
};

export const STATISTICS = process.env.REACT_APP_STATISTICS_URL
  ? {
      products: `${process.env.REACT_APP_STATISTICS_URL}v1/products`,
      bulkProducts: `${process.env.REACT_APP_STATISTICS_URL}v1/products/bulk`,
    }
  : {
      products: 'http://localhost:8008/v1/products',
      bulkProducts: 'http://localhost:8008/v1/products/bulk',
    };

export const S3THUMBNAIL = name =>
  `https://s3.ap-northeast-2.amazonaws.com/files.ai-q.co.kr/shop/thumbnail/${name}`;

export const KEYWORDS = {
  UPSERT: '/keywords',
  GET_LIST: '/keywords',
};
