import { Card, CardBody, Col, Row } from 'reactstrap';
import GridTable from '../Common/GridTable';

const orderCols = [
  {
    label: '번호',
    field: 'index',
    minimal: 'sm',
  },
  {
    label: '주문번호',
    field: 'orderId',
    minimal: 'sm',
  },
  {
    label: '상품명',
    field: 'itemName',
    minimal: 'sm',
  },
  {
    label: '상품금액',
    field: 'priceSell',
    minimal: 'sm',
  },
  {
    label: '수량',
    field: 'itemCount',
    minimal: 'sm',
  },
  {
    label: '배송비',
    field: 'shipCost',
    minimal: 'sm',
  },
  {
    label: '할인금액',
    field: 'dc',
    minimal: 'sm',
  },
  {
    label: '합계',
    field: 'itemAmount',
    minimal: 'sm',
  },
  {
    label: '총 금액',
    field: 'totalAmount',
    minimal: 'sm',
  },
  {
    label: '주문상태',
    field: 'codeLabel',
    minimal: 'sm',
  },
  {
    label: '판매자명',
    field: 'bizName',
    minimal: 'sm',
  },
  {
    label: '구매일',
    field: 'createdAt',
    minimal: 'sm',
  },
];
function UserOrder({ fetched, oder }) {
  if (!fetched) {
    return (
      <Card>
        <CardBody>
          <Row className="mb-3">
            <Col>
              <h4
                style={{
                  borderBottom: '1px solid #EBEBEB',
                  paddingBottom: '0.5rem',
                }}
              >
                주문 내역
              </h4>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
  return (
    <Card>
      <CardBody>
        <Row className="mb-3">
          <Col>
            <h4
              style={{
                borderBottom: '1px solid #EBEBEB',
                paddingBottom: '0.5rem',
              }}
            >
              주문 내역
            </h4>
          </Col>
        </Row>
        <GridTable data={{ columns: orderCols, rows: oder }} />
      </CardBody>
    </Card>
  );
}

export default UserOrder;
