import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { useSelector } from 'react-redux';

import ListHeader from '../../components/Common/ListHeader';
import FormGenerator from '../../util/FormGenerator';
import GridTable from '../../components/Common/GridTable';
import Category from '../../api/category';

const headerInfo = {
  parentLabel: '카테고리 관리',
  parentLink: '/category',
  current: '카테고리 목록',
  btnLabel: '카테고리 등록',
  btnIcon: 'mdi mdi-account-edit mr-2',
};

const defaultFormRowList = [
  [
    {
      type: 'label',
      label: '카테고리',
    },
    {
      type: 'select',
      mdSize: 2,
      feild: 'firstCategory',
      initValue: -1,
      optionList: [{ label: '전체', value: '' }],
    },
    {
      type: 'select',
      mdSize: 2,
      feild: 'parentId',
      initValue: '0',
      optionList: [{ label: '전체', value: '' }],
    },
    {
      type: 'label',
      label: '카테고리 이름',
    },
    {
      type: 'input',
      mdSize: 4,
      feild: 'catgryName',
      initValue: '',
    },
  ],
];

const defaultSearchForm = {
  firstCategory: '',
  parentId: '',
  catgryName: '',
  usedFlag: '1',
};

const catgryCols = [
  {
    label: 'Id',
    field: 'catgryId',
    width: 105,
  },
  {
    label: '카테고리 코드',
    field: 'catgryCode',
    width: 130,
  },
  {
    label: '카테고리 구분',
    field: 'division',
    width: 140,
  },
  {
    label: '카테고리 이름',
    field: 'catgryName',
    width: 530,
  },
  {
    label: '상위 카테고리명',
    field: 'parentName',
    width: 500,
  },
  {
    label: '등록일',
    field: 'createdAt',
    width: 150,
  },
];

const CategoryList = ({ history }) => {
  /* search Form */
  const [searchForm, setSearchForm] = useState(defaultSearchForm);
  const [formRowList, setFormRowList] = useState(defaultFormRowList);
  const { tokenValid } = useSelector(state => ({
    tokenValid: state.Token.data,
  }));

  const getDivision = code => {
    if (code.length > 4) {
      return '소구분';
    }

    if (code.length > 2) {
      return '중구분';
    }

    return '대구분';
  };

  const setSelectOptions = (value, options = []) => {
    const temp = formRowList;
    temp[0][2].optionList = [
      {
        label: '전체',
        value,
      },
    ];

    options.forEach(category => {
      temp[0][2].optionList.push({
        label: category.catgryName,
        value: category.catgryId,
      });
    });

    setFormRowList(temp);
    setSearchForm({
      ...searchForm,
      firstCategory: value,
      parentId: value,
    });
  };

  const evChangeSearch = ({ target }) => {
    const { name, value } = target;

    if (name === 'firstCategory') {
      if (value === '0') {
        setSelectOptions(value);
      } else {
        Category.getList({ parentId: value }).then(response => {
          setSelectOptions(value, response);
        });
      }
    } else {
      setSearchForm({
        ...searchForm,
        [name]: value,
      });
    }
  };

  /* Data Grid */
  const [catgryRows, setCatgryRows] = useState([]);

  /* Button Action */
  const evClickInit = () => {
    setSearchForm({
      ...defaultSearchForm,
    });
  };

  const evClickAdd = () => history.push('/category/add');

  const evClickSearch = useCallback(() => {
    Category.getList(searchForm).then(response => {
      setCatgryRows(
        response.map(category => ({
          ...category,
          createdAt: category.createdAt.replace('T', ' '),
          division: getDivision(category.catgryCode),
          catgryName: (
            <Link to={`/category/${category.catgryId}`}>
              {category.catgryName}
            </Link>
          ),
        })),
      );
    });
  }, [searchForm]);

  // run when side effect
  useEffect(() => {
    if (tokenValid) {
      Category.getList(defaultSearchForm).then(response => {
        const tableData = response.map(category => ({
          ...category,
          createdAt: category.createdAt.replace('T', ' '),
          division: getDivision(category.catgryCode),
          catgryName: (
            <Link to={`/category/${category.catgryId}`}>
              {category.catgryName}
            </Link>
          ),
        }));

        const temp = defaultFormRowList;
        if (temp[0][1].optionList.length < 2) {
          response.forEach(category => {
            if (category.catgryCode.length < 3) {
              temp[0][1].optionList.push({
                label: category.catgryName,
                value: category.catgryId,
              });
            }
          });
        }

        setCatgryRows(tableData);
        setFormRowList(temp);
      });
    }
  }, [tokenValid]);

  return (
    <>
      <div className="container-fluid">
        <ListHeader headerInfo={headerInfo} onClick={evClickAdd} />

        <Row>
          <Col>
            <Card>
              <CardBody>
                <FormGenerator
                  formData={searchForm}
                  changeEvent={evChangeSearch}
                  formRowList={formRowList}
                />

                <div className="form-group line align-items-center justify-content-center">
                  <button
                    onClick={evClickSearch}
                    className="btn btn-primary waves-effect waves-light"
                  >
                    검색
                  </button>
                  <button
                    onClick={evClickInit}
                    className="btn btn-secondary waves-effect waves-light"
                  >
                    초기화
                  </button>
                </div>
              </CardBody>
            </Card>

            <GridTable data={{ columns: catgryCols, rows: catgryRows }} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CategoryList;
