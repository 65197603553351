import React, { Component, useCallback, useEffect, useState } from 'react';
import { Switch, BrowserRouter as Router, useHistory } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';

// Import Routes
import { publicRoutes, masterRoutes, supplierRoutes } from './routes';
import AppRoute from './routes/route';

// layouts
import AuthLayout from './components/Layout';
import NonAuthLayout from './components/NonAuthLayout';

// Import scss
import './assets/scss/theme.scss';

// Import Firebase Configuration file
import { initFirebaseBackend } from './helpers/authUtils';
import auth from './api/auth';
import {
  loginWorkerSuccess,
  logoutWorker,
  logoutWorkerSuccess,
} from './store/actions';
import { setAuthToken } from './api/axios';
import { tokenRefresh } from './store/token/reducer';

const firebaseConfig = {
  apiKey: 'AIzaSyBUctS2mlPY58Y-E1AypSUz7OO0zhlBLC8',
  authDomain: 'themesbrand-admin.firebaseapp.com',
  databaseURL: 'https://themesbrand-admin.firebaseio.com',
  projectId: 'themesbrand-admin',
  storageBucket: 'themesbrand-admin.appspot.com',
  messagingSenderId: '427667224207',
  appId: '1:427667224207:web:3b97af80b8b4824619a2fa',
  measurementId: 'G-S4LDYNV7FY',
};

// init firebase backend
// initFirebaseBackend(firebaseConfig);

const ROLE_ADMIN = 'ROLE_ADMIN';
const ROLE_WORKER = 'ROLE_WORKER';

function App() {
  const { layout, isLogin, token, role } = useSelector(state => ({
    layout: state.Layout,
    isLogin: state.Login.isLogin,
    token: state.Token,
    role: state.Token.role,
  }));
  const dispatch = useDispatch();
  const history = useHistory();
  const checkLogin = () => dispatch(loginWorkerSuccess(history));
  const logout = () => dispatch(logoutWorker(history));
  const checkToken = () => dispatch(tokenRefresh());
  const authUser =
    sessionStorage.getItem('authUser') && sessionStorage.getItem('authUser');
  const isAdmin = role === 'ROLE_ADMIN';
  const [tokenValid, setTokenValid] = useState(null);
  const refresh = async () => {
    const result = await auth.refresh();
    if (!result.fail) {
      setAuthToken(result.token);
      auth.tokenRenewal();
    }
  };
  const checkRefreshToken = useCallback(async () => {
    const result = await auth.checkRefreshToken();
    if (!result) {
      sessionStorage.removeItem('authUser');
      // window.location.reload();
      logout();
    }
    setTokenValid(result);
  }, []);

  useEffect(() => {
    // apiCheck();
    if (!token.loading && !token.data && tokenValid && !token.error) {
      checkToken();
    }
    if (!tokenValid && !isLogin && authUser) {
      // localStorage.removeItem('authUser');
      checkRefreshToken();
    }
    if (!isLogin && tokenValid && authUser) {
      checkLogin();
      refresh();
    }
  }, [
    authUser,
    isLogin,
    refresh,
    tokenValid,
    checkRefreshToken,
    checkToken,
    token,
  ]);
  return (
    <Router>
      <Switch>
        {!authUser &&
          publicRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              title={route.title}
              key={idx}
              isAuthProtected={false}
            />
          ))}
        {/*라우팅방식 수정하기 */}
        {authUser &&
          (isAdmin
            ? masterRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={AuthLayout}
                  title={route.title}
                  component={route.component}
                  key={idx}
                  isAuthProtected={tokenValid}
                />
              ))
            : supplierRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={AuthLayout}
                  title={route.title}
                  component={route.component}
                  key={idx}
                  isAuthProtected={tokenValid}
                />
              )))}
      </Switch>
    </Router>
  );
}

export default App;
