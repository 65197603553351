import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import workerApi from '../../api/worker';

const BizSelectList = ({
  onChange,
  firstItem,
  selected,
  excludeIfHasWorker,
  returnBiz,
}) => {
  const [bizList, setBizList] = useState([]);
  const { tokenValid } = useSelector(state => ({
    tokenValid: state.Token.data,
  }));

  useEffect(() => {
    async function fetchData() {
      const result = await workerApi.getList({
        workerId: excludeIfHasWorker ? -1 : null,
      });
      if (result && Array.isArray(result)) {
        const collator = new Intl.Collator();
        result.sort((a, b) => {
          return collator.compare(a.bizName, b.bizName);
        });
        setBizList(result);
      }
    }
    if (tokenValid) fetchData();
  }, [excludeIfHasWorker, tokenValid]);

  return (
    <select
      className="form-select col-sm-12 form-control"
      aria-label="Default select example"
      name="bizId"
      onChange={onChange}
      value={selected || undefined}
      disabled={selected}
    >
      {firstItem && (
        <option value={firstItem.value}>{firstItem.innerText}</option>
      )}
      {bizList &&
        bizList.map(biz => (
          <option
            key={biz.bizId}
            value={returnBiz ? JSON.stringify(biz) : biz.bizId}
          >
            {biz.bizName}
          </option>
        ))}
    </select>
  );
};

export default BizSelectList;
