import { useCallback } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import GridTable from '../Common/GridTable';

const pointCols = [
  {
    label: '번호',
    field: 'pointId',
    minimal: 'sm',
  },
  {
    label: '포인트 종류',
    field: 'pointName',
    minimal: 'sm',
  },
  {
    label: '포인트',
    field: 'amount',
    minimal: 'sm',
  },
  {
    label: '포인트 구분',
    field: 'amountStatus',
    minimal: 'sm',
  },
  {
    label: '포인트 경로',
    field: 'pointPath',
    minimal: 'sm',
  },
  {
    label: '지급/사용일',
    field: 'createdAt',
    minimal: 'sm',
  },
];

function UserPoint({ point, pointNow, fetched }) {
  if (!fetched)
    return (
      <Card>
        <CardBody>
          <Row className="mb-3">
            <Col>
              <h4
                style={{
                  borderBottom: '1px solid #EBEBEB',
                  paddingBottom: '0.5rem',
                }}
              >
                포인트 정보
              </h4>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  const totalPoint = pointNow.reduce((acc, cur) => {
    return acc + cur.currentPoint;
  }, 0);

  const row = []; // for create point label
  return (
    <Card>
      <CardBody>
        <Row className="mb-3">
          <Col>
            <h4
              style={{
                borderBottom: '1px solid #EBEBEB',
                paddingBottom: '0.5rem',
              }}
            >
              포인트 정보
            </h4>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={3}>
                <label className="col-lg-12 col-form-label bg-secondary rounded">
                  보유 총 포인트
                </label>
              </Col>
              <Col lg={9}>
                <span className="px-2">{totalPoint} P</span>
              </Col>
            </Row>
          </Col>
        </Row>
        {pointNow &&
          pointNow.reduce((acc, cur, i) => {
            row.push(cur);
            if (i !== 0 && i % 2 === 1) {
              acc.push(
                <Row className="mb-3" key={point[i].pointId}>
                  {row.map(r => (
                    <Col sm={6} key={r.pointKindId}>
                      <Row className="align-items-center">
                        <Col lg={3}>
                          <label
                            htmlFor="bizRank"
                            className="col-lg-12 col-form-label bg-secondary rounded"
                          >
                            {r.pointName}
                          </label>
                        </Col>
                        <Col lg={9}>
                          <span className="px-2">{`${r.currentPoint.toLocaleString()} P`}</span>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>,
              );
              row.length = 0;
            }
            if (i === pointNow.length - 1 && i % 2 === 0) {
              acc.push(
                <Row className="mb-3" key={point[i].pointId}>
                  {row.map(r => (
                    <Col sm={6} key={r.pointKindId}>
                      <Row className="align-items-center">
                        <Col lg={3}>
                          <label
                            htmlFor="bizRank"
                            className="col-lg-12 col-form-label bg-secondary rounded"
                          >
                            {r.pointName}
                          </label>
                        </Col>
                        <Col lg={9}>
                          <span className="px-2">{`${r.currentPoint.toLocaleString()} P`}</span>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>,
              );
              row.length = 0;
            }
            return acc;
          }, [])}
        <Row className="my-3">
          <Col>
            <h4
              style={{
                borderBottom: '1px solid #EBEBEB',
                paddingBottom: '0.5rem',
              }}
            >
              포인트 정보
            </h4>
          </Col>
        </Row>
        <GridTable data={{ columns: pointCols, rows: point }} />
      </CardBody>
    </Card>
  );
}
export default UserPoint;
