import S3 from 'react-aws-s3';
import axios from './axios';
import { ITEM, ITEM_IMG, STATISTICS } from './urls';

const config = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
  s3Url: 'https://s3.ap-northeast-2.amazonaws.com/files.ai-q.co.kr',
};

const description = {
  dirName: `${process.env.REACT_APP_DIR_NAME}/description`,
};
const thumbnail = {
  dirName: `${process.env.REACT_APP_DIR_NAME}/thumbnail`,
};

export default {
  async getList(params) {
    try {
      return await axios.get(ITEM.GET_LIST, { params });
    } catch (e) {
      return [];
    }
  },
  async getOne(id) {
    try {
      return await axios.get(ITEM.GET_OR_DELETE + id);
    } catch (e) {
      return e;
    }
  },
  async insert(params) {
    try {
      return await axios.post(ITEM.UPSERT, { ...params });
    } catch (e) {
      return e;
    }
  },
  async insertImage(params) {
    try {
      const result = await axios.post(ITEM_IMG.UPSERT, params);
      return result;
    } catch (e) {
      return e;
    }
  },
  async uploadThumbnailImage(file, filename) {
    try {
      const ReactS3Client = new S3({
        ...config,
        ...thumbnail,
      });
      const result = await ReactS3Client.uploadFile(file, filename);
      return result;
    } catch (e) {
      return e;
    }
  },
  async uploadDescriptionImage(file, filename) {
    try {
      const ReactS3Client = new S3({
        ...config,
        ...description,
      });
      const result = await ReactS3Client.uploadFile(file, filename);
      return result;
    } catch (e) {
      return e;
    }
  },
  async updateImage(id, params) {
    try {
      return await axios.put(ITEM.UPDATE_IMAGE(id), params);
    } catch (e) {
      return e;
    }
  },
  async deleteDescriptionImage(params) {
    try {
      const ReactS3Client = new S3({ ...config, ...description });
      const result = await ReactS3Client.deleteFile(params);
      return result;
    } catch (e) {
      return e;
    }
  },
  async insertItemOption(id, params) {
    try {
      const result = await axios.post(ITEM.UPSERT_ITEM_OPTIONS(id), {
        params,
      });
      return result;
    } catch (e) {
      return e;
    }
  },

  async getItemOption(id) {
    try {
      return await axios.get(ITEM.GET_ITEM_OPTION_LIST(id));
    } catch (e) {
      return e;
    }
  },

  async update(params) {
    try {
      return await axios.put(ITEM.UPSERT, params);
    } catch (e) {
      return e;
    }
  },

  async updateItemOption(id, params) {
    try {
      const result = await axios.put(ITEM.UPSERT_ITEM_OPTIONS(id), { params });
      return result;
    } catch (e) {
      return e;
    }
  },

  async excelInsert(params) {
    try {
      return await axios.post(ITEM.EXCEL, { params });
    } catch (e) {
      return e;
    }
  },

  async delete(id) {
    try {
      return await axios.delete(ITEM.GET_OR_DELETE + id);
    } catch (e) {
      return e;
    }
  },

  async getNotAcceptCount(params) {
    try {
      return await axios.get(ITEM.GET_NOT_ACCEPT_COUNT, { params });
    } catch (e) {
      return undefined;
    }
  },

  async updateItemAcceptBulk(params) {
    try {
      return await axios.put(ITEM.UPDATE_ACCEPT, params);
    } catch (e) {
      return e;
    }
  },

  async getItemImagesById(id, params) {
    try {
      return await axios.get(ITEM.GET_IMAGES(id), { params });
    } catch (e) {
      return [];
    }
  },

  async deleteImage(imgId) {
    try {
      return await axios.delete(ITEM_IMG.GET_LIST, { params: { imgId } });
    } catch (e) {
      return undefined;
    }
  },

  async insertCloneImg(params) {
    try {
      const response = await axios.post(ITEM_IMG.INSERT_CLONE, params);
      return response;
    } catch (e) {
      return undefined;
    }
  },
};
