import React, { Component } from 'react';

class NonAuthLayout extends Component {
  componentDidMount() {
    document.title = `해든앰앤씨 복지몰 관리콘솔`;
  }
  render() {
    return <>{this.props.children}</>;
  }
}

export default NonAuthLayout;
