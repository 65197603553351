import axios from './axios';
import { WORKER } from './urls';

export default {
  async getList(params) {
    try {
      return await axios.get(WORKER.GET_LIST, { params });
    } catch (e) {
      return e;
    }
  },

  async getOne(id) {
    try {
      return await axios.get(WORKER.GET_OR_DELETE + id);
    } catch (e) {
      return e;
    }
  },

  async insert(params) {
    try {
      return await axios.post(WORKER.UPSERT, params);
    } catch (e) {
      return e;
    }
  },

  async update(params) {
    try {
      return await axios.put(WORKER.UPSERT, params);
    } catch (e) {
      return e;
    }
  },

  async delete(id) {
    try {
      return await axios.delete(WORKER.GET_OR_DELETE + id);
    } catch (e) {
      return e;
    }
  },
};
