import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import pointKindApi from '../../api/pointKind';
import pointApi from '../../api/point';
import userApi from '../../api/user';
import {
  aMonthAgo,
  aWeekAgo,
  dateFormat,
  dateToString,
  threeDaysAgo,
  threeMonthsAgo,
  today,
} from '../../util/dateFormat';
import GridTable from '../../components/Common/GridTable';
import DetailHeader from '../../components/Common/DetailHeader';
import PointKindSelect from '../../components/PointKind/PointKindSelect';

const headerInfo = {
  parentLabel: '회원 관리',
  parentLink: '/point',
  current: '회원 포인트 조회 및 추가',
};

const defaultSearchForm = {
  searchKind: 'account',
  searchText: '',
  startDate: '',
  endDate: today,
  pointType: 'amount', // tb_point - amount, point_now
  pointFrom: '',
  pointTo: '',
  transactionType: 'all',
  pointKindId: '',
};

const defaultPointSubmitForm = {
  userId: '',
  account: '',
  pointKindId: '',
  amount: '',
  transactionType: 'add',
  pointPath: '',
  submitKind: 'account',
};

const cols = [
  {
    label: '번호',
    field: 'userId',
  },
  {
    label: '아이디',
    field: 'account',
  },
  {
    label: '성명',
    field: 'userName',
  },
  {
    label: '포인트 종류',
    field: 'pointName',
  },
  {
    label: '포인트량',
    field: 'amount',
  },
  {
    label: '구분',
    field: 'transactionType',
  },
  {
    label: '잔여 포인트량',
    field: 'pointNow',
  },
  {
    label: '메모',
    field: 'pointPath',
  },
  {
    label: '발생일',
    field: 'createdAt', // tb_point // todo ?
  },
];

const fileName = '포인트조회';

const PointList = () => {
  const [pointKindList, setPointKindList] = useState([]);
  const [searchForm, setSearchForm] = useState(defaultSearchForm);
  const [apiData, setApiData] = useState([]);
  const [refreshData, setRefreshData] = useState(true);
  const [searchData, setSearchData] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [pointSubmitForm, setPointSubmitForm] = useState(
    defaultPointSubmitForm,
  );
  const { tokenValid } = useSelector(state => ({
    tokenValid: state.Token.data,
  }));

  const evChangeSearch = ({ target }) => {
    const { name, value } = target;
    setSearchForm({
      ...searchForm,
      [name]: value,
    });
  };

  const evChangePointSubmit = ({ target }) => {
    let { name } = target;
    const { value } = target;

    if (name === 'recipientIdentifier') {
      name = pointSubmitForm.submitKind === 'account' ? 'account' : 'userId';
    }

    setPointSubmitForm({
      ...pointSubmitForm,
      [name]: value,
    });
  };

  const parseSearchForm = () => {
    if (!searchForm.pointTo) searchForm.pointTo = '';
    if (!searchForm.pointFrom) searchForm.pointFrom = '';
    return {
      ...searchForm,
      [searchForm.searchKind]: searchForm.searchText,
    };
  };

  const evSearch = async () => {
    const params = parseSearchForm();
    const result = await pointApi.getList(params);
    if (Array.isArray(result)) {
      result.reverse();
      setApiData(result);
    }
  };

  const validatePointSubmit = async () => {
    let isValid = true;
    if (
      pointSubmitForm.submitKind === 'account' &&
      pointSubmitForm.account.trim() === ''
    ) {
      document.getElementById('submitKindInput').classList.add('is-error');
      isValid = false;
    } else if (
      pointSubmitForm.submitKind === 'userId' &&
      Number.isNaN(parseInt(pointSubmitForm.userId, 10))
    ) {
      document.getElementById('submitKindInput').classList.add('is-error');
      isValid = false;
    } else if (!(await userApi.getByAccountOrId(pointSubmitForm))) {
      document.getElementById('submitKindInput').classList.add('is-error');
      isValid = false;
    } else {
      document.getElementById('submitKindInput').classList.remove('is-error');
    }

    if (!pointSubmitForm.pointKindId) {
      document.getElementById('pointKindSelect').classList.add('is-error');
      isValid = false;
    } else {
      document.getElementById('pointKindSelect').classList.remove('is-error');
    }

    if (
      pointSubmitForm.amount.trim() === '' ||
      !pointSubmitForm.amount.match(/^[1-9]\d*$/)
    ) {
      document.getElementById('amountInput').classList.add('is-error');
      isValid = false;
    } else {
      document.getElementById('amountInput').classList.remove('is-error');
    }
    return isValid;
  };

  // todo - Korean alert message
  const evSubmitPoint = async () => {
    if (await validatePointSubmit()) {
      const result = await pointApi.insert(pointSubmitForm);
      if (Number.isInteger(result) && result !== 0) {
        alert('Success');
        setRefreshData(!refreshData);
      } else {
        alert('Something went wrong');
      }
    }
  };

  const excelMapping = list => {
    return list.map(data => ({
      번호: data.pointId,
      아이디: data.account,
      성명: data.userName,
      포인트종류: data.pointName,
      포인트량: data.amount,
      구분: data.amount > 0 ? '추가' : '감소',
      잔여포인트량: data.pointNow,
      메모: data.pointPath,
      발생일: dateToString(data.createdAt),
    }));
  };

  // Fetch list of point kinds
  useEffect(() => {
    async function fetchData() {
      const result = await pointKindApi.getList('');
      if (Array.isArray(result)) {
        setPointKindList(result);
      }
    }

    if (tokenValid) fetchData();
  }, [tokenValid]);

  // Fetch list of point transactions
  useEffect(() => {
    async function fetchData() {
      const result = await pointApi.getList('');
      if (Array.isArray(result)) {
        result.reverse();
        setApiData(result);
      }
    }

    if (tokenValid) fetchData();
  }, [refreshData, tokenValid]);

  // Map apiData to searchData for table
  useEffect(() => {
    setSearchData(
      apiData.map(point => ({
        ...point,
        createdAt: dateFormat(point.createdAt),
        transactionType: point.amount < 0 ? '감소' : '추가',
        account: (
          <span
            role="button"
            tabIndex={0}
            onKeyDown={() =>
              setPointSubmitForm(() => ({
                ...defaultPointSubmitForm,
                account: point.account,
                userId: point.userId,
                pointKindId: point.pointKindId,
              }))
            }
            onClick={() =>
              setPointSubmitForm(() => ({
                ...defaultPointSubmitForm,
                account: point.account,
                userId: point.userId,
                pointKindId: point.pointKindId,
              }))
            }
            style={{ color: 'blue' }}
          >
            {point.account}
          </span>
        ),
      })),
    );
    setExcelData(() => excelMapping(apiData));
  }, [apiData]);

  return (
    <Container fluid>
      <DetailHeader headerInfo={headerInfo} />
      <Card>
        <CardBody>
          <Row className="align-items-center mb-3">
            <Col>
              <h4>검색</h4>
            </Col>
          </Row>
          <Row className="align-items-center mb-3">
            <Col sm={6}>
              <Row>
                <Col sm={3}>
                  <select
                    className="form-select form-control"
                    name="searchKind"
                    value={searchForm.searchKind}
                    onChange={evChangeSearch}
                  >
                    <option value="account">아이디</option>
                    <option value="userName">성명</option>
                  </select>
                </Col>
                <Col sm={9}>
                  <input
                    className="form-control"
                    type="text"
                    name="searchText"
                    value={searchForm.searchText}
                    onChange={evChangeSearch}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="align-items-center mb-3">
            <Col sm={6}>
              <Row className="align-items-center">
                <Col sm={3}>
                  <label className="col-sm-12 col-form-label bg-secondary rounded">
                    발생일
                  </label>
                </Col>
                <Col sm={4}>
                  <input
                    className="form-control"
                    type="date"
                    name="startDate"
                    max={searchForm.endDate}
                    value={searchForm.startDate}
                    onChange={evChangeSearch}
                  />
                </Col>
                <i className="mdi mdi-tilde col-sm-1 d-flex justify-content-center" />
                <Col sm={4}>
                  <input
                    className="form-control"
                    type="date"
                    name="endDate"
                    value={searchForm.endDate}
                    min={searchForm.startDate}
                    onChange={evChangeSearch}
                  />
                </Col>
              </Row>
            </Col>

            <Col sm={6}>
              <Row>
                <Col className="button-items">
                  <button
                    type="button"
                    className="btn btn-outline-dark"
                    onClick={() =>
                      evChangeSearch({
                        target: { name: 'startDate', value: today },
                      })
                    }
                  >
                    오늘
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-dark"
                    onClick={() =>
                      evChangeSearch({
                        target: {
                          name: 'startDate',
                          value: threeDaysAgo,
                        },
                      })
                    }
                  >
                    3일 전
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-dark"
                    onClick={() =>
                      evChangeSearch({
                        target: { name: 'startDate', value: aWeekAgo },
                      })
                    }
                  >
                    1주 전
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-dark"
                    onClick={() =>
                      evChangeSearch({
                        target: { name: 'startDate', value: aMonthAgo },
                      })
                    }
                  >
                    한달 전
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-dark"
                    onClick={() =>
                      evChangeSearch({
                        target: {
                          name: 'startDate',
                          value: threeMonthsAgo,
                        },
                      })
                    }
                  >
                    3개월 전
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="align-items-center mb-3">
            <Col sm={6}>
              <Row className="align-items-center">
                <Col sm={3}>
                  <select
                    className="form-select form-control"
                    name="pointType"
                    value={searchForm.pointType || ''}
                    onChange={evChangeSearch}
                  >
                    <option value="amount">포인트</option>
                    <option value="pointNow">현재포인트량</option>
                  </select>
                </Col>
                <Col sm={4}>
                  <input
                    className="form-control"
                    type="number"
                    name="pointFrom"
                    value={searchForm.pointFrom || ''}
                    min={0}
                    max={searchForm.pointTo}
                    placeholder="이상"
                    onChange={evChangeSearch}
                  />
                </Col>
                <i className="mdi mdi-tilde col-sm-1 d-flex justify-content-center" />
                <Col sm={4}>
                  <input
                    className="form-control"
                    type="number"
                    name="pointTo"
                    min={searchForm.pointFrom}
                    placeholder="이하"
                    value={searchForm.pointTo}
                    onChange={evChangeSearch}
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={6}>
              <Row className="align-items-center">
                <Col sm={3}>
                  <label className="col-sm-12 col-form-label bg-secondary rounded">
                    포인트구분
                  </label>
                </Col>
                <Col sm={9} style={{ whiteSpace: 'nowrap' }}>
                  <div
                    className="form-check form-check-inline"
                    style={{ marginRight: '20px' }}
                  >
                    <input
                      id="transAll"
                      name="transactionType"
                      className="form-check-input"
                      type="radio"
                      value="all"
                      onChange={evChangeSearch}
                      checked={searchForm.transactionType === 'all'}
                    />
                    <label className="form-check-label" htmlFor="transAll">
                      전체
                    </label>
                  </div>
                  <div
                    className="form-check form-check-inline"
                    style={{ marginRight: '20px' }}
                  >
                    <input
                      id="transAdd"
                      name="transactionType"
                      className="form-check-input"
                      type="radio"
                      value="add"
                      onChange={evChangeSearch}
                      checked={searchForm.transactionType === 'add'}
                    />
                    <label className="form-check-label" htmlFor="transAdd">
                      추가
                    </label>
                  </div>
                  <div
                    className="form-check form-check-inline"
                    style={{ marginRight: '20px' }}
                  >
                    <input
                      id="transRemove"
                      name="transactionType"
                      className="form-check-input"
                      type="radio"
                      value="remove"
                      onChange={evChangeSearch}
                      checked={searchForm.transactionType === 'remove'}
                    />
                    <label className="form-check-label" htmlFor="transRemove">
                      감소
                    </label>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="align-items-center mb-3">
            <Col sm={6}>
              <Row>
                <Col sm={3}>
                  <label className="col-sm-12 col-form-label bg-secondary rounded">
                    포인트 종류
                  </label>
                </Col>
                <Col sm={4}>
                  <PointKindSelect
                    firstOption={{ value: '', text: '전체' }}
                    onChange={evChangeSearch}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="form-group align-items-center justify-content-center button-items">
            <button
              type="button"
              onClick={evSearch}
              className="btn btn-primary waves-effect waves-light"
            >
              검색
            </button>
            <button
              type="button"
              onClick={() => setSearchForm(defaultSearchForm)}
              className="btn btn-info waves-effect waves-light"
            >
              초기화
            </button>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <Row className="align-items-center mb-3">
            <Col>
              <h4>포인트 추가 / 감소</h4>
            </Col>
          </Row>
          <Row className="align-items-center mb-3">
            <Col sm={6}>
              <Row>
                <Col sm={3}>
                  <select
                    className="form-select form-control"
                    name="submitKind"
                    value={pointSubmitForm.submitKind}
                    onChange={evChangePointSubmit}
                  >
                    <option value="account">아이디</option>
                    <option value="userId">번호</option>
                  </select>
                </Col>
                <Col sm={9}>
                  <input
                    className="form-control"
                    type="text"
                    id="submitKindInput"
                    name="recipientIdentifier"
                    value={
                      pointSubmitForm.submitKind === 'account'
                        ? pointSubmitForm.account
                        : pointSubmitForm.userId
                    }
                    onChange={evChangePointSubmit}
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={6}>
              <Row>
                <Col sm={3}>
                  <label className="col-sm-12 col-form-label bg-secondary rounded">
                    포인트 종류
                  </label>
                </Col>
                <Col sm={9}>
                  <PointKindSelect
                    firstOption={{ value: '', text: '포인트선택' }}
                    onChange={evChangePointSubmit}
                    id="pointKindSelect"
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="align-items-center mb-3">
            <Col sm={6}>
              <Row>
                <Col sm={3}>
                  <label className="col-sm-12 col-form-label bg-secondary rounded">
                    포인트량
                  </label>
                </Col>
                <Col sm={2}>
                  <select
                    className="form-select form-control"
                    name="transactionType"
                    value={pointSubmitForm.transactionType}
                    onChange={evChangePointSubmit}
                  >
                    <option value="add">추가</option>
                    <option value="remove">감소</option>
                  </select>
                </Col>
                <Col sm={7}>
                  <input
                    className="form-control"
                    type="text"
                    name="amount"
                    id="amountInput"
                    value={pointSubmitForm.amount}
                    onChange={evChangePointSubmit}
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={6}>
              <Row>
                <Col sm={3}>
                  <label className="col-sm-12 col-form-label bg-secondary rounded">
                    사유
                  </label>
                </Col>
                <Col sm={9}>
                  <input
                    className="form-control"
                    type="text"
                    name="pointPath"
                    value={pointSubmitForm.pointPath}
                    onChange={evChangePointSubmit}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="form-group align-items-center justify-content-center button-items">
            {pointSubmitForm.transactionType === 'add' && (
              <button
                type="button"
                onClick={evSubmitPoint}
                className="btn btn-primary waves-effect waves-light"
              >
                추가
              </button>
            )}

            {pointSubmitForm.transactionType === 'remove' && (
              <button
                type="button"
                onClick={evSubmitPoint}
                className="btn btn-danger waves-effect waves-light"
              >
                감소
              </button>
            )}

            <button
              type="button"
              onClick={() => setPointSubmitForm(defaultPointSubmitForm)}
              className="btn btn-info waves-effect waves-light"
            >
              초기화
            </button>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <GridTable
            data={{ columns: cols, rows: searchData }}
            excelData={excelData}
            fileName={fileName}
          />
        </CardBody>
      </Card>
    </Container>
  );
};
export default PointList;
