import { Card, CardBody, Col, Row } from 'reactstrap';
import React, { useEffect, useRef, useState } from 'react';
import xlsx from 'xlsx';
import { useSelector } from 'react-redux';
import DetailHeader from '../../components/Common/DetailHeader';
import BizSelectList from '../../components/Biz/BizSelectList';
import { formDL } from '../../util/excel';
import pointAPI from '../../api/point';
import userAPI from '../../api/user';
import PointKindSelect from '../../components/PointKind/PointKindSelect';

const headerInfo = {
  parentLabel: '회원 관리',
  parentLink: '/point',
  current: '회원 포인트 일괄 등록',
};

const excelCols = [['account', 'pointKindId', 'amount', 'pointPath']];

const initialState = {
  fileName: '없음',
  length: 0,
  list: undefined,
};

const defaultBiz = { bizCode: '', bizCeo: '', bizCrn: '' };

const PointBulkInsert = () => {
  const [pointForm, setPointForm] = useState({
    transactionType: 'add',
    amount: '',
    pointPath: '',
  });
  const [selectedBiz, setSelectedBiz] = useState(defaultBiz);
  const [userList, setUserList] = useState([]);
  const [fileInfo, setFileInfo] = useState(initialState);
  const [isReady, setIsReady] = useState(false);
  const { tokenValid } = useSelector(state => ({
    tokenValid: state.Token.data,
  }));
  const fileRef = useRef(null);

  const evChangePointForm = ({ target }) => {
    const { name, value } = target;
    setPointForm({
      ...pointForm,
      [name]: value,
    });
  };

  const evFileChange = () => {
    if (fileRef.current.files.length !== 0) {
      setFileInfo(f => ({ ...f, fileName: fileRef.current.files[0].name }));

      const { files } = fileRef.current;
      const f = files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const data = new Uint8Array(e.target.result);
        const workbook = xlsx.read(data, { type: 'array' });
        const wbName = workbook.SheetNames[0];
        const ws = workbook.Sheets[wbName];
        const list = xlsx.utils.sheet_to_json(ws);
        setFileInfo(info => ({
          ...info,
          length: list.length,
          list,
        }));
      };
      reader.readAsArrayBuffer(f);
      setIsReady(true);
    } else {
      setIsReady(false);
      setFileInfo(info => ({
        ...info,
        fileName: '없음',
      }));
    }
  };

  const evSubmitExcelBtn = async () => {
    const result = await pointAPI.excelInsert(fileInfo.list);
    if (result > 0) alert('성공');
  };
  const standardDL = () => {
    formDL('회원포인트일괄등록', excelCols);
  };

  const evSubmitBizBtn = async () => {
    const result = await pointAPI.bulkInsert({
      ...pointForm,
      bizId: selectedBiz.bizId,
    });
    if (result && result === userList.length) {
      return alert('success');
    }
    if (result) {
      return alert(`${userList.length - result} failed.`);
    }
    return alert('Something went wrong');
  };

  useEffect(() => {
    async function fetchUsers() {
      if (!selectedBiz.bizId) return;
      const result = await userAPI.getListByBizId(selectedBiz.bizId);
      if (result && Array.isArray(result)) {
        setUserList(result);
      } else {
        setUserList([]);
      }
    }

    if (tokenValid) fetchUsers();
  }, [selectedBiz, tokenValid]);

  return (
    <>
      <DetailHeader headerInfo={headerInfo} />
      <Card>
        <CardBody>
          <Col>
            <Row>
              <h4>특징 회사 회원들의 포인트 등록</h4>
            </Row>
            <Row>
              회사에 속해있는 회원들의 포인트를 일괄적으로 추가 / 감소 시킬 수
              있습니다. <br />
              회사명을 선택한 후 내가 선택한 회사가 맞는지 확인해주시기
              바랍니다.
            </Row>
          </Col>
          <br />
          <Row className="align-items-center mb-3">
            <Col sm={6}>
              <Row className="align-items-center">
                <Col sm={6}>
                  <label className="col-sm-12 col-form-label bg-secondary rounded">
                    회사명
                  </label>
                </Col>
                <Col sm={6}>
                  <BizSelectList
                    firstItem={{ value: '', innerText: '회사선택' }}
                    onChange={({ target }) => {
                      if (target.value) {
                        setSelectedBiz(JSON.parse(target.value));
                      } else {
                        setSelectedBiz(defaultBiz);
                      }
                    }}
                    returnBiz
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={6}>
              <Row className="align-items-center">
                <Col sm={6}>
                  <label className="col-sm-12 col-form-label bg-secondary rounded">
                    회사코드
                  </label>
                </Col>
                <Col sm={6}>
                  <label className="col-sm-12 col-form-label">
                    {selectedBiz.bizCode}
                  </label>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="align-items-center mb-3">
            <Col sm={6}>
              <Row className="align-items-center">
                <Col sm={6}>
                  <label className="col-sm-12 col-form-label bg-secondary rounded">
                    대표자명
                  </label>
                </Col>
                <Col sm={6}>
                  <label className="col-sm-12 col-form-label">
                    {selectedBiz.bizCeo}
                  </label>
                </Col>
              </Row>
            </Col>
            <Col sm={6}>
              <Row className="align-items-center">
                <Col sm={6}>
                  <label className="col-sm-12 col-form-label bg-secondary rounded">
                    사업자등록번호
                  </label>
                </Col>
                <Col sm={6}>
                  <label className="col-sm-12 col-form-label">
                    {selectedBiz.bizCrn}
                  </label>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="align-items-center mb-3">
            {selectedBiz.bizName && (
              <>
                <Col sm={9}>
                  <h4>내가 선택한 회사가 맞으신가요?</h4>
                </Col>
                <Col sm={12}>
                  <Row className="align-items-center mb-3">
                    <Col sm={9}>
                      <Row className="align-items-center">
                        <Col sm={4}>
                          <label className="col-sm-12 col-form-label bg-secondary rounded">
                            추가 / 감소할 포인트량
                          </label>
                        </Col>
                        <Col sm={8}>
                          <PointKindSelect
                            firstOption={{
                              value: '',
                              text: '포인트 종류 건택',
                            }}
                            onChange={evChangePointForm}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-3">
                    <Col sm={9}>
                      <Row className="align-items-center">
                        <Col sm={4}>
                          <label className="col-sm-12 col-form-label bg-secondary rounded">
                            추가 / 감소할 포인트량
                          </label>
                        </Col>
                        <Col sm={3}>
                          <select
                            className="form-select col-sm-12 form-control"
                            name="transactionType"
                            onChange={evChangePointForm}
                          >
                            <option value="add">추가</option>
                            <option value="remove">감소</option>
                          </select>
                        </Col>
                        <Col sm={5}>
                          <input
                            className="form-control"
                            name="amount"
                            onChange={evChangePointForm}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-3">
                    <Col sm={9}>
                      <Row className="align-items-center">
                        <Col sm={4}>
                          <label className="col-sm-12 col-form-label bg-secondary rounded">
                            사유
                          </label>
                        </Col>
                        <Col sm={6}>
                          <input
                            className="form-control"
                            name="pointPath"
                            onChange={evChangePointForm}
                          />
                        </Col>
                        <Col sm={2}>
                          {selectedBiz && (
                            <button
                              type="button"
                              className="btn btn-success waves-effect waves-light ml-3 col-form-label"
                              onClick={evSubmitBizBtn}
                            >
                              총 {userList.length}명 등록
                            </button>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </>
            )}
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Row className="align-items-center my-3">
            <span
              style={{ fontSize: '1.2rem', fontWeight: 'bold' }}
              className="ml-3"
            >
              엑셀로 회원 일괄 등록
            </span>
            <button
              type="button"
              className="btn btn-primary btn-sm waves-effect waves-light mx-2"
              onClick={standardDL}
            >
              표준양식 다운로드
            </button>
          </Row>
          <Col>
            <Row>
              <h4>특징 회사 회원들의 포인트 등록</h4>
            </Row>
            <Row>
              엑셀 파일로 포인트를 부여할 회원들을 정리하여 회원별로 다량의
              포인트를 등록할 수 있습니다.
              <br />
              표준 양식을 다운로드 받아 등록에 필요한 정보들을 입력해주시기
              바랍니다.
            </Row>
          </Col>
          <Row className="mb-2">
            <Col sm={12}>
              <Row className="align-items-center">
                <Col lg={2}>
                  <label className="col-lg-12 col-form-label bg-secondary rounded">
                    Excel 업로드
                  </label>
                </Col>
                <Col lg={9}>
                  <input
                    type="file"
                    tabIndex="-1"
                    id="excelUpload"
                    style={{ display: 'none' }}
                    ref={fileRef}
                    onChange={evFileChange}
                  />
                  <label
                    htmlFor="excelUpload"
                    className="btn btn-success btn-sm waves-effect waves-light col-form-label"
                  >
                    파일 첨부
                  </label>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col sm={12}>
              <Row className="align-items-center">
                <Col lg={2}>
                  <label className="col-lg-12 col-form-label bg-secondary rounded">
                    첨부된 파일명
                  </label>
                </Col>
                <Col lg={9}>
                  <span>{fileInfo.fileName}</span>
                  {isReady && (
                    <button
                      type="button"
                      className="btn btn-primary btn-sm waves-effect waves-light ml-3 col-form-label"
                      onClick={evSubmitExcelBtn}
                    >
                      {fileInfo.length}건 등록 완료하기
                    </button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};
export default PointBulkInsert;
