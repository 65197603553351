import axios from './axios';
import { SUPPLIER } from './urls';

export default {
  async getList(params) {
    try {
      return await axios.get(SUPPLIER.GET_LIST, { params });
    } catch (e) {
      return e;
    }
  },

  async insert(params) {
    try {
      return await axios.post(SUPPLIER.UPSERT, params);
    } catch (e) {
      return e;
    }
  },
  async update(params) {
    try {
      return await axios.put(SUPPLIER.UPSERT, params);
    } catch (e) {
      return e;
    }
  },

  async updateStatuses(params) {
    try {
      return await axios.put(SUPPLIER.STATUS_UPDATE, params);
    } catch (e) {
      return e;
    }
  },
  async delete(id) {
    try {
      return await axios.delete(SUPPLIER.GET_OR_DELETE + id);
    } catch (e) {
      return e;
    }
  },
};
