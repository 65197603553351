import { useEffect, useState } from 'react';

const useSliceList = (data, page, listSize) => {
  const [sliceList, setSliceList] = useState([]);
  const start = (page - 1) * listSize;
  const end =
    data && start + listSize >= data.length ? data.length : start + listSize;
  useEffect(() => {
    setSliceList(data?.slice(start, end));
  }, [data, start, end]);
  return {
    start,
    end,
    sliceList,
  };
};

export default useSliceList;
