import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'reactstrap';
import orderAPI from '../../api/order';
import AdminMemo from '../../components/Common/AdminMemo';
import DetailHeader from '../../components/Common/DetailHeader';
import OrderBuyerInfo from '../../components/Orders/OrderBuyerInfo';
import OrderClaimInfo from '../../components/Orders/OrderClaimInfo';
import OrderDeliverInfo from '../../components/Orders/OrderDeliverInfo';
import OrderDetailList from '../../components/Orders/OrderDetailList';
import OrderPaymentInfo from '../../components/Orders/OrderPaymentInfo';
import OrderReceiverInfo from '../../components/Orders/OrderReceiverInfo';
import { dateFormat } from '../../util/dateFormat';

const headerInfo = {
  parentLabel: '주문 관리',
  parentLink: '/order',
  current: '주문 상세',
};
const TO_LIST = '/order';

function OrderEdit({ match, history }) {
  const { orderId } = match.params;
  const [orderList, setOrderList] = useState([]);
  const [buyer, setBuyer] = useState({});
  const [receiver, setReceiver] = useState({});
  const [payInfo, setPayInfo] = useState([]);
  const [claim, setClaim] = useState({});
  const [memo, setMemo] = useState('');
  const [statusOrder, setStatusOrder] = useState('');
  const { tokenValid } = useSelector(state => ({
    tokenValid: state.Token.data,
  }));

  const listMapping = list => {
    const mappingList = list.map(data => ({
      ...data,
      mallName: '해든몰',
      priceSell: `${data.priceSell.toLocaleString()}원`,
      shipCost: data.shipCost ? `${data.shipCost.toLocaleString()}원` : '무료',
      totalAmount: `${data.totalAmount?.toLocaleString() || 0}원`,
      totalAmountItem: `${data.totalAmountItem?.toLocaleString() || 0}원`,
      totalAmountShip: `${data.totalAmountShip?.toLocaleString() || 0}원`,
      createdAt: dateFormat(data.createdAt),
    }));
    return mappingList;
  };
  const toListPage = useCallback(() => history.push(TO_LIST), [history]);

  const evChangeMemo = ({ target }) => {
    const { value } = target;
    setMemo(value);
  };

  const updateMemo = useCallback(async () => {
    const result = await orderAPI.update({ orderId, memo });
    if (result.resultCode === 'success') {
      alert('수정 완료');
    }
  }, [orderId, memo]);

  useEffect(() => {
    const fetchData = async () => {
      const orderData = await orderAPI.getList({ orderId });
      const buyerData = await orderAPI.getBuyer(orderId);
      const receiverData = await orderAPI.getReceiver(orderId);
      const payData = await orderAPI.getPayInfo(orderId);
      const claimData = await orderAPI.getClaim(orderId);
      setOrderList(() => listMapping(orderData));
      setBuyer(() => buyerData.user);
      setReceiver(() => receiverData.receiver);
      setPayInfo(() => payData.paymentList);
      setClaim(() => claimData.claim);
      setMemo(() => orderData[0].memo);
      setStatusOrder(() => orderData[0].codeLabel);
    };
    if (tokenValid) {
      fetchData();
    }
  }, [orderId, tokenValid]);

  return (
    <Container fluid>
      <DetailHeader headerInfo={headerInfo} />
      <OrderDetailList orderList={orderList} />
      <OrderBuyerInfo buyer={buyer} />
      <OrderReceiverInfo receiver={receiver} />
      <OrderDeliverInfo receiver={receiver} />
      <OrderPaymentInfo payInfo={payInfo} orderList={orderList} />
      <OrderClaimInfo claim={claim} statusOrder={statusOrder} />
      <AdminMemo memo={memo} evChangeMemo={evChangeMemo} />
      <div className="button-items d-flex justify-content-center mb-5">
        <button type="button" className="btn btn-primary" onClick={updateMemo}>
          메모 수정
        </button>
        <button type="button" className="btn btn-success" onClick={toListPage}>
          목록으로
        </button>
      </div>
    </Container>
  );
}

export default OrderEdit;
