import { Card, CardBody, Col, Row } from 'reactstrap';

function OrderDeliverInfo({ receiver }) {
  const {
    companyName,
    invoiceCode,
    recvrPostCode,
    recvrAdres1,
    recvrAdres2,
    requestText,
  } = receiver;
  return (
    <Card>
      <CardBody>
        <Row className="mb-3">
          <Col>
            <h4
              style={{
                borderBottom: '1px solid #EBEBEB',
                paddingBottom: '0.5rem',
              }}
            >
              배송 정보
            </h4>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label
                  htmlFor="infoModel"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  택배사
                </label>
              </Col>
              <Col lg={8}>
                <span className="px-2">{companyName || '-'}</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label
                  htmlFor="infoModel"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  운송장번호
                </label>
              </Col>
              <Col lg={8}>
                <span className="px-2">{invoiceCode || '-'}</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label
                  htmlFor="infoModel"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  우편번호
                </label>
              </Col>
              <Col lg={8}>
                <span className="px-2">{recvrPostCode}</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label
                  htmlFor="infoModel"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  주소
                </label>
              </Col>
              <Col lg={8}>
                <span className="px-2">{recvrAdres1}</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label
                  htmlFor="infoModel"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  상세 주소
                </label>
              </Col>
              <Col lg={8}>
                <span className="px-2">{recvrAdres2}</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label
                  htmlFor="infoModel"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  요청사항
                </label>
              </Col>
              <Col lg={8}>
                <span className="px-2">{requestText || '-'}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default OrderDeliverInfo;
