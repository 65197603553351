import { useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { infoList } from '../../util/productInfomation';

function ItemProductInfoForm({
  evSelectProductInfo,
  evChangeProductInfoForm,
  productInfoForm,
  productInfo,
  evSetProductInfoText,
}) {
  return (
    <Card>
      <CardBody>
        <Row className="mb-3">
          <Col>
            <h4
              style={{
                borderBottom: '1px solid #EBEBEB',
                paddingBottom: '0.5rem',
              }}
            >
              필수정보고시
            </h4>
          </Col>
        </Row>
        <Row className="align-items-center mb-3">
          <Col sm={12}>
            <Row className="align-items-center">
              <Col lg={2}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  상품 종류
                </label>
              </Col>
              <Col lg={2}>
                <select
                  className="form-control"
                  onChange={evSelectProductInfo}
                  value={productInfoForm?.index}
                >
                  <option value={undefined}>--선택--</option>
                  {infoList.map(p => (
                    <option key={p.id} value={p.id}>
                      {`${p.title} (${p.id})`}
                    </option>
                  ))}
                </select>
              </Col>
              <Col lg={4}>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={evSetProductInfoText}
                >
                  상세정보 별도표기
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
        {productInfo &&
          productInfo.data?.map(p => (
            <Row className="mb-3" key={p.field}>
              <Col sm={12}>
                <Row>
                  <Col lg={2}>
                    <label
                      htmlFor={p.field}
                      className="col-lg-12 col-form-label bg-secondary rounded"
                    >
                      {p.field}
                    </label>
                  </Col>
                  <Col lg={6}>
                    <input
                      id={p.field}
                      name={p.field}
                      type="text"
                      className="form-control"
                      value={productInfoForm[p.field]}
                      onChange={evChangeProductInfoForm}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          ))}
      </CardBody>
    </Card>
  );
}

export default ItemProductInfoForm;
