import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';

import rootReducer from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(
    process.env.REACT_APP_API_URL
      ? applyMiddleware(sagaMiddleware)
      : applyMiddleware(sagaMiddleware, logger),
  ),
);
sagaMiddleware.run(rootSaga);

export default store;
