import { Component, useCallback, useEffect, useState } from 'react';
import { withRouter, Link, useLocation } from 'react-router-dom';
import MetisMenu from 'metismenujs';

import SimpleBar from 'simplebar-react';
import orderAPI from '../../api/order';
import itemAPI from '../../api/item';
import { useDispatch, useSelector } from 'react-redux';
import { fetched } from '../../store/actions';

const initialOrderCount = {
  3: 0,
  4: 0,
  10: 0,
  12: 0,
  30: 0,
  31: 0,
  33: 0,
  40: 0,
  41: 0,
  43: 0,
  44: 0,
};
const initialNotAcceptCount = 0;

const SidebarContent = props => {
  const [orderCount, setOrderCount] = useState(initialOrderCount);
  const [notAcceptedCount, setNotAcceptedCount] = useState(
    initialNotAcceptCount,
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const location = useLocation();
  const { countState, tokenValid, role } = useSelector(state => ({
    countState: state.Count.countState,
    tokenValid: state.Token.data,
    role: state.Token.role,
  }));
  const dispatch = useDispatch();
  const onFetched = () => dispatch(fetched());
  // const authUser =
  //   localStorage.getItem('authUser') &&
  //   JSON.parse(localStorage.getItem('authUser'));
  const isAdmin = role === 'ROLE_ADMIN';
  // const bId = isAdmin ? undefined : authUser?.bId;

  const fetchData = useCallback(async () => {
    // const biz = { bId: 123 };
    const orderCountData = await orderAPI.getStatusOrderCount();
    const notAcceptedCountData = await itemAPI.getNotAcceptCount();
    setOrderCount(o => (orderCountData ? orderCountData.count : o));
    setNotAcceptedCount(a =>
      notAcceptedCountData ? notAcceptedCountData.count : a,
    );
    onFetched();
  }, [onFetched]);
  useEffect(() => {
    if (!isLoaded && tokenValid) {
      fetchData();
      setIsLoaded(true);
    }
    if (!countState && tokenValid) {
      fetchData();
      setIsLoaded(true);
    }
    return () => setIsLoaded(false);
  }, [location.key, countState, tokenValid]);
  return (
    <>
      {isAdmin ? (
        // master admin menu
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title">Main</li> */}

            <li>
              <Link to="/" className="waves-effect">
                <i className="mdi mdi-home mdi-24px" />
                <span>메인화면</span>
              </Link>
            </li>
            <li>
              <a href="https://recohere.net/" className="waves-effect">
                <i className="mdi mdi-table mdi-24px" />
                <span>통계 관리</span>
              </a>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-account mdi-24px" />
                <span>회원 관리</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/user">회원 조회</Link>
                </li>
                <li>
                  <Link to="/user/add">회원 등록</Link>
                </li>
                <li>
                  <Link to="/user/excel">회원 일괄 등록</Link>
                </li>
                <li>
                  <Link to="/point">회원 포인트 조회 및 추가</Link>
                </li>
                <li>
                  <Link to="/point/bulkInsert">회원 포인트 일괄 등록</Link>
                </li>
                <li>
                  <Link to="/pointKind">포인트 종류 조회</Link>
                </li>
                <li>
                  <Link to="/pointKind/add">포인트 종류 등록</Link>
                </li>
                <li>
                  <Link to="/point/setting">포인트 자동 설정</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-gift mdi-24px" />
                <span>상품 관리</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/item">상품 목록</Link>
                </li>
                <li>
                  <Link to="/item/accept">
                    승인 대기 상품
                    <span className="badge badge-pill badge-primary float-right px-2">
                      {notAcceptedCount}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/item/add">상품 등록</Link>
                </li>
                <li>
                  <Link to="/item/excel">상품 일괄 등록</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-truck mdi-24px" />
                <span>주문 관리</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/order">전체 주문 조회</Link>
                </li>
                {/* <li>
          <Link to="/#">주문 등록</Link>
        </li> */}
                <li>
                  <Link to="/order/status/3">
                    결제완료
                    <span className="badge badge-pill badge-primary float-right px-2">
                      {orderCount['3']}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/order/status/4">
                    주문접수
                    <span className="badge badge-pill badge-primary float-right px-2">
                      {orderCount['4']}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/order/status/10">
                    배송중
                    <span className="badge badge-pill badge-primary float-right px-2">
                      {orderCount['10']}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/order/status/12">
                    배송완료
                    <span className="badge badge-pill badge-primary float-right px-2">
                      {orderCount['12']}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/order/status/30">
                    반품신청
                    <span className="badge badge-pill badge-primary float-right px-2">
                      {orderCount['30']}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/order/status/31">
                    반품접수
                    <span className="badge badge-pill badge-primary float-right px-2">
                      {orderCount['31']}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/order/status/33">
                    반품완료
                    <span className="badge badge-pill badge-primary float-right px-2">
                      {orderCount['33']}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/order/status/40">
                    교환신청
                    <span className="badge badge-pill badge-primary float-right px-2">
                      {orderCount['40']}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/order/status/41">
                    교환접수
                    <span className="badge badge-pill badge-primary float-right px-2">
                      {orderCount['41']}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/order/status/43">
                    교환 배송중
                    <span className="badge badge-pill badge-primary float-right px-2">
                      {orderCount['43']}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/order/status/44">
                    교환 완료
                    <span className="badge badge-pill badge-primary float-right px-2">
                      {orderCount['44']}
                    </span>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-shape-plus mdi-24px" />
                <span>카테고리 관리</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/category">카테고리 조회</Link>
                </li>
                <li>
                  <Link to="/category/add">카테고리 등록</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-tag-multiple mdi-24px" />
                <span>태그 관리</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/tag">태그 조회</Link>
                </li>
                <li>
                  <Link to="/tag/add">태그 등록</Link>
                </li>
                <li>
                  <Link to="/tag/excel">태그 일괄 등록</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-home-modern mdi-24px" />
                <span>공급사 관리</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/biz">공급사 조회</Link>
                </li>
                <li>
                  <Link to="/biz/approve">승인 대기 공급사</Link>
                </li>
                <li>
                  <Link to="/biz/add">공급사 등록</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-content-paste mdi-24px" />
                <span>정산 조회</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/settlement">정산 조회</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-account-box mdi-24px" />
                <span>공급사 관리자 관리</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/worker">공급사 관리자 조회</Link>
                </li>
                <li>
                  <Link to="/worker/add">공급사 관리자 등록</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-settings mdi-24px" />
                <span>시스템</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/text-ban">금지어 관리</Link>
                </li>
              </ul>
            </li>

            {/* <li className="menu-title">개발자용</li>
            <li>
              <Link to="/calendar" className="waves-effect">
                <i className="ti-calendar" />
                <span>Calendar</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-email" />
                <span>Email</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/email-inbox">Inbox</Link>
                </li>
                <li>
                  <Link to="/email-read">Email Read</Link>
                </li>
                <li>
                  <Link to="/email-compose">Email Compose</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-package" />
                <span>UI Elements</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/ui-alerts">Alerts</Link>
                </li>
                <li>
                  <Link to="/ui-buttons">Buttons</Link>
                </li>
                <li>
                  <Link to="/ui-cards">Cards</Link>
                </li>
                <li>
                  <Link to="/ui-carousel">Carousel</Link>
                </li>
                <li>
                  <Link to="/ui-dropdowns">Dropdowns</Link>
                </li>
                <li>
                  <Link to="/ui-grid">Grid</Link>
                </li>
                <li>
                  <Link to="/ui-images">Images</Link>
                </li>
                <li>
                  <Link to="/ui-lightbox">Lightbox</Link>
                </li>
                <li>
                  <Link to="/ui-modals">Modals</Link>
                </li>
                <li>
                  <Link to="/ui-rangeslider">Range Slider</Link>
                </li>
                <li>
                  <Link to="/ui-session-timeout">Session Timeout</Link>
                </li>
                <li>
                  <Link to="/ui-progressbars">Progress Bars</Link>
                </li>
                <li>
                  <Link to="/ui-sweet-alert">Sweet-Alert</Link>
                </li>
                <li>
                  <Link to="/ui-tabs-accordions">Tabs &amp; Accordions</Link>
                </li>
                <li>
                  <Link to="/ui-typography">Typography</Link>
                </li>
                <li>
                  <Link to="/ui-video">Video</Link>
                </li>
                <li>
                  <Link to="/ui-general">General</Link>
                </li>
                <li>
                  <Link to="/ui-colors">Colors</Link>
                </li>
                <li>
                  <Link to="/ui-rating">Rating</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="waves-effect">
                <i className="ti-receipt" />
                <span>Forms</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/form-elements">Form Elements</Link>
                </li>
                <li>
                  <Link to="/form-validation">Form Validation</Link>
                </li>
                <li>
                  <Link to="/form-advanced">Form Advanced</Link>
                </li>
                <li>
                  <Link to="/form-editors">Form Editors</Link>
                </li>
                <li>
                  <Link to="/form-uploads">Form File Upload</Link>
                </li>
                <li>
                  <Link to="/form-xeditable">Form Xeditable</Link>
                </li>
                <li>
                  <Link to="/form-repeater">Form Repeater</Link>
                </li>
                <li>
                  <Link to="/form-wizard">Form Wizard</Link>
                </li>
                <li>
                  <Link to="/form-mask">Form Mask</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-pie-chart" />
                <span>Charts</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/charts-chartist">Chartist Chart</Link>
                </li>
                <li>
                  <Link to="/charts-chartjs">Chartjs Chart</Link>
                </li>
                <li>
                  <Link to="/apex-chart">Apex Chart</Link>
                </li>
                <li>
                  <Link to="/charts-sparkline">Sparkline Chart</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-view-grid" />
                <span>Tables</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/tables-basic">Basic Tables</Link>
                </li>
                <li>
                  <Link to="/tables-datatable">Data Table</Link>
                </li>
                <li>
                  <Link to="/tables-responsive">Responsive Table</Link>
                </li>
                <li>
                  <Link to="/tables-editable">Editable Table</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-face-smile" />
                <span>Icons</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/icons-material">Material Design</Link>
                </li>
                <li>
                  <Link to="/icons-fontawesome">Font Awesome</Link>
                </li>
                <li>
                  <Link to="/icons-ion">Ion Icons</Link>
                </li>
                <li>
                  <Link to="/icons-themify">Themify Icons</Link>
                </li>
                <li>
                  <Link to="/icons-dripicons">Dripicons</Link>
                </li>
                <li>
                  <Link to="/icons-typicons">Typicons Icons</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-support" />
                <span> Extra Pages </span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/pages-timeline">Timeline</Link>
                </li>
                <li>
                  <Link to="/pages-invoice">Invoice</Link>
                </li>
                <li>
                  <Link to="/pages-directory">Directory</Link>
                </li>
                <li>
                  <Link to="/pages-blank">Blank Page</Link>
                </li>
                <li>
                  <Link to="/pages-404">Error 404</Link>
                </li>
                <li>
                  <Link to="/pages-500">Error 500</Link>
                </li>
                <li>
                  <Link to="/pages-pricing">Pricing</Link>
                </li>
                <li>
                  <Link to="/pages-gallery">Gallery</Link>
                </li>
                <li>
                  <Link to="/pages-maintenance">Maintenance</Link>
                </li>
                <li>
                  <Link to="/pages-comingsoon">Coming Soon</Link>
                </li>
                <li>
                  <Link to="/pages-faq">FAQs</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-bookmark-alt" />
                <span> Email Templates </span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/email-template-basic">Basic Action Email</Link>
                </li>
                <li>
                  <Link to="/email-template-Alert">Alert Email</Link>
                </li>
                <li>
                  <Link to="/email-template-Billing">Billing Email</Link>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>
      ) : (
        // supplier menu
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title">Main</li> */}

            <li>
              <Link to="/" className="waves-effect">
                <i className="mdi mdi-home mdi-24px" />
                <span>메인화면</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-gift mdi-24px" />
                <span>상품 관리</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/item">상품 목록</Link>
                </li>
                <li>
                  <Link to="/item/accept">
                    승인 대기 상품
                    <span className="badge badge-pill badge-primary float-right px-2">
                      {notAcceptedCount}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/item/add">상품 등록</Link>
                </li>
                <li>
                  <Link to="/item/excel">상품 일괄 등록</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-truck mdi-24px" />
                <span>주문 관리</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/order">전체 주문 조회</Link>
                </li>
                <li>
                  <Link to="/order/status/3">
                    결제완료
                    <span className="badge badge-pill badge-primary float-right px-2">
                      {orderCount['3']}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/order/status/4">
                    주문접수
                    <span className="badge badge-pill badge-primary float-right px-2">
                      {orderCount['4']}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/order/status/10">
                    배송중
                    <span className="badge badge-pill badge-primary float-right px-2">
                      {orderCount['10']}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/order/status/12">
                    배송완료
                    <span className="badge badge-pill badge-primary float-right px-2">
                      {orderCount['12']}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/order/status/30">
                    반품신청
                    <span className="badge badge-pill badge-primary float-right px-2">
                      {orderCount['30']}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/order/status/31">
                    반품접수
                    <span className="badge badge-pill badge-primary float-right px-2">
                      {orderCount['31']}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/order/status/33">
                    반품완료
                    <span className="badge badge-pill badge-primary float-right px-2">
                      {orderCount['33']}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/order/status/40">
                    교환신청
                    <span className="badge badge-pill badge-primary float-right px-2">
                      {orderCount['40']}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/order/status/41">
                    교환접수
                    <span className="badge badge-pill badge-primary float-right px-2">
                      {orderCount['41']}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/order/status/43">
                    교환 배송중
                    <span className="badge badge-pill badge-primary float-right px-2">
                      {orderCount['43']}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/order/status/44">
                    교환 완료
                    <span className="badge badge-pill badge-primary float-right px-2">
                      {orderCount['44']}
                    </span>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-content-paste mdi-24px" />
                <span>정산 조회</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/settlement">정산 조회</Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
    // if (prevProps.location.key !== this.props.location.key) {
    //   this.initMenu();
    // }
  }

  initMenu() {
    if (this.props.type !== 'condensed' || this.props.isMobile) {
      new MetisMenu('#side-menu');

      let matchingMenuItem = null;
      const ul = document.getElementById('side-menu');
      const items = ul.getElementsByTagName('a');
      for (let i = 0; i < items.length; ++i) {
        if (this.props.location.pathname === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        this.activateParentDropdown(matchingMenuItem);
      }
    }
  }

  activateParentDropdown = item => {
    // item.classList.add('mm-active');
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add('mm-active'); // li
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show');

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); // a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add('mm-active');
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <>
        {this.props.type !== 'condensed' ? (
          <SimpleBar style={{ maxHeight: '100%' }}>
            <SidebarContent />
          </SimpleBar>
        ) : (
          <SidebarContent />
        )}
      </>
    );
  }
}

export default withRouter(Sidebar);
