import { Card, CardBody, Col, Row } from 'reactstrap';

function OrderReceiverInfo({ receiver }) {
  // if ()
  const { recvrName, recvrPhone } = receiver;
  return (
    <Card>
      <CardBody>
        <Row className="mb-3">
          <Col>
            <h4
              style={{
                borderBottom: '1px solid #EBEBEB',
                paddingBottom: '0.5rem',
              }}
            >
              받으시는 분
            </h4>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label
                  htmlFor="infoModel"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  받는이 성함
                </label>
              </Col>
              <Col lg={8}>
                <span className="px-2">{recvrName}</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={4}>
                <label
                  htmlFor="infoModel"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  받는이 전화번호
                </label>
              </Col>
              <Col lg={8}>
                <span className="px-2">{recvrPhone}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default OrderReceiverInfo;
