import { Card, CardBody, Col, Row } from 'reactstrap';
import { Editor } from 'react-draft-wysiwyg';
import { useCallback, useEffect, useState } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import ItemAPI from '../../api/item';

const DESCRIPTION = '1';

function ItemDetailForm({ evChangeDetail, infoDetail, evUploadedImages }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [uploadedImages, setUploadedImages] = useState([]);
  const onEditorStateChange = useCallback(
    state => {
      if (state) {
        setEditorState(state);
        evChangeDetail(draftToHtml(convertToRaw(state.getCurrentContent())));
      }
    },
    [evChangeDetail],
  );

  const uploadImageCallback = useCallback(
    async file => {
      // 바이너리화
      const imgInfo = {
        imgName: file.name,
        oder: 0,
        usedFlag: '0',
        imgType: DESCRIPTION,
        imgExt: '0',
      };
      const resultInfo = await ItemAPI.insertImage(imgInfo);
      const newFileName = resultInfo.img.imgName;
      const resultImg = await ItemAPI.uploadDescriptionImage(file, newFileName);
      const imageObject = {
        // file,
        id: resultInfo.img.imgId,
        src: resultImg.location,
        key: resultImg.key,
      };
      evUploadedImages(imageObject);
      return new Promise(reslove => {
        reslove({ data: { link: imageObject.src } });
      });
    },
    [evUploadedImages],
  );

  useEffect(() => {
    if (infoDetail) {
      const blocksFormHtml = htmlToDraft(infoDetail);
      const { contentBlocks, entityMap } = blocksFormHtml;
      const contantState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap,
      );
      const editor = EditorState.createWithContent(contantState);
      setEditorState(editor);
    }
  }, [infoDetail]);

  return (
    <Card>
      <CardBody>
        <Row className="mb-3">
          <Col>
            <h4
              style={{
                borderBottom: '1px solid #EBEBEB',
                paddingBottom: '0.5rem',
              }}
            >
              상품 상세보기
            </h4>
          </Col>
        </Row>
        <Editor
          // 에디터와 툴바 모두에 적용되는 클래스
          wrapperClassName="wrapper-class"
          // 에디터 주변에 적용된 클래스
          editorClassName="editor"
          // 툴바 주위에 적용된 클래스
          toolbarClassName="toolbar-class"
          // 툴바 설정
          toolbar={{
            // inDropdown: 해당 항목과 관련된 항목을 드롭다운으로 나타낼것인지
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: false },
            image: {
              uploadCallback: uploadImageCallback,
              previewImage: true,
              alt: { present: true, mandatory: false },
              inputAccept: 'image/jpeg, image/jpg, image/png',
              // uploadEnabled: false,
            },
          }}
          placeholder="내용을 작성해주세요."
          // 한국어 설정
          localization={{
            locale: 'ko',
          }}
          // 초기값 설정
          editorState={editorState}
          // 에디터의 값이 변경될 때마다 onEditorStateChange 호출
          onEditorStateChange={onEditorStateChange}
        />
      </CardBody>
    </Card>
  );
}

export default ItemDetailForm;
