import axios from './axios';
import {POINT, USER} from './urls';

export default {
  async getList(params) {
    try {
      return await axios.get(POINT.GET_LIST, {params});
    } catch (e) {
      return null;
    }
  },

  async getOne(id) {
    try {
      return await axios.get(POINT.GET_OR_DELETE + id);
    } catch (e) {
      return null;
    }
  },

  async insert(params) {
    try {
      return await axios.post(POINT.UPSERT, params);
    } catch (e) {
      return null;
    }
  },

  async update(params) {
    try {
      return await axios.put(POINT.UPSERT, params);
    } catch (e) {
      return null;
    }
  },

  async delete(id) {
    try {
      return await axios.delete(POINT.GET_OR_DELETE + id);
    } catch (e) {
      return null;
    }
  },

  async excelInsert(params) {
    try {
      return await axios.post(POINT.EXCEL, { params });
    } catch (e) {
      return null;
    }
  },

  async bulkInsert(params) {
    try {
      return await axios.post(POINT.BULK_INSERT, { params });
    } catch (e) {
      return null;
    }
  }
};

