import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody } from 'reactstrap';

import brandAPI from '../../api/brand';
import ListHeader from '../../components/Common/ListHeader';
import GridTable from '../../components/Common/GridTable';

const headerInfo = {
  parentLabel: '브랜 관리',
  parentLink: '/brand',
  current: '브랜드 목록',
  btnLabel: '브랜드 등록',
  btnIcon: 'mdi mdi-account-edit mr-2',
};

const defaultSearchForm = {
  brandCode: '',
  brandName: '',
};

const cols = [
  {
    label: 'Brand Name',
    field: 'brandName',
  },
  {
    label: 'Brand Code',
    field: 'brandCode',
  },
  {
    label: '편집',
    field: 'brandEdit',
  },
];

const BrandList = ({ history }) => {
  const [searchForm, setSearchForm] = useState(defaultSearchForm);

  const evChangeSearch = ({ target }) => {
    const { name, value } = target;

    setSearchForm({
      ...searchForm,
      [name]: value,
    });
  };

  const [brandRows, setBrandRows] = useState([]);

  const evClickInit = () => {
    setSearchForm({
      ...defaultSearchForm,
    });
  };

  const evClickAdd = () => history.push('/brand/add');

  const evClickSearch = useCallback(async () => {
    const result = await brandAPI.getList(
      searchForm.brandName,
      searchForm.brandCode,
    );
    if (Array.isArray(result)) {
      setBrandRows(
        result.map(brand => ({
          ...brand,
          brandEdit: <Link to={`brand/${brand.brandId}`}>편집</Link>,
        })),
      );
    }
  }, [searchForm.brandName, searchForm.brandCode]);

  useEffect(() => {
    evClickSearch();
  }, [evClickSearch]);

  return (
    <>
      <div className="container-fluid">
        <ListHeader headerInfo={headerInfo} onClick={evClickAdd} />

        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row className="form-group">
                  <label
                    htmlFor="brandName"
                    className="col-sm-2 col-form-label"
                  >
                    Brand Name
                  </label>
                  <Col sm={4}>
                    <input
                      id="brandName"
                      name="brandName"
                      className="form-control"
                      type="text"
                      onChange={evChangeSearch}
                      value={searchForm.brandName}
                    />
                  </Col>

                  <label
                    htmlFor="brandCode"
                    className="col-sm-2 col-form-label"
                  >
                    Brand Code
                  </label>
                  <Col sm={4}>
                    <input
                      id="brandCode"
                      name="brandName"
                      className="form-control"
                      type="text"
                      onChange={evChangeSearch}
                      value={searchForm.brandCode}
                    />
                  </Col>
                </Row>

                <div className="form-group line align-items-center justify-content-center">
                  <button
                    onClick={evClickSearch}
                    className="btn btn-primary waves-effect waves-light"
                  >
                    검색
                  </button>
                  <button
                    onClick={evClickInit}
                    className="btn btn-secondary waves-effect waves-light"
                  >
                    초기화
                  </button>
                </div>
              </CardBody>
            </Card>
            <GridTable data={{ columns: cols, rows: brandRows }} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BrandList;
