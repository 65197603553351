import axios from './axios';
import { TEXT_BAN } from './urls';

// export async function getTextBanList(params) {
//   try {
//     return await axios.get('/text-ban', params);
//   } catch (e) {
//     return {};
//   }
// }

export default {
  async getList(params) {
    try {
      return await axios.get(TEXT_BAN.GET_LIST, { params });
    } catch (e) {
      return e;
    }
  },
  async getOne(id) {
    try {
      return await axios.get(TEXT_BAN.GET_OR_DELETE + id);
    } catch (e) {
      return e;
    }
  },
  async insert(params) {
    try {
      return await axios.post(TEXT_BAN.UPSERT, params);
    } catch (e) {
      return e;
    }
  },
  async update(params) {
    try {
      return await axios.put(TEXT_BAN.UPSERT, params);
    } catch (e) {
      return e;
    }
  },
  async delete(id) {
    try {
      return await axios.delete(TEXT_BAN.GET_OR_DELETE + id);
    } catch (e) {
      return e;
    }
  },
};
