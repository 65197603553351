import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// AUTH related methods
import { getFirebaseBackend } from '../helpers/authUtils';

const AppRoute = ({
  component: Component,
  layout: Layout,
  title,
  isAuthProtected,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        // const fireBaseBackend = getFirebaseBackend();
        // const authUser = localStorage.getItem('authUser');
        // const authUser = localStorage.getItem('authUser');
        // if (
        //   isAuthProtected &&
        //   // !authUser
        //   !authUser
        //   // && !fireBaseBackend.getAuthenticatedUser()
        // ) {
        //   return (
        //     <Redirect
        //       to={{ pathname: '/login', state: { from: props.location } }}
        //     />
        //   );
        // }
        return (
          <Layout title={title}>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

export default AppRoute;
