import { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import DetailHeader from '../../components/Common/DetailHeader';
import workerAPI from '../../api/worker';
import BizSelectList from '../../components/Biz/BizSelectList';
import supplierApi from '../../api/supplier';

const headerInfo = {
  parentLabel: '공급사 관리자 관리',
  parentLink: '/worker',
  current: '공급사 관리자 조회',
};

const defaultWorkerInfo = {
  bizName: '',
  account: '',
  password: '',
  workerGrade: '',
  memo: '',
};

const WorkerEdit = ({ history, match }) => {
  const { bizId } = match.params;
  const [worker, setWorker] = useState(defaultWorkerInfo);
  const { tokenValid } = useSelector(state => ({
    tokenValid: state.Token.data,
  }));

  const evChangeWorkerInfo = ({ target }) => {
    const { name, value } = target;
    setWorker({
      ...worker,
      [name]: value,
    });
  };

  // todo - Korean alert message
  const evSaveWorkerInfo = async () => {
    let success = false;
    if (worker.workerId) {
      const result = await workerAPI.update(worker);
      success = result === 1;
    } else {
      const workerResult = await workerAPI.insert(worker);
      success = workerResult > 0;
      if (worker.bizId) {
        const supplierResult = await supplierApi.update({
          ...worker,
          workerId: workerResult,
        });
        success = supplierResult === 1;
      }
    }
    if (success) {
      alert('삭제 성공');
      return history.push('/worker');
    }
    return alert('Something went wrong');
  };

  useEffect(() => {
    async function fetchData() {
      if (!bizId) return;
      headerInfo.current = '공급사 관리자 편집';
      const result = await workerAPI.getOne(bizId);
      if (result) {
        setWorker(result);
      }
    }
    if (tokenValid) fetchData();
  }, [bizId, tokenValid]);

  return (
    <Container fluid>
      <DetailHeader headerInfo={headerInfo} />
      <Card>
        <CardBody>
          <Row>
            <Col>
              <h4>기본 정보</h4>
            </Col>
          </Row>
          <Row className="form-group align-items-center mb-3">
            <Col sm={6}>
              <Row className="form-group">
                <Col sm={6}>
                  <label className="col-sm-12 col-form-label bg-secondary rounded">
                    공급사명
                  </label>
                </Col>
                <Col sm={6}>
                  <BizSelectList
                    onChange={evChangeWorkerInfo}
                    firstItem={{ value: '', innerText: '-- 선택 --' }}
                    selected={worker.bizId}
                    excludeIfHasWorker={!bizId}
                  />
                </Col>
              </Row>
            </Col>

            <Col sm={12}>
              <Row className="form-group">
                <Col sm={3}>
                  <label className="col-sm-12 col-form-label bg-secondary rounded">
                    아이디
                  </label>
                </Col>
                <Col sm={9}>
                  <input
                    className="form-control"
                    type="text"
                    name="account"
                    value={worker.account}
                    onChange={evChangeWorkerInfo}
                  />
                </Col>
              </Row>
            </Col>

            <Col sm={12}>
              <Row className="form-group">
                <Col sm={3}>
                  <label className="col-sm-12 col-form-label bg-secondary rounded">
                    비밀번호
                  </label>
                </Col>
                <Col sm={9}>
                  <input
                    className="form-control"
                    name="password"
                    type="password"
                    value={worker.password}
                    onChange={evChangeWorkerInfo}
                  />
                </Col>
              </Row>
            </Col>

            <Col sm={12}>
              <Row className="form-group">
                <Col sm={3}>
                  <label className="col-sm-12 col-form-label bg-secondary rounded">
                    권한긍급
                  </label>
                </Col>
                <Col sm={9}>
                  <input
                    className="form-control"
                    name="workerGrade"
                    type="number"
                    value={worker.workerGrade}
                    onChange={evChangeWorkerInfo}
                  />
                </Col>
              </Row>
            </Col>

            <Col sm={12}>
              <Row className="form-group">
                <Col sm={3}>
                  <label className="col-sm-12 col-form-label bg-secondary rounded">
                    메모
                  </label>
                </Col>
                <Col sm={9}>
                  <input
                    className="form-control"
                    name="memo"
                    value={worker.memo}
                    onChange={evChangeWorkerInfo}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="form-group align-items-center justify-content-center button-items">
            <button
              className="btn btn-primary waves-effect waves-light"
              onClick={evSaveWorkerInfo}
            >
              {worker.workerId ? '적용' : '추가'}
            </button>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};
export default WorkerEdit;
