import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import SettingMenu from '../Shared/SettingMenu';

class IconTypicons extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Typicons</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/#">Veltrix</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/#">Icons</Link>
                  </li>
                  <li className="breadcrumb-item active">Typicons</li>
                </ol>
              </div>
            </Col>

            <Col sm="6">
              <div className="float-right d-none d-md-block">
                <SettingMenu />
              </div>
            </Col>
          </Row>

          <Row>
            <div className="col-12">
              <Card>
                <CardBody>
                  <h4 className="card-title">Examples</h4>
                  <p className="card-title-desc">
                    Use <code>&lt;i class="pe-7s-album"&gt;&lt;/i&gt;</code>.
                  </p>

                  <Row className="icon-demo-content">
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-chart-pie-outline" /> typcn
                      typcn-chart-pie-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-chart-pie" /> typcn
                      typcn-chart-pie
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-chevron-left-outline" /> typcn
                      typcn-chevron-left-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-chevron-left" /> typcn
                      typcn-chevron-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-chevron-right-outline" /> typcn
                      typcn-chevron-right-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-chevron-right" /> typcn
                      typcn-chevron-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-clipboard" /> typcn
                      typcn-clipboard
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-cloud-storage" /> typcn
                      typcn-cloud-storage
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-cloud-storage-outline" /> typcn
                      typcn-cloud-storage-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-code-outline" /> typcn
                      typcn-code-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-code" /> typcn typcn-code
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-coffee" /> typcn typcn-coffee
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-cog-outline" /> typcn
                      typcn-cog-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-cog" /> typcn typcn-cog
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-compass" /> typcn typcn-compass
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-contacts" /> typcn
                      typcn-contacts
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-credit-card" /> typcn
                      typcn-credit-card
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-css3" /> typcn typcn-css3
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-database" /> typcn
                      typcn-database
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-delete-outline" /> typcn
                      typcn-delete-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-delete" /> typcn typcn-delete
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-device-desktop" /> typcn
                      typcn-device-desktop
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-device-laptop" /> typcn
                      typcn-device-laptop
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-device-phone" /> typcn
                      typcn-device-phone
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-device-tablet" /> typcn
                      typcn-device-tablet
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-directions" /> typcn
                      typcn-directions
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-divide-outline" /> typcn
                      typcn-divide-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-divide" /> typcn typcn-divide
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-document-add" /> typcn
                      typcn-document-add
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-document-delete" /> typcn
                      typcn-document-delete
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-document-text" /> typcn
                      typcn-document-text
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-document" /> typcn
                      typcn-document
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-download-outline" /> typcn
                      typcn-download-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-download" /> typcn
                      typcn-download
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-dropbox" /> typcn typcn-dropbox
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-edit" /> typcn typcn-edit
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-eject-outline" /> typcn
                      typcn-eject-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-eject" /> typcn typcn-eject
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-equals-outline" /> typcn
                      typcn-equals-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-equals" /> typcn typcn-equals
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-export-outline" /> typcn
                      typcn-export-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-export" /> typcn typcn-export
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-eye-outline" /> typcn
                      typcn-eye-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-eye" /> typcn typcn-eye
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-feather" /> typcn typcn-feather
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-film" /> typcn typcn-film
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-filter" /> typcn typcn-filter
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-flag-outline" /> typcn
                      typcn-flag-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-flag" /> typcn typcn-flag
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-flash-outline" /> typcn
                      typcn-flash-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-flash" /> typcn typcn-flash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-flow-children" /> typcn
                      typcn-flow-children
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-flow-merge" /> typcn
                      typcn-flow-merge
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-flow-parallel" /> typcn
                      typcn-flow-parallel
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-flow-switch" /> typcn
                      typcn-flow-switch
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-folder-add" /> typcn
                      typcn-folder-add
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-folder-delete" /> typcn
                      typcn-folder-delete
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-folder-open" /> typcn
                      typcn-folder-open
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-folder" /> typcn typcn-folder
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-gift" /> typcn typcn-gift
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-globe-outline" /> typcn
                      typcn-globe-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-globe" /> typcn typcn-globe
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-group-outline" /> typcn
                      typcn-group-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-group" /> typcn typcn-group
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-headphones" /> typcn
                      typcn-headphones
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-heart-full-outline" /> typcn
                      typcn-heart-full-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-heart-half-outline" /> typcn
                      typcn-heart-half-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-heart-outline" /> typcn
                      typcn-heart-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-heart" /> typcn typcn-heart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-home-outline" /> typcn
                      typcn-home-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-home" /> typcn typcn-home
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-html5" /> typcn typcn-html5
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-image-outline" /> typcn
                      typcn-image-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-image" /> typcn typcn-image
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-infinity-outline" /> typcn
                      typcn-infinity-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-infinity" /> typcn
                      typcn-infinity
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-info-large-outline" /> typcn
                      typcn-info-large-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-info-large" /> typcn
                      typcn-info-large
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-info-outline" /> typcn
                      typcn-info-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-info" /> typcn typcn-info
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-input-checked-outline" /> typcn
                      typcn-input-checked-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-input-checked" /> typcn
                      typcn-input-checked
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-key-outline" /> typcn
                      typcn-key-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-key" /> typcn typcn-key
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-keyboard" /> typcn
                      typcn-keyboard
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-leaf" /> typcn typcn-leaf
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-lightbulb" /> typcn
                      typcn-lightbulb
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-link-outline" /> typcn
                      typcn-link-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-link" /> typcn typcn-link
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-location-arrow-outline" /> typcn
                      typcn-location-arrow-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-location-arrow" /> typcn
                      typcn-location-arrow
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-location-outline" /> typcn
                      typcn-location-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-location" /> typcn
                      typcn-location
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-lock-closed-outline" /> typcn
                      typcn-lock-closed-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-lock-closed" /> typcn
                      typcn-lock-closed
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-lock-open-outline" /> typcn
                      typcn-lock-open-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-lock-open" /> typcn
                      typcn-lock-open
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-mail" /> typcn typcn-mail
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-map" /> typcn typcn-map
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-media-eject-outline" /> typcn
                      typcn-media-eject-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-media-eject" /> typcn
                      typcn-media-eject
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-media-fast-forward-outline" />{' '}
                      typcn typcn-media-fast-forward-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-media-fast-forward" /> typcn
                      typcn-media-fast-forward
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-media-pause-outline" /> typcn
                      typcn-media-pause-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-media-pause" /> typcn
                      typcn-media-pause
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-media-play-outline" /> typcn
                      typcn-media-play-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-media-play-reverse-outline" />{' '}
                      typcn typcn-media-play-reverse-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-media-play-reverse" /> typcn
                      typcn-media-play-reverse
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-media-play" /> typcn
                      typcn-media-play
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-media-record-outline" /> typcn
                      typcn-media-record-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-media-record" /> typcn
                      typcn-media-record
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-media-rewind-outline" /> typcn
                      typcn-media-rewind-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-media-rewind" /> typcn
                      typcn-media-rewind
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-media-stop-outline" /> typcn
                      typcn-media-stop-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-media-stop" /> typcn
                      typcn-media-stop
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-message-typing" /> typcn
                      typcn-message-typing
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-message" /> typcn typcn-message
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-messages" /> typcn
                      typcn-messages
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-microphone-outline" /> typcn
                      typcn-microphone-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-microphone" /> typcn
                      typcn-microphone
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-minus-outline" /> typcn
                      typcn-minus-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-minus" /> typcn typcn-minus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-mortar-board" /> typcn
                      typcn-mortar-board
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-news" /> typcn typcn-news
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-notes-outline" /> typcn
                      typcn-notes-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-notes" /> typcn typcn-notes
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-pen" /> typcn typcn-pen
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-pencil" /> typcn typcn-pencil
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-phone-outline" /> typcn
                      typcn-phone-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-phone" /> typcn typcn-phone
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-pi-outline" /> typcn
                      typcn-pi-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-pi" /> typcn typcn-pi
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-pin-outline" /> typcn
                      typcn-pin-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-pin" /> typcn typcn-pin
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-pipette" /> typcn typcn-pipette
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-plane-outline" /> typcn
                      typcn-plane-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-plane" /> typcn typcn-plane
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-plug" /> typcn typcn-plug
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-plus-outline" /> typcn
                      typcn-plus-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-plus" /> typcn typcn-plus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-point-of-interest-outline" />{' '}
                      typcn typcn-point-of-interest-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-point-of-interest" /> typcn
                      typcn-point-of-interest
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-power-outline" /> typcn
                      typcn-power-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-power" /> typcn typcn-power
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-printer" /> typcn typcn-printer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-puzzle-outline" /> typcn
                      typcn-puzzle-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-puzzle" /> typcn typcn-puzzle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-radar-outline" /> typcn
                      typcn-radar-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-radar" /> typcn typcn-radar
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-refresh-outline" /> typcn
                      typcn-refresh-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-refresh" /> typcn typcn-refresh
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-rss-outline" /> typcn
                      typcn-rss-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-rss" /> typcn typcn-rss
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-scissors-outline" /> typcn
                      typcn-scissors-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-scissors" /> typcn
                      typcn-scissors
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-shopping-bag" /> typcn
                      typcn-shopping-bag
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-shopping-cart" /> typcn
                      typcn-shopping-cart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-social-at-circular" /> typcn
                      typcn-social-at-circular
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-social-dribbble-circular" />{' '}
                      typcn typcn-social-dribbble-circular
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-social-dribbble" /> typcn
                      typcn-social-dribbble
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-social-facebook-circular" />{' '}
                      typcn typcn-social-facebook-circular
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-social-facebook" /> typcn
                      typcn-social-facebook
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-social-flickr-circular" /> typcn
                      typcn-social-flickr-circular
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-social-flickr" /> typcn
                      typcn-social-flickr
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-social-github-circular" /> typcn
                      typcn-social-github-circular
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-social-github" /> typcn
                      typcn-social-github
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-social-google-plus-circular" />{' '}
                      typcn typcn-social-google-plus-circular
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-social-google-plus" /> typcn
                      typcn-social-google-plus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-social-instagram-circular" />{' '}
                      typcn typcn-social-instagram-circular
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-social-instagram" /> typcn
                      typcn-social-instagram
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-social-last-fm-circular" />{' '}
                      typcn typcn-social-last-fm-circular
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-social-last-fm" /> typcn
                      typcn-social-last-fm
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-social-linkedin-circular" />{' '}
                      typcn typcn-social-linkedin-circular
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-social-linkedin" /> typcn
                      typcn-social-linkedin
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-social-pinterest-circular" />{' '}
                      typcn typcn-social-pinterest-circular
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-social-pinterest" /> typcn
                      typcn-social-pinterest
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-social-skype-outline" /> typcn
                      typcn-social-skype-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-social-skype" /> typcn
                      typcn-social-skype
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-social-tumbler-circular" />{' '}
                      typcn typcn-social-tumbler-circular
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-social-tumbler" /> typcn
                      typcn-social-tumbler
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-social-twitter-circular" />{' '}
                      typcn typcn-social-twitter-circular
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-social-twitter" /> typcn
                      typcn-social-twitter
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-social-vimeo-circular" /> typcn
                      typcn-social-vimeo-circular
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-social-vimeo" /> typcn
                      typcn-social-vimeo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-social-youtube-circular" />{' '}
                      typcn typcn-social-youtube-circular
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-social-youtube" /> typcn
                      typcn-social-youtube
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-sort-alphabetically-outline" />{' '}
                      typcn typcn-sort-alphabetically-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-sort-alphabetically" /> typcn
                      typcn-sort-alphabetically
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-sort-numerically-outline" />{' '}
                      typcn typcn-sort-numerically-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-sort-numerically" /> typcn
                      typcn-sort-numerically
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-spanner-outline" /> typcn
                      typcn-spanner-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-spanner" /> typcn typcn-spanner
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-spiral" /> typcn typcn-spiral
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-star-full-outline" /> typcn
                      typcn-star-full-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-star-half-outline" /> typcn
                      typcn-star-half-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-star-half" /> typcn
                      typcn-star-half
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-star-outline" /> typcn
                      typcn-star-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-star" /> typcn typcn-star
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-starburst-outline" /> typcn
                      typcn-starburst-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-starburst" /> typcn
                      typcn-starburst
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-stopwatch" /> typcn
                      typcn-stopwatch
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-support" /> typcn typcn-support
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-tabs-outline" /> typcn
                      typcn-tabs-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-tag" /> typcn typcn-tag
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-tags" /> typcn typcn-tags
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-th-large-outline" /> typcn
                      typcn-th-large-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-th-large" /> typcn
                      typcn-th-large
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-th-list-outline" /> typcn
                      typcn-th-list-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-th-list" /> typcn typcn-th-list
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-th-menu-outline" /> typcn
                      typcn-th-menu-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-th-menu" /> typcn typcn-th-menu
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-th-small-outline" /> typcn
                      typcn-th-small-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-th-small" /> typcn
                      typcn-th-small
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-thermometer" /> typcn
                      typcn-thermometer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-thumbs-down" /> typcn
                      typcn-thumbs-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-thumbs-ok" /> typcn
                      typcn-thumbs-ok
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-thumbs-up" /> typcn
                      typcn-thumbs-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-tick-outline" /> typcn
                      typcn-tick-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-tick" /> typcn typcn-tick
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-ticket" /> typcn typcn-ticket
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-time" /> typcn typcn-time
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-times-outline" /> typcn
                      typcn-times-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-times" /> typcn typcn-times
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-trash" /> typcn typcn-trash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-tree" /> typcn typcn-tree
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-upload-outline" /> typcn
                      typcn-upload-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-upload" /> typcn typcn-upload
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-user-add-outline" /> typcn
                      typcn-user-add-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-user-add" /> typcn
                      typcn-user-add
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-user-delete-outline" /> typcn
                      typcn-user-delete-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-user-delete" /> typcn
                      typcn-user-delete
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-user-outline" /> typcn
                      typcn-user-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-user" /> typcn typcn-user
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-vendor-android" /> typcn
                      typcn-vendor-android
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-vendor-apple" /> typcn
                      typcn-vendor-apple
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-vendor-microsoft" /> typcn
                      typcn-vendor-microsoft
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-video-outline" /> typcn
                      typcn-video-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-video" /> typcn typcn-video
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-volume-down" /> typcn
                      typcn-volume-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-volume-mute" /> typcn
                      typcn-volume-mute
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-volume-up" /> typcn
                      typcn-volume-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-volume" /> typcn typcn-volume
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-warning-outline" /> typcn
                      typcn-warning-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-warning" /> typcn typcn-warning
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-watch" /> typcn typcn-watch
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-waves-outline" /> typcn
                      typcn-waves-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-waves" /> typcn typcn-waves
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-weather-cloudy" /> typcn
                      typcn-weather-cloudy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-weather-downpour" /> typcn
                      typcn-weather-downpour
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-weather-night" /> typcn
                      typcn-weather-night
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-weather-partly-sunny" /> typcn
                      typcn-weather-partly-sunny
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-weather-shower" /> typcn
                      typcn-weather-shower
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-weather-snow" /> typcn
                      typcn-weather-snow
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-weather-stormy" /> typcn
                      typcn-weather-stormy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-weather-sunny" /> typcn
                      typcn-weather-sunny
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-weather-windy-cloudy" /> typcn
                      typcn-weather-windy-cloudy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-weather-windy" /> typcn
                      typcn-weather-windy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-wi-fi-outline" /> typcn
                      typcn-wi-fi-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-wi-fi" /> typcn typcn-wi-fi
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-wine" /> typcn typcn-wine
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-world-outline" /> typcn
                      typcn-world-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-world" /> typcn typcn-world
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-zoom-in-outline" /> typcn
                      typcn-zoom-in-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-zoom-in" /> typcn typcn-zoom-in
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-zoom-out-outline" /> typcn
                      typcn-zoom-out-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-zoom-out" /> typcn
                      typcn-zoom-out
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-zoom-outline" /> typcn
                      typcn-zoom-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="typcn typcn-zoom" /> typcn typcn-zoom
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Row>
        </div>
      </>
    );
  }
}

export default IconTypicons;
