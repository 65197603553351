import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DetailHeader from '../../components/Common/DetailHeader';
import GridTable from '../../components/Common/GridTable';
import workerAPI from '../../api/worker';
import supplierAPI from '../../api/supplier';
import { dateFormat, dateToString } from '../../util/dateFormat';
import BizSelectList from '../../components/Biz/BizSelectList';

const headerInfo = {
  parentLabel: '공급사 관리자 관리',
  parentLink: '/worker',
  current: '공급사 관리자 조회',
};

const defaultSearchForm = {
  bizId: '',
  workerId: '',
};

const cols = [
  {
    label: '번호',
    field: 'workerId',
  },
  {
    label: '공급사',
    field: 'bizName',
  },
  {
    label: '아이디',
    field: 'account',
  },
  {
    label: '관한 등급',
    field: 'workerGrade',
  },
  {
    label: '메모',
    field: 'memo',
  },
  {
    label: '마지막 로그인',
    field: 'loginAt',
  },
  {
    label: '등록일',
    field: 'createdAt',
  },
];

const WorkerList = ({ history }) => {
  const [searchForm, setSearchForm] = useState(defaultSearchForm);
  const [apiData, setApiData] = useState([]);
  const { tokenValid } = useSelector(state => ({
    tokenValid: state.Token.data,
  }));

  const evSearch = useCallback(async () => {
    const result = await workerAPI.getList(searchForm);
    if (result && Array.isArray(result)) {
      setApiData(() =>
        result.map(worker => ({
          ...worker,
          createdAt: dateFormat(worker.createdAt),
          loginAt: dateToString(worker.loginAt),
          bizName: <Link to={`worker/${worker.bizId}`}>{worker.bizName}</Link>,
          clickEvent: () => history.push(`worker/${worker.bizId}`),
        })),
      );
    } else {
      setApiData([]);
    }
  }, [searchForm, history]);

  const evChangeSearch = ({ target }) => {
    const { name, value } = target;
    setSearchForm({
      [name]: value,
    });
  };

  useEffect(() => {
    if (tokenValid) evSearch();
  }, [evSearch, searchForm, tokenValid]);

  return (
    <>
      <Container fluid>
        <DetailHeader headerInfo={headerInfo} />

        <Card>
          <CardBody>
            <Row>
              <Col>
                <h4>검색</h4>
              </Col>
            </Row>
            <Row className="form-group align-items-center mb-3">
              <Col sm={6}>
                <Row>
                  <Col sm={3}>
                    <label className="col-sm-12 col-form-label bg-secondary rounded">
                      공급사
                    </label>
                  </Col>
                  <Col sm={9}>
                    <BizSelectList
                      onChange={evChangeSearch}
                      firstItem={{ innerText: '전체', value: '' }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col sm={6}>
                <Row>
                  <Col sm={3}>
                    <label className="col-sm-12 col-form-label bg-secondary rounded">
                      아이디
                    </label>
                  </Col>
                  <Col sm={9}>
                    <input
                      className="form-control"
                      type="text"
                      name="workerId"
                      onChange={evChangeSearch}
                      value={searchForm.workerId}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="form-group align-items-center justify-content-center button-items">
              <button
                type="button"
                onClick={evSearch}
                className="btn btn-primary waves-effect waves-light"
              >
                검색
              </button>
              <button
                type="button"
                onClick={() => setSearchForm(defaultSearchForm)}
                className="btn btn-info waves-effect waves-light"
              >
                초기화
              </button>
            </Row>
          </CardBody>
        </Card>
        <GridTable
          data={{ columns: cols, rows: apiData }}
          evClickBtn={() => {
            history.push('/worker/add');
          }}
        />
      </Container>
    </>
  );
};

export default WorkerList;
