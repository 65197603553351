import { useRef, useState } from 'react';
import { Card, CardBody, Col, Container, Row, Table } from 'reactstrap';
import xlsx from 'xlsx';
import DetailHeader from '../../components/Common/DetailHeader';
import itemAPI from '../../api/item';
import { formDL } from '../../util/excel';
import { getProductInfo } from '../../util/productInfomation';
import { bulkItemValid } from '../../util/validation';

const TO_LIST = '/item';
const headerInfo = {
  parentLabel: '상품 관리',
  parentLink: TO_LIST,
  current: '상품 일괄 등록',
};

const getGrossMargin = (priceSell, priceSupl) => {
  const sell = parseInt(priceSell, 10);
  const supl = parseInt(priceSupl, 10);
  if (!sell || !supl) {
    return '';
  }
  const GM = ((sell - supl) / sell) * 100;
  return Math.floor(GM);
};

const columns = [
  {
    kr: '상품명',
    desc: '[필수]텍스트로 최대 60자까지 작성 가능합니다',
    field: 'itemName',
  },
  {
    kr: '모델명',
    desc: '[필수]텍스트로 최대 60자까지 작성 가능합니다',
    field: 'infoModel',
  },
  {
    kr: '생산국(원산지)',
    desc: '[필수]텍스트로 최대 60자까지 작성 가능합니다',
    field: 'infoOrigin',
  },
  {
    kr: '카테고리번호',
    desc:
      '[필수]카테고리 관리 -> 카테고리 조회 페이지를 통해 소구분 카테고리의 id를 입력합니다.',
    field: 'catgryId',
  },
  {
    kr: '권장소비자가격',
    desc: '[필수]숫자만 입력가능합니다.',
    field: 'priceReal',
  },
  { kr: '공급가격', desc: '[필수]숫자만 입력가능합니다.', field: 'priceSupl' },
  { kr: '판매가격', desc: '[필수]숫자만 입력가능합니다.', field: 'priceSell' },
  { kr: '배송비', desc: '숫자만 입력가능합니다.', field: 'shipCost' },
  {
    kr: '판매상태',
    desc: '[필수]해당 번호를 입력합니다. \n판매중: 1, 품절: 0, 판매중단: 2',
    field: 'statusSell',
  },
  {
    kr: '전시상태',
    desc: '[필수]해당 번호를 입력합니다. \n 전시: 1, 미전시: 0',
    field: 'statusOpen',
  },
  {
    kr: '판매대상',
    desc:
      '[필수]통계를 위한 항목으로 해당하는 문자를 입력합니다. \n공용: both\n남성: male\n여성: female',
    field: 'gender',
  },
  {
    kr: '과세/면세/상품권',
    desc: '[필수]해당 번호를 입력합니다. \n과세: 1, 면세: 0, 상품권: 2',
    field: 'taxKind',
  },
  { kr: '재고', desc: '[필수]숫자만 입력가능합니다.', field: 'stock' },
  {
    kr: '필수정보고지 분류',
    desc:
      '[필수]해당 숫자를 입력합니다.\n해당 숫자는 상품등록 -> 필수정보고시 -> 상품 종류 참고\n각 항목의 내용은 "상세정보 별도표기"로 작성되며 추후 수정바랍니다.\n',
    field: 'infoProduct',
  },
  {
    kr: '상품상세정보',
    desc: 'HTML태그 또는 문자로 입력합니다.',
    field: 'infoDetail',
  },
  {
    kr: '대표이미지',
    desc:
      '[필수]상품의 대표이미지입니다.\n이미지 절대 경로를 입력합니다.\n권장사이즈는 500*500 입니다.',
    field: 'image1',
  },
  {
    kr: '썸네일2',
    desc:
      '상품의 두번째 썸네일이미지입니다.\n이미지 절대 경로를 입력합니다.\n권장사이즈는 500*500 입니다.',
    field: 'image2',
  },
  {
    kr: '썸네일3',
    desc:
      '상품의 세번째 썸네일이미지입니다.\n이미지 절대 경로를 입력합니다.\n권장사이즈는 500*500 입니다.',
    field: 'image3',
  },
  {
    kr: '썸네일4',
    desc:
      '상품의 네번째 썸네일이미지입니다.\n이미지 절대 경로를 입력합니다.\n권장사이즈는 500*500 입니다.',
    field: 'image4',
  },
  {
    kr: '썸네일5',
    desc:
      '상품의 다섯번째 썸네일이미지입니다.\n이미지 절대 경로를 입력합니다.\n권장사이즈는 500*500 입니다.',
    field: 'image5',
  },
];

// const cols = [
//   [
//     `catgryId`,
//     `itemName`,
//     `statusSell`,
//     `statusOpen`,
//     `statusAccept`,
//     `priceSupl`,
//     `priceReal`,
//     `priceSell`,
//     `infoModel`,
//     `infoOrigin`,
//     `infoDetail`,
//     `infoProduct`,
//     `shipCost`,
//     `shipCostMountain`,
//     `taxKind`,
//     `mileageRatio`,
//     `stock`,
//     `image1`,
//     `image2`,
//     `image3`,
//     `image4`,
//     `image5`,
//   ],
// ];

// const krCols = [
//   '상품명',
//   '모델명',
//   '생산국(원산지)',
//   '카테고리번호',
//   '권장소비자가',
//   '공급가',
//   '판매가',
//   '배송비',
//   '판매상태',
//   '전시상태',
//   '판매대상',
//   '과세/면세/상품권',
//   '재고',
//   '필수정보고지 분류',
//   '상품상세정보',
//   '대표이미지',
//   '썸네일2',
//   '썸네일3',
//   '썸네일4',
//   '썸네일5',
// ];

const initialState = {
  fileName: '없음',
  length: 0,
  list: undefined,
};
const fileName = '상품일괄등록';

function ItemExcel() {
  const [fileInfo, setFileInfo] = useState(initialState);
  const [isReady, setIsReady] = useState(false);
  const fileRef = useRef(null);
  // const authUser = JSON.parse(localStorage.getItem('authUser'));
  // const { wId } = authUser;
  const evFileChange = () => {
    if (fileRef.current.files.length !== 0) {
      setFileInfo(f => ({ ...f, fileName: fileRef.current.files[0].name }));

      const { files } = fileRef.current;
      const f = files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const data = new Uint8Array(e.target.result);
        const workbook = xlsx.read(data, { type: 'array' });
        const wbName = workbook.SheetNames[0];
        const ws = workbook.Sheets[wbName];
        columns.forEach((c, index) => {
          const cellAdd = xlsx.utils.encode_cell({ c: index, r: 0 });
          ws[cellAdd].w = c.field; // w가 속성명인듯 v는 몰루
        });
        // console.log(ws);
        const list = xlsx.utils.sheet_to_json(ws);
        // console.log(list);
        setFileInfo(info => ({
          ...info,
          length: list.length,
          list,
        }));
      };
      reader.readAsArrayBuffer(f);
      setIsReady(true);
    } else {
      setIsReady(false);
      setFileInfo(info => ({
        ...info,
        fileName: '없음',
      }));
    }
  };
  const setProductInfo = index => {
    const info = getProductInfo(index);
    const data = info?.data.reduce((acc, cur) => {
      return {
        ...acc,
        title: info.title,
        index: info.id,
        [cur.field]: '상세보기 별도표기',
      };
    }, {});
    return JSON.stringify(data);
  };
  const evSubmitBtn = async () => {
    const fail = [];
    const itemList = fileInfo.list.reduce((acc, cur, index) => {
      const margin = getGrossMargin(cur.priceSell, cur.priceSupl);
      if (!margin ?? margin < 15) {
        fail.push(index + 1);
      }
      acc.push({
        item: {
          ...cur,
          infoProduct: setProductInfo(cur.infoProduct),
          // workerId: wId,
          optionStock: cur.stock,
        },
        imgList: [cur.image1, cur.image2, cur.image3, cur.image4, cur.image5],
      });
      return acc;
    }, []);
    // if (fail.length > 0) return alert(`마진율 미달 ${fail}`);
    const validResult = bulkItemValid(fileInfo.list);
    if (validResult.length > 0) {
      alert(`작성오류: ${validResult}`);
      return;
    }
    const result = await itemAPI.excelInsert(itemList);
    if (result.resultCode && result.resultCode === 'fail') {
      alert(`카테고리오류 : ${result.list}`);
      return;
    }
    // console.log('result');
    // console.log(result);
    // console.log(fileInfo);
    alert('일괄등록성공');
  };

  const standardDL = () => {
    const cols = [columns.map(c => c.kr)];
    formDL(fileName, cols);
  };

  return (
    <Container fluid>
      <DetailHeader headerInfo={headerInfo} />
      <Card>
        <CardBody>
          <Row className="align-items-center my-3">
            <span
              style={{ fontSize: '1.2rem', fontWeight: 'bold' }}
              className="ml-3"
            >
              엑셀로 상품 일괄 등록
            </span>
            <button
              type="button"
              className="btn btn-primary btn-sm waves-effect waves-light mx-2"
              onClick={standardDL}
            >
              표준양식 다운로드
            </button>
          </Row>
          <p>
            엑셀 파일로 <strong style={{ fontWeight: 'bold' }}>등록</strong>할
            상품들을 정리하여 다량의 상품을 등록할 수 있습니다
            <br />
            표준 양식을 다운로드 받아 등록에 필요한 정보들을 입력해주시기
            바립니다.
          </p>
          <Row className="mb-2">
            <Col sm={12}>
              <Row className="align-items-center">
                <Col lg={2}>
                  <label className="col-lg-12 col-form-label bg-secondary rounded">
                    Excel 업로드
                  </label>
                </Col>
                <Col lg={9}>
                  <input
                    type="file"
                    tabIndex="-1"
                    id="excelUpload"
                    style={{ display: 'none' }}
                    ref={fileRef}
                    onChange={evFileChange}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                  <label
                    htmlFor="excelUpload"
                    className="btn btn-success btn-sm waves-effect waves-light col-form-label"
                  >
                    파일 첨부
                  </label>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col sm={12}>
              <Row className="align-items-center">
                <Col lg={2}>
                  <label className="col-lg-12 col-form-label bg-secondary rounded">
                    첨부된 파일명
                  </label>
                </Col>
                <Col lg={9}>
                  <span>{fileInfo.fileName}</span>
                  {isReady && (
                    <button
                      type="button"
                      className="btn btn-primary btn-sm waves-effect waves-light ml-3 col-form-label"
                      onClick={evSubmitBtn}
                    >
                      {fileInfo.length}건 등록 완료하기
                    </button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Table>
                <thead>
                  <th colSpan={2}>항목 설명</th>
                </thead>
                <tbody>
                  {columns.map(c => (
                    <tr key={c.kr}>
                      <th>{c.kr}</th>
                      <td>
                        <p>{c.desc}</p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
}

export default ItemExcel;
