import { useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

function UserInfoForm({ evChangeForm, form, onCheckAccount }) {
  const [isShown, setIsShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setIsShown(!isShown);
  };
  return (
    <Card>
      <CardBody>
        <Row className="mb-3">
          <Col>
            <h4
              style={{
                borderBottom: '1px solid #EBEBEB',
                paddingBottom: '0.5rem',
              }}
            >
              개인 정보
            </h4>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={3}>
                <label
                  htmlFor="account"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  아이디
                </label>
              </Col>
              <Col lg={9} className="input-group">
                <input
                  id="account"
                  name="account"
                  type="text"
                  className="form-control"
                  onChange={evChangeForm}
                />
                <button
                  className="input-group-text"
                  style={{
                    borderRadius: '0 0.25rem 0.25rem 0',
                    cursor: 'pointer',
                  }}
                  onClick={onCheckAccount}
                >
                  중복확인
                </button>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={3}>
                <label
                  htmlFor="password"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  비밀번호
                </label>
              </Col>
              <Col lg={9} className="input-group">
                <input
                  id="password"
                  name="password"
                  type={isShown ? 'text' : 'password'}
                  className="form-control"
                  onChange={evChangeForm}
                />
                <i
                  aria-hidden="true"
                  className={
                    isShown
                      ? 'mdi mdi-eye-off input-group-text'
                      : 'mdi mdi-eye input-group-text'
                  }
                  style={{ borderRadius: '0 0.25rem 0.25rem 0' }}
                  onClick={togglePasswordVisiblity}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Row>
              <Col lg={3}>
                <label
                  htmlFor="userName"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  성명
                </label>
              </Col>
              <Col lg={9}>
                <input
                  id="userName"
                  name="userName"
                  className="form-control"
                  onChange={evChangeForm}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row>
              <Col lg={3}>
                <label
                  htmlFor="birthday"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  생년월일
                </label>
              </Col>
              <Col lg={9}>
                <input
                  id="birthday"
                  name="birthday"
                  className="form-control"
                  onChange={evChangeForm}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Row>
              <Col lg={3}>
                <label
                  htmlFor="phone"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  핸드폰
                </label>
              </Col>
              <Col lg={9}>
                <input
                  id="phone"
                  name="phone"
                  className="form-control"
                  onChange={evChangeForm}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row>
              <Col lg={3}>
                <label
                  htmlFor="email"
                  className="col-lg-12 col-form-label bg-secondary rounded"
                >
                  이메일
                </label>
              </Col>
              <Col lg={9}>
                <input
                  id="email"
                  name="email"
                  className="form-control"
                  onChange={evChangeForm}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={3}>
                <label className="col-lg-12 col-form-label bg-secondary rounded">
                  성별
                </label>
              </Col>
              <Col lg={9}>
                <div className="form-check form-check-inline">
                  <input
                    id="genderM"
                    name="gender"
                    className="form-check-input"
                    type="radio"
                    value="M"
                    onChange={evChangeForm}
                    checked={form.gender === 'M'}
                  />
                  <label className="form-check-label" htmlFor="genderM">
                    남성
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    id="genderF"
                    name="gender"
                    className="form-check-input"
                    type="radio"
                    value="F"
                    onChange={evChangeForm}
                    checked={form.gender === 'F'}
                  />
                  <label className="form-check-label" htmlFor="genderF">
                    여성
                  </label>
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row className="align-items-center">
              <Col lg={3}>
                <label className="col-lg-12 col-form-label bg-secondary rounded">
                  승인 여부
                </label>
              </Col>
              <Col lg={9}>
                <div className="form-check form-check-inline">
                  <input
                    id="statusAccept1"
                    name="statusAccept"
                    className="form-check-input"
                    type="radio"
                    value="1"
                    onChange={evChangeForm}
                  />
                  <label className="form-check-label" htmlFor="statusAccept1">
                    승인
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    id="statusAccept0"
                    name="statusAccept"
                    className="form-check-input"
                    type="radio"
                    value="0"
                    onChange={evChangeForm}
                    checked={form.statusAccept === '0'}
                  />
                  <label className="form-check-label" htmlFor="statusAccept0">
                    미승인
                  </label>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default UserInfoForm;
