import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import bizManagerAPI from '../../api/bizManager';
import DetailHeader from '../../components/Common/DetailHeader';

const TO_LIST = '/biz-manager';

const header = {
  parentLabel: '담당자 관리',
  parentLink: '/biz-manager',
  current: '담당자 등록',
};

const initialState = {
  bizId: undefined,
  mgrName: '',
  mgrPhone: '',
  mgrGrade: '',
  mgrTeam: '',
  mgrEmail: '',
  mgrEnter: '',
};

function BizManagerEdit({ match, history }) {
  const { mgrId } = match.params;
  const [mgrData, setMgrData] = useState(initialState);
  const { tokenValid } = useSelector(state => ({
    tokenValid: state.Token.data,
  }));
  const evChangeForm = ({ target }) => {
    const { name, value } = target;
    setMgrData(mgr => ({
      ...mgr,
      [name]: value,
    }));
  };
  const evToList = () => history.push(TO_LIST);

  const evClickSave = async () => {
    if (mgrId) {
      await bizManagerAPI.update(mgrData);
    } else {
      await bizManagerAPI.insert(mgrData);
    }
    evToList();
  };

  const evClickDelete = async () => {
    await bizManagerAPI.delete(mgrId);
    evToList();
  };

  useEffect(() => {
    async function fetchData() {
      if (!mgrId) return;
      const result = await bizManagerAPI.getOne(mgrId);
      setMgrData(() => result);
    }
    if (tokenValid) fetchData();
  }, [mgrId, tokenValid]);

  return (
    <Container fluid>
      <DetailHeader headerInfo={header} />
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h4
                    style={{
                      borderBottom: '1px solid #EBEBEB',
                      paddingBottom: '0.5rem',
                    }}
                  >
                    기본 정보
                  </h4>
                </Col>
              </Row>
              {mgrId && (
                <Row className="form-group">
                  <Col sm={2}>
                    <label
                      htmlFor="mgr-id"
                      className="col-sm-12 col-form-label bg-secondary rounded"
                    >
                      번호
                    </label>
                  </Col>
                  <Col sm={4} className="d-flex align-items-center">
                    {mgrId}
                  </Col>
                </Row>
              )}
              <Row className="form-group">
                <Col sm={2}>
                  <label
                    htmlFor="biz-id"
                    className="col-sm-12 col-form-label bg-secondary rounded"
                  >
                    bizId
                  </label>
                </Col>
                <Col sm={4}>
                  <input
                    id="biz-id"
                    name="bizId"
                    className="form-control"
                    type="text"
                    onChange={evChangeForm}
                    value={mgrData.bizId}
                  />
                </Col>
              </Row>
              <Row className="form-group">
                <Col sm={2}>
                  <label
                    htmlFor="mgr-name"
                    className="col-sm-12 col-form-label bg-secondary rounded"
                  >
                    이름
                  </label>
                </Col>
                <Col sm={4}>
                  <input
                    id="mgr-name"
                    name="mgrName"
                    className="form-control"
                    type="text"
                    onChange={evChangeForm}
                    value={mgrData.mgrName}
                  />
                </Col>
              </Row>
              <Row className="form-group">
                <Col sm={2}>
                  <label
                    htmlFor="mgr-phone"
                    className="col-sm-12 col-form-label bg-secondary rounded"
                  >
                    휴대폰
                  </label>
                </Col>
                <Col sm={4}>
                  <input
                    id="mgr-phone"
                    name="mgrPhone"
                    className="form-control"
                    type="text"
                    onChange={evChangeForm}
                    value={mgrData.mgrPhone}
                  />
                </Col>
              </Row>
              <Row className="form-group">
                <Col sm={2}>
                  <label
                    htmlFor="mgr-grade"
                    className="col-sm-12 col-form-label bg-secondary rounded"
                  >
                    직급
                  </label>
                </Col>
                <Col sm={4}>
                  <input
                    id="mgr-grade"
                    name="mgrGrade"
                    className="form-control"
                    type="text"
                    onChange={evChangeForm}
                    value={mgrData.mgrGrade}
                  />
                </Col>
              </Row>
              <Row className="form-group">
                <Col sm={2}>
                  <label
                    htmlFor="mgr-team"
                    className="col-sm-12 col-form-label bg-secondary rounded"
                  >
                    부서
                  </label>
                </Col>
                <Col sm={4}>
                  <input
                    id="mgr-team"
                    name="mgrTeam"
                    className="form-control"
                    type="text"
                    onChange={evChangeForm}
                    value={mgrData.mgrTeam}
                  />
                </Col>
              </Row>
              <Row className="form-group">
                <Col sm={2}>
                  <label
                    htmlFor="mgr-email"
                    className="col-sm-12 col-form-label bg-secondary rounded"
                  >
                    이메일
                  </label>
                </Col>
                <Col sm={4}>
                  <input
                    id="mgr-email"
                    name="mgrEmail"
                    className="form-control"
                    type="text"
                    onChange={evChangeForm}
                    value={mgrData.mgrEmail}
                  />
                </Col>
              </Row>
              <Row className="form-group">
                <Col sm={2}>
                  <label
                    htmlFor="mgr-enter"
                    className="col-sm-12 col-form-label bg-secondary rounded"
                  >
                    입사일
                  </label>
                </Col>
                <Col sm={4}>
                  <input
                    id="mgr-enter"
                    name="mgrEnter"
                    className="form-control"
                    type="text"
                    onChange={evChangeForm}
                    value={mgrData.mgrEnter}
                  />
                </Col>
              </Row>

              <div className="form-group line align-items-center justify-content-center">
                <button
                  onClick={evToList}
                  className="btn btn-info waves-effect waves-light"
                >
                  목록으로
                </button>
                <button
                  onClick={evClickSave}
                  className="btn btn-primary waves-effect waves-light"
                >
                  저장
                </button>
                {mgrId && (
                  <button
                    onClick={evClickDelete}
                    className="btn btn-warning waves-effect waves-light"
                  >
                    삭제
                  </button>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default BizManagerEdit;
