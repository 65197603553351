import axios from './axios';
import { SETTLEMENT } from './urls';

export default {
  /**
   * @param {Object} params : Search conditions
   * @returns {Array}
   */
  async getList(params) {
    try {
      return await axios.get(SETTLEMENT.GET_LIST, { params });
    } catch (e) {
      return [];
    }
  },

  async getDetailList(params) {
    try {
      const response = await axios.get(SETTLEMENT.GET_DETAIL_LIST, { params });
      return response;
    } catch (e) {
      return [];
    }
  },

  async acceptSettlement(params) {
    try {
      const response = await axios.put(SETTLEMENT.ACCEPT_SETTLEMENT, params);
      return response;
    } catch (e) {
      return e;
    }
  },
};
