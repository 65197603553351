import { Card, CardBody, Row, Col } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DetailHeader from '../../components/Common/DetailHeader';
import PointKindSelect from '../../components/PointKind/PointKindSelect';
import pointSetAPI from '../../api/pointSet';
import pointKindAPI from '../../api/pointKind';

const headerInfo = {
  parentLabel: '회원 관리',
  parentLink: '/pointKind',
  current: '포인트 자동 설정',
};

const defaultPointSet = {
  pointSetId: '',
  setRegist: '',
  setRegistPct: '0',
  setBuy: '',
  setBuyPct: '0',
  setCgr: '',
  setCgrPct: '0',
  setReview: '',
  setReviewPct: '0',
};

const PointSetting = () => {
  const [pointKind, setPointKind] = useState({});
  const [pointSet, setPointSet] = useState(defaultPointSet);
  const { tokenValid } = useSelector(state => ({
    tokenValid: state.Token.data,
  }));

  const evChangePointSet = ({ target }) => {
    const { name, value } = target;
    setPointSet({
      ...pointSet,
      [name]: value,
    });
  };

  // todo - Korean alert messages
  const evSubmit = async () => {
    if (pointKind.pointSetId) {
      const result = await pointSetAPI.update(pointSet);
      if (result === 1) {
        return alert('success');
      }
    } else {
      let result = await pointSetAPI.insert(pointSet);
      if (result) {
        const params = { ...pointKind, pointSetId: result };
        result = await pointKindAPI.update(params);
        return alert('success');
      }
    }
    return alert('Something went wrong');
  };

  useEffect(() => {
    async function fetchData() {
      if (!pointKind.pointSetId) {
        setPointSet(defaultPointSet);
        return;
      }
      const result = await pointSetAPI.getOne(pointKind.pointSetId);
      if (result) {
        setPointSet({
          ...defaultPointSet,
          ...result,
        });
      }
    }
    if (tokenValid) fetchData();
  }, [pointKind.pointSetId, tokenValid]);

  return (
    <>
      <DetailHeader headerInfo={headerInfo} />
      <Card>
        <CardBody>
          <Row className="align-items-center mb-3">
            <Col>
              <h4>포인트 자동 설정</h4>
            </Col>
          </Row>
          <Row className="align-items-center mb-3">
            <Col>
              특정 상황에 포인트가 자동적으로 추가할 수 있도록 설정할 수
              있습니다.
            </Col>
          </Row>
          <Row className="align-items-center mb-3">
            <Col sm={2}>
              <label className="col-sm-12 col-form-label bg-secondary rounded">
                포인트 선택
              </label>
            </Col>
            <Col sm={4}>
              <PointKindSelect
                onChange={({ target }) => {
                  if (target.value) {
                    setPointKind(JSON.parse(target.value));
                  } else {
                    setPointKind({});
                  }
                }}
                firstOption={{ value: '', text: '포인트 선택' }}
                returnPointKind
              />
            </Col>
            <br />
          </Row>
          <Row className="align-items-center mb-3">
            <Col>
              <h4>특정 행동마다 부여하고자 할 때</h4>
            </Col>
          </Row>
          <Row className="align-items-center mb-3">
            <Col sm={2}>
              <label className="col-sm-12 col-form-label bg-secondary rounded">
                회원가입 시
              </label>
            </Col>
            <Col sm={4}>
              <input
                className="form-control"
                placeholder="원"
                name="setRegist"
                value={pointSet.setRegist ?? ''}
                onChange={evChangePointSet}
              />
            </Col>
          </Row>
          <Row className="align-items-center mb-3">
            <Col sm={2}>
              <label className="col-sm-12 col-form-label bg-secondary rounded">
                상품구매 시
              </label>
            </Col>
            <Col sm={1}>
              <select
                className="form-select col-sm-12 form-control"
                name="setBuyPct"
                onChange={evChangePointSet}
                value={pointSet.setBuyPct ?? ''}
              >
                <option value="0">원</option>
                <option value="1">%</option>
              </select>
            </Col>
            <Col sm={3}>
              <input
                className="form-control"
                name="setBuy"
                value={pointSet.setBuy ?? ''}
                onChange={evChangePointSet}
              />
            </Col>
          </Row>
          <Row className="align-items-center mb-3">
            <Col sm={2}>
              <label className="col-sm-12 col-form-label bg-secondary rounded">
                구매확정 시
              </label>
            </Col>
            <Col sm={1}>
              <select
                className="form-control"
                name="setCgrPct"
                value={pointSet.setCgrPct ?? ''}
                onChange={evChangePointSet}
              >
                <option value="0">원</option>
                <option value="1">%</option>
              </select>
            </Col>
            <Col sm={3}>
              <input
                className="form-control"
                name="setCgr"
                value={pointSet.setCgr ?? ''}
                onChange={evChangePointSet}
              />
            </Col>
          </Row>
          <Row className="align-items-center mb-3">
            <Col sm={2}>
              <label className="col-sm-12 col-form-label bg-secondary rounded">
                리뷰작성 시
              </label>
            </Col>
            <Col sm={1}>
              <select
                className="form-select col-sm-12 form-control"
                name="setReviewPct"
                value={pointSet.setReviewPct ?? ''}
                onChange={evChangePointSet}
              >
                <option value="0">원</option>
                <option value="1">%</option>
              </select>
            </Col>
            <Col sm={3}>
              <input
                className="form-control"
                name="setReview"
                value={pointSet.setReview ?? ''}
                onChange={evChangePointSet}
              />
            </Col>
          </Row>
          <Row className="form-group align-items-center justify-content-center button-items">
            <button
              type="button"
              onClick={evSubmit}
              className="btn btn-primary waves-effect waves-light"
            >
              설정하기
            </button>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};
export default PointSetting;
