import firebase from 'firebase/app';
// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';

import Auth from '../api/auth';
import { setAuthToken } from '../api/axios';
import { loginTime } from '../util/dateFormat';

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
      // firebase.auth().onAuthStateChanged(user => {
      //   if (user) {
      //     sessionStorage.setItem('authUser', JSON.stringify(user));
      //   } else {
      //     sessionStorage.removeItem('authUser');
      //   }
      // });
    }
  }

  /**
   * Registers the user with given details
   */
  registerUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser);
          },
          error => {
            reject(this._handleError(error));
          },
        );
    });
  };

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser);
          },
          error => {
            reject(this._handleError(error));
          },
        );
    });
  };

  /**
   * forget Password user with given details
   */
  forgetPassword = email => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url: `${window.location.protocol}//${window.location.host}/login`,
        })
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          reject(this._handleError(error));
        });
    });
  };

  loginWorker = (account, password) => {
    return new Promise((resolve, reject) => {
      Auth.login({ account, password }).then(
        response => {
          if (response == 'login fail') {
            reject(response);
          } else {
            sessionStorage.setItem('authUser', account);
            resolve({ account });
          }
        },
        error => {
          reject(this._handleError(error));
        },
      );
    });
  };

  logoutWorker = () => {
    return new Promise((resolve, reject) => {
      try {
        sessionStorage.removeItem('authUser');
        resolve(true);
      } catch {
        reject(false);
      }
    });
  };

  setLoggeedInUser = user => {
    sessionStorage.setItem('authUser', JSON.stringify(user));
  };

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!sessionStorage.getItem('authUser')) return null;
    return JSON.parse(sessionStorage.getItem('authUser'));
  };

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    const errorMessage = error.message;
    return errorMessage;
  }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = config => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebaseBackend };

export default {
  login: (account, password) => {
    return new Promise((resolve, reject) => {
      Auth.login({ account, password })
        .then(response => {
          if (response.fail) {
            reject(response.fail);
          } else {
            sessionStorage.setItem('authUser', account);
            setAuthToken(response.token);
            resolve({ account, role: response.role });
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });
  },
};
