/* eslint-disable */

import { Row, Col } from 'reactstrap';
import '../css/form-generator.css';

/**
 * Form Group Component
 * ------------------------------------------
 * This component props and elements are TBD.
 * We need to discuss and modify about this.
 * ------------------------------------------
 * @param {Object} formData : Model Data
 * @param {Event}  changeEvent : Event when input value is changed
 * @param {Array}  formRowList : Information to render form group
 *
 *  formRowList: [
 *    formRow: [
 *      element: {
 *          type: String - [ label, input(default), textarea, select, radio, TBD ]
 *          feild: String - model data key (ex: searchKind)
 *          placeHolder: String
 *          className: Array - [ string ]
 *          mdSize: Number - cols(default: 2)
 *          offset: Number - cols(default: 0)
 *          - optional -
 *            - Use it that type is range -
 *              optionList: Array - Use it that type is select
 *                > option: Object { label: String, value: String }
 *            - Use it that type is range -
 *              secondPlaceHolder: String
 *              secondFeild: String - model data key (ex: searchKind)
 *      }
 *    ]
 *  ]
 * @returns {FormGroup}
 */
const HeadenForm = ({ formData, changeEvent, formRowList }) => {
  const makeLabel = element => {
    const className = element.className ? element.className.join(' ') : '';

    return (
      <Col sm={{ size: element.mdSize ?? 2, offset: element.offset ?? 0 }}>
        <input
          className={`form-control form-control-label ${className}`}
          type="text"
          id={element.name ?? ''}
          name={element.name ?? ''}
          value={element.label}
          disabled
        />
      </Col>
    );

    // TODO CHECK :: Label style for Headen
    // return (
    //   <label htmlFor={element.forTarget ?? ''} className={element.className}>
    //     {element.text}
    //   </label>
    // );
  };

  const makeTextInput = element => {
    const className = element.className ? element.className.join(' ') : '';

    return (
      <Col md={{ size: element.mdSize ?? 10, offset: element.offset ?? 0 }}>
        <input
          className={`form-control ${className}`}
          type="text"
          id={element.feild}
          name={element.feild}
          width={element.width ?? '100%'}
          value={formData[element.feild]}
          onChange={changeEvent}
          autoComplete="off"
        />
      </Col>
    );
  };

  const makeTextForm = element => {
    const className = element.className ? element.className.join(' ') : '';

    return (
      <Col md={{ size: element.mdSize ?? 10, offset: element.offset ?? 0 }}>
        <span className={className} style={element.style ?? ''}>
          {element.text}
        </span>
      </Col>
    );
  };

  const makeSelect = element => {
    const className = element.className ? element.className.join(' ') : '';

    const optionTags = element.optionList.map(opt => {
      return (
        <option value={opt.value} key={opt.value}>
          {' '}
          {opt.label}{' '}
        </option>
      );
    });

    return (
      <Col md={{ size: element.mdSize ?? 10, offset: element.offset ?? 0 }}>
        <select
          className={`form-select form-control headen-select ${className}`}
          id={element.feild}
          name={element.feild}
          width={element.width ?? '100%'}
          value={formData[element.feild]}
          onChange={changeEvent}
        >
          {optionTags}
        </select>
      </Col>
    );
  };

  const makeTextarea = element => {
    const className = element.className ? element.className.join(' ') : '';

    return (
      <Col md={{ size: element.mdSize ?? 10, offset: element.offset ?? 0 }}>
        <textarea
          className={`form-control ${className}`}
          id={element.feild}
          name={element.feild}
          width={element.width ?? '100%'}
          value={formData[element.feild]}
          rows={element.rows ?? 5}
          onChange={changeEvent}
        />
      </Col>
    );
  };

  const makeRadioGroup = element => {
    const optionTags = element.optionList.map(opt => {
      const className = element.className ? element.className.join(' ') : '';

      return (
        <div
          className={`custom-control custom-radio custom-control-inline ${className}`}
        >
          <input
            className="custom-control-input"
            type="radio"
            id={opt.label}
            name={opt.label}
            value={opt.value}
            onChange={changeEvent}
          />
          <label htmlFor={opt.label} className="custom-control-label">
            {opt.label}
          </label>
        </div>
      );
    });

    return (
      <Col md={{ size: element.mdSize ?? 10, offset: element.offset ?? 0 }}>
        {optionTags}
      </Col>
    );
  };

  const makeRangeForm = element => {
    return (
      <Col md={{ size: element.mdSize ?? 4, offset: element.offset ?? 0 }}>
        <Row className="align-items-center">
          <Col sm={6}>
            <input
              className="form-control range-form"
              type="number"
              name={element.feild}
              value={formData[element.feild]}
              placeholder={element.placeHolder}
              onChange={changeEvent}
            />
          </Col>
          <Col sm={6} className="form-tilde">
            <input
              className="form-control range-form"
              type="number"
              name={element.secondFeild}
              value={formData[element.secondFeild]}
              placeholder={element.secondPlaceHolder}
              onChange={changeEvent}
            />
          </Col>
        </Row>
      </Col>
    );
  };

  const getElement = element => {
    switch (element.type) {
      case 'label':
        return makeLabel(element);

      case 'text':
        return makeTextForm(element);

      case 'select':
        return makeSelect(element);

      case 'textarea':
        return makeTextarea(element);

      case 'radio':
        return makeRadioGroup(element);

      case 'range':
        return makeRangeForm(element);

      default:
        return makeTextInput(element);
    }
  };

  /* From group rendering */
  const formElements = formRowList.map((formRow, index) => {
    const rowElements = formRow.map(element => {
      return getElement(element);
    });
    return (
      <Row className="form-group from-generator" key={index}>
        {' '}
        {rowElements}{' '}
      </Row>
    );
  });

  return <>{formElements}</>;
};

export default HeadenForm;
