import React from 'react';
import { Redirect } from 'react-router-dom';

// Pages Component

// Authentication related pages
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import Register from '../pages/Authentication/Register';
import ForgetPwd from '../pages/Authentication/FogetPassword';

import Dashboard from '../pages/Dashboard';

import Calendar from '../pages/Apps/Calendar/index';

import EmailInbox from '../pages/Apps/Email/inbox';
import EmailRead from '../pages/Apps/Email/read';
import EmailCompose from '../pages/Apps/Email/compose';

import Emailtemplatealert from '../pages/Apps/EmailTemplate/email-template-alert';
import Emailtemplatebasic from '../pages/Apps/EmailTemplate/email-template-basic';
import Emailtemplatebilling from '../pages/Apps/EmailTemplate/email-template-billing';

// UI Pages
import UiSweetAlert from '../pages/UI/UiSweetAlert';
import UiAlerts from '../pages/UI/Alerts';
import UiButtons from '../pages/UI/Buttons';
import UiCards from '../pages/UI/Cards';
import UiCarousel from '../pages/UI/Carousel';
import UiDropdown from '../pages/UI/Dropdown';
import UiGrid from '../pages/UI/Grid';
import UiImages from '../pages/UI/Images';
import UiLightbox from '../pages/UI/Lightbox';
import UiModals from '../pages/UI/Modals';
import UiRangeSlider from '../pages/UI/RangeSlider';
import UiSessionTimeout from '../pages/UI/SessionTimeout';
import Progressbar from '../pages/UI/Progressbar';
import TabsAccordion from '../pages/UI/TabsAccordion';
import Typography from '../pages/UI/Typography';
import General from '../pages/UI/General';
import Colors from '../pages/UI/Colors';
import Rating from '../pages/UI/Rating';

// Form
import FormElement from '../pages/Forms/FormElement';
import FormValidation from '../pages/Forms/FormValidation';
import FormAdvanced from '../pages/Forms/FormAdvanced';
import FormMask from '../pages/Forms/FormMask';
import FormUpload from '../pages/Forms/FormUpload';
import FormXeditable from '../pages/Forms/FormXeditable';
import FormEditor from '../pages/Forms/FormEditor';

// Charts
import ChartistChart from '../pages/Charts/ChartistChart';
import ChartjsChart from '../pages/Charts/ChartjsChart';
import ApexChart from '../pages/Charts/ApexChart';
import SarklineChart from '../pages/Charts/SarklineChart';

// Tables
import TableDatatable from '../pages/Tables/TableDatatable';
import TableResponsive from '../pages/Tables/TableResponsive';
import TableEditable from '../pages/Tables/TableEditable';

// Maps
import IconDripicons from '../pages/Icons/IconDripicons';
import IconFontAwesome from '../pages/Icons/IconFontAwesome';
import IconIon from '../pages/Icons/IconIon';
import IconMaterial from '../pages/Icons/IconMaterial';
import IconThemify from '../pages/Icons/IconThemify';
import IconTypicons from '../pages/Icons/IconTypicons';

// Maps
import MapsVector from '../pages/Maps/MapsVector';

// Extra Pages
import PagesInvoice from '../pages/ExtraPages/PagesInvoice';
import PagesDirectory from '../pages/ExtraPages/PagesDirectory';
import PagesBlank from '../pages/ExtraPages/PagesBlank';
import PagesPricing from '../pages/ExtraPages/PagesPricing';
import PagesGallery from '../pages/ExtraPages/PagesGallery';
import PagesFaq from '../pages/ExtraPages/PagesFaq';
import Pages404 from '../pages/ExtraPages/Pages404';
import Pages500 from '../pages/ExtraPages/Pages500';
import PagesMaintenance from '../pages/ExtraPages/PagesMaintenance';
import PagesComingsoon from '../pages/ExtraPages/PagesComingsoon';

/* develop by headen */
import UserList from '../pages/Users/ListPage';
import UserEdit from '../pages/Users/EditPage';
import UserExcel from '../pages/Users/ExcelPage';

import CodeList from '../pages/Code/ListPage';
import CodeEdit from '../pages/Code/EditPage';

import BrandList from '../pages/Brand/ListPage';
import BrandEdit from '../pages/Brand/EditPage';

import CategoryList from '../pages/Category/ListPage';
import CategoryEdit from '../pages/Category/EditPage';

import TagList from '../pages/Tag/ListPage';
import TagEdit from '../pages/Tag/EditPage';
import TagExcel from '../pages/Tag/ExcelPage';

import TextBanList from '../pages/TextBan/ListPage';
import TextBanEdit from '../pages/TextBan/EditPage';

import BizManagerEdit from '../pages/BizManager/EditPage';
import BizManagerList from '../pages/BizManager/ListPage';
import SettlementList from '../pages/Settlement/ListPage';

import BizList from '../pages/Biz/ListPage';
import BizEdit from '../pages/Biz/EditPage';
import BizApprove from '../pages/Biz/ApprovePage';
import WorkerList from '../pages/Worker/WorkerList';
import WorkerEdit from '../pages/Worker/EditPage';

import PointList from '../pages/Point/ListPage';
import PointBulkInsert from '../pages/Point/BulkInsert';

import PointKindList from '../pages/PointKind/ListPage';
import PointKindEdit from '../pages/PointKind/EditPage';
import PointSetting from '../pages/PointKind/Setting';

import ItemEdit from '../pages/Item/EditPage';
import ItemList from '../pages/Item/ListPage';
import ItemExcel from '../pages/Item/ExcelPage';
import OrderEdit from '../pages/Order/EditPage';
import OrderList from '../pages/Order/ListPage';

// Worker Login
import WorkerLogin from '../pages/Authentication/Login_worker';
// import Dashboard from '../pages/Dashboard';
import SettlementDetail from '../pages/Settlement/DetailPage';

const supplierRoutes = [
  // Settlement
  {
    path: '/settlement/detail',
    component: SettlementDetail,
    title: '정산 상세 정보',
  },
  { path: '/settlement', component: SettlementList, title: '정산 조회' },
  // Order
  { path: '/order/status/:code', component: OrderList, title: '주문 조회' },
  { path: '/order/:orderId', component: OrderEdit, title: '주문 정보' },
  { path: '/order', component: OrderList, title: '주문 목록' },

  // Item
  { path: '/item/add', component: ItemEdit, title: '상품 등록' },
  { path: '/item/excel', component: ItemExcel, title: '상품 일괄 등록' },
  { path: '/item/accept', component: ItemList, title: '승인 대기 상품' },
  { path: '/item/:itemId', component: ItemEdit, title: '상품 정보' },
  { path: '/item', component: ItemList, title: '상품 목록' },

  { path: '/', component: Dashboard, title: '대쉬보드' },

  { path: '/', exact: true, component: () => <Redirect to="/" /> },
];

const masterRoutes = [
  // Order
  { path: '/order/status/:code', component: OrderList, title: '주문' },
  { path: '/order/:orderId', component: OrderEdit, title: '주문' },
  { path: '/order', component: OrderList, title: '주문' },

  // Item
  { path: '/item/excel', component: ItemExcel, title: '상품 일괄 등록' },
  { path: '/item/add', component: ItemEdit, title: '상품 등록' },
  { path: '/item/accept', component: ItemList, title: '상품 승인 대기' },
  { path: '/item/:itemId', component: ItemEdit, title: '상품 정보' },
  { path: '/item', component: ItemList, title: '상품 목록' },

  // Biz-manager
  { path: '/biz-manager/add', component: BizManagerEdit, title: '담당자 추가' },
  {
    path: '/biz-manager/:mgrId',
    component: BizManagerEdit,
    title: '담당자 정보',
  },
  { path: '/biz-manager', component: BizManagerList, title: '담당자 목록' },

  // Text-ban
  { path: '/text-ban/add', component: TextBanEdit },
  { path: '/text-ban/:banId', component: TextBanEdit },
  { path: '/text-ban', component: TextBanList },

  // Code
  { path: '/code/add', component: CodeEdit },
  { path: '/code/:codeId', component: CodeEdit },
  { path: '/code', component: CodeList },

  // Brand
  { path: '/brand/add', component: BrandEdit },
  { path: '/brand/:brandId', component: BrandEdit },
  { path: '/brand', component: BrandList },

  // User
  { path: '/user/add', component: UserEdit, title: '회원 추가' },
  { path: '/user/excel', component: UserExcel, title: '회원 일괄 등록' },
  { path: '/user/:userId', component: UserEdit, title: '회원 정보' },
  { path: '/user', component: UserList, title: '회원 목록' },

  // Category
  { path: '/category/add', component: CategoryEdit, title: '카테고리 등록' },
  {
    path: '/category/:catgryId',
    component: CategoryEdit,
    title: '카테고리 정보',
  },
  { path: '/category', component: CategoryList, title: '카테고리 목록' },

  // Tag
  { path: '/tag/excel', component: TagExcel },
  { path: '/tag/add', component: TagEdit },
  { path: '/tag/:tagId', component: TagEdit },
  { path: '/tag', component: TagList },

  // Settlement
  {
    path: '/settlement/detail',
    component: SettlementDetail,
    title: '정산 정보',
  },
  { path: '/settlement', component: SettlementList, title: '정산 조회' },

  // Biz
  { path: '/biz/add', component: BizEdit, title: '공급사 등록' },
  { path: '/biz/approve', component: BizApprove, title: '승인 대기 공급사' },
  { path: '/biz/:bizId', component: BizEdit, title: '공급사 등록' },
  { path: '/biz', component: BizList, title: '공급사 조회' },

  // Worker
  { path: '/worker/add', component: WorkerEdit, title: '관리자 등록' },
  { path: '/worker/:bizId', component: WorkerEdit, title: '관리자 정보' },
  { path: '/worker', component: WorkerList, title: '관리자 조회' },

  // Point
  {
    path: '/point/bulkInsert',
    component: PointBulkInsert,
    title: '회원 포인트 일괄 등록',
  },
  {
    path: '/point/setting',
    component: PointSetting,
    title: '포인트 자동 설정',
  },
  { path: '/point', component: PointList, title: '회원 포인트 조회 및 추가' },

  // PointKind
  { path: '/pointKind/add', component: PointKindEdit, title: '포인트 등록' },
  {
    path: '/pointKind/:pointKindId',
    component: PointKindEdit,
    title: '포인트 정보',
  },
  { path: '/pointKind', component: PointKindList, title: '포인트 조회' },

  // // Calendar
  // { path: '/calendar', component: Calendar },
  // // Email Template
  // { path: '/email-inbox', component: EmailInbox },
  // { path: '/email-read', component: EmailRead },
  // { path: '/email-compose', component: EmailCompose },
  // { path: '/email-template-alert', component: Emailtemplatealert },
  // { path: '/email-template-basic', component: Emailtemplatebasic },
  // { path: '/email-template-billing', component: Emailtemplatebilling },

  // // Ui Pages
  // { path: '/ui-sweet-alert', component: UiSweetAlert },
  // { path: '/ui-alerts', component: UiAlerts },
  // { path: '/ui-buttons', component: UiButtons },
  // { path: '/ui-cards', component: UiCards },
  // { path: '/ui-carousel', component: UiCarousel },
  // { path: '/ui-dropdowns', component: UiDropdown },
  // { path: '/ui-grid', component: UiGrid },
  // { path: '/ui-images', component: UiImages },
  // { path: '/ui-lightbox', component: UiLightbox },
  // { path: '/ui-modals', component: UiModals },
  // { path: '/ui-rangeslider', component: UiRangeSlider },
  // { path: '/ui-session-timeout', component: UiSessionTimeout },
  // { path: '/ui-progressbars', component: Progressbar },
  // { path: '/ui-tabs-accordions', component: TabsAccordion },
  // { path: '/ui-typography', component: Typography },
  // { path: '/ui-general', component: General },
  // { path: '/ui-colors', component: Colors },
  // { path: '/ui-rating', component: Rating },

  // // Form
  // { path: '/form-elements', component: FormElement },
  // { path: '/form-validation', component: FormValidation },
  // { path: '/form-advanced', component: FormAdvanced },
  // { path: '/form-mask', component: FormMask },
  // { path: '/form-uploads', component: FormUpload },
  // { path: '/form-xeditable', component: FormXeditable },
  // { path: '/form-editors', component: FormEditor },

  // // Charts
  // { path: '/charts-chartist', component: ChartistChart },
  // { path: '/charts-chartjs', component: ChartjsChart },
  // { path: '/apex-chart', component: ApexChart },
  // { path: '/charts-sparkline', component: SarklineChart },

  // // Tables
  // { path: '/tables-datatable', component: TableDatatable },
  // { path: '/tables-responsive', component: TableResponsive },
  // { path: '/tables-editable', component: TableEditable },

  // // Icons
  // { path: '/icons-dripicons', component: IconDripicons },
  // { path: '/icons-fontawesome', component: IconFontAwesome },
  // { path: '/icons-ion', component: IconIon },
  // { path: '/icons-material', component: IconMaterial },
  // { path: '/icons-themify', component: IconThemify },
  // { path: '/icons-typicons', component: IconTypicons },

  // Maps
  // { path: '/maps-vector', component: MapsVector },

  // Extra Pages
  // { path: '/pages-invoice', component: PagesInvoice },
  // { path: '/pages-directory', component: PagesDirectory },
  // { path: '/pages-blank', component: PagesBlank },
  // { path: '/pages-pricing', component: PagesPricing },
  // { path: '/pages-gallery', component: PagesGallery },
  // { path: '/pages-faq', component: PagesFaq },

  { path: '/', component: Dashboard, title: '대쉬보드' },

  { path: '/', exact: true, component: () => <Redirect to="/" /> },
];

const publicRoutes = [
  // { path: '/logout', component: Logout },
  // { path: '/login/worker', component: WorkerLogin },
  // { path: '/login', component: Login },
  { path: '/login', component: WorkerLogin, title: '로그인' },
  // { path: '/forget-password', component: ForgetPwd },
  { path: '/register', component: Register, title: '등록 요청' },
  { path: '/pages-404', component: Pages404 },
  { path: '/pages-500', component: Pages500 },
  { path: '/pages-Maintenance', component: PagesMaintenance },
  { path: '/pages-comingsoon', component: PagesComingsoon },

  { path: '/', exact: true, component: () => <Redirect to="/login" /> },
];

export { masterRoutes, publicRoutes, supplierRoutes };
