import axios from "./axios";
import { POINT_SET } from "./urls";

export default {
  async getOne(id) {
    try {
      return await axios.get(POINT_SET.GET_OR_DELETE + id);
    } catch (e) {
      return e;
    }
  },

  async insert(params) {
    try {
      return await axios.post(POINT_SET.UPSERT, params);
    } catch (e) {
      return e;
    }
  },

  async update(params) {
    try {
      return await axios.put(POINT_SET.UPSERT, params);
    } catch (e) {
      return e;
    }
  },
};
