export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const API_ERROR = 'LOGIN_API_ERROR';

export const LOGIN_WORKER = 'LOGIN_WORKER';
export const LOGOUT_WORKER = 'LOGOUT_WORKER';
export const LOGIN_WORKER_SUCCESS = 'LOGIN_WORKER_SUCCESS';
export const LOGOUT_WORKER_SUCCESS = 'LOGOUT_WORKER_SUCCESS';
