export const infoList = [
  {
    id: 1,
    title: '의류',
    data: [
      {
        field: '제품소재',
        id: 1,
        order: 1,
        tip:
          '(섬유의 조성 또는 혼용률을 백분율로 표시, 기능성인 경우 성적서 또는 허가서)',
      },
      {
        field: '색상',
        id: 17,
        order: 2,
      },
      {
        field: '치수',
        id: 33,
        order: 3,
      },
      {
        field: '제조자 또는 수입자',
        id: 38,
        order: 4,
        tip: '(병행수입의 경우 병행수입 여부로 대체 가능)',
      },
      {
        field: '제조국',
        id: 48,
        order: 5,
      },
      {
        field: '세탁방법 및 취급시 주의사항',
        id: 72,
        order: 6,
      },
      {
        field: '제조연월',
        id: 83,
        order: 7,
      },
      {
        field: '품질보증기준',
        id: 84,
        order: 8,
      },
      {
        field: 'A/S 책임자와 전화번호',
        id: 71,
        order: 9,
      },
    ],
  },
  {
    id: 2,
    title: '구두/신발',
    data: [
      {
        field: '제품소재',
        id: 1,
        order: 1,
        tip: '(운동화인 경우에는 겉감, 안감을 구분하여 표시)',
      },
      {
        field: '색상',
        id: 17,
        order: 2,
      },
      {
        field: '치수',
        id: 33,
        order: 3,
        tip:
          '발길이: 해외사이즈 표기시 국내사이즈 병행 표기(mm) 굽높이 (굽 재료를 사용하는 여성화에 한함, cm)',
      },
      {
        field: '제조자 또는 수입자',
        id: 38,
        order: 4,
        tip: '(병행수입의 경우 병행수입 여부로 대체 가능)',
      },
      {
        field: '제조국',
        id: 48,
        order: 5,
      },
      {
        field: '취급시 주의사항',
        id: 73,
        order: 6,
      },
      {
        field: '품질보증기준',
        id: 84,
        order: 7,
      },
      {
        field: 'A/S 책임자와 전화번호',
        id: 71,
        order: 8,
      },
    ],
  },
  {
    id: 3,
    title: '가방',
    data: [
      {
        field: '종류',
        id: 0,
        order: 1,
      },
      {
        field: '소재',
        id: 16,
        order: 2,
      },
      {
        field: '색상',
        id: 17,
        order: 3,
      },
      {
        field: '크기',
        id: 14,
        order: 4,
      },
      {
        field: '제조자 또는 수입자',
        id: 38,
        order: 5,
        tip: '(병행수입의 경우 병행수입 여부로 대체 가능)',
      },
      {
        field: '제조국',
        id: 48,
        order: 6,
      },
      {
        field: '취급시 주의사항',
        id: 73,
        order: 7,
      },
      {
        field: '품질보증기준',
        id: 84,
        order: 8,
      },
      {
        field: 'A/S 책임자와 전화번호',
        id: 71,
        order: 9,
      },
    ],
  },
  {
    id: 4,
    title: '패션잡화(모자/벨트/액세서리)',
    data: [
      {
        field: '종류',
        id: 0,
        order: 1,
      },
      {
        field: '소재',
        id: 16,
        order: 2,
      },
      {
        field: '치수',
        id: 33,
        order: 3,
      },
      {
        field: '제조자 또는 수입자',
        id: 38,
        order: 4,
        tip: '(병행수입의 경우 병행수입 여부로 대체 가능)',
      },
      {
        field: '제조국',
        id: 48,
        order: 5,
      },
      {
        field: '취급시 주의사항',
        id: 73,
        order: 6,
      },
      {
        field: '품질보증기준',
        id: 84,
        order: 7,
      },
      {
        field: 'A/S 책임자와 전화번호',
        id: 71,
        order: 8,
      },
    ],
  },
  {
    id: 5,
    title: '침구류/커튼',
    data: [
      {
        field: '제품소재',
        id: 1,
        order: 1,
        tip:
          '(섬유의 조성 또는 혼용률을 백분율로 표시) (충전재를 사용한 제품은 충전재를 함께 표기)',
      },
      {
        field: '색상',
        id: 17,
        order: 2,
      },
      {
        field: '치수',
        id: 33,
        order: 3,
      },
      {
        field: '제품 구성',
        id: 49,
        order: 4,
      },
      {
        field: '제조자 또는 수입자',
        id: 38,
        order: 5,
        tip: '(병행수입의 경우 병행수입 여부로 대체 가능)',
      },
      {
        field: '제조국',
        id: 48,
        order: 6,
      },
      {
        field: '세탁방법 및 취급시 주의사항',
        id: 72,
        order: 7,
      },
      {
        field: '품질보증기준',
        id: 84,
        order: 8,
      },
      {
        field: 'A/S 책임자와 전화번호',
        id: 71,
        order: 9,
      },
    ],
  },
  {
    id: 6,
    title: '가구(침대/소파/싱크대/DIY제품)',
    data: [
      {
        field: '품명 및 모델명',
        id: 2,
        order: 1,
      },
      {
        field: 'KC인증 필 여부',
        id: 19,
        order: 2,
        tip:
          '(품질경영 및 공산품안전관리법 상 안전·품질표시대상공산품에 한함) 01: 국가통합인증(KC마크) 02: 공상품 안전검사 03: 안정인증대상 공상품 04: 자율안전 확인 대상 광산품 05: 전기용품 안정인증 06: 의료기기제조 품목허가',
      },
      {
        field: '색상',
        id: 17,
        order: 3,
      },
      {
        field: '구성품',
        id: 36,
        order: 4,
      },
      {
        field: '주요소재',
        id: 61,
        order: 5,
      },
      {
        field: '제조자 또는 수입자',
        id: 38,
        order: 6,
        tip: '(병행수입의 경우 병행수입 여부로 대체 가능)',
      },
      {
        field: '제조국',
        id: 48,
        order: 7,
      },
      {
        field: '크기',
        id: 14,
        order: 8,
      },
      {
        field: '배송/설치비용',
        id: 102,
        order: 9,
      },
      {
        field: '품질보증기준',
        id: 84,
        order: 10,
      },
      {
        field: 'A/S 책임자와 전화번호',
        id: 71,
        order: 11,
      },
    ],
  },
  {
    id: 7,
    title: '영상가전(TV류)',
    data: [
      {
        field: '품명 및 모델명',
        id: 2,
        order: 1,
      },
      {
        field: 'KC인증 필 여부',
        id: 19,
        order: 2,
        tip:
          '(전기용품 안전관리법 상 안전인증대상전기용품, 자율안전확인대상전기용품, 공급자적합성확인대상전기용품에 한함)',
      },
      {
        field: '정격전압, 소비전력, 에너지소비효율등급',
        id: 34,
        order: 3,
        tip: '(에너지이용합리화법 상 의무대상상품에 한함)',
      },
      {
        field: '동일모델의 출시년월일',
        id: 18,
        order: 4,
      },
      {
        field: '제조자 또는 수입자',
        id: 38,
        order: 5,
        tip: '(병행수입의 경우 병행수입 여부로 대체 가능)',
      },
      {
        field: '제조국',
        id: 48,
        order: 6,
      },
      {
        field: '크기',
        id: 14,
        order: 7,
      },
      {
        field: '화면사양',
        id: 93,
        order: 8,
        tip: '(크기, 해상도, 화면비율 등)',
      },
      {
        field: '품질보증기준',
        id: 84,
        order: 9,
      },
    ],
  },
  {
    id: 8,
    title: '가정용 전기제품(냉장고/세탁기/식기세척기/전자레인지)',
    data: [
      {
        field: '품명 및 모델명',
        id: 2,
        order: 1,
      },
      {
        field: 'KC인증 필 여부',
        id: 19,
        order: 2,
        tip:
          '(전기용품 안전관리법 상 안전인증대상전기용품, 자율안전확인대상전기용품, 공급자적합성확인대상전기용품에 한함)',
      },
      {
        field: '정격전압, 소비전력, 에너지소비효율등급',
        id: 34,
        order: 3,
        tip: '(에너지이용합리화법 상 의무대상상품에 한함)',
      },
      {
        field: '동일모델의 출시년월일',
        id: 18,
        order: 4,
      },
      {
        field: '제조자 또는 수입자',
        id: 38,
        order: 5,
        tip: '(병행수입의 경우 병행수입 여부로 대체 가능)',
      },
      {
        field: '제조국',
        id: 48,
        order: 6,
      },
      {
        field: '크기',
        id: 14,
        order: 7,
        tip: '(용량, 형태 포함)',
      },
      {
        field: '품질보증기준',
        id: 84,
        order: 8,
      },
      {
        field: 'A/S 책임자와 전화번호',
        id: 71,
        order: 9,
      },
    ],
  },
  {
    id: 9,
    title: '계절가전(에어컨/온풍기)',
    data: [
      {
        field: '품명 및 모델명',
        id: 2,
        order: 1,
      },
      {
        field: 'KC인증 필 여부',
        id: 19,
        order: 2,
        tip:
          '(전기용품 안전관리법 상 안전인증대상전기용품, 자율안전확인대상전기용품, 공급자적합성확인대상전기용품에 한함)',
      },
      {
        field: '정격전압, 소비전력, 에너지소비효율등급',
        id: 34,
        order: 3,
        tip: '(에너지이용합리화법 상 의무대상상품에 한함)',
      },
      {
        field: '동일모델의 출시년월일',
        id: 18,
        order: 4,
      },
      {
        field: '제조자 또는 수입자',
        id: 38,
        order: 5,
        tip: '(병행수입의 경우 병행수입 여부로 대체 가능)',
      },
      {
        field: '제조국',
        id: 48,
        order: 6,
      },
      {
        field: '크기',
        id: 14,
        order: 7,
      },
      {
        field: '냉난방 면적',
        id: 94,
        order: 8,
      },
      {
        field: '추가설치비용',
        id: 103,
        order: 9,
      },
      {
        field: '품질보증기준',
        id: 84,
        order: 10,
      },
      {
        field: 'A/S 책임자와 전화번호',
        id: 71,
        order: 11,
      },
    ],
  },
  {
    id: 10,
    title: '사무용기기(컴퓨터/노트북/프린터)',
    data: [
      {
        field: '품명 및 모델명',
        id: 2,
        order: 1,
      },
      {
        field: 'KC인증 필 여부',
        id: 19,
        order: 2,
        tip:
          '(전기용품 안전관리법 상 안전인증대상전기용품, 자율안전확인대상전기용품, 공급자적합성확인대상전기용품에 한함)',
      },
      {
        field: '정격전압, 소비전력, 에너지소비효율등급',
        id: 34,
        order: 3,
      },
      {
        field: '동일모델의 출시년월일',
        id: 18,
        order: 4,
      },
      {
        field: '제조자 또는 수입자',
        id: 38,
        order: 5,
        tip: '(병행수입의 경우 병행수입 여부로 대체 가능)',
      },
      {
        field: '제조국',
        id: 48,
        order: 6,
      },
      {
        field: '크기, 무게',
        id: 74,
        order: 7,
        tip: '(무게는 노트북에 한함)',
      },
      {
        field: '제품 주요사양',
        id: 22,
        order: 8,
        tip:
          '(컴퓨터와 노트북의 경우 성능, 용량, 운영체제포함여부 등 / 프린터의 경우 인쇄 속도 등)',
      },
      {
        field: '품질보증기준',
        id: 84,
        order: 9,
      },
      {
        field: 'A/S 책임자와 전화번호',
        id: 71,
        order: 10,
      },
    ],
  },
  {
    id: 11,
    title: '광학기기(디지털카메라/캠코더)',
    data: [
      {
        field: '품명 및 모델명',
        id: 2,
        order: 1,
      },
      {
        field: 'KC인증 필 여부',
        id: 19,
        order: 2,
        tip:
          '(전기용품 안전관리법 상 안전인증대상전기용품, 자율안전확인대상전기용품, 공급자적합성확인대상전기용품에 한함)',
      },
      {
        field: '동일모델의 출시년월일',
        id: 18,
        order: 3,
      },
      {
        field: '제조자 또는 수입자',
        id: 38,
        order: 4,
        tip: '(병행수입의 경우 병행수입 여부로 대체 가능)',
      },
      {
        field: '제조국',
        id: 48,
        order: 5,
      },
      {
        field: '크기, 무게',
        id: 74,
        order: 6,
      },
      {
        field: '제품 주요사양',
        id: 22,
        order: 7,
      },
      {
        field: '품질보증기준',
        id: 84,
        order: 8,
      },
      {
        field: 'A/S 책임자와 전화번호',
        id: 71,
        order: 9,
      },
    ],
  },
  {
    id: 12,
    title: '소형전자(MP3/전자사전 등)',
    data: [
      {
        field: '품명 및 모델명',
        id: 2,
        order: 1,
      },
      {
        field: 'KC인증 필 여부',
        id: 19,
        order: 2,
        tip:
          '(전파법상 인증대상상품에 한함,MIC 인증필 혼용가능 01: 국가 통합인증(KC마크) 02: 공산품 안전검사 03: 안정인증대상 공산품 04: 자율안전 확인 대상 공산품 05: 전기용품 안전인증 06: 의료기기제조 품목허가',
      },
      {
        field: '정격전압, 소비전력',
        id: 35,
        order: 3,
      },
      {
        field: '동일모델의 출시년월일',
        id: 18,
        order: 4,
      },
      {
        field: '제조자 또는 수입자',
        id: 38,
        order: 5,
        tip: '(병행수입의 경우 병행수입 여부로 대체 가능)',
      },
      {
        field: '제조국',
        id: 48,
        order: 6,
      },
      {
        field: '크기, 무게',
        id: 74,
        order: 7,
      },
      {
        field: '제품 주요사양',
        id: 22,
        order: 8,
      },
      {
        field: '품질보증기준',
        id: 84,
        order: 9,
      },
      {
        field: 'A/S 책임자와 전화번호',
        id: 71,
        order: 10,
      },
    ],
  },
  {
    id: 13,
    title: '휴대폰',
    data: [
      {
        field: '품명 및 모델명',
        id: 2,
        order: 1,
      },
      {
        field: 'KC인증 필 여부',
        id: 19,
        order: 2,
        tip:
          '(전기용품 안전관리법 상 안전인증대상전기용품, 자율안전확인대상전기용품, 공급자적합성확인대상전기용품에 한함)',
      },
      {
        field: '동일모델의 출시년월일',
        id: 18,
        order: 3,
      },
      {
        field: '제조자 또는 수입자',
        id: 38,
        order: 4,
        tip: '(병행수입의 경우 병행수입 여부로 대체 가능)',
      },
      {
        field: '제조국',
        id: 48,
        order: 5,
      },
      {
        field: '크기, 무게',
        id: 74,
        order: 6,
      },
      {
        field: '이동통신사 및 가입조건/절차/추가부담 사항',
        id: 85,
        order: 7,
      },
      {
        field: '제품 주요사양',
        id: 22,
        order: 8,
      },
      {
        field: '품질보증기준',
        id: 84,
        order: 9,
      },
      {
        field: 'A/S 책임자와 전화번호',
        id: 71,
        order: 10,
      },
    ],
  },
  {
    id: 14,
    title: '내비게이션',
    data: [
      {
        field: '품명 및 모델명',
        id: 2,
        order: 1,
      },
      {
        field: 'KC인증 필 여부',
        id: 19,
        order: 2,
        tip:
          '(전기용품 안전관리법 상 안전인증대상전기용품, 자율안전확인대상전기용품, 공급자적합성확인대상전기용품에 한함)',
      },
      {
        field: '정격전압, 소비전력',
        id: 35,
        order: 3,
      },
      {
        field: '동일모델의 출시년월일',
        id: 18,
        order: 4,
      },
      {
        field: '제조자 또는 수입자',
        id: 38,
        order: 5,
        tip: '(병행수입의 경우 병행수입 여부로 대체 가능)',
      },
      {
        field: '제조국',
        id: 48,
        order: 6,
      },
      {
        field: '크기, 무게',
        id: 74,
        order: 7,
      },
      {
        field: '제품 주요사양',
        id: 22,
        order: 8,
      },
      {
        field: '맵업데이트 비용 및 무상기간',
        id: 104,
        order: 9,
      },
      {
        field: '품질보증기준',
        id: 84,
        order: 10,
      },
      {
        field: 'A/S 책임자와 전화번호',
        id: 71,
        order: 11,
      },
    ],
  },
  {
    id: 15,
    title: '자동차용품(자동차부품/기타 자동차용품)',
    data: [
      {
        field: '품명 및 모델명',
        id: 2,
        order: 1,
      },
      {
        field: '동일모델의 출시년월일',
        id: 18,
        order: 2,
      },
      {
        field: 'KC인증 필 여부',
        id: 19,
        order: 3,
        tip: '(자동차 관리법에 따른 자기인증 대상 자동차부품에 한함)',
      },
      {
        field: '제조자 또는 수입자',
        id: 38,
        order: 4,
        tip: '(병행수입의 경우 병행수입 여부로 대체 가능)',
      },
      {
        field: '제조국',
        id: 48,
        order: 5,
      },
      {
        field: '크기',
        id: 14,
        order: 6,
      },
      {
        field: '적용차종',
        id: 86,
        order: 7,
      },
      {
        field: '제품사용으로 인한 위험 및 유의사항',
        id: 95,
        order: 8,
        tip: '(연료절감장치에 한함)',
      },
      {
        field: '품질보증기준',
        id: 84,
        order: 9,
      },
      {
        field: '첨가제 촉매제 검사합격증 번호 표시',
        id: 111,
        order: 10,
        tip: '(대기환경보전법에 따른 첨가제/촉매제에 한함)',
      },
      {
        field: 'A/S 책임자와 전화번호',
        id: 71,
        order: 11,
      },
    ],
  },
  {
    id: 16,
    title: '의료기기',
    data: [
      {
        field: '품명 및 모델명',
        id: 2,
        order: 1,
      },
      {
        field: '허가신고번호 및 사전심의번호',
        id: 20,
        order: 2,
      },
      {
        field: '정격전압, 소비전력',
        id: 35,
        order: 3,
        tip: '(전기용품에 한함)',
      },
      {
        field: '동일모델의 출시년월일',
        id: 18,
        order: 4,
      },
      {
        field: '제조자 또는 수입자',
        id: 38,
        order: 5,
        tip: '(병행수입의 경우 병행수입 여부로 대체 가능)',
      },
      {
        field: '제조국',
        id: 48,
        order: 6,
      },
      {
        field: '제품의 사용목적 및 사용 방법',
        id: 87,
        order: 7,
      },
      {
        field: '취급시 주의사항',
        id: 73,
        order: 8,
      },
      {
        field: '품질보증기준',
        id: 84,
        order: 9,
      },
      {
        field: 'A/S 책임자와 전화번호',
        id: 71,
        order: 10,
      },
    ],
  },
  {
    id: 17,
    title: '주방용품',
    data: [
      {
        field: '품명 및 모델명',
        id: 2,
        order: 1,
      },
      {
        field: '재질',
        id: 21,
        order: 2,
      },
      {
        field: '구성품',
        id: 36,
        order: 3,
      },
      {
        field: '크기',
        id: 14,
        order: 4,
      },
      {
        field: '동일모델의 출시년월일',
        id: 18,
        order: 5,
      },
      {
        field: '제조자 또는 수입자',
        id: 38,
        order: 6,
        tip: '(병행수입의 경우 병행수입 여부로 대체 가능)',
      },
      {
        field: '제조국',
        id: 48,
        order: 7,
      },
      {
        field: '수입식품안전관리특별법 관련 수입신고 여부',
        id: 96,
        order: 8,
        tip: '“수입식품안전관리특별법에 따른 수입신고를 필함”의 문구',
      },
      {
        field: '품질보증기준',
        id: 84,
        order: 9,
      },
      {
        field: 'A/S 책임자와 전화번호',
        id: 71,
        order: 10,
      },
    ],
  },
  {
    id: 18,
    title: '화장품',
    data: [
      {
        field: '용량 또는 중량',
        id: 3,
        order: 1,
      },
      {
        field: '제품 주요사양',
        id: 22,
        order: 2,
        tip: '(피부타입, 색상(호, 번) 등)',
      },
      {
        field: '사용기한 또는 개봉후 사용기간',
        id: 37,
        order: 3,
        tip:
          '개봉후 사용기간을 기재할 경우에제조연월일 병행표기 예) 배송일 기준 최소 1개월 이상 남은 상품으로 발송 예)배송 상품은 2012년 1월 생산된 상품으로 발송 예)1번 상품은 제조일 2012년 1월 1-1번 구성상품은 제조일 2011년 12월 ->여러가지 상품으로 구성시 모두표기',
      },
      {
        field: '사용방법',
        id: 50,
        order: 4,
      },
      {
        field: '제조자 또는 판매자',
        id: 62,
        order: 5,
      },
      {
        field: '제조국',
        id: 48,
        order: 6,
      },
      {
        field: '주요성분',
        id: 88,
        order: 7,
        tip: '(유기농 화장품의 경우 유기농 원료 함량 포함)',
      },
      {
        field:
          '기능성 화장품의 경우 화장품법에 따른 식품의약품안전처 심사 필 유무',
        id: 97,
        order: 8,
        tip: '(미백, 주름개선, 자외선차단 등)',
      },
      {
        field: '사용시 주의사항',
        id: 105,
        order: 9,
      },
      {
        field: '품질보증기준',
        id: 84,
        order: 10,
      },
      {
        field: 'A/S 책임자와 전화번호',
        id: 71,
        order: 11,
      },
    ],
  },
  {
    id: 19,
    title: '귀금속/보석/시계류',
    data: [
      {
        field: '소재/순도/ 밴드재질(시계)',
        id: 4,
        order: 1,
      },
      {
        field: '중량',
        id: 23,
        order: 2,
      },
      {
        field: '제조자 또는 수입자',
        id: 38,
        order: 3,
        tip: '(병행수입의 경우 병행수입 여부로 대체 가능)',
      },
      {
        field: '치수',
        id: 33,
        order: 5,
      },
      {
        field: '착용시 주의사항',
        id: 75,
        order: 6,
      },
      {
        field: '제품 주요사양',
        id: 22,
        order: 7,
        tip: '(귀금속,보석류-등급/시계-기능,방수)',
      },
      {
        field: '보증서 제공여부',
        id: 98,
        order: 8,
      },
      {
        field: '품질보증기준',
        id: 84,
        order: 9,
      },
      {
        field: 'A/S 책임자와 전화번호',
        id: 71,
        order: 10,
      },
    ],
  },
  {
    id: 20,
    title: '식품(농수산물)',
    data: [
      {
        field: '품목 또는 명칭',
        id: 5,
        order: 1,
      },
      {
        field: '포장단위별 내용물의 용량(중량), 수량, 크기',
        id: 24,
        order: 2,
      },
      {
        field: '생산자 및 수입여부',
        id: 39,
        order: 3,
        tip: '(수입상품시 수입자 또는 수입업체 표기)',
      },
      {
        field: '농수산물 원산지 표시 법률에 따른 원산지',
        id: 51,
        order: 4,
      },
      {
        field: '제조연월일(포장일 또는 생산연도), 유통기한',
        id: 63,
        order: 5,
      },
      {
        field: '관련법상 표시사항',
        id: 76,
        order: 6,
        tip:
          '농산물 - 농수산물품질관리법상 유전자변형농산물 표시, 지리적 표시 축산물 - 축산법에 따른 등급 표시, 쇠고기의 경우 이력 관리에 따른 표시 유무 수산물 - 농수산물품질관리법상 유전자변형수산물 표시, 지리적 표시',
      },
      {
        field: '상품구성',
        id: 89,
        order: 7,
      },
      {
        field: '보관방법 또는 취급방법',
        id: 99,
        order: 8,
      },
      {
        field: '소비자안전을 위한 주의사항',
        id: 106,
        order: 9,
        tip: '(식품 등의 표시 광고에 관한 법률)',
      },
      {
        field: '수입식품안전관리특별법 관련 수입신고 여부',
        id: 96,
        order: 10,
        tip: '“수입식품안전관리특별법에 따른 수입신고를 필함”의 문구',
      },
      {
        field: '소비자 상담 전화번호',
        id: 70,
        order: 11,
      },
    ],
  },
  {
    id: 21,
    title: '가공식품',
    data: [
      {
        field: '제품명',
        id: 6,
        order: 1,
      },
      {
        field: '식품의 유형',
        id: 25,
        order: 2,
        tip: '식품 위생법에 따라 표시할것(제품에 표시정보 참고)',
      },
      {
        field: '생산자 및 소재지',
        id: 68,
        order: 3,
        tip: '(수입상품시 수입자 또는 수입업체 표기)',
      },
      {
        field: '제조연월일/유통기한 또는 품질유지기한',
        id: 52,
        order: 4,
        tip:
          '예) 배송일 기준 최소 1개월 이상 남은 상품으로 발송 예) 배송 상품은 2012년 1월 생산된 상품으로 발송 예) 1번상품은 제조일 2012년 1월 1-1번 구성상품은 제조일 2011년 12월 -> 여러가지 상품으로 구성시 모두 표기',
      },
      {
        field: '포장단위별 내용물의 용량(중량), 수량, 크기',
        id: 24,
        order: 5,
      },
      {
        field: '원재료명 및 함량',
        id: 77,
        order: 6,
        tip: '(농수산물의 원산지 표시에 관한 법률에따른 원산지 표시 포함)',
      },
      {
        field: '영양성분',
        id: 90,
        order: 7,
        tip: '(식품위생법에 따른 영양성분 표시대상식품에 한함)',
      },
      {
        field: '유전자재조합식품에 해당하는 경우의 표시',
        id: 100,
        order: 8,
      },
      {
        field: '소비자안전을 위한 주의사항',
        id: 106,
        order: 9,
      },
      {
        field: '수입식품안전관리특별법 관련 수입신고 여부',
        id: 96,
        order: 10,
        tip: '“수입식품안전관리특별법에 따른 수입신고를 필함”의 문구',
      },
      {
        field: '소비자 상담 전화번호',
        id: 70,
        order: 11,
      },
    ],
  },
  {
    id: 22,
    title: '건강기능식품',
    data: [
      {
        field: '제품명',
        id: 6,
        order: 1,
      },
      {
        field: '식품의 유형',
        id: 25,
        order: 2,
        tip: '식품 위생법에 따라 표시할것(제품에 표시정보 참고)',
      },
      {
        field: '생산자 및 소재지',
        id: 68,
        order: 3,
        tip: '(수입상품시 수입자 또는 수입업체 표기)',
      },
      {
        field: '제조연월일/유통기한 또는 품질유지기한',
        id: 52,
        order: 4,
      },
      {
        field: '포장단위별 내용물의 용량(중량), 수량, 크기',
        id: 24,
        order: 5,
      },
      {
        field: '원재료명 및 함량',
        id: 77,
        order: 6,
        tip: '(농수산물의 원산지 표시에 관한 법률에따른 원산지 표시 포함)',
      },
      {
        field: '영양성분',
        id: 90,
        order: 7,
      },
      {
        field: '기능정보',
        id: 101,
        order: 8,
      },
      {
        field: '섭취량, 섭취방법 및 섭취 시 주의사항 및 부작용 가능성',
        id: 107,
        order: 9,
      },
      {
        field: '의약품 아님 표시',
        id: 108,
        order: 10,
        tip: '질병의 예방 및 치료를 위한 의약품이 아님',
      },
      {
        field: '유전자재조합식품에 해당하는 경우의 표시',
        id: 100,
        order: 11,
      },
      {
        field: '수입식품안전관리특별법 관련 수입신고 여부',
        id: 96,
        order: 12,
        tip: '“수입식품안전관리특별법에 따른 수입신고를 필함”의 문구',
      },
      {
        field: '소비자안전을 위한 주의사항',
        id: 106,
        order: 13,
      },
      {
        field: '소비자 상담 전화번호',
        id: 70,
        order: 14,
      },
    ],
  },
  {
    id: 23,
    title: '영유아용품',
    data: [
      {
        field: '품명 및 모델명',
        id: 2,
        order: 1,
      },
      {
        field: 'KC인증 필 여부',
        id: 19,
        order: 2,
        tip:
          '(어린이제품 안전 특별법 상 안전인증대상어린이제품, 안전확인대상어린이제품, 공금자적합성확인대상어린이제품에 대한 KC인증)',
      },
      {
        field: '크기, 중량',
        id: 15,
        order: 3,
      },
      {
        field: '색상',
        id: 17,
        order: 4,
        tip: '대표되는 색상 표시 또는 이미지 참고로 표시',
      },
      {
        field: '재질',
        id: 21,
        order: 5,
        tip: '(섬유의 경우 혼용률)',
      },
      {
        field: '사용연령 또는 체중범위',
        id: 78,
        order: 6,
        tip:
          '(품질경영 및 공산품안전관리법에 따라 표시해야 하는 사항은 반드시 표기)',
      },
      {
        field: '동일모델의 출시년월일',
        id: 18,
        order: 7,
      },
      {
        field: '제조자 또는 수입자',
        id: 38,
        order: 8,
        tip: '(병행수입의 경우 병행수입 여부로 대체 가능)',
      },
      {
        field: '제조국',
        id: 48,
        order: 9,
      },
      {
        field: '취급방법 및 취급시 주의사항, 안전표시',
        id: 112,
        order: 10,
        tip: '(주의, 경고 등)',
      },
      {
        field: 'A/S 책임자와 전화번호',
        id: 71,
        order: 12,
      },
    ],
  },
  {
    id: 24,
    title: '악기',
    data: [
      {
        field: '품명 및 모델명',
        id: 2,
        order: 1,
      },
      {
        field: '크기',
        id: 14,
        order: 2,
      },
      {
        field: '색상',
        id: 17,
        order: 3,
      },
      {
        field: '재질',
        id: 21,
        order: 4,
      },
      {
        field: '제품 구성',
        id: 49,
        order: 5,
      },
      {
        field: '동일모델의 출시년월일',
        id: 18,
        order: 6,
      },
      {
        field: '제조자 또는 수입자',
        id: 38,
        order: 7,
        tip: '(병행수입의 경우 병행수입 여부로 대체 가능)',
      },
      {
        field: '제조국',
        id: 48,
        order: 8,
      },
      {
        field: '상품별 세부사양',
        id: 109,
        order: 9,
      },
      {
        field: '품질보증기준',
        id: 84,
        order: 10,
      },
      {
        field: 'A/S 책임자와 전화번호',
        id: 71,
        order: 11,
      },
    ],
  },
  {
    id: 25,
    title: '스포츠용품',
    data: [
      {
        field: '품명 및 모델명',
        id: 2,
        order: 1,
      },
      {
        field: '크기, 중량',
        id: 15,
        order: 2,
      },
      {
        field: '색상',
        id: 17,
        order: 3,
      },
      {
        field: '재질',
        id: 21,
        order: 4,
      },
      {
        field: '제품 구성',
        id: 49,
        order: 5,
      },
      {
        field: '동일모델의 출시년월일',
        id: 18,
        order: 6,
      },
      {
        field: '제조자 또는 수입자',
        id: 38,
        order: 7,
        tip: '(병행수입의 경우 병행수입 여부로 대체 가능)',
      },
      {
        field: '제조국',
        id: 48,
        order: 8,
      },
      {
        field: '상품별 세부사양',
        id: 109,
        order: 9,
      },
      {
        field: '품질보증기준',
        id: 84,
        order: 10,
      },
      {
        field: 'A/S 책임자와 전화번호',
        id: 71,
        order: 11,
      },
    ],
  },
  {
    id: 26,
    title: '서적',
    data: [
      {
        field: '도서명',
        id: 7,
        order: 1,
      },
      {
        field: '저자, 출판사',
        id: 26,
        order: 2,
      },
      {
        field: '크기',
        id: 14,
        order: 3,
        tip: '(전자책의 경우 파일의 용량)',
      },
      {
        field: '쪽수',
        id: 53,
        order: 4,
        tip: '(전자책의 경우 제외)',
      },
      {
        field: '제품 구성',
        id: 49,
        order: 5,
        tip: '(전집 또는 세트일 경우 낱권 구성, CD 등)',
      },
      {
        field: '출간일',
        id: 79,
        order: 6,
      },
      {
        field: '목차 또는 책소개',
        id: 91,
        order: 7,
        tip: '(아동용 학습교재의 경우 사용연령을 포함)',
      },
    ],
  },
  {
    id: 27,
    title: '호텔/펜션 예약',
    data: [
      {
        field: '국가 또는 지역명',
        id: 8,
        order: 1,
      },
      {
        field: '숙소형태',
        id: 27,
        order: 2,
      },
      {
        field: '등급, 객실타입',
        id: 40,
        order: 3,
      },
      {
        field: '사용가능 인원',
        id: 54,
        order: 4,
      },
      {
        field: '부대시설',
        id: 64,
        order: 5,
      },
      {
        field: '취소 규정',
        id: 80,
        order: 6,
      },
      {
        field: '예약담당 연락처',
        id: 92,
        order: 7,
      },
    ],
  },
  {
    id: 28,
    title: '여행패키지',
    data: [
      {
        field: '여행사',
        id: 9,
        order: 1,
      },
      {
        field: '이용교통편',
        id: 28,
        order: 2,
      },
      {
        field: '여행기간 및 일정',
        id: 41,
        order: 3,
      },
      {
        field: '제한사항',
        id: 42,
        order: 3,
        tip: '(출발일, 귀국일 변경가능 여부 등)',
      },
      {
        field: '총 예정 인원',
        id: 55,
        order: 4,
      },
      {
        field: '숙박정보',
        id: 65,
        order: 5,
      },
      {
        field: '포함 내역',
        id: 81,
        order: 6,
        tip:
          '(식사, 인솔자, 공연관람, 관광지 입장료, 유류할증료, 공항이용료, 관련 세금 및 공과금 등)',
      },
      {
        field: '취소 규정',
        id: 80,
        order: 7,
      },
      {
        field: '추가 경비 항목과 금액',
        id: 82,
        order: 7,
        tip:
          '(유류할증료가 가격에 포함되지 않은 경우 그 금액과 변동가능성, 선택관광, 안내원 봉사료 등)',
      },
      {
        field: '여행경보단계',
        id: 110,
        order: 9,
      },
    ],
  },
  {
    id: 29,
    title: '항공권',
    data: [
      {
        field: '요금조건, 왕복·편도 여부',
        id: 10,
        order: 1,
      },
      {
        field: '유효기간',
        id: 29,
        order: 2,
      },
      {
        field: '제한사항',
        id: 42,
        order: 3,
        tip: '(출발일, 귀국일 변경가능 여부 등)',
      },
      {
        field: '티켓수령방법',
        id: 56,
        order: 4,
      },
      {
        field: '좌석종류',
        id: 66,
        order: 5,
      },
      {
        field: '추가 경비 항목과 금액',
        id: 82,
        order: 6,
        tip: '(유류할증료, 공항이용료 등)',
      },
      {
        field: '취소 규정',
        id: 80,
        order: 7,
        tip: '(환불, 위약금 등)',
      },
      {
        field: '예약담당 연락처',
        id: 92,
        order: 8,
      },
    ],
  },
  {
    id: 30,
    title: '자동차 대여 서비스(렌터카)',
    data: [
      {
        field: '차종',
        id: 11,
        order: 1,
      },
      {
        field: '소유권 이전 조건',
        id: 30,
        order: 2,
        tip: '(소유권이 이전되는 경우에 한함)',
      },
      {
        field: '추가 선택시 비용',
        id: 43,
        order: 3,
        tip: '(자차면책제도, 내비게이션 등)',
      },
      {
        field: '차량 반환 시 연료대금 정산 방법',
        id: 57,
        order: 4,
      },
      {
        field: '차량의 고장·훼손 시 소비자 책임',
        id: 67,
        order: 5,
      },
      {
        field: '중도 해약 시 환불 기준',
        id: 58,
        order: 6,
      },
      {
        field: '소비자 상담 전화번호',
        id: 70,
        order: 7,
      },
    ],
  },
  {
    id: 31,
    title: '물품대여 서비스(정수기/비데/공기청정기 등)',
    data: [
      {
        field: '품명 및 모델명',
        id: 2,
        order: 1,
      },
      {
        field: '소유권 이전 조건',
        id: 30,
        order: 2,
        tip: '(소유권이 이전되는 경우에 한함)',
      },
      {
        field: '유지보수 조건',
        id: 44,
        order: 3,
        tip: '(점검·필터교환 주기, 추가 비용 등)',
      },
      {
        field: '상품의 고장·분실·훼손 시 소비자 책임',
        id: 45,
        order: 3,
      },
      {
        field: '중도 해약 시 환불 기준',
        id: 58,
        order: 5,
      },
      {
        field: '제품 구성',
        id: 49,
        order: 6,
        tip: '(용량, 소비전력 등)',
      },
      {
        field: '소비자 상담 전화번호',
        id: 70,
        order: 7,
      },
    ],
  },
  {
    id: 32,
    title: '물품대여 서비스(서적/유아용품/행사용품 등)',
    data: [
      {
        field: '품명 및 모델명',
        id: 2,
        order: 1,
      },
      {
        field: '소유권 이전 조건',
        id: 30,
        order: 2,
        tip: '(소유권이 이전되는 경우에 한함)',
      },
      {
        field: '상품의 고장·분실·훼손 시 소비자 책임',
        id: 45,
        order: 3,
      },
      {
        field: '중도 해약 시 환불 기준',
        id: 58,
        order: 4,
      },
      {
        field: '소비자 상담 전화번호',
        id: 70,
        order: 5,
      },
    ],
  },
  {
    id: 33,
    title: '디지털 콘텐츠(음원/게임/인터넷 강의 등)',
    data: [
      {
        field: '제작자 또는 공급자',
        id: 12,
        order: 1,
      },
      {
        field: '이용조건, 이용기간',
        id: 31,
        order: 2,
      },
      {
        field: '상품제공 방식',
        id: 46,
        order: 3,
        tip: '(CD, 다운로드, 실시간 스트리밍 등)',
      },
      {
        field: '최소 시스템 사양, 필수 소프트웨어',
        id: 59,
        order: 4,
      },
      {
        field: '청약철회 또는 계약의 해제에 따른 효과',
        id: 69,
        order: 5,
      },
      {
        field: '소비자 상담 전화번호',
        id: 70,
        order: 6,
      },
    ],
  },
  {
    id: 34,
    title: '상품권/쿠폰',
    data: [
      {
        field: '발행자',
        id: 13,
        order: 1,
      },
      {
        field: '유효기간',
        id: 29,
        order: 2,
        tip: '(유효기간 경과 시 보상 기준,사용제한품목 및 기간 등)',
      },
      {
        field: '이용가능 매장',
        id: 47,
        order: 3,
      },
      {
        field: '잔액 환급조건',
        id: 60,
        order: 4,
      },
      {
        field: '소비자 상담 전화번호',
        id: 70,
        order: 5,
      },
      {
        field: '소비자 상담 전화번호',
        id: 70,
        order: 5,
      },
    ],
  },
  {
    id: 35,
    title: '기타',
    data: [
      {
        field: '품명 및 모델명',
        id: 2,
        order: 1,
      },
      {
        field: '인증, 허가 관련 정보',
        id: 32,
        order: 2,
      },
      {
        field: '제조국',
        id: 48,
        order: 3,
      },
      {
        field: '제조자 또는 수입자',
        id: 38,
        order: 4,
      },
      {
        field: 'A/S 책임자와 전화번호',
        id: 71,
        order: 5,
      },
    ],
  },
];

export const getProductInfo = id => {
  return infoList.find(info => info.id === parseInt(id, 10));
};
