import { Card, CardBody, Col, Row } from 'reactstrap';

function AdminMemo({ evChangeMemo, memo }) {
  return (
    <Card>
      <CardBody>
        <Row className="mb-3">
          <Col>
            <h4
              style={{
                borderBottom: '1px solid #EBEBEB',
                paddingBottom: '0.5rem',
              }}
            >
              관리자 메모
            </h4>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <textarea
              rows="5"
              className="form-control"
              name="memo"
              onChange={evChangeMemo}
              value={memo || ''}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default AdminMemo;
