import { Card, CardBody, Col, Row } from 'reactstrap';

function ItemPriceInfoForm({ evChangeForm, form }) {
  const {
    priceReal,
    priceSupl,
    priceSell,
    mileageRatio,
    shipCost,
    shipCostMountain,
    taxKind,
    grossMargin,
  } = form;

  return (
    <Card>
      <CardBody>
        <Row className="mb-3">
          <Col>
            <h4
              style={{
                borderBottom: '1px solid #EBEBEB',
                paddingBottom: '0.5rem',
              }}
            >
              가격 정보
            </h4>
          </Col>
        </Row>
        <Row className="align-items-center mb-3">
          <Col sm={6}>
            <Row>
              <Col sm={4}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  권장소비자가격
                </label>
              </Col>
              <Col sm={8}>
                <input
                  className="form-control"
                  type="text"
                  name="priceReal"
                  value={priceReal}
                  placeholder="원"
                  onChange={evChangeForm}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-center mb-3">
          <Col sm={6}>
            <Row>
              <Col sm={4}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  공급가격
                </label>
              </Col>
              <Col sm={8}>
                <input
                  className="form-control"
                  type="text"
                  name="priceSupl"
                  value={priceSupl}
                  placeholder="원"
                  onChange={evChangeForm}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-center mb-3">
          <Col sm={6}>
            <Row>
              <Col sm={4}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  판매가격
                </label>
              </Col>
              <Col sm={8}>
                <input
                  className="form-control"
                  type="text"
                  name="priceSell"
                  value={priceSell}
                  placeholder="원"
                  onChange={evChangeForm}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {/* <Row className="align-items-center mb-3">
          <Col sm={6}>
            <Row>
              <Col sm={4}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  적립금(%)
                </label>
              </Col>
              <Col sm={8}>
                <input
                  className="form-control"
                  type="text"
                  name="mileageRatio"
                  value={mileageRatio}
                  placeholder="%"
                  onChange={evChangeForm}
                />
              </Col>
            </Row>
          </Col>
        </Row> */}
        <Row className="align-items-center mb-3">
          <Col sm={6}>
            <Row>
              <Col sm={4}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  배송비
                </label>
              </Col>
              <Col sm={8}>
                <input
                  className="form-control"
                  type="text"
                  name="shipCost"
                  value={shipCost}
                  placeholder="원"
                  onChange={evChangeForm}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-center mb-3">
          <Col sm={6}>
            <Row>
              <Col sm={4}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  배송비 : 도서산간지역 배송비
                </label>
              </Col>
              <Col sm={8}>
                <input
                  className="form-control"
                  type="text"
                  name="shipCostMountain"
                  value={shipCostMountain}
                  placeholder="원"
                  onChange={evChangeForm}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-center mb-3">
          <Col sm={6}>
            <Row className="align-items-center">
              <Col sm={4}>
                <label className="col-lg-12 col-form-label bg-secondary rounded">
                  과세 / 비과세 / 상품권
                </label>
              </Col>
              <Col sm={8}>
                <div className="form-check form-check-inline">
                  <input
                    id="taxKind1"
                    name="taxKind"
                    className="form-check-input"
                    type="radio"
                    value="1"
                    onChange={evChangeForm}
                    checked={taxKind === '1'}
                  />
                  <label className="form-check-label" htmlFor="taxKind1">
                    과세
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    id="taxKind0"
                    name="taxKind"
                    className="form-check-input"
                    type="radio"
                    value="0"
                    onChange={evChangeForm}
                    checked={taxKind === '0'}
                  />
                  <label className="form-check-label" htmlFor="taxKind0">
                    비과세
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    id="taxKind2"
                    name="taxKind"
                    className="form-check-input"
                    type="radio"
                    value="2"
                    onChange={evChangeForm}
                    checked={taxKind === '2'}
                  />
                  <label className="form-check-label" htmlFor="taxKind2">
                    상품권
                  </label>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-center mb-3">
          <Col sm={6}>
            <Row>
              <Col sm={4}>
                <label className="col-sm-12 col-form-label bg-secondary rounded">
                  매익률
                </label>
              </Col>
              <Col sm={8}>
                <input
                  className="form-control"
                  type="text"
                  name="grossMargin"
                  value={`${grossMargin || ''}%`}
                  onChange={evChangeForm}
                  readOnly
                />
              </Col>
            </Row>
          </Col>
          {grossMargin < 15 && (
            <Col>
              <span className="text-danger">마진율을 15% 이상 맞춰주세요</span>
            </Col>
          )}
        </Row>
      </CardBody>
    </Card>
  );
}

export default ItemPriceInfoForm;
